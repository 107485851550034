/* eslint-disable @typescript-eslint/restrict-template-expressions */

// import Button from "@mui/material/Button";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Unstable_Grid2";
// import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  Card,
  Box
  // OutlinedInput,
  // FormControl,
  // MenuItem,
  // Select,
  // SelectChangeEvent
  // CardContent, Typography
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudCarbon from "../../components/Svg/CloudCarbon";
import "./index.scss";
import Dollar from "../../components/Svg/Dollar";

import CarbonInsightChart from "./CarbonInsightChart";
import OverviewCard from "../../components/OverviewCard/OverviewCard";
import Tree from "../../components/Svg/Tree";
import Bulb from "../../components/Svg/Bulb";
// import { useAllPlatformData } from "../../api/useAllPlatformData";
import { useOverviewData } from "../../api/useOverviewData";
import useCeConversion from "../../utils/useCeConversion";
import useCeConversionFixed from "../../utils/useCeConversionFixed";
// import { useServerOverviewData } from "../../api/useServerOverviewData";

export const Summary = ({
  selectedPlatform,
  setSelectedPlatform
}: {
  selectedPlatform: string;
  setSelectedPlatform: React.Dispatch<React.SetStateAction<string>>;
}) => {
  // let SummaryData: any;
  // let isError: boolean;
  // let isLoading: boolean;
  // if (selectedPlatform === "all") {
  //   const {
  //     data: Data,
  //     isError: isAllPlatformError,
  //     isLoading: isAllPlatformLoading
  //   } = useAllPlatformData();
  //   SummaryData = Data;
  //   isError = isAllPlatformError;
  //   isLoading = isAllPlatformLoading;
  // } else if (selectedPlatform === "client") {
  const { data: Data, isError: isClientError } = useOverviewData();
  const SummaryData = Data;
  const isError = isClientError;
  // } else {
  //   const {
  //     data: Data,
  //     isError: isServerError,
  //     isLoading: isServerLoading
  //   } = useServerOverviewData();
  //   SummaryData = Data;
  //   isError = isServerError;
  //   isLoading = isServerLoading;
  // }
  // const { data: apdata } = useAllPlatformData();

  // const handleDropdownChange = (event: SelectChangeEvent) => {
  //   const value = event.target.value;
  //   setSelectedPlatform(value);
  // };
  // const dropdownItems = [
  // { name: "All", value: "all" },
  // { name: "Client", value: "client" }
  // { name: "Server", value: "server" },
  // { name: "Cloud", value: "cloud" }
  // ];

  if (isError) {
    return <p>Overview data not available at this moment</p>;
  }
  return (
    <Grid>
      <Grid>
        {/* First Row */}
        <Grid className="grid-title">
          <Box className="label1" mb={1}>
            Overview
          </Box>
          {/* <div>
            <FormControl className="drop ">
              <Select
                className="PageDropdown title-dropdown"
                value={selectedPlatform}
                variant="standard"
                IconComponent={ExpandMoreIcon}
                onChange={handleDropdownChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  const label = dropdownItems.find((itm) => itm.value === selected)?.name;
                  return label;
                }}
              >
                {dropdownItems.map((itm) => (
                  <MenuItem className="menu-item" key={itm.name} value={itm.value}>
                    {itm.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="secondary-text"> Overview</div> */}
        </Grid>
        <Grid container style={{ paddingBottom: "15px" }} spacing={2}>
          <Grid item lg={6}>
            <Card className="overview-cards">
              <OverviewCard
                metricdata={useCeConversionFixed(SummaryData?.ce)}
                metrictooltip={useCeConversion(SummaryData?.ce)}
                // secondaryMetric={` of ${useCeConversion(apdata?.ce?.toFixed(2))}`}
                // {...(selectedPlatform === "client"                                         //commented for coming release
                //   ? { secondaryMetric: ` of ${useCeConversion(apdata?.ce?.toFixed(2))}` }
                //   : {})}
                title="Total Carbon Emission"
                primaryIcon={<CloudCarbon />}
                secondaryText={`≈ ${SummaryData?.ec?.toFixed(3)} kWh of Energy `}
                secondaryIcon={<Bulb />}
                sp={selectedPlatform}
              />
            </Card>
          </Grid>
          <Grid item lg={6}>
            <Card className="overview-cards">
              <OverviewCard
                metricdata={SummaryData === undefined ? "" : `${SummaryData?.cc?.toFixed(3)} €`}
                metrictooltip={SummaryData === undefined ? "" : `${String(SummaryData?.cc)} €`}
                // secondaryMetric={` of ${apdata?.cc?.toFixed(2)} €`}
                // {...(selectedPlatform === "client"                          //commented for coming release
                //   ? { secondaryMetric: ` of ${apdata?.cc?.toFixed(2)} €` }
                //   : {})}
                title="Total Carbon Offset"
                primaryIcon={<Dollar />}
                secondaryText={`≈ Planting ${SummaryData?.trees?.toFixed(3)} tree`}
                secondaryIcon={<Tree />}
                sp={selectedPlatform}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} className="chart-grid-itm-parent">
        <div className="label1 dash-m-10">Carbon Insight</div>
        <Card className="dash-br-15">
          <CarbonInsightChart selectedPlatform={selectedPlatform} />
        </Card>
      </Grid>
    </Grid>
  );
};

import { Box, Divider, Grid } from "@mui/material";
import dayjs from "dayjs";
import Chart from "../../../../../../components/Svg/Chart";
import TableIcon from "../../../../../../components/Svg/Table";
import { DropdownOption, ColorCounts } from "../../PageSectionBody";
import AllColors from "../../../../../../components/Svg/AllColors";
import { CustomDropdown } from "../../../../../../components/CustomDropdown/CustomDropdown";
import { ToggleButtonGroup } from "../../../../../../components/ToggleButtonGroup/ToggleButtonGroup";
import CustomButton from "../../../../../../components/CustomButton/CustomButton";

export const CarbonEmissionCardHeader = ({
  isOpen,
  setIsOpen,
  chartView,
  setChartView,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedOption,
  setSelectedOption,
  colorCounts,
  colorTab,
  setColorTab,
  isCEGraphDataPresent,
  isStatic,
  selectedPlatform
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  chartView: boolean;
  setChartView: React.Dispatch<React.SetStateAction<boolean>>;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  selectedOption: DropdownOption | null;
  setSelectedOption: React.Dispatch<React.SetStateAction<DropdownOption | null>>;
  colorCounts: ColorCounts;
  colorTab: number;
  setColorTab: React.Dispatch<React.SetStateAction<number>>;
  isCEGraphDataPresent: boolean;
  isStatic: boolean;
  selectedPlatform: string | undefined;
}) => {
  return (
    <Grid>
      <Grid
        px={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <Grid py={1} px={1} className="card-heading" style={{ cursor: "pointer" }}>
          {chartView ? "Carbon Emission" : "Job Stats"}
          <Grid display={"flex"} ml={1}>
            {chartView ? <Divider orientation="vertical" flexItem /> : null}
            <Grid>
              {isCEGraphDataPresent && chartView ? (
                <Grid ml={1} className="card-heading-legends">
                  <Box className="info-cont legend">
                    <div className="info-box grey"></div>
                    <p className="info-txt" style={{ marginLeft: "2px" }}>
                      Failed - {colorCounts.Failed}
                    </p>
                  </Box>
                  <Box className="info-cont legend">
                    <div className="info-box green"></div>
                    <p className="info-txt" style={{ marginLeft: "2px" }}>
                      Low - {colorCounts.Green}
                    </p>
                  </Box>
                  <Box className="info-cont legend">
                    <div className="info-box amber"></div>
                    <p className="info-txt" style={{ marginLeft: "2px" }}>
                      Medium - {colorCounts.Amber}
                    </p>
                  </Box>
                  <Box className="info-cont legend">
                    <div className="info-box red"></div>
                    <p className="info-txt" style={{ marginLeft: "2px" }}>
                      High - {colorCounts.Red}
                    </p>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className="ce-card-header-right"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          {/* <ButtonGroup> */}
          {isCEGraphDataPresent && !chartView ? (
            <Grid className="ce-filter-buttons">
              <CustomButton
                sx={{
                  ...(colorTab === 0 ? activeSx : inActiveSx),
                  borderRadius: "4px",
                  margin: "2px",
                  height: "30px"
                }}
                onClick={() => setColorTab(0)}
              >
                <Box className="info-cont">
                  <Box className="all-colors-box">
                    <AllColors />
                  </Box>
                  <p className="info-txt" style={{ marginLeft: "2px" }}>
                    All -{" "}
                    {colorCounts.Failed + colorCounts.Green + colorCounts.Amber + colorCounts.Red}
                  </p>
                </Box>
              </CustomButton>
              <CustomButton
                sx={{
                  ...(colorTab === 1 ? activeSx : inActiveSx),
                  borderRadius: "4px",
                  margin: "2px",
                  height: "30px"
                }}
                onClick={() => setColorTab(1)}
              >
                <Box className="info-cont">
                  <div className="info-box grey"></div>
                  <p className="info-txt" style={{ marginLeft: "2px" }}>
                    Failed - {colorCounts.Failed}
                  </p>
                </Box>
              </CustomButton>
              <CustomButton
                sx={{
                  ...(colorTab === 2 ? activeSx : inActiveSx),
                  borderRadius: "4px",
                  margin: "2px",
                  height: "30px"
                }}
                onClick={() => setColorTab(2)}
              >
                <Box className="info-cont">
                  <div className="info-box green"></div>
                  <p className="info-txt" style={{ marginLeft: "2px" }}>
                    Low - {colorCounts.Green}
                  </p>
                </Box>
              </CustomButton>
              <CustomButton
                sx={{
                  ...(colorTab === 3 ? activeSx : inActiveSx),
                  borderRadius: "4px",
                  margin: "2px",
                  height: "30px"
                }}
                onClick={() => setColorTab(3)}
              >
                <Box className="info-cont">
                  <div className="info-box amber"></div>
                  <p className="info-txt" style={{ marginLeft: "2px" }}>
                    Medium - {colorCounts.Amber}
                  </p>
                </Box>
              </CustomButton>
              <CustomButton
                sx={{
                  ...(colorTab === 4 ? activeSx : inActiveSx),
                  borderRadius: "4px",
                  margin: "2px",
                  height: "30px"
                }}
                onClick={() => setColorTab(4)}
              >
                <Box className="info-cont">
                  <div className="info-box red"></div>
                  <p className="info-txt" style={{ marginLeft: "2px" }}>
                    High - {colorCounts.Red}
                  </p>
                </Box>
              </CustomButton>
            </Grid>
          ) : null}
          {isCEGraphDataPresent && !chartView ? (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: "70%",
                marginLeft: "8px",
                marginTop: "8px"
              }}
            />
          ) : null}
          {/* </ButtonGroup> */}
          <Box>
            {isCEGraphDataPresent ? (
              <Box mx={1} mt={0.25}>
                <ToggleButtonGroup
                  button1={<Chart />}
                  button2={<TableIcon />}
                  active={chartView}
                  setActive={setChartView}
                />
              </Box>
            ) : null}
          </Box>

          <Box mt={1}>
            {isCEGraphDataPresent ? (
              <CustomDropdown
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                position="left"
                isStatic={isStatic}
                selectedPlatform={selectedPlatform}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
const commonSx = {
  textTransform: "capitalize",
  borderColor: "#00677F"
};
const activeSx = {
  ...commonSx,
  color: "white",
  bgcolor: "#00677F",
  "&:hover": { backgroundColor: "#00677F", borderRightColor: "transparent", borderColor: "#00677F" }
};

const inActiveSx = {
  ...commonSx,
  color: "white",
  bgcolor: "#303030",
  "&:hover": { borderRightColor: "transparent", borderColor: "#00677F" }
};

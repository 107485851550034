import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../api/useResetPasswordMutation";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { axiosClient } from "../../globals/client/axios";
import { RESET_TOKEN_VERIFY } from "../../api/paths";
import CustomButton from "../../components/CustomButton/CustomButton";

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const nav = useNavigate();
  const token = searchParams.get("token");
  const { enqueueSnackbar } = useSnackbar();

  const [isChecking, setIsChecking] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    if (!token || token.trim() === "") {
      setIsTokenValid(false);
      return;
    }
    axiosClient
      .post(`${RESET_TOKEN_VERIFY}`, null, {
        params: {
          token
        }
      })
      .then((res) => setIsTokenValid(res.data))
      .catch((err) => {
        console.log(err);
        setIsTokenValid(false);
      })
      .finally(() => {
        setIsChecking(false);
      });
  }, [searchParams]);

  const passwordRE =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

  if (!token || token.trim() === "") {
    nav("/error/404");
  }

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const passwordValid = passwordRE.test(newPassword);
  const confirmValid = newPassword === confirmNewPassword;

  const resetSuccess = () => {
    enqueueSnackbar("Password Reset Successful", {
      variant: "success",
      autoHideDuration: 1000
    });
    nav("/login");
  };

  const { mutate: resetPassword, isLoading, isError } = useResetPasswordMutation(resetSuccess);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    resetPassword({ newPassword, token: token ?? "" });
  };

  return (
    <Container component="main" className="main-container">
      {isChecking ? (
        <Typography
          className="main-container-typography"
          textAlign="center"
          component="h1"
          variant="h5"
        >
          Please Wait
        </Typography>
      ) : !isTokenValid ? (
        <Typography
          className="main-container-typography"
          textAlign="center"
          component="h1"
          variant="h5"
        >
          Reset Link has expired!. Please generate new one <Link to="/forgot-password">here.</Link>
        </Typography>
      ) : (
        <Box className="main-container-box">
          <Typography className="main-container-typography" component="h1" variant="h5">
            Enter New Password
          </Typography>
          <Box component="form" className="form-fields-box" onSubmit={handleSubmit}>
            <TextField
              required
              margin="normal"
              autoFocus
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={newPassword}
              error={!passwordValid && newPassword !== ""}
              helperText={
                !passwordValid && newPassword !== ""
                  ? `Password must be 8 character long consisting of uppercase letters, lowercase letters, numbers, and symbols.`
                  : ""
              }
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((b) => !b)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <TextField
              required
              margin="normal"
              fullWidth
              name="confirmpassword"
              label="Confirm Password"
              type="password"
              value={confirmNewPassword}
              error={!confirmValid && confirmNewPassword !== ""}
              helperText={!confirmValid && confirmNewPassword !== "" ? `passwords don't match` : ""}
              onChange={(e) => setconfirmNewPassword(e.target.value)}
            />

            {
              <CustomButton
                type="submit"
                fullWidth
                variant="contained"
                className="login-button"
                disabled={!passwordValid || !confirmValid}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" className="circular-progress" />
                ) : (
                  "Reset"
                )}
              </CustomButton>
            }
            {isError && (
              <Typography sx={{ color: "red" }} align="center">
                Failed to reset password!, Link has Expired. Please generate new one
                <Link to="/forgot-password">here.</Link>
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Container>
  );
}

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_COMMENTS } from "./paths";

const getAdminComment = async (id: number): Promise<string> => {
  return await axiosClient.get<string>(`${GET_COMMENTS}${id}`).then((res) => res.data);
};

export const usecomment = (id: number | undefined) => {
  return useQuery<string, unknown, string>({
    queryKey: ["Comment", id],
    queryFn: () => {
      if (id !== undefined) {
        return getAdminComment(id);
      } else {
        return Promise.reject(new Error("ID is undefined"));
      }
    }
  });
};

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_PROJECTS_BYEMAIL } from "./paths";
import { IProject } from "./useProjectsData";

const ProjectsDataByEmail = async ({ emailId }: { emailId: string | null }) => {
  return await axiosClient
    .get<IProject[]>(`${GET_PROJECTS_BYEMAIL}/${emailId!}`)
    .then((res) => res.data);
};

export const useProjectsByEmail = ({ emailId }: { emailId: string | null }) => {
  return useQuery({
    queryKey: ["projects-data-by-email", emailId],
    queryFn: () => ProjectsDataByEmail({ emailId }),
    enabled: !!emailId
  });
};

import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import "./index.scss";
const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: "10px",
  fontWeight: "400",
  height: "20px",
  padding: "4px 6px",
  border: "none",
  lineHeight: "11.5px",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  color: "#ffffff",
  opacity: "0.5",
  borderRadius: "50px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(","),
  "&:hover": {
    boxShadow: "none",
    border: "none",
    color: "#ffffff",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderColor: "rgba(255, 255, 255, 0.1)",
    opacity: "0.5",
    borderRadius: "50px"
  }
});
interface CustomButtonWithRightIconProps {
  excludedList: string[];
  onCloseClicked: (param: string) => void;
}
const CustomButtonWithRightIcon: React.FC<CustomButtonWithRightIconProps> = ({
  excludedList,
  onCloseClicked
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      style={{
        display: "flex",
        flexWrap: "wrap"
      }}
    >
      {excludedList.map(
        (each: string, index: number) =>
          each.length > 0 && (
            <BootstrapButton
              key={index}
              variant="outlined"
              endIcon={
                <CloseIcon
                  className="closeIcon"
                  fontSize="small"
                  onClick={() => onCloseClicked(each)}
                />
              }
            >
              {each}
            </BootstrapButton>
          )
      )}
    </Stack>
  );
};
export default CustomButtonWithRightIcon;

import { Typography } from "@mui/material";
import "./Text.scss";

export const Text = (props) => {
  const { type, comp, classname, data, ...rest } = props;
  return (
    <Typography component={comp || type} variant={type} className={classname} {...rest}>
      {data}
    </Typography>
  );
};

import { ReactNode } from "react";
import "./MonitorTool.css";
import { Grid, Link } from "@mui/material";
interface monitorToolProps {
  children: ReactNode;
  guidelines: {
    heading: string;
    steps: string[];
  };
}

const MonitorTool = ({ children, guidelines }: monitorToolProps) => {
  return (
    <Grid className="monitorToolSteps">
      <Grid className="stepsHeading">
        <div className="icon-container">
          <span className="headingIcon icon-container">{children}</span>
          {guidelines.heading}
        </div>
      </Grid>
      <Grid>
        {
          <ol className="steps">
            {guidelines.steps.map((stepItem, index) => (
              <li key={index}>
                {stepItem.includes("https") ? (
                  <>
                    {stepItem.substring(0, stepItem.indexOf("https"))}
                    <Link
                      href={stepItem.substring(
                        stepItem.indexOf("https"),
                        stepItem.lastIndexOf("/") + 1
                      )}
                      className="linkDisplay"
                    >
                      {stepItem.substring(stepItem.indexOf("https"), stepItem.lastIndexOf("/") + 1)}
                    </Link>
                    {stepItem.substring(stepItem.lastIndexOf("/") + 1)}
                  </>
                ) : stepItem.includes("www") ? (
                  <>
                    {stepItem.substring(0, stepItem.indexOf("www"))}
                    <Link
                      href={stepItem.substring(stepItem.indexOf("www"))}
                      className="linkDisplay"
                    >
                      {stepItem.substring(stepItem.indexOf("www"))}
                    </Link>
                  </>
                ) : (
                  stepItem
                )}
              </li>
            ))}
          </ol>
        }
      </Grid>
    </Grid>
  );
};

export default MonitorTool;

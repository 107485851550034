import { Box } from "@mui/material";
import { Audit } from "../../../api/useSuggestionsData";
import MetricsItem from "./MetricsItem";

interface MetricsProps {
  metrics: Audit[];
  setActiveMetric: React.Dispatch<React.SetStateAction<string | null>>;
  activeMetric: string | null;
  activeTab: string | null;
}

const Metrics = (props: MetricsProps) => {
  const { metrics, activeMetric, setActiveMetric, activeTab } = props;
  return (
    <Box className="metrics-container">
      <Box>
        {metrics?.map((metric, index) => (
          <MetricsItem
            metric={metric}
            activeMetric={activeMetric}
            activeTab={activeTab}
            setActiveMetric={setActiveMetric}
            key={metric.numericValue ?? `nokey-${index}`}
            isActive={metric.audit === activeMetric}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Metrics;

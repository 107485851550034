export default function RamIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33465 12.0629C9.34023 12.6191 8.89091 13.073 8.3347 13.073H5.65975C5.11007 13.073 4.66345 12.6293 4.65977 12.0797L4.65479 11.3346C4.65479 10.693 5.17395 10.168 5.82145 10.168H8.16062C8.80228 10.168 9.32145 10.6871 9.32728 11.3288L9.33465 12.0629Z"
        fill="white"
      />
      <path
        d="M10.7915 6.08492V7.35076C10.7915 7.65992 10.914 7.95742 11.1357 8.17909L11.614 8.65742C11.8357 8.87909 11.9582 9.17659 11.9582 9.48576V10.1683C11.9582 11.4143 11.5013 12.3544 10.5094 12.7967C10.2189 12.9262 9.92129 12.6861 9.92017 12.368L9.9165 11.3233C9.90484 10.3666 9.11734 9.58492 8.1665 9.58492H5.82734C4.859 9.58492 4.07734 10.3724 4.07734 11.3349V12.3758C4.07734 12.6932 3.78151 12.9344 3.49068 12.8071C2.48762 12.3682 2.0415 11.4218 2.0415 10.1683V4.33492C2.0415 3.0214 2.52779 2.03786 3.63897 1.63153C3.93152 1.52455 4.21323 1.76864 4.20877 2.08011L4.194 3.10992C4.18817 3.38409 4.28734 3.64659 4.474 3.84492C4.6665 4.04326 4.91734 4.15409 5.21484 4.15992C5.51477 4.15992 5.78186 4.03582 5.96412 3.83381C6.02957 3.76125 6.15491 3.75833 6.224 3.82742C6.41067 4.02576 6.66734 4.14242 6.96484 4.14826C7.26487 4.14826 7.53853 4.01639 7.72526 3.80413C7.78663 3.73438 7.91228 3.72882 7.974 3.79826C8.16067 3.99659 8.41734 4.11326 8.71484 4.11909C9.27484 4.11909 9.724 3.68159 9.73567 3.11576L9.75454 2.05907C9.75997 1.75476 10.0369 1.52123 10.3237 1.62305C11.4489 2.02253 11.9582 3.01409 11.9582 4.33492V4.91826C11.9582 5.23909 11.6957 5.50159 11.3748 5.50159C11.054 5.50159 10.7915 5.76409 10.7915 6.08492Z"
        fill="white"
      />
      <path
        d="M5.22282 1.42093C5.47447 1.41931 5.67814 1.62511 5.67389 1.87673L5.65247 3.14464C5.64664 3.3838 5.45414 3.5763 5.21497 3.5763H5.20914C4.96414 3.57047 4.77164 3.37214 4.77747 3.12714V1.86916C4.77747 1.62273 4.9764 1.42252 5.22282 1.42093Z"
        fill="white"
      />
      <path
        d="M6.97573 1.41797C7.22626 1.41797 7.42816 1.62332 7.42391 1.87382L7.40247 3.1388C7.39664 3.37214 7.20414 3.56464 6.96497 3.56464H6.95914C6.71414 3.5588 6.52164 3.36047 6.52747 3.1213L6.52747 1.86622C6.52747 1.61866 6.72816 1.41797 6.97573 1.41797Z"
        fill="white"
      />
      <path
        d="M8.72848 1.42081C8.97794 1.42238 9.1781 1.62734 9.17377 1.87677L9.15247 3.10367C9.14664 3.34283 8.95414 3.53533 8.71497 3.53533H8.70914C8.46414 3.5295 8.27164 3.33117 8.27747 3.092V1.86899C8.27747 1.62036 8.47986 1.41924 8.72848 1.42081Z"
        fill="white"
      />
    </svg>
  );
}

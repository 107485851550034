import { Box, Grid } from "@mui/material";

import { useState } from "react";
import { GraphDatum } from "../../../../../../../api/usePageOverallCEWithGraph";
import ReusableTable, { Column, Row } from "../../../../../../../components/CustomTable";
import { NoData } from "../../../../../../../components/NoData/NoData";
import { CI_JOBS_STATUS_COLUMNS } from "../../../../../../../utils/constants";
import { formatDateTime } from "../../../../../../../utils/useFormatDateTime";
import { formatNumber } from "../../../../../Format";

export const JobStatusTableContainer = ({
  filteredData,
  jobId,
  setJobId,
  setPerVisitView,
  colorTab,
  selectedPlatform
}: {
  filteredData: GraphDatum[] | undefined;
  jobId: number | undefined;
  setJobId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setPerVisitView: React.Dispatch<React.SetStateAction<boolean>>;
  colorTab: number;
  selectedPlatform: string | undefined;
}) => {
  function filterDataByColor(colorTab: number) {
    if (colorTab === 0) {
      // Return all data for colorTab = 0 (all colors)
      // const filteredRevData = filteredData?.slice().reverse();
      return filteredData?.slice().reverse();
    } else if (colorTab === 1) {
      return filteredData?.filter((job) => job.errorMessage).reverse();
    } else {
      const targetColor = getColorForColorTab(colorTab);
      return filteredData?.filter((job) => job.thresholdColour === targetColor).reverse();
    }
  }
  function getColorForColorTab(colorTab: number) {
    switch (colorTab) {
      case 2:
        return "Green";
      case 3:
        return "Amber";
      case 4:
        return "Red";
      default:
        return "";
    }
  }

  const colorFilteredData = filterDataByColor(colorTab)?.map((val, idx) => ({ ...val, id: idx++ }));
  const storageColName = selectedPlatform === "client" ? "Storage Used" : "CPU/Memory/GPU";
  const storageCol = { field: "size", headerName: storageColName };
  const JobStatusTableCols = [...CI_JOBS_STATUS_COLUMNS];
  JobStatusTableCols.splice(5, 0, storageCol);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const handleButtonClicked = (rowId: string | number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandedRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };
  const renderCustomContent = (row: Row, column: Column) => {
    switch (column.field) {
      case "thresholdColour":
        return (
          <Box>
            <Grid className="symbol">
              <div className={`box ${row[column.field] as string}`}></div>
            </Grid>
          </Box>
        );
      case "createdDate":
        return <Box>{formatDateTime(row[column.field])}</Box>;
      case "variation":
        return (
          <Box>
            {(row[column.field] >= 0 ? "+" : "") + formatNumber(row[column.field]).toFixed(3) + "%"}
          </Box>
        );
      case "ce":
        return <Box>{(formatNumber(row[column.field]).toFixed(3) || "N/A") + "g"}</Box>;
      case "cc":
        return <Box>{(formatNumber(row[column.field]).toFixed(3) || "N/A") + "€"}</Box>;
      case "size":
        return <Box>{(formatNumber(row[column.field]).toFixed(3) || "N/A") + "MB"}</Box>;
      case "errorMessage": {
        const isExpanded = expandedRows[row.jobId] || false;
        return (
          <Box className={`${row.errorMessage ? "error errorover" : ""}`}>
            {row[column.field]
              ? isExpanded
                ? row[column.field]
                : row[column.field].slice(0, 27)
              : "-"}
            {row[column.field] && row[column.field].length > 27 && (
              <span className="read-more" onClick={handleButtonClicked(row.jobId)}>
                {isExpanded ? "...show less" : "...read more"}
              </span>
            )}
          </Box>
        );
      }

      case "status":
        return (
          <Box className={`${row.errorMessage ? "error" : ""}`}>
            {row.errorMessage ? (
              <>
                CEC {row.cecStatus}
                <br />
                Lighthouse {row.lhStatus}
              </>
            ) : (
              "-"
            )}
          </Box>
        );
      default:
        return <Box>{row[column.field]}</Box>;
    }
  };
  const handleRowClick = (row: Row) => {
    setJobId(row.jobId);
    setPerVisitView(true);
  };
  const rowClassName = (row: Row) => `custom-row item ${jobId === row.jobId ? "active-row" : ""}`;
  const containerClassName = "custom-table-container";

  return (
    <Grid item lg={12}>
      {colorFilteredData && colorFilteredData.length > 0 ? (
        <ReusableTable
          columns={JobStatusTableCols}
          data={selectedPlatform === "client" ? colorFilteredData : []}
          enableSorting={false}
          enableFiltering={false}
          enablePagination={false}
          renderCustomContent={renderCustomContent}
          onRowClick={handleRowClick}
          rowClassName={rowClassName}
          containerClassName={containerClassName}
        />
      ) : (
        <NoData />
      )}
    </Grid>
  );
};

export default function UpArrow() {
  return (
    <svg width="65" height="45" viewBox="0 -8 56 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4368 11.7136L0 25.4243L6.89405 25.4243V45.0003H15.9896V25.4243L22.8736 25.4243L11.4368 11.7136Z"
        fill="#F63E3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.1839 18.8408L41.7471 32.5515L48.6451 32.5515V45.0001H57.7407V32.5515L64.6207 32.5515L53.1839 18.8408Z"
        fill="#F63E3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3103 0L20.8735 13.7107L27.7716 13.7107V45H36.8671V13.7107L43.7471 13.7107L32.3103 0Z"
        fill="#F63E3E"
      />
    </svg>
  );
}

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";

export const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  headers: {
    // Authorization: `Bearer <<paste your auth code>>` /* uncomment this line and comment the below line while running in local */
    Authorization: `Bearer ${localStorage.getItem("authCode") ?? ""}`
  }
});

let authInterceptor: number;

export function addAuthToken() {
  authInterceptor = axiosClient.interceptors.request.use(
    (req: InternalAxiosRequestConfig) => {
      req.headers = req.headers ?? {};
      req.headers.Authorization = `Bearer ${localStorage.getItem("authCode") ?? ""}`;

      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
}

export function removeAuthToken() {
  if (authInterceptor !== undefined && authInterceptor !== null) {
    axiosClient.interceptors.request.eject(authInterceptor);
  }
}
const authCode = localStorage.getItem("authCode");

// const useremailId = localStorage.getItem("emailId");

const refreshToken = async () => {
  try {
    // Make a request to the refresh token API endpoint
    const response = await axiosClient.post(
      "/login/user-auths/refreshToken" +
        `?authCode=${localStorage.getItem("authCode") ?? ""}` +
        `&emailId=${localStorage.getItem("emailId") ?? ""}`
    );

    // Update the access token in local storage
    localStorage.setItem("authCode", response.data);

    // Return the new access token
    return response.data;
  } catch (error) {
    // Handle the error if refreshing the token fails
    console.error("Error refreshing token:", error);
    throw error;
  }
};

let isRefreshing = false;
const requestQueue: Function[] = [];
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  async function (error: AxiosError) {
    const originalRequest = error.config as any;
    if (error.response && error.response.status === 440 && !originalRequest._retry) {
      originalRequest._retry = true;
      // need to call api here
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newAccessToken: string = await refreshToken();

          // axiosClient.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
          if (authCode && originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          }
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: `Bearer ${newAccessToken}`
          };
          // const authCode = localStorage.getItem("authCode");

          addAuthToken();
          showAlert();
          return await axios(originalRequest);
        } catch (refreshError) {
          // Handle the error if refreshing the token fails
          console.error("Error refreshing token:", refreshError);
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      } else {
        // Token refresh request is already in progress, add the request to the queue
        return await new Promise((resolve) => {
          requestQueue.push(() => {
            // Retry the original request with the updated access token
            originalRequest._retry = true;
            resolve(axiosClient(originalRequest));
          });
        });
      }
    } else {
      const curPath = window.location.pathname;
      if (
        curPath.includes("login") ||
        curPath.includes("signup") ||
        curPath.includes("verify") ||
        curPath.includes("Reset") ||
        curPath.includes("forgot")
      ) {
        return await Promise.reject(error);
      } else if (!authCode && error.response && error.response.status === 401) {
        window.location.replace("/app/login");
      } else {
        if (error.response && error.response.status === 401) {
          window.location.href = "/app/error/401";
        } else if (error.response && error.response.status === 500) {
          window.location.href = "/app/error/500";
        } else if (error.response && error.response.status === 404) {
          window.location.href = "/app/error/404";
        }
      }
    }
    return await Promise.reject(error);
  }
);

function showAlert() {
  const overlay = document.createElement("div");
  overlay.style.position = "fixed";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.width = "100%";
  overlay.style.height = "100%";
  overlay.style.background = "rgba(0, 0, 0, 0.5)";
  overlay.style.zIndex = "9999"; // Set a high z-index to ensure it appears on top

  const modal = document.createElement("div");
  modal.style.position = "fixed";
  modal.style.top = "50%";
  modal.style.left = "50%";
  modal.style.transform = "translate(-50%, -50%)";
  modal.style.background = "white";
  modal.style.padding = "20px";
  modal.style.borderRadius = "5px";
  modal.style.border = "1px solid #ccc";
  modal.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.3)";
  modal.style.zIndex = "10000"; // Set a higher z-index than the overlay to make sure it's above it

  const message = document.createElement("p");
  message.textContent = "Session expired. Click on OK to Continue";
  message.style.color = "black";

  const button = document.createElement("button");
  button.textContent = "OK";
  button.addEventListener("click", () => {
    location.reload();
  });

  modal.appendChild(message);
  modal.appendChild(button);
  overlay.appendChild(modal);
  document.body.appendChild(overlay);
}

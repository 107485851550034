import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute: React.FC = () => {
  const authCode = localStorage.getItem("authCode");
  return process.env.REACT_APP_BUILD_ENV !== "development" && !authCode ? (
    <>
      <Navigate replace to="/login" />
    </>
  ) : (
    <Outlet />
  );
};

export default PrivateRoute;

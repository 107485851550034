import { Box, CircularProgress } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerifyData } from "../../api/useVerifyData";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CustomButton from "../../components/CustomButton/CustomButton";

export default function Verify() {
  const nav = useNavigate();
  const location = useLocation();
  const queryString = location.search;
  const tokenStartIndex = queryString.indexOf("token=") + 6; // add 6 to skip 'token='
  const tokenEndIndex = queryString.indexOf("&", tokenStartIndex);
  const tokenid =
    tokenEndIndex === -1
      ? queryString.substring(tokenStartIndex)
      : queryString.substring(tokenStartIndex, tokenEndIndex);

  const { data: verifyData, isError, error } = useVerifyData(String.raw`${tokenid}`);

  const errors: any = error;
  // if (isError) {
  //   return <Typography color="red">{errors.response.data.details[0]}</Typography>;
  // }
  const handleNav = () => {
    nav("/login");
  };
  const handleSignup = () => {
    nav("/signup");
  };
  return (
    <>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          background: "var(--login-card-bg)",
          borderRadius: 1,
          p: "20px",
          padding: "80px"
        }}
      >
        <>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              lineHeight: "130%"
            }}
          >
            {verifyData === "Valid User" ? (
              <CheckCircleOutlinedIcon sx={{ color: "#50886F", fontSize: 60 }} />
            ) : isError ? (
              <CancelOutlinedIcon sx={{ color: "red", fontSize: 60 }} />
            ) : (
              <CircularProgress />
            )}

            <Grid className="verify-text">
              <Typography
                style={{
                  textDecoration: "underline",
                  color: " #50886F"
                }}
              >
                {isError ? "Link Expired" : "Verifying email...."}
              </Typography>
              <br></br>
              {verifyData === "Valid User" ? (
                <Typography>Please click on Proceed</Typography>
              ) : isError ? (
                <Typography color="red">{errors.response.data.details[0]}</Typography>
              ) : (
                <Typography>Please wait while we verify your login. </Typography>
              )}
            </Grid>
            <br></br>
            {isError ? (
              <CustomButton
                type="submit"
                onClick={handleSignup}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, background: " #50886F" }}
              >
                Go to Signup Page
              </CustomButton>
            ) : (
              <CustomButton
                type="submit"
                onClick={handleNav}
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  background: verifyData === "Valid User" ? " #50886F" : "#595959"
                }}
                disabled={verifyData !== "Valid User"}
              >
                Proceed Log In
              </CustomButton>
            )}
          </Box>
        </>
      </Container>
    </>
  );
}

import { Grid, TableCell, TableRow, Tooltip } from "@mui/material";

import { Text } from "../../../components/Text Component/Text";
import "./index.scss";

import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getFirstWebpageId } from "../../../api/getFirstWebpage";
import { LatestPagesJobs } from "../../../api/useLatestJobsAndPagesData";

interface ProjectItemProps {
  project: LatestPagesJobs;
  platform: string;
}

const ProjectItem = ({ project, platform }: ProjectItemProps) => {
  const latestJob = project.lastJobs?.at(project.lastJobs.length - 1);
  const nav = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const navToProject = async (projectId: number) => {
    try {
      const [error, pageId] = await getFirstWebpageId(projectId);
      if (error) throw error;
      if (pageId === null) {
        enqueueSnackbar("No Web pages Available", {
          variant: "warning",
          autoHideDuration: 2000
        });
        return;
      }
      nav(`/dashboard/carbon/${projectId}/${platform}/${pageId}`);
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "warning",
        autoHideDuration: 2000
      });
    }
  };

  return (
    <TableRow
      className="row dash-table-row"
      key={project.id}
      onClick={() => {
        navToProject(project.id);
      }}
    >
      <TableCell className="cell-design1">
        <Grid className="symbol" pl={1}>
          <div className={latestJob?.thresholdColour}></div>
        </Grid>
      </TableCell>
      <TableCell className="cell-design prj-name ellipsis">
        <Tooltip title={project.name} placement="left">
          <span className="prj-name-p">{project.abbr}</span>
        </Tooltip>
      </TableCell>
      <TableCell className="cell-design">
        {latestJob
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric"
            }).format(new Date(latestJob.createdDate))
          : ""}
      </TableCell>

      <TableCell className="cell-design">
        <Text
          data={` ${
            latestJob?.thresholdColour !== "Grey"
              ? project.ceSum.toFixed(2) !== "N/A"
                ? `${project.ceSum.toFixed(2)} g`
                : "N/A"
              : "-"
          }`}
          className="cell-text"
        />
      </TableCell>

      <TableCell className="cell-design">
        <Text
          data={` ${
            latestJob?.thresholdColour !== "Grey"
              ? project.ccSum.toFixed(2) !== "N/A"
                ? `${project.ccSum.toFixed(2)} €`
                : "N/A"
              : "-"
          }`}
          className="cell-text"
        />
      </TableCell>
      <TableCell className="cell-design-n eb" component="th" align="left">
        <div className="ellipse-btn">{project.suggestionsCount}</div>
      </TableCell>
    </TableRow>
  );
};

export default ProjectItem;

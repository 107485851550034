import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  // IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  // Tooltip,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackArrow from "../../../components/Svg/BackArrow";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { updateOnboard, Details2, useDataById } from "../../../api/useCreateOnboardData";
import { useMutation } from "@tanstack/react-query";
import Successfull from "../../../components/Svg/Successfull";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import Compress from "react-image-file-resizer";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomButton from "../../../components/CustomButton/CustomButton";
// import { InfoOutlined } from "@mui/icons-material";
interface Option {
  emailId: string;
  id: number;
  name: string;
}
const EditProject = () => {
  const { mutate } = useMutation(updateOnboard, {
    onSuccess: (data) => {
      setsuccessBoxOpen(true);
    },
    onError: () => {
      console.log("There is an error");
    }
  });
  const { projectId } = useParams();
  const rowId = parseInt(projectId ?? "", 10);
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [firstDropdownValue, setFirstDropdownValue] = useState<Option | null>(null);
  const [secondDropdownValue, setSecondDropdownValue] = useState<Option | null>(null);
  const [successBoxOpen, setsuccessBoxOpen] = useState(false);
  const [appInfoClassificationErrorMessage, setAppInfoClassificationErrorMessage] = useState("");
  const [, setRowData] = useState<Details2 | null>(null);
  const dataByIdQuery = useDataById(rowId);

  const [inputValue, setInputValue] = useState<Details2>({
    name: "",
    projectAbbr: "",
    currentVersion: "",
    image: "",
    devType: "",
    id: 0,
    projectMonitoring: {
      isToolAvailable: false,
      server: {
        monitoringType: "",
        domainUrl: ""
      },
      client: {
        monitoringType: "",
        domainUrl: ""
      }
    },
    department: {
      name: ""
    },
    projectsInfra: {
      appId: "",
      appType: "",
      networkType: "",
      hostingType: "",
      environment: "",
      deputyId: "",
      powerUser: "",
      deputyUserId: 0,
      powerUserId: 0
    },
    projectDetails: {
      description: "",
      appInfoClassification: "",
      techStack: "",
      endUserType: "",
      plannedStartDate: null,
      plannedEndDate: null
    }
  });

  useEffect(() => {
    if (rowId) {
      if (dataByIdQuery.isSuccess) {
        const fetchedData = dataByIdQuery.data;
        setRowData(dataByIdQuery.data);
        setInputValue({
          name: fetchedData.name,
          projectAbbr: fetchedData.projectAbbr,
          currentVersion: fetchedData.currentVersion,
          image: fetchedData.image,
          devType: fetchedData.devType,
          id: fetchedData.id,
          department: {
            name: fetchedData.department.name
          },
          projectMonitoring: {
            isToolAvailable: fetchedData?.projectMonitoring?.isToolAvailable,
            server: {
              monitoringType: fetchedData?.projectMonitoring?.server?.monitoringType || "",
              domainUrl: fetchedData?.projectMonitoring?.server?.domainUrl || ""
            },

            client: {
              monitoringType: fetchedData?.projectMonitoring?.client?.monitoringType || "",
              domainUrl: fetchedData?.projectMonitoring?.client?.domainUrl || ""
            }
          },
          projectsInfra: {
            appId: fetchedData.projectsInfra.appId,
            appType: fetchedData.projectsInfra.appType,
            networkType: fetchedData.projectsInfra.networkType,
            hostingType: fetchedData.projectsInfra.hostingType,
            environment: fetchedData.projectsInfra.environment,
            deputyId: fetchedData.projectsInfra.deputyUserId.toString(),
            powerUser: fetchedData.projectsInfra.powerUserId.toString(),
            powerUserId: fetchedData.projectsInfra.powerUserId,
            deputyUserId: fetchedData.projectsInfra.deputyUserId
          },
          projectDetails: {
            description: fetchedData.projectDetails.description,
            appInfoClassification: fetchedData.projectDetails.appInfoClassification,
            techStack: fetchedData.projectDetails.techStack,
            endUserType: fetchedData.projectDetails.endUserType,
            plannedStartDate: fetchedData.projectDetails.plannedStartDate,
            plannedEndDate: fetchedData.projectDetails.plannedEndDate
          }
        });
        // Set the initial values for powerUser and deputyId
        setFirstDropdownValue({
          emailId: "",
          id: fetchedData.projectsInfra.deputyUserId || 0,
          name: fetchedData.projectsInfra.deputyId || ""
        });
        setSecondDropdownValue({
          emailId: "",
          id: fetchedData.projectsInfra.powerUserId || 0,
          name: fetchedData.projectsInfra.powerUser || ""
        });
      }
    }
  }, [rowId, dataByIdQuery.isSuccess, dataByIdQuery.data]);

  const handlePlannedStartDateChange = (date: Dayjs | null) => {
    setInputValue((prevFormData) => ({
      ...prevFormData,
      projectDetails: {
        ...prevFormData.projectDetails,
        plannedStartDate: date
      }
    }));
  };

  const handlePlannedEndDateChange = (date: Dayjs | null) => {
    setInputValue((prevFormData) => ({
      ...prevFormData,
      projectDetails: {
        ...prevFormData.projectDetails,
        plannedEndDate: date
      }
    }));
  };
  const nav = useNavigate();
  const handleClose = () => {
    setsuccessBoxOpen(false);
    nav("../dashboard");
  };
  const handlesuccessBoxClose = () => {
    setsuccessBoxOpen(false); // Close successBox
    nav("../dashboard");
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ): void => {
    const { name, value } = event.target;
    if (name.startsWith("projectMonitoring.client.domainUrl")) {
      const nestedFieldName = name.split(".")[2];
      setInputValue((prevFormData) => ({
        ...prevFormData,

        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          client: {
            ...prevFormData.projectMonitoring.client,
            [nestedFieldName]: value
          }
        }
      }));
    } else if (name.startsWith("projectMonitoring.server.domainUrl")) {
      const nestedFieldName = name.split(".")[2];

      setInputValue((prevFormData) => ({
        ...prevFormData,

        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          server: {
            ...prevFormData.projectMonitoring.server,
            [nestedFieldName]: value
          }
        }
      }));
    } else if (name.startsWith("projectMonitoring.client.monitoringType")) {
      const nestedFieldName = name.split(".")[2];
      setInputValue((prevFormData) => ({
        ...prevFormData,

        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          client: {
            ...prevFormData.projectMonitoring.client,
            [nestedFieldName]: value
          }
        }
      }));
    } else if (name.startsWith("projectMonitoring.server.monitoringType")) {
      const nestedFieldName = name.split(".")[2];

      setInputValue((prevFormData) => ({
        ...prevFormData,

        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          server: {
            ...prevFormData.projectMonitoring.server,
            [nestedFieldName]: value
          }
        }
      }));
    } else if (name === "projectMonitoring.isToolAvailable") {
      const isToolAvailable = value === "true";
      const fetchedData = dataByIdQuery.data;

      setInputValue((prevFormData) => ({
        ...prevFormData,
        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          isToolAvailable,
          client: {
            ...prevFormData.projectMonitoring.client,
            monitoringType:
              fetchedData?.projectMonitoring?.isToolAvailable === isToolAvailable
                ? fetchedData.projectMonitoring.client.monitoringType
                : isToolAvailable
                ? "Instana"
                : "None"
          },
          server: {
            ...prevFormData.projectMonitoring.server,
            monitoringType:
              fetchedData?.projectMonitoring?.isToolAvailable === isToolAvailable
                ? fetchedData.projectMonitoring.server.monitoringType
                : isToolAvailable
                ? "None"
                : "NA"
          }
        }
      }));
    } else if (name === "devType") {
      setInputValue((prevFormData) => ({
        ...prevFormData,
        devType: value
      }));
    } else if (name === "projectDetails.appInfoClassification") {
      if (value.length > 20) {
        setAppInfoClassificationErrorMessage("Character Limit Exceeded");
      } else {
        setAppInfoClassificationErrorMessage("");
      }

      setInputValue((prevFormData) => ({
        ...prevFormData,
        projectDetails: {
          ...prevFormData.projectDetails,
          appInfoClassification: value
        }
      }));
    } else if (
      name.startsWith("projectsInfra.") ||
      name.startsWith("projectDetails.") ||
      name.startsWith("department.")
    ) {
      const nestedFieldName = name.split(".")[1];
      if (nestedFieldName === "description") {
        if (value.length > 255) {
          setDescriptionErrorMessage("Character Limit Exceeded");
        } else {
          setDescriptionErrorMessage("");
        }
        const parsedValue = value.slice(0, 255);
        setInputValue((prevFormData) => ({
          ...prevFormData,
          projectsInfra: {
            ...prevFormData.projectsInfra,
            [nestedFieldName]: parsedValue
          },
          projectDetails: {
            ...prevFormData.projectDetails,
            [nestedFieldName]: parsedValue
          }
        }));
      } else {
        // Extract the nested field name

        setInputValue((prevFormData) => ({
          ...prevFormData,
          department: {
            ...prevFormData.department,
            [nestedFieldName]: value
          },
          projectsInfra: {
            ...prevFormData.projectsInfra,
            [nestedFieldName]: value
          },
          projectDetails: {
            ...prevFormData.projectDetails,
            [nestedFieldName]: value
          }
        }));
      }
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const id = rowId;
    const formData = {
      ...inputValue,
      id
    };
    mutate(formData);
  };
  const BackArrowComponent = () => {
    const nav = useNavigate();
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          nav(`/dashboard/project-settings/${rowId}`, {
            replace: false,
            relative: "path"
          })
        }
      >
        <BackArrow />
      </div>
    );
  };
  // Function to handle file selection
  const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0].size > 300000) {
        compressAndSaveImage(e.target.files[0]);
      } else {
        saveAsBase64(e.target.files[0]);
      }
    }
  };

  // Inside the 'compressAndSaveImage' function:
  const compressAndSaveImage = (file: File) => {
    Compress.imageFileResizer(
      file,
      400,
      400,
      "PNG",
      100,
      0,
      (uri: string | File | Blob | ProgressEvent<FileReader>) => {
        if (typeof uri === "string") {
          setInputValue((prevInputValue) => ({
            ...prevInputValue,
            image: uri // Update the state with 'uri'
          }));
        } else {
          console.log("Received something other than a string: ", uri);
        }
      },
      "base64"
    );
  };

  const saveAsBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const baseURL = reader.result;
      if (typeof baseURL === "string" || baseURL instanceof ArrayBuffer) {
        setInputValue((prevInputValue) => ({
          ...prevInputValue,
          image: baseURL as string // Cast baseURL to the correct type
        }));
      }
    };
  };

  return (
    <>
      <div className="header-edit">
        {<BackArrowComponent />}
        <Typography variant="h5">Edit Project</Typography>
      </div>

      <Box className="edit-project-container">
        <div>
          <DialogTitle></DialogTitle>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="sub-heading">Team Details</div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="name">
                Department Name<span className="mandatory">*</span>
              </label>
              <input
                id="inputReadonly"
                name="department.name"
                placeholder="Enter Department Name"
                value={inputValue.department.name}
                readOnly
              />
            </div>
          </div>
          <div className="sub-heading">Monitoring Details</div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="MonitoringTool">
                Monitoring Tool<span className="mandatory">*</span>
              </label>
              {/* <input
                id="input"
                name="projectMonitoring.isToolAvailable"
                value={inputValue?.projectMonitoring?.isToolAvailable?.toString()}
                readOnly
              /> */}
              <FormControl className="onboard-dropdown">
                <Select
                  labelId="input-label"
                  id="dropdown-input"
                  name="projectMonitoring.isToolAvailable"
                  IconComponent={ExpandMoreIcon}
                  value={inputValue.projectMonitoring?.isToolAvailable?.toString()}
                  size="small"
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem className="onboard-menu-item" value="true">
                    Yes
                  </MenuItem>
                  <MenuItem className="onboard-menu-item" value="false">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          {/* {inputValue.projectMonitoring.isToolAvailable && ( // Check if monitoring tool is available
            <div>
              <div className="text-field">
                <div className="text-field1">
                  <label htmlFor="client">
                    Client<span className="mandatory">*</span>
                    <Tooltip
                      title={
                        <Box className="field-guide-tooltip-option">
                          <div>
                            NA -
                            <span className="field-guide-tooltip-description">
                              {" "}
                              Client not present for the project
                            </span>
                          </div>
                          <div>
                            None -
                            <span className="field-guide-tooltip-description">
                              {" "}
                              No monitoring tool, hence static scan for client
                            </span>
                          </div>
                          <div>
                            Instana -
                            <span className="field-guide-tooltip-description">
                              {" "}
                              Real time scan through Instana
                            </span>
                          </div>
                        </Box>
                      }
                      placement="right"
                    >
                      <IconButton className="field-guide">
                        <InfoOutlined className="field-guide-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <FormControl className="onboard-dropdown">
                    <Select
                      labelId="input-label"
                      id="dropdown-input"
                      name="projectMonitoring.client.monitoringType"
                      value={inputValue?.projectMonitoring?.client?.monitoringType || ""}
                      IconComponent={ExpandMoreIcon}
                      className={`${
                        inputValue?.projectMonitoring?.client?.monitoringType
                          ? "selected-white"
                          : "grey-class"
                      }`}
                      size="small"
                      onChange={handleInputChange}
                      renderValue={(selected) => selected || "Choose Client Monitoring Type"}
                      displayEmpty
                      required
                    >
                      <MenuItem className="onboard-menu-item" value="NA">
                        NA
                      </MenuItem>
                      <MenuItem className="onboard-menu-item" value="None">
                        None
                      </MenuItem>
                      <MenuItem className="onboard-menu-item" value="Instana">
                        Instana
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="text-field2">
                  <label htmlFor="server">
                    Server<span className="mandatory">*</span>
                    <Tooltip
                      title={
                        <Box className="field-guide-tooltip-option">
                          <div>
                            None -
                            <span className="field-guide-tooltip-description">
                              {" "}
                              No monitoring tool, hence no scan for server
                            </span>
                          </div>
                          <div>
                            Instana -
                            <span className="field-guide-tooltip-description">
                              {" "}
                              Real time scan through Instana
                            </span>
                          </div>
                        </Box>
                      }
                      placement="right"
                    >
                      <IconButton className="field-guide">
                        <InfoOutlined className="field-guide-icon" />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <FormControl className="onboard-dropdown">
                    <Select
                      labelId="input-label"
                      id="dropdown-input"
                      name="projectMonitoring.server.monitoringType"
                      value={inputValue?.projectMonitoring?.server?.monitoringType || ""}
                      IconComponent={ExpandMoreIcon}
                      className={`${
                        inputValue?.projectMonitoring?.server?.monitoringType
                          ? "selected-white"
                          : "grey-class"
                      }`}
                      size="small"
                      onChange={handleInputChange}
                      renderValue={(selected) => selected || "Choose Server Monitoring Type"}
                      displayEmpty
                      required
                    >
                      <MenuItem className="onboard-menu-item" value="None">
                        None
                      </MenuItem>
                      <MenuItem className="onboard-menu-item" value="Instana">
                        Instana
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="text-field">
                <div className="text-field1">
                  <label htmlFor="ClientURL">Client Domain URL</label>
                  <input
                    type="text"
                    id="inputReadonly"
                    name="projectMonitoring.client.domainUrl"
                    placeholder="Enter Client Domian Url"
                    value={inputValue.projectMonitoring.client.domainUrl}
                    readOnly
                  />
                </div>
                <div className="text-field2">
                  <label htmlFor="ServerURL">Server Domain URL</label>
                  <input
                    type="text"
                    id="inputReadonly"
                    name="projectMonitoring.server.domainUrl"
                    placeholder="Enter Server Domian Url"
                    value={inputValue.projectMonitoring.server.domainUrl}
                    readOnly
                  />
                </div>
              </div>
            </div>
          )} */}

          {/* <div className="text-field">
            <div className="text-field1">
              <label htmlFor="client">
                Client<span className="mandatory">*</span>
              </label>
              <input
                id="input"
                name="projectMonitoring.client.monitoringType"
                value={inputValue?.projectMonitoring?.client?.monitoringType}
                readOnly
              />
            </div>

            <div className="text-field2">
              <label htmlFor="server">
                Server<span className="mandatory">*</span>
              </label>
              <input
                id="input"
                name="projectMonitoring.server.monitoringType"
                value={inputValue?.projectMonitoring?.server?.monitoringType}
                readOnly
              />
            </div>
          </div> */}

          <div className="sub-heading">Project Details</div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="name">
                Project Name<span className="mandatory">*</span>
              </label>
              <input
                id="inputReadonly"
                name="name"
                placeholder="Enter Project Name"
                value={inputValue.name}
                readOnly
              />
            </div>
            <div className="text-field2">
              <label htmlFor="AppprojectAbbr">
                Project Abbreviation<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="inputReadonly"
                name="projectAbbr"
                placeholder="Enter Project Abbreviation"
                value={inputValue.projectAbbr}
                readOnly
              />
            </div>
          </div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="name">Description</label>
              <textarea
                id="input1"
                name="projectDetails.description"
                placeholder=" Enter Description"
                value={inputValue.projectDetails.description}
                onChange={handleInputChange}
                style={{
                  overflowWrap: "break-word"
                }}
              ></textarea>
              {descriptionErrorMessage && (
                <div className="error-message">{descriptionErrorMessage}</div>
              )}
            </div>
          </div>

          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="currentVersion">
                Current Version<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="inputReadonly"
                name="currentVersion"
                placeholder="Enter Current Version"
                value={inputValue.currentVersion}
                readOnly
              />
            </div>
            <div className="text-field2">
              <label htmlFor="appId">
                App ID<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="inputReadonly"
                name="projectsInfra.appId"
                placeholder=" Enter App ID"
                value={inputValue.projectsInfra.appId}
                readOnly
              />
            </div>
          </div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="appType">
                App Type<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="inputReadonly"
                name="projectsInfra.appType"
                placeholder="Enter App Type"
                value={inputValue.projectsInfra.appType}
                readOnly
              />
            </div>
            <div className="text-field2">
              <label htmlFor="networkType">
                Network Type<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="inputReadonly"
                name="projectsInfra.networkType"
                placeholder="Enter Network Type"
                value={inputValue.projectsInfra.networkType}
                readOnly
              />
            </div>
          </div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="hostingType">
                Hosting Info<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="inputReadonly"
                name="projectsInfra.hostingType"
                placeholder="Enter Hosting Info"
                value={inputValue.projectsInfra.hostingType}
                readOnly
              />
            </div>
            <div className="text-field2">
              <label htmlFor="environment">
                Environment Type<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="inputReadonly"
                name="projectsInfra.environment"
                placeholder="Enter Environment Type"
                value={inputValue.projectsInfra.environment}
                readOnly
              />
            </div>
          </div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="image">Project Logo</label>
              <div className="custom-file-upload">
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleUploadImage}
                  style={{ display: "none" }} // Hide the default input field
                />
                <label className="file-input-label" htmlFor="image">
                  {inputValue.image ? (
                    <img className="logo" src={inputValue.image} alt="Project logo" />
                  ) : (
                    " No file selected"
                  )}
                </label>

                {/* Display the image preview */}
                <CustomButton
                  type="button"
                  onClick={() => document.getElementById("image")?.click()}
                >
                  Browse
                </CustomButton>
              </div>
            </div>
          </div>
          <div>
            <div className="sub-heading">User Details</div>
            <div className="text-field-bottom">
              <div className="text-field1">
                <label htmlFor="DEmailId" className="auto-Label">
                  Deputy Name<span className="mandatory">*</span>
                </label>
                <input
                  id="inputReadonly"
                  name="projectsInfra.deputyId"
                  placeholder="Enter Deputy Name"
                  value={firstDropdownValue ? firstDropdownValue.name : ""}
                  readOnly
                />
              </div>

              <div className="text-field2">
                <label htmlFor="AEmailId" className="auto-Label">
                  Administrator Name<span className="mandatory">*</span>
                </label>
                <input
                  id="inputReadonly"
                  name="projectsInfra.powerUser"
                  placeholder="Enter Administrator Name"
                  value={secondDropdownValue ? secondDropdownValue.name : ""}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div>
            <div className="sub-heading">Other Details</div>
            <div className="text-field">
              <div className="text-field1">
                <label htmlFor="plannedStartDate">Planned Start Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="date-select"
                    slots={{
                      openPickerIcon: CalendarMonthOutlinedIcon
                    }}
                    sx={{
                      ".MuiInputBase-input": {
                        width: "276px",
                        height: "7px",
                        opacity: 0.5
                      }
                    }}
                    value={dayjs(inputValue.projectDetails.plannedStartDate)}
                    onChange={handlePlannedStartDateChange}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </div>
              <div className="text-field2">
                <label htmlFor="plannedEndDate">Planned End Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="date-sel">
                    <DatePicker
                      className="date-select"
                      slots={{
                        openPickerIcon: CalendarMonthOutlinedIcon
                      }}
                      onChange={handlePlannedEndDateChange}
                      value={dayjs(inputValue.projectDetails.plannedEndDate)}
                      format="DD/MM/YYYY"
                      sx={{
                        ".MuiInputBase-input": { width: "276px", height: "7px", opacity: 0.5 }
                      }}
                    />
                  </div>
                </LocalizationProvider>
              </div>
            </div>
            <div className="text-field">
              <div className="text-field1">
                <label htmlFor="appInfoClassification">App Info Classification</label>
                <input
                  type="text"
                  id="input"
                  name="projectDetails.appInfoClassification"
                  placeholder="Enter App Info Classification"
                  value={inputValue.projectDetails.appInfoClassification}
                  onChange={handleInputChange}
                />
                {appInfoClassificationErrorMessage && (
                  <div className="error-message">{appInfoClassificationErrorMessage}</div>
                )}
              </div>
              <div className="text-field2">
                <label htmlFor="endUserType">End-user Type</label>
                <FormControl className="onboard-dropdown">
                  <Select
                    labelId="input-label"
                    id="dropdown-input"
                    name="projectDetails.endUserType"
                    value={inputValue.projectDetails.endUserType || ""}
                    IconComponent={ExpandMoreIcon}
                    className={`${
                      inputValue.projectDetails.endUserType ? "selected-white" : "grey-class"
                    }`}
                    size="small"
                    onChange={handleInputChange}
                    displayEmpty
                    renderValue={(selected) => selected || "Choose End-user Type"}
                  >
                    <MenuItem className="onboard-menu-item" value="EmployerFacing">
                      Employer facing
                    </MenuItem>
                    <MenuItem className="onboard-menu-item" value="CustomerFacing">
                      Customer facing
                    </MenuItem>
                    <MenuItem className="onboard-menu-item" value="DealerFacing">
                      Dealer facing
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="devType">Development Type</label>
              <FormControl className="onboard-dropdown">
                <Select
                  labelId="input-label"
                  id="dropdown-input"
                  name="devType"
                  value={inputValue.devType || ""}
                  IconComponent={ExpandMoreIcon}
                  className={`${inputValue.devType ? "selected-white" : "grey-class"}`}
                  size="small"
                  onChange={handleInputChange}
                  displayEmpty
                  renderValue={(selected) => selected || "Choose Development Type"}
                >
                  <MenuItem className="onboard-menu-item" value="InhouseDevelopment">
                    Inhouse development
                  </MenuItem>
                  <MenuItem className="onboard-menu-item" value="OutsourcedDevelopment">
                    Outsourced development
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="text-field2">
              <label htmlFor="techStack">Tech Stack</label>
              <input
                type="text"
                id="input"
                name="projectDetails.techStack"
                placeholder="Enter Tech Stack"
                value={inputValue.projectDetails.techStack}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <Divider />
          <DialogActions>
            <div className="button">
              <CustomButton
                type="submit"
                sx={{
                  background: "#00677f",
                  borderRadius: 0.5,
                  color: "#FFFFFF",
                  fontFamily: "inherit",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "18px",
                  px: "25px",
                  py: "6px",
                  textTransform: "Capitalize",
                  "&:hover": { backgroundColor: "#00677f" }
                }}
              >
                Save
              </CustomButton>
            </div>
          </DialogActions>
        </form>
      </Box>
      <div>
        <Dialog
          className="success-box"
          open={successBoxOpen}
          onClose={handlesuccessBoxClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "100%",
                maxWidth: "574px",
                maxHeight: "369px",
                backgroundColor: "#000",
                borderRadius: "15px"
              }
            }
          }}
        >
          <div>
            <CloseIcon className="close-icon" onClick={handleClose}></CloseIcon>
          </div>
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              mb: "45px"
            }}
          >
            <div className="success-image">
              <Successfull />
            </div>
            <div className="success-message1">Successfully Resubmitted</div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default EditProject;

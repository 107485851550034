import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { getFirstWebpageId } from "../../../api/getFirstWebpage";
import { useProjectsByEmail } from "../../../api/useGetProjectsByEmail";
import { useProjectSummaryData } from "../../../api/useProjectSummaryData";
// import { useProjectWebPagesData } from "../../../api/useProjectWebPages";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Divider, Grid, OutlinedInput, SelectChangeEvent, Tooltip } from "@mui/material";
import { useAppSelector } from "../../../store";
// import BackArrow from "../../../components/Svg/BackArrow";
// import { formatNumber } from "../Format";
import { OverallCEWithGraph } from "../../../api/usePageOverallCEWithGraph";
// import useCeConversionFixed from "../../../utils/useCeConversionFixed";
// import useCeConversion from "../../../utils/useCeConversion";
import { getFirstContainerId } from "../../../api/getFirstContainer";
import CustomBasicDropdown from "../../../components/CustomBasicDropdown";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { returnDropDownOptionsFromAnyObj } from "../../../utils/common";
import { formatDate } from "../../../utils/useFormatDate";

export const ProjectSummaryHeader = ({
  webpageId,
  projectId,
  setJobId,
  setPerVisitView,
  isStatic,
  pageOverallCEWithGraph
}: {
  webpageId: string | undefined;
  projectId: string | undefined;
  setJobId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setPerVisitView: React.Dispatch<React.SetStateAction<boolean>>;
  isStatic: boolean;
  pageOverallCEWithGraph: OverallCEWithGraph;
}) => {
  const nav = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const emailId = useAppSelector((state) => state.auth.emailId);
  const { data: projects } = useProjectsByEmail({ emailId });
  // const { data: webpages } = useProjectWebPagesData(projectId!, true);
  const { data: projectSummaryData } = useProjectSummaryData(projectId!);
  const { platform } = useParams();
  const handleProjectSelect = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    if (!value) {
      enqueueSnackbar("Can't find projectId", {
        variant: "warning",
        autoHideDuration: 2000
      });
      return;
    }
    const navToProject = async () => {
      let url = `/dashboard/carbon/${String(value)}/${platform!}/`;
      if (platform! === "client") {
        try {
          const [error, pageId] = await getFirstWebpageId(String(value));
          if (error) throw error;
          if (pageId === null) {
            enqueueSnackbar("No Web pages Available", {
              variant: "warning",
              autoHideDuration: 2000
            });
            return;
          }
          url += `${pageId}`;
          setJobId(undefined);
          setPerVisitView(false);
        } catch (error) {
          enqueueSnackbar("Something went wrong", {
            variant: "warning",
            autoHideDuration: 2000
          });
        }
      } else if (platform! === "server") {
        try {
          const [error, containerId] = await getFirstContainerId(String(value));
          if (error) throw error;
          if (containerId === null) {
            enqueueSnackbar("No Containers Available", {
              variant: "warning",
              autoHideDuration: 2000
            });
            const [clientError, pageId] = await getFirstWebpageId(String(value));
            if (clientError) throw clientError;
            if (pageId === null) {
              enqueueSnackbar("Client platform not available for this project", {
                variant: "warning",
                autoHideDuration: 2000
              });
              return;
            }
            enqueueSnackbar("Redirecting to Client", {
              variant: "success",
              autoHideDuration: 3000
            });
            url = `/dashboard/carbon/${String(value)}/client/${pageId}`;
            nav(url);
            return;
          }
          url += `${containerId}`;
        } catch (error) {
          enqueueSnackbar("Something went wrong", {
            variant: "warning",
            autoHideDuration: 2000
          });
        }
      }
      nav(url);
    };
    navToProject();
  };
  if (!projectSummaryData) {
    return <div>Loading...</div>;
  }
  const projectOptions = projects ? returnDropDownOptionsFromAnyObj(projects, "name", "id") : [];
  const projectOptionsWithCustomClass = projectOptions?.map((itm) => ({
    ...itm,
    className: `menu-item${projectId === itm.value.toString() ? " active-menu-item" : ""}`
  }));

  return (
    <Grid className="page-selection-grid">
      <Grid className="grid-title">
        {/* <BackArrowComponent /> */}
        <CustomBasicDropdown
          className="PageDropdown title-dropdown"
          variant="standard"
          IconComponent={ExpandMoreIcon}
          input={<OutlinedInput />}
          renderValue={(selected) => (
            <Tooltip title={projectSummaryData?.name}>
              <div>{selected as string}</div>
            </Tooltip>
          )}
          options={projectOptionsWithCustomClass}
          value={projectSummaryData?.abbr}
          onChange={handleProjectSelect}
        />
        <Divider className="divider-vertical" orientation="vertical" flexItem />
        <div className="secondary-text">
          Updated on {formatDate(projectSummaryData?.client?.updatedDate)}
        </div>
      </Grid>
      {/* <Grid className="grid-title">
        <Tooltip title={useCeConversion(projectSummaryData?.allCE)}>
          <div className="label1" style={{ marginLeft: "4px" }}>{`${useCeConversionFixed(
            projectSummaryData?.allCE
          )} `}</div>
        </Tooltip>

        <div className="secondary-text">
          <div>Total Carbon Emission ≈ </div>
          <Tooltip title={`${projectSummaryData?.allEC} kWh`}>
            <div style={{ marginLeft: "4px" }}>{` ${formatNumber(projectSummaryData?.allEC).toFixed(
              3
            )} kWh `}</div>
          </Tooltip>
        </div>
        {
          <Divider
            className="divider-vertical-project-summary secondary-text"
            orientation="vertical"
            flexItem
          />
        }

        <div className="label1" style={{ marginLeft: "4px" }}>
          <Tooltip title={`${projectSummaryData?.allCC} €`}>
            <div>{`${formatNumber(projectSummaryData?.allCC).toFixed(3)} €`}</div>
          </Tooltip>
        </div>
        <div className="secondary-text">
          <div>Total Carbon Credit ≈ </div>
          <Tooltip title={projectSummaryData?.allTrees}>
            <div style={{ marginLeft: "4px" }}>
              {" "}
              {`${Math.round(projectSummaryData?.allTrees)} trees`}{" "}
            </div>
          </Tooltip>
        </div>
      </Grid> */}
      <Grid className="grid-title">
        <SettingsButtonComponent isStatic={isStatic} projectId={projectId} />
      </Grid>
    </Grid>
  );
};

// const BackArrowComponent = () => {
//   const nav = useNavigate();
//   return (
//     <div
//       style={{ cursor: "pointer" }}
//       onClick={() =>
//         nav(`../../../../`, {
//           replace: false,
//           relative: "path"
//         })
//       }
//     >
//       <BackArrow />
//     </div>
//   );
// };
const SettingsButtonComponent = ({
  projectId,
  isStatic
}: {
  projectId: string | undefined;
  isStatic: boolean;
}) => {
  const nav = useNavigate();

  return (
    <CustomButton
      variant="outlined"
      className="settings-button"
      onClick={() =>
        nav(`../../../../project-settings/${projectId!}`, {
          replace: false,
          relative: "path",
          state: isStatic
        })
      }
      startIcon={<SettingsOutlinedIcon fontSize="small" className="settings-icon" />}
    >
      Settings
    </CustomButton>
  );
};

import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./errorPage.style.scss";
import CustomButton from "../../components/CustomButton/CustomButton";

export const ErrorPage = () => {
  const nav = useNavigate();
  const { type } = useParams();
  return (
    <Container component="main" className="errorContainer">
      <Typography className="header">{type}</Typography>
      <Typography variant="h6">Something went wrong</Typography>
      <p>Sorry we were unable to find that page</p>
      {type === "401" && (
        <CustomButton
          onClick={() => {
            nav("/login");
          }}
        >
          Back to Login
        </CustomButton>
      )}
    </Container>
  );
};

export default function ApproveUser() {
  return (
    <svg width="96" height="72" viewBox="0 0 96 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="48" cy="36.999" r="28" fill="white" />
      <path
        d="M48.3077 45.054C49.9887 45.054 51.4237 46.489 51.4237 48.17C51.4237 49.892 49.9887 51.286 48.2667 51.286C46.5447 51.286 45.1097 49.892 45.1097 48.17C45.1097 46.448 46.5037 45.054 48.3077 45.054ZM50.5217 42.102H45.8477L45.8887 41.651C45.8477 37.469 46.0527 36.977 48.5127 34.23C50.4397 32.139 50.7677 31.606 50.7677 30.417C50.7677 29.064 49.8247 28.203 48.3897 28.203C46.6267 28.203 45.6017 29.351 45.5197 31.483H41.5427C41.6247 28.818 42.0347 27.465 43.0597 26.235C44.4537 24.636 46.5037 23.816 49.0867 23.816C53.4327 23.816 56.0567 26.153 56.0567 30.007C56.0567 32.262 55.5237 33.287 52.7767 36.321C50.6857 38.576 50.5217 38.904 50.5217 41.159V42.102Z"
        fill="#80BD24"
      />
      <circle opacity="0.2" cx="87.5" cy="53.5" r="2.5" fill="white" />
      <circle opacity="0.2" cx="9.5" cy="58.5" r="3" stroke="white" />
      <circle opacity="0.2" cx="85" cy="26" r="1.5" stroke="white" />
      <circle opacity="0.2" cx="11" cy="28" r="2" fill="#D9D9D9" />
    </svg>
  );
}

import "./index.scss";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip
} from "@mui/material";
import Success from "../../components/Svg/Success";
import { createNewAction } from "../../api/useActionUsers";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import ApproveUser from "../../components/Svg/ApproveUser";
import UserDetailsDialog from "./userDetailsSidePanal";
import { useQueryClient } from "@tanstack/react-query";
import CustomButton from "../../components/CustomButton/CustomButton";

export const UsersList = ({ userlist, filters, valueradio }) => {
  const [isPopupOpen, setPopupOpen] = React.useState(false);
  const [selectUser, setSelectUser] = React.useState({
    id: "",
    action: "",
    username: ""
  });
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(userlist);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedUser, setselectedUser] = useState([]);
  const [editingProjectId, setEditingProjectId] = useState(null);

  const queryClient = useQueryClient();

  const mutation = createNewAction(
    (data, variables, context) => {},
    (error) => {
      console.log("Error:", error);
    }
  );

  const handleActionClick = (id, action, username) => {
    setSelectUser({
      id,
      action: (action === "Approve" ? "Approve" : "") || (action === "Reject" ? "Reject" : ""),
      username
    });
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    queryClient.invalidateQueries("userData");
  };
  const handleSuccessPopupClose = () => {
    setSuccessModalOpen(false);
  };

  const handleConfirm = () => {
    const { id, action } = selectUser;
    mutation.mutate(
      {
        id,
        action
      },
      {
        onSuccess: () => {
          setSuccessModalOpen(true);
          queryClient.invalidateQueries("userData");
        }
      }
    );
    setPopupOpen(false);
  };

  const openDialog = (user) => {
    setIsDialogOpen(true);
    setselectedUser(user);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setEditingProjectId(null);
    queryClient.invalidateQueries("userData");
  };

  useEffect(() => {
    const projectMatches = (user, prjct) => {
      return user.projects.some((project) => project.projectAbbr === prjct);
    };
    // Filtering logic based on filters and userData
    const newFilteredUsers = userlist?.filter((user) => {
      const roleMatch = filters.role === "All" || user.applicationRole === filters.role;
      const statusMatch = filters.status === "All" || user.powerUserAccessReq === filters.status;
      const prjctMatch = filters.prjct === "All" || projectMatches(user, filters.prjct);
      if (valueradio === "role") {
        return roleMatch && prjctMatch;
      } else {
        return prjctMatch && statusMatch;
      }
    });

    setFilteredUsers(newFilteredUsers);
  }, [userlist, filters]);
  return (
    <div className="projectList">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Application Role</TableCell>
              <TableCell align="left">Projects</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers?.map((row, i) => (
              <TableRow className="row" key={i} style={{ height: "50px" }}>
                <TableCell className="cell-design1">
                  <Grid container spacing={1} alignItems="stretch" flexWrap="nowrap">
                    <Grid item sx={{ ml: 2 }}>
                      <Typography sx={{ cursor: "pointer" }}>{row.name}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell sx={{ ml: 6 }} className="cell-design">
                  <Grid container spacing={1} alignItems="stretch" flexWrap="nowrap">
                    <Grid item sx={{ ml: 1 }}>
                      <Typography sx={{ cursor: "pointer" }}>{row.applicationRole}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell sx={{ ml: 7 }} className="cell-design">
                  <Grid container spacing={1} alignItems="stretch" flexWrap="nowrap">
                    <Grid item sx={{ ml: 1 }}>
                      {row.projects && Array.isArray(row.projects) && row.projects.length > 6 ? (
                        <div>
                          {row.projects
                            .filter((project) => project.projectAbbr !== null)
                            .slice(0, 6)
                            .map((project, index, array) => (
                              <React.Fragment key={project.id}>
                                <Tooltip title={project.projectName}>
                                  <span>
                                    {project.projectAbbr}
                                    {index < array.length - 1 && ", "}
                                  </span>
                                </Tooltip>
                              </React.Fragment>
                            ))}
                          <span className="blue-text">+{row.projects.length - 6} Others</span>
                        </div>
                      ) : (
                        <div className="project-abbreviations">
                          {row.projects && Array.isArray(row.projects)
                            ? row.projects
                                .filter((project) => project.projectAbbr !== null)
                                .map((project, index, array) => (
                                  <React.Fragment key={project.id}>
                                    <Tooltip title={project.projectName}>
                                      <span>
                                        {project.projectAbbr}
                                        {index < array.length - 1 && ", "}
                                      </span>
                                    </Tooltip>
                                  </React.Fragment>
                                ))
                            : "No projects"}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell className="cell-design">
                  <Grid container spacing={1} alignItems="stretch" flexWrap="nowrap">
                    <Grid item sx={{ ml: 1 }}>
                      {row.powerUserAccessReq === "Pending" ? (
                        <Box sx={{ display: "flex" }} className="actionColumn">
                          <Typography
                            sx={{ mr: 1 }}
                            className="successFontColor"
                            onClick={() => handleActionClick(row.id, "Approve", row.name)}
                          >
                            Approve
                          </Typography>
                          <Typography
                            className="rejectFontColor"
                            onClick={() => handleActionClick(row.id, "Reject", row.name)}
                          >
                            Reject
                          </Typography>
                        </Box>
                      ) : null}
                      <Typography
                        variant="text"
                        style={{ color: "#FF5733 " }}
                        onClick={() => openDialog(row)}
                      >
                        Show Details
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={isPopupOpen}
        onClose={handlePopupClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "300px",
              height: "270px",
              backgroundColor: "#252525",
              borderRadius: "10px"
            }
          }
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <div className="heading">
            {selectUser.action === "Approve" ? "Approve" : "Reject"} User
          </div>
          <div className="req-cancel">
            <CloseIcon onClick={handlePopupClose} sx={{ cursor: "pointer" }}></CloseIcon>
          </div>
        </DialogTitle>

        <Divider />

        <div className="resSvg" style={{ textAlign: "center" }}>
          <ApproveUser />
        </div>
        <div style={{ textAlign: "center" }}>Are you sure you want to {selectUser.action}</div>

        <div style={{ textAlign: "center", paddingBottom: "12px" }}>{selectUser.username}?</div>

        <Divider className="reqdivider" />
        <DialogActions
          className="actionButton"
          sx={{ display: "flex", justifyContent: "center", color: "#FFFFFF" }}
        >
          <CustomButton
            onClick={handleConfirm}
            sx={{
              width: "84px",
              height: "30px",
              background: "#00677F",
              borderRadius: "3px",
              color: "#FFFFFF",
              "&:hover": {
                background: "#00677F"
              },

              "& .MuiButton-label": {
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "14px"
              }
            }}
          >
            Yes
          </CustomButton>
          <CustomButton
            onClick={handlePopupClose}
            sx={{
              width: "84px",
              height: "30px",
              background: "#252525",
              borderRadius: "3px",
              border: "1px solid #00677F",
              color: "#FFFFFF",
              "&:hover": {
                background: "#252525"
              },

              "& .MuiButton-label": {
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "14px"
              }
            }}
          >
            No
          </CustomButton>
        </DialogActions>
      </Dialog>
      {isSuccessModalOpen && (
        <Dialog
          open={isSuccessModalOpen}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "285px",
                height: "238px",
                backgroundColor: "#252525",
                borderRadius: "10px"
              }
            }
          }}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="req-cancel">
              <CloseIcon onClick={handleSuccessPopupClose} sx={{ cursor: "pointer" }}></CloseIcon>
            </div>
          </DialogTitle>
          <div className="resSvg" style={{ textAlign: "center" }}>
            <Success />
          </div>
          <div style={{ textAlign: "center" }}>Successful</div>
          <div style={{ textAlign: "center" }}>
            {selectUser.action === "Approve"
              ? "User Approved successfully"
              : "User Rejected successfully"}
          </div>
        </Dialog>
      )}

      {
        <UserDetailsDialog
          open={isDialogOpen}
          onClose={closeDialog}
          user={selectedUser}
          editingProjectId={editingProjectId}
          setEditingProjectId={setEditingProjectId}
        />
      }
    </div>
  );
};

import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { axiosClient } from "../globals/client/axios";
import { REGISTER } from "./paths";

const registerUser = async ({ emailId, password }: IRegisterData) => {
  return await axiosClient.post(REGISTER, { emailId, password });
};

export const useRegisterMutation = (onRegisterSuccess: onSuccess, onError: any) => {
  return useMutation({
    mutationFn: registerUser,
    onSuccess: onRegisterSuccess,
    onError
  });
};

interface IRegisterData {
  emailId: string;
  password: string;
}

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: IRegisterData,
  context: unknown
) => unknown;

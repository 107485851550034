import React from "react";

export default function Memory() {
  return (
    <svg width="68" height="59" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.0047 40.749C32.0239 42.6559 30.4833 44.212 28.5763 44.212H19.4051C17.5205 44.212 15.9892 42.6909 15.9766 40.8063L15.9595 38.252C15.9595 36.052 17.7395 34.252 19.9595 34.252H27.9795C30.1795 34.252 31.9595 36.032 31.9795 38.232L32.0047 40.749Z"
        fill="#6CB2C2"
      />
      <path
        d="M36.9995 20.2527V24.5927C36.9995 25.6527 37.4195 26.6727 38.1795 27.4327L39.8195 29.0727C40.5795 29.8327 40.9995 30.8527 40.9995 31.9127V34.2527C40.9995 38.5248 39.4331 41.748 36.0324 43.2643C35.0364 43.7084 34.0159 42.8853 34.0121 41.7948L33.9995 38.2127C33.9595 34.9327 31.2595 32.2527 27.9995 32.2527H19.9795C16.6595 32.2527 13.9795 34.9527 13.9795 38.2527V41.8212C13.9795 42.9097 12.9653 43.7365 11.9681 43.3001C8.52906 41.7952 6.99951 38.5506 6.99951 34.2527V14.2527C6.99951 9.74916 8.66679 6.37702 12.4765 4.9839C13.4796 4.61711 14.4454 5.45398 14.4301 6.52187L14.3795 10.0527C14.3595 10.9927 14.6995 11.8927 15.3395 12.5727C15.9995 13.2527 16.8595 13.6327 17.8795 13.6527C18.9079 13.6527 19.8236 13.2272 20.4485 12.5345C20.6729 12.2858 21.1026 12.2758 21.3395 12.5127C21.9795 13.1927 22.8595 13.5927 23.8795 13.6127C24.9082 13.6127 25.8465 13.1605 26.4867 12.4328C26.6971 12.1937 27.1279 12.1746 27.3395 12.4127C27.9795 13.0927 28.8595 13.4927 29.8795 13.5127C31.7995 13.5127 33.3395 12.0127 33.3795 10.0727L33.4442 6.44973C33.4628 5.40639 34.4122 4.6057 35.3955 4.95482C39.2534 6.32444 40.9995 9.72407 40.9995 14.2527V16.2527C40.9995 17.3527 40.0995 18.2527 38.9995 18.2527C37.8995 18.2527 36.9995 19.1527 36.9995 20.2527Z"
        fill="#6CB2C2"
      />
      <path
        d="M17.9067 4.2621C18.7695 4.25654 19.4678 4.96217 19.4533 5.82485L19.3798 10.172C19.3598 10.992 18.6998 11.652 17.8798 11.652H17.8598C17.0198 11.632 16.3598 10.952 16.3798 10.112V5.7989C16.3798 4.954 17.0619 4.26755 17.9067 4.2621Z"
        fill="#6CB2C2"
      />
      <path
        d="M23.9167 4.25195C24.7757 4.25195 25.4679 4.95601 25.4533 5.81486L25.3798 10.152C25.3598 10.952 24.6998 11.612 23.8798 11.612H23.8598C23.0198 11.592 22.3598 10.912 22.3798 10.092L22.3798 5.78882C22.3798 4.94003 23.0679 4.25195 23.9167 4.25195Z"
        fill="#6CB2C2"
      />
      <path
        d="M29.9261 4.2617C30.7814 4.26708 31.4677 4.9698 31.4529 5.82497L31.3798 10.0315C31.3598 10.8515 30.6998 11.5115 29.8798 11.5115H29.8598C29.0198 11.4915 28.3598 10.8115 28.3798 9.99148V5.7983C28.3798 4.94585 29.0737 4.25632 29.9261 4.2617Z"
        fill="#6CB2C2"
      />
    </svg>
  );
}

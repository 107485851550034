export default function Successfull() {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49 98C75.95 98 98 75.95 98 49C98 22.05 75.95 0 49 0C22.05 0 0 22.05 0 49C0 75.95 22.05 98 49 98Z"
        fill="#32CC66"
      />
      <path
        d="M28 48.867L41.867 62.734L69.65 35"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import {
  Grid,
  // FormControl,
  // Select,
  // OutlinedInput,
  // MenuItem,
  // SelectChangeEvent,
  Divider,
  Typography,
  Box
} from "@mui/material";

// import { formatDate } from "../Format";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useProjectWebPagesData } from "../../../api/useProjectWebPages";
// import { useNavigate } from "react-router-dom";
// import { getFirstWebpageId } from "../../../api/getFirstWebpage";
// import { useSnackbar } from "notistack";
import { ProjectSummary } from "../../../api/useProjectSummaryData";
// import { getFirstContainerId } from "../../../api/getFirstContainer";
import { useEffect, useState } from "react";
import { ToggleButtonGroup } from "../../../components/ToggleButtonGroup/ToggleButtonGroup";
import ProjectScanForm from "../../../components/ProjectScanForm/ProjectScanForm";
import ProjectScanIcon from "../../../components/Svg/ProjectScanIcon";
import CustomButton from "../../../components/CustomButton/CustomButton";

interface ChildComponentProps {
  setStatic: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string | undefined;
  isStatic: boolean;
  projectSummaryData: ProjectSummary | undefined;
  selectedPlatform: string;
  setPerVisitView: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PlatformSectionHeader: React.FC<ChildComponentProps> = ({
  projectId,
  setStatic,
  projectSummaryData,
  selectedPlatform,
  setPerVisitView,
  isStatic
}) => {
  // const [disableRtToggle, setDisableRtToggle] = useState(false);
  const [openScanDetails, setOpenScanDetails] = useState(false);
  const [isGaLocked, setIsGaLocked] = useState(projectSummaryData?.isGaLock ?? false);

  useEffect(() => {
    setIsGaLocked(projectSummaryData?.isGaLock ?? false);
  }, [projectSummaryData?.isGaLock]);

  useEffect(() => {
    if (selectedPlatform === "client") {
      // const hasClientData = projectSummaryData?.client != null;
      const hasClientRtData = projectSummaryData?.clientRT != null;
      // setDisableRtToggle(!(hasClientData && hasClientRtData));
      if (!hasClientRtData) {
        setStatic(true);
      } else {
        setStatic(false);
      }
    }
  });
  const handleScanModalOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpenScanDetails(true);
  };
  const handleScanModalClose = () => {
    setOpenScanDetails(false);
  };
  const { data: webpages } = useProjectWebPagesData(projectId!, true);

  // const nav = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  // const handleDropdownChange = (event: SelectChangeEvent) => {
  //   setPerVisitView(false);
  //   const value = event.target.value;
  //   const navToProject = async () => {
  //     let url = `/dashboard/carbon/${String(projectId)}/${value}/`;
  //     if (value === "client") {
  //       try {
  //         const [error, pageId] = await getFirstWebpageId(String(projectId));
  //         if (error) throw error;
  //         if (pageId === null) {
  //           enqueueSnackbar("No Web pages Available", {
  //             variant: "warning",
  //             autoHideDuration: 2000
  //           });
  //           return;
  //         }
  //         url += `${pageId}`;
  //       } catch (error) {
  //         enqueueSnackbar("Something went wrong", {
  //           variant: "warning",
  //           autoHideDuration: 2000
  //         });
  //       }
  //     } else if (value === "server") {
  //       try {
  //         const [error, containerId] = await getFirstContainerId(String(projectId));
  //         if (error) throw error;
  //         if (containerId === null) {
  //           enqueueSnackbar("No Containers Available", {
  //             variant: "warning",
  //             autoHideDuration: 2000
  //           });
  //           return;
  //         }
  //         url += `${containerId}`;
  //       } catch (error) {
  //         enqueueSnackbar("Something went wrong", {
  //           variant: "warning",
  //           autoHideDuration: 2000
  //         });
  //       }
  //     }
  //     nav(url);
  //   };
  //   navToProject();
  // };

  // const dropdownItems = [
  //   { name: "Client", value: "client" },
  //   { name: "Server", value: "server" }
  // ];
  // const filteredDropdownItems = dropdownItems.filter((item) =>
  //   Object.keys(projectSummaryData ?? {}).includes(item.value)
  // );

  return (
    <>
      <Typography className="breadcrumbs-typography platform-breadcrumbs">
        <span style={{ cursor: "pointer" }}>{projectSummaryData?.name}</span> /
      </Typography>
      <Grid className="page-selection-grid">
        <Grid className="grid-title">
          {/* <FormControl className="drop ">
            <Select
              className="PageDropdown title-dropdown"
              value={selectedPlatform}
              variant="standard"
              IconComponent={ExpandMoreIcon}
              onChange={handleDropdownChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                const label = dropdownItems.find((itm) => itm.value === selected)?.name;
                return label;
              }}
            >
              {filteredDropdownItems.map((itm) => (
                <MenuItem className="menu-item" key={itm.name} value={itm.value}>
                  {itm.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <Grid className="title-dropdown" mr={1}>
            Client
          </Grid>
          <Divider className="divider-vertical" orientation="vertical" flexItem />
          {selectedPlatform === "client" ? (
            <div className="Carbon-insigts-platform-section-heading">
              <div className="secondary-text">
                ({webpages?.length} pages)
                <Divider className="divider-vertical" orientation="vertical" flexItem /> Overview
              </div>
              <div className="platform-section-header-actions">
                <ToggleButtonGroup
                  button1="Static Scan"
                  button2="Real Time"
                  active={isStatic}
                  setActive={setStatic}
                  isDisabled={true}
                />
                {isStatic && (
                  <Box>
                    <CustomButton
                      variant="contained"
                      startIcon={<ProjectScanIcon />}
                      onClick={handleScanModalOpen}
                      className={`scan-btn ${isGaLocked ? "disabled" : ""}`}
                      disabled={isGaLocked}
                    >
                      Start Scan
                    </CustomButton>
                    <ProjectScanForm
                      projectAbbr={projectSummaryData?.abbr}
                      openScanDetails={openScanDetails}
                      handleModalClose={handleScanModalClose}
                      projectId={projectId!}
                      setIsGaLocked={setIsGaLocked}
                    />
                  </Box>
                )}
              </div>
            </div>
          ) : (
            <div className="secondary-text">
              (3 Containers){" "}
              <Divider className="divider-vertical" orientation="vertical" flexItem /> Overview
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

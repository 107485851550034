import React from "react";

const EditConfigurationDetails = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.73463 2.10021L2.94546 7.16937C2.76463 7.36187 2.58963 7.74104 2.55463 8.00354L2.3388 9.89354C2.26296 10.576 2.75296 11.0427 3.42963 10.926L5.30796 10.6052C5.57046 10.5585 5.93796 10.366 6.11879 10.1677L10.908 5.09854C11.7363 4.22354 12.1096 3.22604 10.8205 2.00687C9.53713 0.799375 8.56296 1.22521 7.73463 2.10021Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.93555 2.94543C7.18638 4.55543 8.49305 5.78627 10.1147 5.9496"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 12.8334H12.25"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditConfigurationDetails;

import { Box, Divider } from "@mui/material";
import { useEffect, useRef } from "react";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDatePicker from "./CustomDatePicker";
import { DropdownOption } from "../../pages/Carbon/Page Section/Page Section Body/PageSectionBody";

export const CustomDropdown = ({
  isOpen,
  setIsOpen,
  selectedOption,
  setSelectedOption,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  position,
  isStatic,
  selectedPlatform
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOption: DropdownOption | null;
  setSelectedOption: React.Dispatch<React.SetStateAction<DropdownOption | null>>;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  position: string;
  isStatic?: boolean;
  selectedPlatform?: string;
}) => {
  const filterDropdown = useRef<HTMLDivElement>(null);
  const currentDate = dayjs();
  const dropdownOptions: DropdownOption[] =
    (selectedPlatform === "client" && !isStatic) || selectedPlatform === "server"
      ? [
          { label: "Today", value: "today" },
          { label: "Yesterday", value: "yesterday" },
          { label: "Last 7 days", value: "last-week" },
          { label: "Last 14 days", value: "last-two-weeks" }
        ]
      : [
          { label: "Today", value: "today" },
          { label: "Yesterday", value: "yesterday" },
          { label: "This Week", value: "week" },
          { label: "This Month", value: "month" },
          { label: "This Quarter", value: "quarter" },
          { label: "This Year", value: "year" },
          { label: "Custom Range", value: "custom" }
        ];
  const selectOption = (option: DropdownOption): void => {
    setSelectedOption(option);
    switch (option.value) {
      case "today":
        setStartDate(currentDate.startOf("day"));
        setEndDate(currentDate.endOf("day"));
        break;
      case "yesterday":
        setStartDate(currentDate.subtract(1, "day").startOf("day"));
        setEndDate(currentDate.subtract(1, "day").endOf("day"));
        break;
      case "week":
        setStartDate(currentDate.startOf("week"));
        setEndDate(currentDate.endOf("week"));
        break;
      case "month":
        setStartDate(currentDate.startOf("month"));
        setEndDate(currentDate.endOf("month"));
        break;
      case "quarter":
        setStartDate(currentDate.startOf("quarter"));
        setEndDate(currentDate.endOf("quarter"));
        break;
      case "year":
        setStartDate(currentDate.startOf("year"));
        setEndDate(currentDate.endOf("year"));
        break;
      case "last-week":
        setStartDate(currentDate.subtract(7, "day").startOf("day"));
        setEndDate(currentDate.endOf("day"));
        break;
      case "last-two-weeks":
        setStartDate(currentDate.subtract(14, "day").startOf("day"));
        setEndDate(currentDate.endOf("day"));
        break;
    }
    if (option.value !== "custom") {
      setIsOpen(false);
    }
  };
  const toggleDropdown = (e: any) => {
    setIsOpen(!isOpen);
    e.stopPropagation();
  };
  const handleClickOutside = (event: MouseEvent) => {
    const isInsideDatePicker = (event.target as Element).closest(".date-picker-container");
    const isCustomOption = (event.target as Element).closest(".custom");

    if (isCustomOption) {
      setIsOpen(true);
    } else if (isInsideDatePicker) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside as unknown as EventListener);

    return () => {
      document.removeEventListener("click", handleClickOutside as unknown as EventListener);
    };
  }, []);
  return (
    <div>
      <div className="dropdown">
        <div className="dropdown-header" onClick={toggleDropdown} ref={filterDropdown}>
          <Box>{selectedOption?.label} </Box>
          <Box className={isOpen ? "rotate" : ""}>
            <ExpandMoreIcon />
          </Box>
        </div>
        {isOpen && (
          <div
            className="dropdown-container"
            style={{ right: position === "left" ? 0 : undefined }}
          >
            <div className="menu">
              {dropdownOptions.map((option) => (
                <div
                  key={option.value}
                  onClick={() => selectOption(option)}
                  className={`menu-item ${
                    selectedOption?.value === option.value ? "active-menu-item" : ""
                  } ${option.value === "custom" ? "custom" : ""}`}
                >
                  {option.label}
                </div>
              ))}
            </div>
            {selectedOption?.value === "custom" ? (
              <>
                <Divider orientation="vertical" flexItem />
                <CustomDatePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

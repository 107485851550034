import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/PrivateRouting/PrivateRouting";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import { Admin } from "../pages/Admin";
import { CIWrapper } from "../pages/Carbon/Wrapper";
import Overview from "../pages/Dashboard/Dashboard";
import { ErrorPage } from "../pages/ErrorPage";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "../pages/Login";
import EditProject from "../pages/Project Settings/EditProject/EditProject";
import { ProjectSettings } from "../pages/Project Settings/projectSettings";
import ResetPassword from "../pages/ResetPassword";
import Signup from "../pages/Signup/Signup";
import Suggestions from "../pages/Suggestion/Suggestions";
import Verify from "../pages/Verification";

export const RouterConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="dashboard" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/paswordReset" element={<ResetPassword />} />
      <Route element={<PrivateRoute />}>
        <Route
          path="admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path="error/:type" element={<ErrorPage />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path="project-settings/:projectId" element={<ProjectSettings />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path="app/project-settings/:projectId" element={<ProjectSettings />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path="/project-settings/editProject/:projectId" element={<EditProject />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path="dashboard">
          <Route path="" element={<Overview />} />
          <Route path="carbon/:projectId/:platform/:webpageId/*" element={<CIWrapper />} />
          <Route path="suggestions/:projectId/:webpageId" element={<Suggestions />} />
          <Route path="project-settings/:projectId" element={<ProjectSettings />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default RouterConfig;

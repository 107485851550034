import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ClickAwayListener,
  Modal,
  Typography,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent
  // IconButton,
  // Tooltip
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./index.scss";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useUsersData } from "../../../api/useUsersData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createOnboard, DetailsBase } from "../../../api/useCreateOnboardData";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Successfull from "../../../components/Svg/Successfull";
import Success from "../../../components/Svg/Success";
import { useNewAddedData } from "../../../api/getNewAddedData";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { useloggedInUserDetails } from "../../../api/getLoggedInUserDetails";
import AccessReq from "../../../components/Svg/Access";
import { userequestAccess } from "../../../api/useRequestAccess";
import CustomButton from "../../../components/CustomButton/CustomButton";
// import { InfoOutlined } from "@mui/icons-material";

interface Option {
  id: number;
  createdDate: string;
  name: string;
  emailId: string;
  password: string;
  expiryDate: string;
}

const Onboard = ({
  setActiveTab
}: {
  setActiveTab: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { mutate } = useMutation(createOnboard, {
    onSuccess: (data) => {
      setsuccessBoxOpen(true);
    },
    onError: () => {
      console.log("There is an error");
    }
  });
  const queryClient = useQueryClient();
  const onRequestSuccess = (data: any) => {
    setRequested(true);
    reqsetOpen(false);
    queryClient.invalidateQueries();
  };
  const { mutate: mutate1 } = userequestAccess(onRequestSuccess);

  const emailId = useSelector((state: RootState) => state.auth.emailId);
  const { refetch: refetchTableData } = useNewAddedData(emailId);
  const [abbreviationErrorMessage, setAbbreviationErrorMessage] = useState("");
  // const [depNamelimitation, setdepNamelimitationt] = useState("");
  const [open, setOpen] = useState(false);
  const [reqopen, reqsetOpen] = useState(false);
  const [requested, setRequested] = useState(false);
  const [firstDropdownValue, setFirstDropdownValue] = useState<Option | null>(null);
  const [secondDropdownValue, setSecondDropdownValue] = useState<Option | null>(null);
  const [successBoxOpen, setsuccessBoxOpen] = useState(false);
  const [idVerify, setidVerify] = useState("");
  const [depNameLimitation, setDepNameLimitation] = useState("");

  const [inputValue, setInputValue] = useState<DetailsBase>({
    name: "",
    projectAbbr: "",
    currentVersion: "",

    image: "",
    devType: "",
    projectMonitoring: {
      isToolAvailable: false,
      server: {
        monitoringType: "",
        domainUrl: ""
      },
      client: {
        monitoringType: "",
        domainUrl: ""
      }
    },
    department: {
      name: ""
    },
    projectsInfra: {
      appId: "",
      appType: "",
      networkType: "",
      hostingType: "",
      environment: "",
      deputyId: "",
      powerUser: "",
      deputyUserId: 0,
      powerUserId: 0
    },
    projectDetails: {
      description: "",
      appInfoClassification: "",
      techStack: "",
      endUserType: "",
      plannedStartDate: null,
      plannedEndDate: null
    }
  });
  const { data: allUsers } = useUsersData();
  const { data: userData, isSuccess: userDataSuccess } = useloggedInUserDetails();

  const firstDropdownOptions = allUsers?.filter(
    (option) => option.name !== (secondDropdownValue?.name ?? undefined)
  );

  const secondDropdownOptions = allUsers?.filter(
    (option) => option.name !== (firstDropdownValue?.name ?? undefined)
  );

  const handlereqAccess = () => {
    if (userDataSuccess) {
      mutate1({ Id: userData?.id });
    }
  };

  const handleFirstDropdownChange = (event: React.SyntheticEvent, value: Option | null) => {
    setFirstDropdownValue(value);
  };

  const handleSecondDropdownChange = (event: React.SyntheticEvent, value: Option | null) => {
    setSecondDropdownValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleReqmodalOpen = () => {
    reqsetOpen(true);
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
    reqsetOpen(false);
    setsuccessBoxOpen(false);
    refetchTableData();
  };
  const handlesuccessBoxClose = () => {
    setsuccessBoxOpen(false); // Close successBox
    refetchTableData();
    setActiveTab(false);
    setRequested(false);
  };
  const handleReqsuccessBoxClose = () => {
    setsuccessBoxOpen(false); // Close request successBox
    setRequested(false);
  };
  const resetForm = () => {
    setFirstDropdownValue(null);
    setSecondDropdownValue(null);
    setInputValue({
      name: "",
      projectAbbr: "",
      currentVersion: "",

      image: "",
      devType: "",
      department: {
        name: ""
      },
      projectMonitoring: {
        server: {
          monitoringType: "",
          domainUrl: ""
        },
        client: {
          monitoringType: "",
          domainUrl: ""
        },
        isToolAvailable: false
      },
      projectsInfra: {
        appId: "",
        appType: "",
        networkType: "",
        hostingType: "",
        environment: "",
        deputyId: "",
        powerUser: "",
        powerUserId: 0,
        deputyUserId: 0
      },
      projectDetails: {
        description: "",
        appInfoClassification: "",
        techStack: "",
        endUserType: "",
        plannedStartDate: null,
        plannedEndDate: null
      }
    });
  };

  const handleInputChange = (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = event.target;
    if (name.startsWith("projectMonitoring.client.monitoringType")) {
      const nestedFieldName = name.split(".")[2];

      // Update the nested fields in projectMonitoring
      setInputValue((prevFormData) => ({
        ...prevFormData,

        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          client: {
            ...prevFormData.projectMonitoring.client,
            [nestedFieldName]: value
          }
        }
      }));
    } else if (name.startsWith("projectMonitoring.server.monitoringType")) {
      const nestedFieldName = name.split(".")[2];

      // Update the nested fields in projectMonitoring
      setInputValue((prevFormData) => ({
        ...prevFormData,

        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          server: {
            ...prevFormData.projectMonitoring.server,
            [nestedFieldName]: value
          }
        }
      }));
    } else if (name === "projectMonitoring.isToolAvailable") {
      const isToolAvailable = value === "true";

      setInputValue((prevFormData) => ({
        ...prevFormData,
        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          isToolAvailable,
          client: {
            ...prevFormData.projectMonitoring.client,
            monitoringType: isToolAvailable ? "Instana" : "None"
          },
          server: {
            ...prevFormData.projectMonitoring.server,
            monitoringType: isToolAvailable ? "None" : "NA"
          }
        }
      }));
    } else if (
      name.startsWith("projectsInfra.") ||
      name.startsWith("projectDetails.") ||
      name.startsWith("department.")
    ) {
      const nestedFieldName = name.split(".")[1]; // Extract the nested field name

      // Check for department name limitation
      if (nestedFieldName === "name" && value.length > 7) {
        setDepNameLimitation("Max 7 characters can be used.");
        return; // Prevent further processing
      } else {
        setDepNameLimitation("");
      }

      setInputValue((prevFormData) => ({
        ...prevFormData,
        department: {
          ...prevFormData.department,
          [nestedFieldName]: value
        },

        projectsInfra: {
          ...prevFormData.projectsInfra,
          [nestedFieldName]: value
        },
        projectDetails: {
          ...prevFormData.projectDetails,
          [nestedFieldName]: value
        }
      }));
    } else {
      // Update non-nested fields
      let parsedValue: string | number = value;
      if (name === "projectAbbr") {
        if (value.length > 5) {
          setAbbreviationErrorMessage("Max 5 characters can be used.");
          return;
        } else {
          setAbbreviationErrorMessage("");
        }
        parsedValue = value.slice(0, 5).toUpperCase();
      }

      setInputValue((prevFormData) => ({
        ...prevFormData,
        [name]: parsedValue
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userId = parseInt(localStorage.id, 10);
    const deputyIdId = firstDropdownValue?.id;
    const powerUserId = secondDropdownValue?.id;
    const deputyId = firstDropdownValue ? firstDropdownValue.id.toString() : "";
    const powerUser = secondDropdownValue ? secondDropdownValue.id.toString() : "";
    if (deputyIdId === userId || powerUserId === userId) {
      const formData = {
        ...inputValue,
        projectsInfra: {
          ...inputValue.projectsInfra,
          deputyId,
          powerUser
        }
      };
      mutate(formData);
      handleClose();
      setActiveTab(false);
      refetchTableData();
      setidVerify("");
    } else {
      setidVerify("Logged in user should be selected either as Deputy or Admin.");
    }
  };

  // to prevent close of dialogue box on click of outside dialogue
  const handleClickAway = () => {
    setOpen(true);
  };

  return (
    <>
      <>
        {userData?.role !== "Collaborator" ? (
          <CustomButton
            className="buttonl"
            variant="contained"
            id="btn2"
            onClick={handleOpen}
            size="small"
          >
            <AddIcon fontSize="small" /> Onboard Project
          </CustomButton>
        ) : (
          <CustomButton
            className="buttonl"
            variant="contained"
            id="btn1"
            onClick={handleReqmodalOpen}
            size="small"
          >
            <AddIcon fontSize="small" /> Onboard Project
          </CustomButton>
        )}

        {(userData?.powerUserAccessReq !== null || "Pending" || "Rejected") && (
          <>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "760px",
                      backgroundColor: "#000",
                      borderRadius: "15px"
                    }
                  }
                }}
              >
                <div>
                  <DialogTitle sx={{ fontFamily: "inherit" }}>Onboarding Project</DialogTitle>
                  <div className="cancel">
                    <CloseIcon onClick={handleClose}></CloseIcon>
                  </div>
                </div>
                <Divider />

                <form onSubmit={handleSubmit}>
                  <div className="sub-heading">Team Details</div>
                  <div className="text-field">
                    <div className="text-field1">
                      <label htmlFor="depName">
                        Department Name<span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="input"
                        name="department.name"
                        placeholder=" Enter Department Name"
                        value={inputValue.department.name}
                        onChange={handleInputChange}
                      />
                      {depNameLimitation && (
                        <div className="error-message">{depNameLimitation}</div>
                      )}
                    </div>
                  </div>
                  <div className="sub-heading">Monitoring Details</div>
                  <div className="text-field">
                    <div className="text-field1">
                      <label htmlFor="MonitoringTool">
                        Monitoring Tool<span className="mandatory">*</span>
                      </label>
                      <FormControl className="onboard-dropdown">
                        <Select
                          labelId="input-label"
                          id="dropdown-input"
                          name="projectMonitoring.isToolAvailable"
                          IconComponent={ExpandMoreIcon}
                          value={inputValue.projectMonitoring.isToolAvailable.toString()}
                          size="small"
                          onChange={handleInputChange}
                          required
                        >
                          <MenuItem className="onboard-menu-item" value="true">
                            Yes
                          </MenuItem>
                          <MenuItem className="onboard-menu-item" value="false">
                            No
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/* {inputValue.projectMonitoring.isToolAvailable && ( // Check if monitoring tool is available
                    <div className="text-field">
                      <div className="text-field1">
                        <label htmlFor="client">
                          Client<span className="mandatory">*</span>
                          <Tooltip
                            title={
                              <Box className="field-guide-tooltip-option">
                                <div>
                                  NA -
                                  <span className="field-guide-tooltip-description">
                                    {" "}
                                    Client not present for the project
                                  </span>
                                </div>
                                <div>
                                  None -
                                  <span className="field-guide-tooltip-description">
                                    {" "}
                                    No monitoring tool, hence static scan for client
                                  </span>
                                </div>
                                <div>
                                  Instana -
                                  <span className="field-guide-tooltip-description">
                                    {" "}
                                    Real time scan through Instana
                                  </span>
                                </div>
                              </Box>
                            }
                            placement="right"
                          >
                            <IconButton className="field-guide">
                              <InfoOutlined className="field-guide-icon" />
                            </IconButton>
                          </Tooltip>
                        </label>
                        <FormControl className="onboard-dropdown">
                          <Select
                            labelId="input-label"
                            id="dropdown-input"
                            name="projectMonitoring.client.monitoringType"
                            value={inputValue?.projectMonitoring?.client?.monitoringType || ""}
                            IconComponent={ExpandMoreIcon}
                            className={`${
                              inputValue?.projectMonitoring?.client?.monitoringType
                                ? "selected-white"
                                : "grey-class"
                            }`}
                            size="small"
                            onChange={handleInputChange}
                            renderValue={(selected) => selected || "Choose Client Monitoring Type"}
                            displayEmpty
                            required
                          >
                            <MenuItem className="onboard-menu-item" value="NA">
                              NA
                            </MenuItem>
                            <MenuItem className="onboard-menu-item" value="None">
                              None
                            </MenuItem>
                            <MenuItem className="onboard-menu-item" value="Instana">
                              Instana
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="text-field2">
                        <label htmlFor="server">
                          Server<span className="mandatory">*</span>
                          <Tooltip
                            title={
                              <Box className="field-guide-tooltip-option">
                                <div>
                                  None -
                                  <span className="field-guide-tooltip-description">
                                    {" "}
                                    No monitoring tool, hence no scan for server
                                  </span>
                                </div>
                                <div>
                                  Instana -
                                  <span className="field-guide-tooltip-description">
                                    {" "}
                                    Real time scan through Instana
                                  </span>
                                </div>
                              </Box>
                            }
                            placement="right"
                          >
                            <IconButton className="field-guide">
                              <InfoOutlined className="field-guide-icon" />
                            </IconButton>
                          </Tooltip>
                        </label>
                        <FormControl className="onboard-dropdown">
                          <Select
                            labelId="input-label"
                            id="dropdown-input"
                            name="projectMonitoring.server.monitoringType"
                            value={inputValue?.projectMonitoring?.server?.monitoringType || ""}
                            IconComponent={ExpandMoreIcon}
                            className={`${
                              inputValue?.projectMonitoring?.server?.monitoringType
                                ? "selected-white"
                                : "grey-class"
                            }`}
                            size="small"
                            onChange={handleInputChange}
                            renderValue={(selected) => selected || "Choose Server Monitoring Type"}
                            displayEmpty
                            required
                          >
                            <MenuItem className="onboard-menu-item" value="None">
                              None
                            </MenuItem>
                            <MenuItem className="onboard-menu-item" value="Instana">
                              Instana
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )} */}

                  <div className="sub-heading">Project Details</div>
                  <div className="text-field">
                    <div className="text-field1">
                      <label htmlFor="name">
                        Project Name<span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="input"
                        name="name"
                        placeholder="Enter Project Name"
                        value={inputValue.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="text-field2">
                      <label htmlFor="AppprojectAbbr">
                        Project Abbreviation<span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="input"
                        name="projectAbbr"
                        placeholder="Enter Project Abbreviation"
                        value={inputValue.projectAbbr}
                        onChange={handleInputChange}
                        required
                      />
                      {abbreviationErrorMessage && (
                        <div className="error-message">{abbreviationErrorMessage}</div>
                      )}
                    </div>
                  </div>

                  <div className="text-field">
                    <div className="text-field1">
                      <label htmlFor="currentVersion">
                        Current Version<span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="input"
                        name="currentVersion"
                        placeholder="Enter Current Version"
                        value={inputValue.currentVersion}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="text-field2">
                      <label htmlFor="appId">
                        App ID<span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="input"
                        name="projectsInfra.appId"
                        placeholder="Enter App ID"
                        value={inputValue.projectsInfra.appId}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-field">
                    <div className="text-field1">
                      <label htmlFor="appType">
                        App Type<span className="mandatory">*</span>
                      </label>
                      <FormControl className="onboard-dropdown">
                        <Select
                          labelId="input-label"
                          id="dropdown-input"
                          name="projectsInfra.appType"
                          value={inputValue.projectsInfra.appType || ""}
                          IconComponent={ExpandMoreIcon}
                          className={`${
                            inputValue.projectsInfra.appType ? "selected-white" : "grey-class"
                          }`}
                          size="small"
                          onChange={handleInputChange}
                          displayEmpty
                          renderValue={(selected) => selected || "Choose App Type"}
                          required
                        >
                          {/* <MenuItem value="Mobile">Mobile</MenuItem> */}
                          <MenuItem className="onboard-menu-item" value="Web">
                            Web
                          </MenuItem>
                          {/* <MenuItem value="ECU">ECU</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="text-field2">
                      <label htmlFor="networkType">
                        Network Type<span className="mandatory">*</span>
                      </label>
                      <FormControl className="onboard-dropdown">
                        <Select
                          labelId="input-label"
                          id="dropdown-input"
                          name="projectsInfra.networkType"
                          value={inputValue.projectsInfra.networkType || ""}
                          IconComponent={ExpandMoreIcon}
                          className={`${
                            inputValue.projectsInfra.networkType ? "selected-white" : "grey-class"
                          }`}
                          size="small"
                          onChange={handleInputChange}
                          displayEmpty
                          renderValue={(selected) => selected || "Select Network Type"}
                          required
                        >
                          <MenuItem className="onboard-menu-item" value="Private">
                            Private
                          </MenuItem>
                          <MenuItem className="onboard-menu-item" value="Public">
                            Public
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="text-field">
                    <div className="text-field1">
                      <label htmlFor="hostingType">
                        Hosting Info<span className="mandatory">*</span>
                      </label>
                      <FormControl className="onboard-dropdown">
                        <Select
                          labelId="input-label"
                          id="dropdown-input"
                          name="projectsInfra.hostingType"
                          value={inputValue.projectsInfra.hostingType || ""}
                          IconComponent={ExpandMoreIcon}
                          className={`${
                            inputValue.projectsInfra.hostingType ? "selected-white" : "grey-class"
                          }`}
                          size="small"
                          onChange={handleInputChange}
                          displayEmpty
                          renderValue={(selected) => selected || "Select Hosting Info"}
                          required
                        >
                          <MenuItem className="onboard-menu-item" value="Green">
                            Green Hosted
                          </MenuItem>
                          <MenuItem className="onboard-menu-item" value="NonGreen">
                            Non Green Hosted
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="text-field2">
                      <label htmlFor="environment">
                        Environment Type<span className="mandatory">*</span>
                      </label>
                      <FormControl className="onboard-dropdown">
                        <Select
                          labelId="input-label"
                          id="dropdown-input"
                          name="projectsInfra.environment"
                          value={inputValue.projectsInfra.environment || ""}
                          IconComponent={ExpandMoreIcon}
                          className={`${
                            inputValue.projectsInfra.environment ? "selected-white" : "grey-class"
                          }`}
                          size="small"
                          onChange={handleInputChange}
                          displayEmpty
                          renderValue={(selected) => selected || "Select Environment Type"}
                          required
                        >
                          <MenuItem className="onboard-menu-item" value="INT">
                            INT
                          </MenuItem>
                          <MenuItem className="onboard-menu-item" value="DEV">
                            DEV
                          </MenuItem>
                          <MenuItem className="onboard-menu-item" value="PROD">
                            PROD
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div>
                    <div className="sub-heading">
                      User Details
                      {idVerify && <div className="error-message">{idVerify}</div>}
                    </div>
                    <div className="text-field-bottom">
                      <div className="text-field1">
                        <label htmlFor="DEmailId" className="auto-Label">
                          Deputy Name<span className="mandatory">*</span>
                        </label>
                        <Autocomplete
                          value={firstDropdownValue}
                          onChange={handleFirstDropdownChange}
                          sx={{ width: 340, height: 20 }}
                          size="small"
                          options={firstDropdownOptions!}
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter Deputy Name"
                              required
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password" // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className="text-field2">
                        <label htmlFor="AEmailId" className="auto-Label">
                          Administrator Name<span className="mandatory">*</span>
                        </label>

                        <Autocomplete
                          sx={{ width: 340, height: 20 }}
                          size="small"
                          options={secondDropdownOptions!}
                          value={secondDropdownValue}
                          onChange={handleSecondDropdownChange}
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Enter Administrator Name"
                              required
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password"
                                // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <DialogActions>
                    <div className="button">
                      <CustomButton
                        type="submit"
                        sx={{
                          background: "#00677f",
                          borderRadius: 0.5,
                          color: "#FFFFFF",
                          fontFamily: "inherit",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "18px",
                          px: "25px",
                          py: "6px",
                          textTransform: "Capitalize",
                          "&:hover": { backgroundColor: "#00677f" }
                        }}
                      >
                        Save
                      </CustomButton>
                    </div>
                  </DialogActions>
                </form>
              </Dialog>
            </ClickAwayListener>
            <div>
              <Dialog
                className="success-box"
                open={successBoxOpen}
                onClose={handlesuccessBoxClose}
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      height: "100%",
                      maxWidth: "574px",
                      maxHeight: "369px",
                      backgroundColor: "#000",
                      borderRadius: "15px"
                    }
                  }
                }}
              >
                <div>
                  <CloseIcon className="close-icon" onClick={handlesuccessBoxClose}></CloseIcon>
                </div>
                <DialogContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    mb: "45px"
                  }}
                >
                  <div className="success-image">
                    <Successfull />
                  </div>
                  <div className="success-message1">Successfully Added</div>
                  <div className="success-message2">
                    {" "}
                    Project will be activated after admin approval.
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </>
        )}
      </>
      <div>
        {(userData?.powerUserAccessReq === null || "Pending" || "Rejected") && (
          <Modal open={reqopen} onClose={handleClose} id="modal2">
            <>
              <Box className="reqModal">
                <Box className="reqModal-header">
                  <DialogTitle className="reqModal-header-text">Request Access</DialogTitle>
                  <Box className="reqCancel">
                    <CloseIcon onClick={handleClose}></CloseIcon>
                  </Box>
                </Box>
                <Divider />
                <Box className="reqSvg">
                  <AccessReq />
                </Box>

                {userData?.powerUserAccessReq === "Rejected" ? (
                  <Typography className="reqText" style={{ color: "#eed202" }}>
                    Your Request is Rejected by Administrator.
                  </Typography>
                ) : userData?.powerUserAccessReq === "Pending" ? (
                  <Typography className="reqText" style={{ color: "#eed202" }}>
                    Your Request is Pending.
                  </Typography>
                ) : (
                  <Typography className="reqText">
                    You need to request for access to onboard the projects
                  </Typography>
                )}

                <Divider className="reqdivider" />
                <Box>
                  <CustomButton
                    onClick={handlereqAccess}
                    className="reqbtn"
                    type="submit"
                    disabled={userData?.powerUserAccessReq === "Pending"}
                    sx={{
                      background: userData?.powerUserAccessReq === "Pending" ? "grey" : "#00677f",
                      borderRadius: 0.8,
                      color: "#FFFFFF", // set family to inherit incase not reflected
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "14px",
                      mt: "24px",
                      textTransform: "Capitalize",
                      "&:hover": {
                        backgroundColor: "#00677f"
                      }
                    }}
                  >
                    Request Access
                  </CustomButton>
                </Box>
              </Box>
            </>
          </Modal>
        )}
      </div>
      <Modal open={requested} onClose={handleReqsuccessBoxClose}>
        <Box className="reqModal">
          <Box className="reqModal-header">
            {/* temp fix */}
            <Box></Box>
            <Box className="reqCancel">
              <CloseIcon onClick={handleReqsuccessBoxClose}></CloseIcon>
            </Box>
          </Box>

          <div className="reqSvgSuccess">
            <Success />
          </div>
          <div className="reqTextSucess">Successful</div>
          <div className="reqTextSucess2"> Successfully sent request to the administrator.</div>
        </Box>
      </Modal>
    </>
  );
};

export default Onboard;

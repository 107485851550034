import React from "react";
import { Box } from "@mui/material";
import "./Legend.scss";
export const Legend = ({ label }: { label: string }) => {
  return (
    <Box className="info-cont">
      <div className={`info-box ${label}`}></div>
      <p className="info-txt">{label}</p>
    </Box>
  );
};

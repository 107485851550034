import convert from "convert-units";

const useCeConversion = (value: number) => {
  if (value > 1000) {
    const convertedWeight: number = convert(value).from("g").to("kg");
    return `${convertedWeight} kg`;
  } else {
    return `${value} g`;
  }
};

export default useCeConversion;

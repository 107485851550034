import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_PROJECT_SUMMARY_DATA } from "./paths";

const ProjectSummaryData = async (projectId: number | string) => {
  return await axiosClient
    .get<ProjectSummary>(`${GET_PROJECT_SUMMARY_DATA}/${projectId}`)
    .then((res) => res.data);
};

export const useProjectSummaryData = (projectId: number | string) => {
  return useQuery({
    queryKey: ["project-summary-data", projectId],
    queryFn: () => ProjectSummaryData(projectId)
  });
};
export interface ProjectSummary {
  allEC: number;
  allTrees: number;
  client: clientSummaryData | null;
  name: string;
  clientRT: clientRtSummaryData | null;
  allCC: number;
  allCE: number;
  server: serverSummaryData;
  abbr: string;
  isGaLock: boolean;
}
export interface clientSummaryData {
  cc: number;
  score: number;
  ce: number;
  storage: number;
  updatedDate: string;
  trees: number;
  ec: number;
  suggestionsCount: CountPerPage[];
}

export interface clientRtSummaryData {
  cc: number;
  ce: number;
  ec: number;
  name: string;
  updatedDate: string;
  resourceConsumed: number;
  suggestionsCount: CountPerPage[];
  trees: number;
  avgNumberOfPageLoads: number;
  avgNumberOfUsers: number;
  abbr: string;
}

export interface serverSummaryData {
  cc: number;
  ce: number;
  cpuCe: number;
  cpuEc: number;
  cpuPercent: number;
  ec: number;
  gpuCe: number;
  gpuEc: number;
  gpuPercent: number;
  memCe: number;
  memEc: number;
  memPercent: number;
  trees: number;
}
export interface CountPerPage {
  pageId: number;
  suggestionsCount: number;
}

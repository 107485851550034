import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import ProfilePic from "../../../assets/svg/ProfilePic.svg";
import { Avatar } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeAuthToken } from "../../../globals/client/axios";
import { logout } from "../../../store/authSlice";
import "./ProfileOptions.scss";

export const ProfileOptionsComponent = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [getUserEmail, setUserEmail] = useState(localStorage.emailId);

  useEffect(() => {
    const handleStorageChange = () => {
      setUserEmail(localStorage.getItem("emailId"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const getUserName = getUserEmail ? getUserEmail.match(/^([^@]*)@/)[1] : "unknown";

  return (
    <React.Fragment>
      <Box className="ProfileBox ">
        <IconButton
          className="ProfileOptionsButton"
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ bgcolor: "#CC5500", width: 24, height: 24 }}>
            {getUserName.charAt(0)}
          </Avatar>
          <Typography variant="h6" className="UserName">
            {getUserName}
          </Typography>{" "}
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <a
          style={{ textDecoration: "none" }}
          href="https://git.i.mercedes-benz.com/IT-QIS/cec-engine-r/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <MenuItem> Help</MenuItem>
        </a>
        <Divider className="Divider" />
        <a
          style={{ textDecoration: "none" }}
          href="https://matter.i.mercedes-benz.com/mercedes-benz/channels/care-it"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuItem> Support</MenuItem>
        </a>
        <Divider className="Divider" />
        <MenuItem
          onClick={() => {
            removeAuthToken();
            dispatch(logout());
            if (process.env.REACT_APP_BUILD_ENV !== "development") {
              nav("/login");
            } else {
              window.location.href = process.env.REACT_APP_LOGOUT_URL!;
            }
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { FormEvent, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import "./login.style.scss";
import { useDispatch, useSelector } from "react-redux";

import { login } from "../../store/authSlice";
import { onSuccess, useLoginMutation } from "../../api/useLoginMutation";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomButton from "../../components/CustomButton/CustomButton";

const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [emailError, setEmailError] = useState(false);
  const [wrongPass, setWrongPass] = useState("");
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const nav = useNavigate();

  const onLoginSuccess: onSuccess = (data, variables) => {
    enqueueSnackbar("Welcome", { variant: "success", autoHideDuration: 2000 });
    dispatch(login({ emailId: variables.emailId }));
    nav("/dashboard");
  };

  const onError = (error: any) => {
    if (error.response) {
      console.log("Error Status:", error.response.status);
      console.log("Error Response:", error.response.data);
      setWrongPass(error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  };

  const { mutate, isLoading, isError, error } = useLoginMutation(onLoginSuccess, onError);

  if (isLoggedIn) {
    // TODO: redirect to dash
  }

  if (isError && error instanceof Error) {
    console.error("Error:", error.message);
    // Handle the error accordingly
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmailError(false);
    const regex: RegExp = /^[A-Z0-9._%+-]+@mercedes-benz+\.[A-Z]{2,}$/i;
    const formData = new FormData(event.currentTarget);
    const emailId = formData.get("emailId")?.toString();
    const result: boolean = regex.test(emailId!);
    const password = formData.get("password")?.toString();
    if (!emailId || !password) return;
    if (result) {
      mutate({ emailId, password });
    } else setEmailError(true);
  };
  return (
    <Container component="main" className="main-container">
      <Box className="main-container-box">
        <Typography className="main-container-typography" component="h1" variant="h5">
          Log in
        </Typography>
        <Box component="form" className="form-fields-box" onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="emailId"
            label="Email ID"
            name="emailId"
            autoFocus
            type="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Box sx={{ textAlign: "right" }}>
            <Link component={RouterLink} to="/forgot-password" variant="body2">
              {" Forgot password?"}
            </Link>
          </Box>

          <CustomButton type="submit" fullWidth variant="contained" className="login-button">
            {isLoading ? (
              <CircularProgress color="inherit" className="circular-progress" />
            ) : (
              "Log In"
            )}
          </CustomButton>
          <Grid container className="signup-option-grid">
            <Grid item>
              <Link component={RouterLink} to="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
        {isError ? <Typography color="red">{wrongPass?.toString()}</Typography> : null}
        {emailError ? (
          <Typography style={{ color: "red" }}>Enter correct email address</Typography>
        ) : (
          ""
        )}
      </Box>
    </Container>
  );
};

export default Login;

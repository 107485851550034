import React from "react";

export default function CalendarIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="calendar">
        <path
          id="Vector"
          d="M7.33398 1.83301V4.58301"
          stroke="white"
          strokeWidth="0.916667"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M14.666 1.83301V4.58301"
          stroke="white"
          strokeWidth="0.916667"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M3.20898 8.33203H18.7923"
          stroke="white"
          strokeWidth="0.916667"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M19.25 7.79134V15.583C19.25 18.333 17.875 20.1663 14.6667 20.1663H7.33333C4.125 20.1663 2.75 18.333 2.75 15.583V7.79134C2.75 5.04134 4.125 3.20801 7.33333 3.20801H14.6667C17.875 3.20801 19.25 5.04134 19.25 7.79134Z"
          stroke="white"
          strokeWidth="0.916667"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="Group 229">
          <path
            id="Vector_5"
            d="M14.3869 12.5584H14.3951"
            stroke="white"
            strokeWidth="1.28333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_6"
            d="M14.3869 15.3084H14.3951"
            stroke="white"
            strokeWidth="1.28333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_7"
            d="M10.9953 12.5584H11.0035"
            stroke="white"
            strokeWidth="1.28333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_8"
            d="M10.9953 15.3084H11.0035"
            stroke="white"
            strokeWidth="1.28333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_9"
            d="M7.60271 12.5584H7.61095"
            stroke="white"
            strokeWidth="1.28333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_10"
            d="M7.60271 15.3084H7.61095"
            stroke="white"
            strokeWidth="1.28333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

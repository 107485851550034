import { OutlinedInput } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate, useParams } from "react-router-dom";
import { Webpage } from "../../api/useProjectWebPages";
import { returnDropDownOptionsFromAnyObj } from "../../utils/common";
import CustomBasicDropdown from "../CustomBasicDropdown";
import "./Dropdown.scss";

const PageDropdown = ({ webpages }: PageDropdownProps) => {
  const { webpageId = "", projectId } = useParams();
  const nav = useNavigate();
  const handleSelect = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target;
    nav(`../../${projectId!}/${value as string}/`, { replace: true, relative: "path" });
  };
  const webPageOptions = webpages ? returnDropDownOptionsFromAnyObj(webpages, "name", "id") : [];

  return (
    <div>
      <CustomBasicDropdown
        className="PageDropdown"
        options={webPageOptions}
        value={webpageId}
        variant="standard"
        displayEmpty
        input={<OutlinedInput />}
        onChange={handleSelect}
      />
    </div>
  );
};

interface PageDropdownProps {
  webpages: Webpage[];
}

export default PageDropdown;

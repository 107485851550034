import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { axiosClient } from "../globals/client/axios";
import { POST_REQUSER_ACCESS } from "./paths";

const requestAccess = async ({ Id }: { Id: string }) => {
  return await axiosClient.post(POST_REQUSER_ACCESS + Id);
};

export const userequestAccess = (onRequestSuccess: onSuccess) => {
  return useMutation({
    mutationFn: requestAccess,
    onSuccess: onRequestSuccess
  });
};

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: any,
  context: unknown
) => unknown;

import { Box, CardContent, Divider, Grid, Typography } from "@mui/material";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Resource, usePageCEWithBreakdown } from "../../../../../api/usePageCEWithBreakdown";
import Close from "../../../../../components/Svg/Close";
import { ToolTip } from "../../../../../components/ToolTip";
import { formatNumber } from "../../../Format";
import { formatDateAsReadable } from "../../../../../utils/useFormatDateTimeAsReadable";
import useCeConversion from "../../../../../utils/useCeConversion";
import { useNavigate, useParams } from "react-router-dom";
interface PerVisitProps {
  defaultJobId: number | undefined;
  webpageId: string | undefined;
  setPerVisitView: React.Dispatch<React.SetStateAction<boolean>>;
  setJobId: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedPlatform: string | undefined;
}
const PerVisitSummaryCard = ({
  title,
  value,
  energyValue,
  unit,
  comparison
}: {
  title: String;
  value: number | string | undefined;
  energyValue?: string;
  unit?: string;
  comparison: number | undefined;
}) => {
  return (
    <Box className="per-visit-summary-card">
      <Typography className="card-title">{title}</Typography>
      <ToolTip
        title={`${String(value)} ${typeof value === "number" && unit ? unit : ""}`}
        placement={"left-start"}
        child={
          <Typography className="card-value">
            {typeof value === "number" ? formatNumber(value).toFixed(2) : value}{" "}
            {typeof value === "number" ? unit : ""}
            {energyValue && <span className="card-label3"> ({energyValue})</span>}
          </Typography>
        }
      />
      <ToolTip
        title={`${String(Math.abs(comparison ?? 0))}%`}
        placement={"left-start"}
        child={
          <Typography
            className={`card-diff 
                ${(comparison ?? 0) > 0.0 ? "delta increased" : "delta decreased"}`}
          >
            {Math.abs(formatNumber(comparison) ?? 0).toFixed(4)}%
          </Typography>
        }
      />
    </Box>
  );
};
export const PerVisitCard = ({
  defaultJobId,
  setPerVisitView,
  setJobId,
  webpageId,
  selectedPlatform
}: PerVisitProps) => {
  const { isLoading: chartDataLoading, data: pageCEWithBreakdown } = usePageCEWithBreakdown(
    selectedPlatform!,
    webpageId!,
    defaultJobId!
  );
  const nav = useNavigate();
  const { "*": jobParam } = useParams();

  return (
    <>
      {chartDataLoading ? (
        <Grid container justifyContent="center" mt={5}></Grid>
      ) : (
        <CardContent style={{ padding: "0" }}>
          <Grid
            container
            px={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Grid py={1.05} px={1} className="card-heading">
              {pageCEWithBreakdown?.lastUpdated !== null
                ? formatDateAsReadable(pageCEWithBreakdown?.lastUpdated)
                : "Per Visit"}
            </Grid>
            <Grid
              onClick={() => {
                if (jobParam) {
                  nav("", {
                    replace: false,
                    relative: "path"
                  });
                }
                setPerVisitView(false);
                setJobId(undefined);
              }}
              style={{ cursor: "pointer" }}
            >
              <Close />
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            px={2}
            py={2}
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Grid className="card-heading">Summary</Grid>
              <Grid
                container
                my={1}
                columnSpacing={3}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {selectedPlatform === "client" ? (
                  <>
                    <Grid item>
                      <PerVisitSummaryCard
                        title={"Carbon Emission"}
                        value={pageCEWithBreakdown?.latestCe}
                        unit={"g"}
                        comparison={pageCEWithBreakdown?.ceComparison}
                      />
                    </Grid>
                    <Grid item>
                      <PerVisitSummaryCard
                        title={"Carbon Credit"}
                        value={pageCEWithBreakdown?.latestCC}
                        unit={"€"}
                        comparison={pageCEWithBreakdown?.ccComparision}
                      />
                    </Grid>
                    <Grid item>
                      <PerVisitSummaryCard
                        title={"Storage Used"}
                        value={pageCEWithBreakdown?.latestSize}
                        unit={"MB"}
                        comparison={pageCEWithBreakdown?.sizeComparision}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item>
                      <PerVisitSummaryCard
                        title={"CPU"}
                        value={useCeConversion(26.88)}
                        energyValue={`${formatNumber(362)} mWh`}
                        comparison={42}
                      />
                    </Grid>
                    <Grid item>
                      <PerVisitSummaryCard
                        title={"Memory"}
                        value={useCeConversion(26.88)}
                        energyValue={`${formatNumber(285)} mWh`}
                        comparison={10}
                      />
                    </Grid>
                    <Grid item>
                      <PerVisitSummaryCard
                        title={"GPU"}
                        value={useCeConversion(0.08)}
                        energyValue={`${formatNumber(11)} mWh`}
                        comparison={7}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Grid className="card-heading">Page Breakdown</Grid>
              <Grid>
                <PageBreakDownChart
                  resources={pageCEWithBreakdown?.resources ?? []}
                  selectedPlatform={selectedPlatform}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </>
  );
};
function PageBreakDownChart({
  resources,
  selectedPlatform
}: {
  resources: Resource[];
  selectedPlatform: string | undefined;
}) {
  const labelStyle = {
    fontSize: "9px",
    fontWeight: "400",
    fontFamily: "inherit",
    colors: "#C5C5C7"
  };
  const verticalSeries: ApexAxisChartSeries = [
    {
      name: "Co2 Emission (g)",
      data: resources.map((itm) => {
        return { x: itm.type, y: itm.overallCe.toFixed(2) };
      })
    }
  ];
  const tooltipData = {
    "Pod 1": { app: 40, api: 3, database: undefined, batch: undefined, middleware: undefined },
    "Pod 2": { app: 6, api: 7, database: 10, batch: undefined, middleware: undefined },
    "Pod 3": { app: 10, api: 4, database: 7, batch: 10, middleware: undefined },
    "Pod 4": { app: 5, api: 3, database: 4, batch: 5, middleware: 3 }
  };
  const horizontalSeries: ApexAxisChartSeries = [
    {
      name: "Co2 Emission (g)",
      data: [
        {
          x: "Pod 1",
          y: 43
        },
        {
          x: "Pod 2",
          y: 23
        },
        {
          x: "Pod 3",
          y: 31
        },
        {
          x: "Pod 4",
          y: 17
        }
      ]
    }
  ];

  const verticalOptions: ApexOptions = {
    colors: ["rgba(108, 178, 194, 1)"],
    chart: {
      background: "transparent",
      type: "bar",
      height: "auto",
      toolbar: {
        show: false
      },
      offsetX: 10
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        horizontal: selectedPlatform !== "client",
        columnWidth: "50%",
        barHeight: "50%",
        dataLabels: {
          position: "top"
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      labels: {
        style: labelStyle
      },
      axisBorder: {
        show: true
      },
      title: {
        text: selectedPlatform === "client" ? "Carbon Emission in g" : "Services",
        offsetX: selectedPlatform === "client" ? -5 : 10,
        style: {
          fontSize: "12px",
          fontWeight: "400",
          fontFamily: "inherit",
          color: "white"
        }
      }
    },
    xaxis: {
      labels: {
        style: labelStyle,
        formatter: (val: string | number) => {
          if (selectedPlatform === "client") {
            return String(val);
          } else {
            return typeof val === "string" ? val : ` ${val} g`;
          }
        }
      },
      axisBorder: {
        show: true
      },
      title: {
        text: selectedPlatform === "client" ? "Components" : "Carbon Emission in g",
        offsetY: selectedPlatform === "client" ? 150 : 15,
        style: {
          fontSize: "12px",
          fontWeight: "400",
          fontFamily: "inherit",
          color: "white"
        }
      }
    },
    grid: {
      show: true,
      borderColor: "rgba(80,136,111,0.1)",
      padding: {
        left: 15,
        right: 20,
        bottom: selectedPlatform === "client" ? 0 : 10
      },
      yaxis: {
        lines: {
          show: false // Hide y-axis grid lines
        }
      },
      xaxis: {
        lines: {
          show: selectedPlatform !== "client" // Hide y-axis grid lines
        }
      }
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: "dark"
    },
    fill: {
      type: "solid",

      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["rgba(80,136,111,0.1)", "#000000"],
        inverseColors: true,
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 98, 100]
      }
    },
    tooltip: {
      custom: function (opts) {
        const itm = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
        const { x, y } = itm;

        let tooltipHTML = "";
        type TooltipKey = keyof typeof tooltipData;
        const tooltipItem = tooltipData[String(x) as TooltipKey] as
          | { app?: number; api?: number; database?: number; batch?: number; middleware?: number }
          | undefined;
        if (tooltipItem) {
          tooltipHTML =
            `<div class='to-body'>` +
            (tooltipItem.app !== undefined
              ? `<div class='tooltip-item'><p class='value'>${String(
                  tooltipItem.app
                )}g</p><p class='label'>App</p></div>`
              : "") +
            (tooltipItem.api !== undefined
              ? `<div class='tooltip-item'><p class='value'>${String(
                  tooltipItem.api
                )}g</p><p class='label'>API</p></div>`
              : "") +
            (tooltipItem.database !== undefined
              ? `<div class='tooltip-item'><p class='value'>${String(
                  tooltipItem.database
                )}g</p><p class='label'>Database</p></div>`
              : "") +
            (tooltipItem.batch !== undefined
              ? `<div class='tooltip-item'><p class='value'>${String(
                  tooltipItem.batch
                )}g</p><p class='label'>Batch</p></div>`
              : "") +
            (tooltipItem.middleware !== undefined
              ? `<div class='tooltip-item'><p class='value'>${String(
                  tooltipItem.middleware
                )}g</p><p class='label'>Middleware</p></div>`
              : "") +
            `</div>`;
        }

        const platformHTML =
          `<div class='header'>${String(x)}</div>` +
          (selectedPlatform === "client"
            ? `<div class='to-body'>
              <p class='value'>${String(y)}g</p>
              <p class='label'>Carbon Emission</p>
            </div>`
            : tooltipHTML);

        return platformHTML;
      }
    }
  };
  return (
    <ReactApexChart
      // options={selectedPlatform === "client" ? verticalOptions : horizontalOptions}
      options={verticalOptions}
      series={selectedPlatform === "client" ? verticalSeries : horizontalSeries}
      type="bar"
      height={290}
    />
  );
}

import { axiosClient } from "../globals/client/axios";
import { GET_CONTAINER_LIST } from "./paths";
import { ContainerList } from "./useContainerList";

export async function getFirstContainerId(
  projectId: number | string
): Promise<[Error | null, number | null]> {
  try {
    const containers = await axiosClient.get<ContainerList[]>(`${GET_CONTAINER_LIST}/${projectId}`);
    if (containers.data.length === 0) return [null, null];
    return [null, containers.data[0].id];
  } catch (error) {
    if (error instanceof Error) return [error, null];
    throw new Error();
  }
}

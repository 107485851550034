import React from "react";
import "./ToggleSwitch.scss";

interface ToggleSwitchProps {
  isOn: boolean;
  handleToggle: () => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isOn, handleToggle }) => {
  return (
    <div className={`toggle-switch ${isOn ? "on" : "off"}`} onClick={handleToggle}>
      <div className="toggle-knob"></div>
    </div>
  );
};

export default ToggleSwitch;

import { Tooltip } from "@mui/material";

export const ToolTip = ({ title, placement, child }) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      className="tooltip-component"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "rgb(37,35,35)",
            fontFamily: "inherit",
            fontSize: "0.75rem"
          }
        }
      }}
    >
      {child}
    </Tooltip>
  );
};

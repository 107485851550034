import { InputAdornment, TextField } from "@mui/material";
import "./index.scss";
import SearchIcon from "../Svg/SearchIcon";
export const SearchBox = ({
  value,
  handleSearch,
  placeholder,
  className
}: {
  value: string;
  handleSearch: (e: { target: { value: string } }) => void;
  placeholder: string;
  className?: string;
}) => {
  return (
    <>
      <TextField
        value={value}
        onChange={handleSearch}
        placeholder={placeholder}
        className={className ? `${className} searchInput` : `searchInput`}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </>
  );
};

import { ProfileOptions } from "./ProfileOptions/ProfileOptions";
import "./Header.scss";
import { AppLogo } from "./App Logo/AppLogo";
import { NavBar } from "./Nav Bar/NavBar";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useloggedInUserDetails } from "../../api/getLoggedInUserDetails";

export const Header = () => {
  const loc = useLocation();
  const [userEmail, setUserEmail] = useState(localStorage.emailId);

  const { data: userData, isSuccess } = useloggedInUserDetails();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("id", userData.id);
      localStorage.setItem("emailId", userData.emailId);
      localStorage.setItem("role", userData.role);

      const storedUserRole = localStorage.getItem("role");
      setUserRole(storedUserRole!);
    }
  }, [isSuccess, userData, userRole]);

  useEffect(() => {
    setUserEmail(localStorage.emailId);
  }, [localStorage.emailId]);
  // const authCode = localStorage.getItem("authCode");
  const curPath = loc.pathname.split("/")[1];
  return (
    <>
      {userEmail && (
        <div className="HeaderDiv">
          <AppLogo />
          {curPath === "login" ||
          curPath === "signup" ||
          curPath === "verify" ||
          curPath === "forgot-password" ||
          curPath === "paswordReset" ||
          curPath === "error" ? null : (
            <>
              <NavBar />
              <div className="Profile">{userEmail && <ProfileOptions />}</div>
            </>
          )}
        </div>
      )}
    </>
  );
};

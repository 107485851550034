import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem("authCode"),
  emailId: localStorage.getItem("emailId")
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ emailId: string }>) => {
      state.isLoggedIn = true;
      state.emailId = action.payload.emailId;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.emailId = null;
      localStorage.clear();
    }
  }
});

// action creators
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;

interface AuthState {
  isLoggedIn: boolean;
  emailId: string | null;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid, OutlinedInput, SelectChangeEvent } from "@mui/material";
import { useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useLatestJobsAndPagesData } from "../../api/useLatestJobsAndPagesData";
import CustomBasicDropdown from "../../components/CustomBasicDropdown";
import { Legend } from "../../components/Legend/Legend";
import { NoData } from "../../components/NoData/NoData";
import { ToggleButtonGroup } from "../../components/ToggleButtonGroup/ToggleButtonGroup";
import { CARBON_INSIGHT_JOBS_OPTIONS, CARBON_INSIGHT_MONTHS_OPTIONS } from "../../utils/constants";
import { DropdownOption } from "../Carbon/Page Section/Page Section Body/PageSectionBody";
import { CarbonCreditChart } from "./Charts/CarbonCredit/CarbonCreditChart";
import { ProjectGraph } from "./Charts/CarbonEmissionbyJobs/ProjectGraph";
import "./index.scss";
export interface AbbrType {
  [key: string]: string;
}
// export interface CloudGraphData {
//   total: PlatformGraphData;
//   aws: PlatformGraphData;
// }
// export interface ServerGraphData {
//   total: PlatformGraphData;
//   app: PlatformGraphData;
//   api: PlatformGraphData;
//   database: PlatformGraphData;
//   batch: PlatformGraphData;
//   middleware: PlatformGraphData;
// }
export default function CarbonInsightChart({ selectedPlatform }: { selectedPlatform: string }) {
  const [isEmission, setIsEmission] = useState(true);
  const curMonth = new Date().getMonth() + 1;
  const [nJobs, setNJobs] = useState(5);
  const [selectedMonth, setSelectedMonth] = useState(curMonth);
  const Id = localStorage.getItem("id");
  const { data: projectsJobsAndPages } = useLatestJobsAndPagesData({
    Id,
    nJobs
  });
  const currentDate = dayjs();
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(currentDate.startOf("month"));
  const [endDate, setEndDate] = useState<Dayjs | null>(currentDate.endOf("month"));
  const conditionalScroll = selectedPlatform === "client" ? "" : "carbonInsight-scroll";
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>({
    label: "This Month",
    value: "month"
  });
  // const {
  //   data: allPlatformGraphData,
  //   refetch: refetchAllPlatformGraphData,
  //   isError
  // } = useAllPlatformGraphData(
  //   startDate?.format("YYYY-MM-DD") ?? "",
  //   endDate?.format("YYYY-MM-DD") ?? ""
  // );
  // const ServerGraphData: ServerGraphData = {
  //   total: {
  //     ce: 8.75,
  //     cc: 11.7,
  //     graphData: [
  //       { jobId: 1, ce: 0.907, cc: 0.907, createdDate: "2021-10-01T00:00:00" },
  //       { jobId: 2, ce: 0.989, cc: 0.989, createdDate: "2021-10-06T00:00:00" },
  //       { jobId: 3, ce: 0.985, cc: 0.985, createdDate: "2021-11-01T00:00:00" },
  //       { jobId: 4, ce: 0.895, cc: 0.895, createdDate: "2021-12-01T00:00:00" }
  //     ]
  //   },
  //   app: {
  //     ce: 1.3,
  //     cc: 2.5,
  //     graphData: [
  //       { jobId: 1, ce: 0.887, cc: 0.887, createdDate: "2021-10-01T00:00:00" },
  //       { jobId: 2, ce: 0.909, cc: 0.909, createdDate: "2021-10-06T00:00:00" },
  //       { jobId: 3, ce: 0.505, cc: 0.505, createdDate: "2021-11-01T00:00:00" },
  //       { jobId: 4, ce: 0.605, cc: 0.605, createdDate: "2021-12-01T00:00:00" }
  //     ]
  //   },
  //   api: {
  //     ce: 1.3,
  //     cc: 1.6,
  //     graphData: [
  //       { jobId: 1, ce: 0.101, cc: 0.101, createdDate: "2021-10-02T00:00:00" },
  //       { jobId: 2, ce: 0.888, cc: 0.888, createdDate: "2021-11-22T00:00:00" },
  //       { jobId: 4, ce: 0.505, cc: 0.505, createdDate: "2021-12-03T00:00:00" }
  //     ]
  //   },
  //   database: {
  //     ce: 2.25,
  //     cc: 2.2,
  //     graphData: [
  //       { jobId: 1, ce: 0.687, cc: 0.887, createdDate: "2021-10-01T00:00:00" },
  //       { jobId: 2, ce: 0.909, cc: 0.909, createdDate: "2021-11-07T00:00:00" },
  //       { jobId: 3, ce: 0.505, cc: 0.505, createdDate: "2021-12-03T00:00:00" }
  //     ]
  //   },
  //   batch: {
  //     ce: 1.7,
  //     cc: 3.0,
  //     graphData: [
  //       { jobId: 1, ce: 0.587, cc: 0.947, createdDate: "2021-10-03T00:00:00" },
  //       { jobId: 2, ce: 0.909, cc: 0.909, createdDate: "2021-11-08T00:00:00" },
  //       { jobId: 3, ce: 0.059, cc: 0.805, createdDate: "2021-12-03T00:00:00" }
  //     ]
  //   },
  //   middleware: {
  //     ce: 2.2,
  //     cc: 2.4,
  //     graphData: [
  //       { jobId: 1, ce: 0.287, cc: 0.787, createdDate: "2021-10-01T00:00:00" },
  //       { jobId: 2, ce: 0.709, cc: 0.609, createdDate: "2021-11-07T00:00:00" },
  //       { jobId: 3, ce: 0.805, cc: 0.705, createdDate: "2021-12-03T00:00:00" }
  //     ]
  //   }
  // };
  // const cloudGraphData: CloudGraphData = {
  //   total: {
  //     ce: 3.2,
  //     cc: 2.5,
  //     graphData: [
  //       { jobId: 1, ce: 0.987, cc: 0.987, createdDate: "2021-10-01T00:00:00" },
  //       { jobId: 2, ce: 0.949, cc: 0.949, createdDate: "2021-10-06T00:00:00" },
  //       { jobId: 3, ce: 0.925, cc: 0.925, createdDate: "2021-11-01T00:00:00" },
  //       { jobId: 4, ce: 0.855, cc: 0.855, createdDate: "2021-12-01T00:00:00" }
  //     ]
  //   },
  //   aws: {
  //     ce: 3.2,
  //     cc: 2.5,
  //     graphData: [
  //       { jobId: 1, ce: 0.887, cc: 0.887, createdDate: "2021-10-01T00:00:00" },
  //       { jobId: 2, ce: 0.909, cc: 0.909, createdDate: "2021-10-05T00:00:00" },
  //       { jobId: 1, ce: 0.505, cc: 0.505, createdDate: "2021-11-01T00:00:00" },
  //       { jobId: 2, ce: 0.101, cc: 0.101, createdDate: "2021-11-09T00:00:00" },
  //       { jobId: 1, ce: 0.888, cc: 0.888, createdDate: "2021-11-20T00:00:00" },
  //       { jobId: 2, ce: 0.909, cc: 0.909, createdDate: "2021-12-01T00:00:00" }
  //     ]
  //   }
  // };

  // const getDataforGraph = () => {
  //   switch (selectedPlatform) {
  //     case "all":
  //       return allPlatformGraphData;
  //     case "server":
  //       return ServerGraphData;
  //     case "cloud":
  //       return cloudGraphData;
  //   }
  // };
  // const getDataLength = () => {
  //   switch (selectedPlatform) {
  //     case "all":
  //       return allPlatformGraphData?.AllPlatforms?.graphData?.length;
  //     case "server":
  //       return ServerGraphData?.total?.graphData?.length;
  //     case "cloud":
  //       return cloudGraphData?.total?.graphData?.length;
  //   }
  // };

  // const CeCheck = () => {
  //   switch (selectedPlatform) {
  //     case "all":
  //       return Number(allPlatformGraphData?.AllPlatforms?.ce) < 0;
  //     case "server":
  //       return Number(ServerGraphData?.total?.ce) < 0;
  //     case "cloud":
  //       return Number(cloudGraphData?.total?.ce) < 0;
  //   }
  // };
  // const CcCheck = () => {
  //   switch (selectedPlatform) {
  //     case "all":
  //       return Number(allPlatformGraphData?.AllPlatforms?.cc) < 0;
  //     case "server":
  //       return Number(ServerGraphData?.total?.cc) < 0;
  //     case "cloud":
  //       return Number(cloudGraphData?.total?.cc) < 0;
  //   }
  // };

  // if (isError) {
  //   return <p>Overview data not available at this moment</p>;
  // }

  // useEffect(() => {
  //   if (selectedPlatform === "all") refetchAllPlatformGraphData();
  // }, [startDate, endDate]);

  let maxSum = 0;
  projectsJobsAndPages?.forEach((project) => {
    let curSum = 0;
    project.lastJobs?.forEach((job) => {
      curSum += job.ceGms;
    });
    if (curSum > maxSum) maxSum = curSum;
  });

  const dropdownItems = isEmission ? CARBON_INSIGHT_JOBS_OPTIONS : CARBON_INSIGHT_MONTHS_OPTIONS;
  const dropdownItemsWithMenuItems = dropdownItems.map((each) => ({
    ...each,
    className: "menu-item"
  }));
  const handleCISelectChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    isEmission ? setNJobs(value as number) : setSelectedMonth(value as number);
  };

  const abbr: { [key: string]: string } = {};
  projectsJobsAndPages?.forEach((obj) => {
    abbr[obj.name] = obj.abbr;
  });
  return (
    <Box className="carbonInsightChartContainer">
      {projectsJobsAndPages?.length ? (
        <Box
          className="chart-container"
          sx={{ bgcolor: "var(--dash-card-bg)", borderRadius: "15px" }}
          p={1}
          px={2}
        >
          <Grid
            container
            my={1}
            justifyContent="space-between"
            alignItems="center"
            mb={isEmission ? "8px" : selectedPlatform === "Client" ? "0px" : "8px"}
            mt="-1px"
          >
            <Grid item className="flex-container">
              <ToggleButtonGroup
                button1="Emission"
                button2="Offset"
                active={isEmission}
                setActive={setIsEmission}
              />

              <Box className="info-top-gird">
                {/* Legend section */}
                {isEmission && selectedPlatform === "client" && (
                  <Grid className="legends-container">
                    <Legend label="Failed" />
                    <Legend label="Low" />
                    <Legend label="Medium" />
                    <Legend label="High" />
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Grid container gap={1} alignItems="center" className="info-top-gird">
                {selectedPlatform === "client" && (
                  <CustomBasicDropdown
                    sx={{ ml: -2 }}
                    options={dropdownItemsWithMenuItems}
                    displayEmpty
                    className="PageDropdown ci-chart-dashboard"
                    variant="standard"
                    IconComponent={ExpandMoreIcon}
                    input={<OutlinedInput size="small" />}
                    value={isEmission ? nJobs : selectedMonth}
                    onChange={handleCISelectChange}
                    renderValue={(selected) => {
                      let label: string | undefined = dropdownItemsWithMenuItems.find(
                        (itm) => itm.value === selected
                      )?.label;
                      if (!isEmission) label = `Offset (${label!})`;
                      return label;
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Box>
            <Box className={conditionalScroll}>
              {isEmission ? (
                selectedPlatform === "client" ? (
                  projectsJobsAndPages?.map((project) => (
                    <ProjectGraph
                      abbr={project.abbr}
                      key={project.name}
                      projectName={project.name}
                      lastJobs={project?.lastJobs ?? []}
                      maxSum={maxSum}
                      projectId={project.id}
                      nJobs={nJobs}
                    />
                  ))
                ) : (
                  // ) : getDataLength() === 0 ||
                  //   getDataLength() === undefined ||
                  //   CeCheck() === true ||
                  //   CcCheck() === true ? (
                  //   <div style={{ fontSize: "12px" }}>
                  //     {getDataLength() === undefined
                  //       ? "No data as no project assigned to the user"
                  //       : getDataLength() === 0
                  //       ? "No records found for the selected range"
                  //       : "Jobs failed for the assigned projects"}
                  //   </div>
                  <>
                    {/* <Card>
                      <CardContent className="emissionpie-chart-section-card-content">
                        <EmissionChartSection
                          Heading={"Emission Split"}
                          // data={getDataforGraph()}
                          isEmission={isEmission}
                          selectedPlatform={selectedPlatform}
                        />
                      </CardContent>
                    </Card>
                    <Card className="historical-chart-section-card">
                      <CardContent className="historical-chart-section-card-content">
                        <HistoricalChartSection
                          // data={getDataforGraph()}
                          isEmission={isEmission}
                          selectedPlatform={selectedPlatform}
                        />
                      </CardContent>
                    </Card> */}
                  </>
                )
              ) : selectedPlatform === "client" ? (
                <CarbonCreditChart abbr={abbr} selectedMonth={selectedMonth} />
              ) : (
                // ) : getDataLength() === 0 ||
                //   getDataLength() === undefined ||
                //   CeCheck() === true ||
                //   CcCheck() === true ? (
                //   <div style={{ fontSize: "12px" }}>
                //     {getDataLength() === undefined
                //       ? "No data as no project assigned to the user"
                //       : getDataLength() === 0
                //       ? "No records found for the selected range"
                //       : "Jobs failed for the assigned projects"}
                //   </div>
                <>
                  {/* <Card>
                    <CardContent className="emissionpie-chart-section-card-content">
                      <EmissionChartSection
                        Heading={"Offset split"}
                        isEmission={isEmission}
                        data={getDataforGraph()}
                        selectedPlatform={selectedPlatform}
                      />
                    </CardContent>
                  </Card>
                  <Card className="historical-chart-section-card">
                    <CardContent className="historical-chart-section-card-content">
                      <HistoricalChartSection
                        data={getDataforGraph()}
                        isEmission={isEmission}
                        selectedPlatform={selectedPlatform}
                      />
                    </CardContent>
                  </Card> */}
                </>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <NoData />
      )}
    </Box>
  );
}

import { Fragment, useState } from "react";
import {
  Box,
  Tooltip,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableSortLabel
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import { visuallyHidden } from "@mui/utils";
import { StatusSymbol } from "../../../components/StatusSymbol";
// import MinusButton from "../../../components/Svg/MinusButton";
// import PlusButton from "../../../components/Svg/PlusButton";
import { RelativeAuditWithMetricType } from "../../../api/useSuggestionsData";

function EnhancedTableHead(props: any) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="none"
            sx={{ padding: headCell.label === "Priority" ? "0 16px" : "" }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography component="h6" fontSize="0.75rem" fontWeight={600}>
                {headCell.label}
              </Typography>

              {/* {headCell.id === "relAudScore" && (
                  <ToolTip
                    title={<StatusInfo />}
                    placement={"bottom"}
                    child={<InfoOutlinedIcon fontSize="small" sx={{ ml: "5px", color: "gray" }} />}
                  />
                )} */}

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export interface HeadCell {
  id: string;
  numeric: boolean;
  label: string;
}
interface PerformanceTableProps {
  activeMetric: string | null;
  data: RelativeAuditWithMetricType[];
  searchValue: string;
  headCells: HeadCell[];
}

export default function PerformanceTable({
  activeMetric,
  data,
  searchValue,
  headCells
}: PerformanceTableProps) {
  const [order, setOrder] = useState("asc");
  const [active] = useState<number | undefined>();
  // ------------setActive hidden-----

  const [orderBy, setOrderBy] = useState("relAudTitle");

  const handleRequestSort = (_event: any, property: any) => {
    console.log(property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Hidden until fully implemented
  // const handleExpand = (id: number) => {
  //   setActive((prevActive) => (prevActive === id ? undefined : id));
  // };

  const rows = data.filter((opp) => {
    const search = opp.relAudTitle?.toLowerCase().includes(searchValue.trim().toLowerCase());
    const active = activeMetric ? opp.metricType === activeMetric : true;
    return search && active;
  });
  return (
    <>
      {rows.length > 0 ? (
        <Box className="performance-table">
          <TableContainer>
            <Table size={"medium"}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />
              <TableBody>
                {rows.sort(getComparator(order, orderBy)).map((r: any, index) => {
                  return (
                    <Fragment key={r.id}>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={r.id ? `${r.id as string}-${index}` : `nokey-${index}`}
                        sx={{ marginBottom: "10px" }}
                      >
                        <TableCell
                          align="left"
                          style={{
                            borderTopLeftRadius: 4
                          }}
                        >
                          <Tooltip
                            title={`Score: ${
                              isNaN(parseFloat(r.relAudScore ?? "NaN"))
                                ? "Not Available"
                                : parseFloat(r.relAudScore ?? "-1") * 100
                            }`}
                          >
                            <span>
                              <StatusSymbol
                                color={
                                  parseFloat(r.relAudScore ?? "-1") < 0
                                    ? "na"
                                    : parseFloat(r.relAudScore ?? "-1") < 0.49
                                    ? "red"
                                    : parseFloat(r.relAudScore ?? "-1") < 0.9
                                    ? "yellow"
                                    : "green"
                                }
                              />
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left" className="audit-title-cell">
                          <Typography
                            fontSize="0.8rem"
                            fontWeight={600}
                            component="h1"
                            variant="h1"
                          >
                            {r.relAudTitle}
                          </Typography>
                          <ReactMarkdown
                            className="react-markdown"
                            components={{ a: LinkRenderer }}
                          >
                            {r.relAudDescription}
                          </ReactMarkdown>
                        </TableCell>
                        {/* <TableCell align="left">
                      <Typography fontSize="0.75rem" fontWeight={300}>
                        {r.metricType.match(/[A-Z]/g)?.join("")}
                      </Typography>
                    </TableCell> */}
                        {/* <TableCell align="left">
                      <Typography fontSize="0.75rem" fontWeight={300}>
                        {"0.3s"}
                      </Typography>
                    </TableCell> */}
                        {/* ----------------------------Hidden until fully implemented------------------------- */}
                        {/* <TableCell
                          align="left"
                          style={{
                            borderTopRightRadius: 4
                          }}
                        >
                          <Box className="expand-option" onClick={() => handleExpand(r.id)}>
                            {active === r.id ? <MinusButton /> : <PlusButton />}
                          </Box>
                        </TableCell> */}
                      </TableRow>
                      <TableRow
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        key={r.id ? `${r.id as string}-detail-${index}` : `nokey-detail-${index}`}
                        sx={{
                          borderBottom: "10px solid #252525"
                        }}
                      >
                        {active === r.id ? (
                          <TableCell
                            align="left"
                            colSpan={3}
                            style={{
                              borderBottomLeftRadius: 4,
                              borderBottomRightRadius: 4,
                              paddingTop: 0
                            }}
                            className="table-cell-2"
                          >
                            <Box className="details-table">
                              <TableContainer>
                                <Table
                                  style={{ borderCollapse: "separate", borderSpacing: "0 10px" }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        colSpan={3}
                                        className="details-table-row-cell nested-table-head-font"
                                      >
                                        mercedes-benz.com
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-head-font">
                                        Total CPU Time
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-head-font">
                                        Script Evaluation
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-head-font">
                                        Script Parse
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        colSpan={3}
                                        className="details-table-row-cell nested-table-body-font"
                                      >
                                        …1.46.0/brandhub.min.js(assets.oneweb.mercedes-benz.com)
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-body-font">
                                        2270 ms
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-body-font">
                                        1287 ms
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-body-font">
                                        16 ms
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        colSpan={3}
                                        className="details-table-row-cell nested-table-body-font"
                                      >
                                        …1.46.0/brandhub.min.js(assets.oneweb.mercedes-benz.com)
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-body-font">
                                        2270 ms
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-body-font">
                                        1287 ms
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-body-font">
                                        16 ms
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        colSpan={3}
                                        className="details-table-row-cell nested-table-body-font"
                                      >
                                        …1.46.0/brandhub.min.js(assets.oneweb.mercedes-benz.com)
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-body-font">
                                        2270 ms
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-body-font">
                                        1287 ms
                                      </TableCell>
                                      <TableCell className="details-table-row-cell nested-table-body-font">
                                        16 ms
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box className="no-data">No Relative Audits</Box>
      )}
    </>
  );
}

export function descendingComparator(a: any, b: any, orderBy: any) {
  if (a[orderBy] == null) {
    return -1;
  }
  if (b[orderBy] == null) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function getComparator(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

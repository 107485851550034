import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GIT_ACTION_DISPATCH } from "./paths";

const gitActionDispatch = async (projectId: number, body: GitActionsRequestBody) => {
  const response = await axiosClient({
    method: "post",
    url: GIT_ACTION_DISPATCH,
    params: { projectId },
    data: {
      POOL_ID: body.poolId,
      PAIRING_KEY: body.pingOnePairingKey,
      PROXY_ADDRESS: body.proxyAddress
    }
  });
  return response.data;
};

export const useGitActionDispatch = (
  onDispatchSuccess: onDispatchSuccess,
  onDispatchFailure: onDispatchFailure
) => {
  return useMutation({
    mutationFn: ({ projectId, body }: { projectId: number; body: GitActionsRequestBody }) =>
      gitActionDispatch(projectId, body),
    onSuccess: onDispatchSuccess,
    onError: onDispatchFailure
  });
};

interface GitActionsRequestBody {
  poolId: string;
  pingOnePairingKey: string;
  proxyAddress: boolean;
}
export type onDispatchSuccess = (data: string) => unknown;
export type onDispatchFailure = (data: string) => unknown;

import { Grid, Typography, Tooltip } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { useSnackbar } from "notistack";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { getFirstWebpageId } from "../../../../api/getFirstWebpage";
import { LastJobs } from "../../../../api/useLatestJobsAndPagesData";
import { formatDate } from "../../../../utils/useFormatDate";

export function ProjectGraph({
  projectName,
  lastJobs,
  maxSum,
  projectId,
  abbr,
  nJobs
}: {
  projectId?: number;
  projectName: string;
  lastJobs: LastJobs[];
  maxSum: number;
  abbr: string;
  nJobs: number;
}) {
  //   let curSum = 0;
  const sortedLastJobs = lastJobs.sort((a, b) => a.jobId - b.jobId);

  const series =
    sortedLastJobs.flatMap((itm, idx, arr) => {
      //   curSum += itm.ceGms;
      const currentJob = {
        name: `Job ${idx + 1}`,
        data: [
          {
            x: "Carbon Emission (g)",
            y: itm.errorMessage ? 0.1 : itm.ceGms,
            jobId: itm.jobId,
            createdDate: itm.createdDate,
            // sizeMB: itm.sizeMB,
            emission: itm.errorMessage ? 0.008 : itm.ceGms,
            offset: itm.ccEuro,
            errorMessage: itm.errorMessage,
            cecStatus: itm.cecStatus,
            lhStatus: itm.lhStatus
          }
        ]
      };

      const additionalJob = {
        name: `additionalJob ${idx + 1}`,
        data: [
          {
            x: "",
            y: 0.005,
            jobId: -99,
            createdDate: "",
            // sizeMB: 0,
            emission: 0,
            offset: 0,
            errorMessage: "",
            cecStatus: "",
            lhStatus: ""
          }
        ]
      };

      return idx === arr.length - 1 ? [currentJob] : [currentJob, additionalJob];
    }) ?? [];
  const colors: string[] = lastJobs?.flatMap((itm, idx) => {
    const jobColor = (() => {
      if (itm.errorMessage) return "#878787";
      if (itm.thresholdColour === "Green") return "#80BD24";
      if (itm.thresholdColour === "Amber") return "#ee9225";
      return "#f63e3e"; // red
    })();

    const white = "#FFFFFF";

    return idx === lastJobs.length - 1 ? [jobColor] : [jobColor, white];
  }) ?? ["#00000000"];

  const nav = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const navToJob = async (jobId: number) => {
    if (!projectId) {
      enqueueSnackbar("Can't find projectId", {
        variant: "warning",
        autoHideDuration: 2000
      });
      return;
    }
    try {
      const [error, pageId] = await getFirstWebpageId(projectId);

      if (error) throw error;
      if (pageId === null) {
        enqueueSnackbar("No Web pages Available", {
          variant: "warning",
          autoHideDuration: 2000
        });
        return;
      }
      nav(`/dashboard/carbon/${projectId}/client/${pageId}/${jobId}`);
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "warning",
        autoHideDuration: 2000
      });
    }
  };

  const graphWidth = () => {
    if (nJobs === 5) return 25;
    else if (nJobs === 10) return 40;
    else if (nJobs === 15) return 60;
    else if (nJobs === 30) return 90;
    else if (nJobs === 45) return 115;
  };
  const options: ApexOptions = {
    colors,
    chart: {
      type: "bar",
      stacked: true,
      stackType: "normal",
      parentHeightOffset: 0,
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
      offsetX: -10,
      events: {
        click(event, chartContext, config) {
          const jobId = config.config.series[config.seriesIndex]?.data[config.dataPointIndex].jobId;
          if (jobId && jobId > 0) navToJob(jobId);
        }
      }
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        top: -30,
        bottom: -20
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 2,
        borderRadiusWhenStacked: "last",
        borderRadiusApplication: "end",
        dataLabels: {
          total: {
            enabled: false
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: false,
      width: 1,
      colors: ["#fff"]
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },

    yaxis: {
      min: 0,
      max: graphWidth(),
      // max: 0,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },

    tooltip: {
      custom: function (opts) {
        const itm = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];

        const { jobId, createdDate, emission, offset, errorMessage, cecStatus, lhStatus } = itm;

        if (jobId < 0) {
          return null;
        } else if (errorMessage) {
          return `<div class='header'>${formatDate(createdDate)}</div>
            <div class='to-body'>
            <p class='head'>${String(cecStatus)}</p>
            <p class='title'>CEC Status</p>
            <p class='head'>${String(lhStatus)}</p>
            <p class='title'>LH Status</p>
              </div>
            </div>`;
        } else {
          return `<div class='header'>${formatDate(createdDate)}</div>
            <div class='to-body'>
                <p class='head'>${+emission.toFixed(2)} g</p>
                <p class='title'>Carbon Emission</p>
                <p class='head'>${+offset.toFixed(2)} €</p>
                <p class='title'>Carbon Offset</p>
              </div>
            </div>`;
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 1,
        gradientToColors: undefined,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [100],
        colorStops: []
      }
    },
    legend: {
      show: false
    }
  };

  return (
    <Grid container alignItems="center" my={0} flexWrap="nowrap" pt={0.8} className="ci-chart-wrap">
      <Grid item xs={1} mr={2}>
        <Tooltip title={projectName} placement="bottom-end">
          <Typography sx={{ cursor: "pointer" }}>{abbr || projectName.slice(0, 5)}</Typography>
        </Tooltip>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <ReactApexChart series={series} type="bar" options={options} height={40} />
      </Grid>
    </Grid>
  );
}

// Checks if any object has the key with valid value
export const isKeyPresentAndNotNull = <T, K extends keyof T>(obj: T, key: K): boolean => {
  return (
    Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== null && obj[key] !== undefined
  );
};
export default isKeyPresentAndNotNull;

interface finalOptions {
  label: any;
  value: any;
}

// Function to transform the array into the desired array of objects
export const returnDropDownOptionsFromArray = (arr: string[]): finalOptions[] => {
  return arr.map((item) => ({
    label: item,
    value: item
  }));
};

export const returnDropDownOptionsFromAnyObj = (
  input: Record<string, any>,
  labelKey: string,
  valueKey: string
): finalOptions[] => {
  const output: finalOptions[] = [];

  input.forEach((item: Record<string, any>) => {
    if (labelKey in item && valueKey in item) {
      const transformedItem: finalOptions = {
        label: item[labelKey],
        value: item[valueKey]
      };
      output.push(transformedItem);
    } else {
      console.warn(
        `Skipping item: ${JSON.stringify(item)} as it doesn't have required properties.`
      );
    }
  });

  return output;
};

import { Box, Tooltip, Typography } from "@mui/material";
import { StatusSymbol } from "../../../components/StatusSymbol";
import ReactMarkdown from "react-markdown";
import "./index.scss";
import { useState, useEffect } from "react";
import { Audit } from "../../../api/useSuggestionsData";
import { LinkRenderer } from "../SuggestionsTable/Table";
import PlusButton from "../../../components/Svg/PlusButton";
import MinusButton from "../../../components/Svg/MinusButton";

interface MetricsItemprops {
  metric: Audit;
  activeMetric: string | null;
  activeTab: string | null;
  setActiveMetric: React.Dispatch<React.SetStateAction<string | null>>;
  isActive: boolean;
}

const MetricsItem = (props: MetricsItemprops) => {
  const { metric, activeMetric, activeTab, setActiveMetric, isActive } = props;
  const [expand, setExpand] = useState(false);
  const auditScore = parseFloat(metric.auditScore ?? "-1") * 100;
  const metricColor =
    auditScore < 0 ? "na" : auditScore < 49 ? "red" : auditScore < 90 ? "yellow" : "green";

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    setExpand(metric.audit === activeMetric && expand);
  }, [activeMetric, metric.audit]);

  return (
    <Box
      onClick={() => {
        if (isActive) return;
        setActiveMetric(metric.audit);
      }}
      className={`metrics-item ${isActive ? "metric-active" : ""}`}
    >
      <Box className="metrics-item-info">
        <Box className="metrics-item-header">
          <Typography
            variant="h2"
            component="h2"
            color="white"
            sx={{ fontSize: "16px", fontWeight: 400 }}
          >
            {metric.audit + " "}
            {activeTab === "Performance" ? (
              <Tooltip title={`Socre : ${auditScore}`}>
                <span>
                  <StatusSymbol color={metricColor} />
                </span>
              </Tooltip>
            ) : null}
          </Typography>
        </Box>
        {expand && isActive ? (
          <Box className="metrics-item-content">
            <ReactMarkdown className="react-markdown" components={{ a: LinkRenderer }}>
              {metric.auditDescription}
            </ReactMarkdown>
          </Box>
        ) : null}
      </Box>
      <Box className="time-expand-container">
        {activeTab !== "Best Practices" ? (
          <Box className="time">
            <Typography fontSize="1rem" fontWeight={600}>
              {metric.displayValue}
            </Typography>
          </Box>
        ) : null}
        {metric.auditDescription ? (
          <Box className="expand" onClick={handleExpand}>
            {expand && isActive ? <MinusButton /> : <PlusButton />}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default MetricsItem;

export default function Cpu() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3957 7.6888C12.6407 7.6888 12.8332 7.49047 12.8332 7.2513C12.8332 7.0063 12.6407 6.8138 12.3957 6.8138H11.6665V5.53047H12.3957C12.6407 5.53047 12.8332 5.33797 12.8332 5.09297C12.8332 4.8538 12.6407 4.65547 12.3957 4.65547H11.5323C11.2523 3.72797 10.5232 2.9988 9.59567 2.7188V1.85547C9.59567 1.6163 9.39734 1.41797 9.15817 1.41797C8.919 1.41797 8.72067 1.6163 8.72067 1.85547V2.58464H7.43734V1.85547C7.43734 1.6163 7.239 1.41797 6.99984 1.41797C6.76067 1.41797 6.56234 1.6163 6.56234 1.85547V2.58464H5.28484V1.85547C5.28484 1.6163 5.0865 1.41797 4.84734 1.41797C4.60234 1.41797 4.40984 1.6163 4.40984 1.85547V2.7188C3.4765 2.9988 2.74734 3.72797 2.46734 4.65547H1.604C1.36484 4.65547 1.1665 4.8538 1.1665 5.09297C1.1665 5.33797 1.36484 5.53047 1.604 5.53047H2.33317V6.8138H1.604C1.36484 6.8138 1.1665 7.0063 1.1665 7.2513C1.1665 7.49047 1.36484 7.6888 1.604 7.6888H2.33317V8.97214H1.604C1.36484 8.97214 1.1665 9.16464 1.1665 9.40964C1.1665 9.6488 1.36484 9.84714 1.604 9.84714H2.46734C2.7415 10.7746 3.4765 11.5038 4.40984 11.7838V12.6471C4.40984 12.8863 4.60234 13.0846 4.84734 13.0846C5.0865 13.0846 5.28484 12.8863 5.28484 12.6471V11.918H6.56817V12.6471C6.56817 12.8863 6.76067 13.0846 7.00567 13.0846C7.24484 13.0846 7.44317 12.8863 7.44317 12.6471V11.918H8.72067V12.6471C8.72067 12.8863 8.919 13.0846 9.15817 13.0846C9.39734 13.0846 9.59567 12.8863 9.59567 12.6471V11.7838C10.5232 11.5038 11.2523 10.7746 11.5323 9.84714H12.3957C12.6407 9.84714 12.8332 9.6488 12.8332 9.40964C12.8332 9.16464 12.6407 8.97214 12.3957 8.97214H11.6665V7.6888H12.3957ZM10.0682 8.56964C10.0682 9.53214 9.28067 10.3196 8.31817 10.3196H5.6815C4.719 10.3196 3.9315 9.53214 3.9315 8.56964V5.93297C3.9315 4.97047 4.719 4.18297 5.6815 4.18297H8.31817C9.28067 4.18297 10.0682 4.97047 10.0682 5.93297V8.56964Z"
        fill="white"
      />
      <path
        d="M5.84516 9.73077H8.161C8.89016 9.73077 9.48516 9.1416 9.48516 8.4066V6.09077C9.48516 5.3616 8.896 4.7666 8.161 4.7666H5.84516C5.116 4.7666 4.521 5.35577 4.521 6.09077V8.4066C4.521 9.1416 5.11016 9.73077 5.84516 9.73077Z"
        fill="white"
      />
    </svg>
  );
}

import { CardContent, Box, Typography, Grid, LinearProgress } from "@mui/material";
import { ToolTip } from "../ToolTip";
// import { PlantTree } from "../PlantTree/PlantTree";
// import { EnergyConsumption } from "../EnergyConsumption/EnergyConsumption";

interface CustomCardProps {
  metricdata: string | undefined;
  metrictooltip?: string | undefined;
  secondaryMetric?: string;
  secondaryText: string | React.ReactNode;
  secondaryIcon?: React.ReactNode;
  perProgress?: number;
  primaryIcon?: React.ReactNode;
  title: string | undefined;
  sp?: string;
}

const OverviewCard: React.FC<CustomCardProps> = ({
  metricdata,
  metrictooltip,
  secondaryMetric,
  title,
  primaryIcon,
  secondaryText,
  secondaryIcon,
  perProgress,
  sp
}) => {
  return (
    <CardContent>
      <Box className="box-design1" display="flex" justifyContent="space-between" mt="2px">
        <Typography className="card-label1">
          {metrictooltip !== undefined ? (
            <ToolTip
              title={metrictooltip}
              placement="right-end"
              child={<span>{metricdata}</span>}
            />
          ) : (
            <span>{metricdata}</span>
          )}
          {sp !== "all" ? (
            <div className="secondary-text">{secondaryMetric}</div>
          ) : (
            <div className="secondary-text"></div>
          )}
          <ToolTip
            title={title}
            placement="left-end"
            child={<div className="card-label3">{title}</div>}
          />
        </Typography>
        <Typography>{primaryIcon}</Typography>
      </Box>
      <Box>
        {perProgress ? (
          <Grid className="label">
            <Box sx={{ paddingTop: "30px", width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={perProgress <= 0 ? 0.1 : perProgress}
                sx={{
                  backgroundColor: "#333",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00677f"
                  }
                }}
              />
            </Box>
            <ToolTip
              title={secondaryText}
              placement="left-end"
              child={
                <Grid className="label">
                  <Box className="card-label2" style={{ paddingTop: "4px" }}>
                    <Box paddingTop="4px">{secondaryIcon}&nbsp;</Box>
                    <Box>{secondaryText}</Box>
                  </Box>
                </Grid>
              }
            ></ToolTip>
          </Grid>
        ) : (
          <ToolTip
            title={secondaryText}
            placement="left-end"
            child={
              <Grid className="label">
                <Box className="card-label2">
                  <Box>{secondaryIcon}</Box>
                  <Box>{secondaryText}</Box>
                </Box>
              </Grid>
            }
          ></ToolTip>
        )}
      </Box>
    </CardContent>
  );
};

export default OverviewCard;

import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { DELETE_USERS, GET_PROJECT_COLLABDATA, POST_USERS, PUT_USERS } from "./paths";

const getProjectCollaboraterPages = async (projectId: number | string) => {
  try {
    const response = await axiosClient.get<CollaboraterPages[]>(GET_PROJECT_COLLABDATA, {
      params: { projectId }
    });
    const data = await response.data;

    return data;
  } catch (error) {
    console.error("Error fetching CollaboratorsData:", error);
  }
};

export const useCollaboratorsData = (
  projectId: number | string,
  enabled: boolean,
  onSuccess?: onSuccess
) => {
  return useQuery({
    queryKey: ["CollaboraterPages", projectId],
    queryFn: () => getProjectCollaboraterPages(projectId),
    onSuccess,
    enabled
  });
};

const createCollaborator = async (CollaboraterPage: CollaboraterPages) => {
  return await axiosClient({
    method: "post",
    url: POST_USERS,
    data: CollaboraterPage
  });
};

export const createNewCollaborator = (onCollaboratorAdded: any, onError: any) => {
  return useMutation({
    mutationFn: createCollaborator,
    onSuccess: onCollaboratorAdded,
    onError
  });
};

const updateCollabor = async (Collaborater: CollaboraterPages) => {
  return await axiosClient.put(PUT_USERS + `${String(Collaborater?.id)}`, Collaborater);
};

export const updateCollaboratorById = (onCollaboratorUpdate: any) => {
  return useMutation({
    mutationFn: updateCollabor,
    onSuccess: onCollaboratorUpdate
  });
};

const deleteCollaborator = async (Collaborater: CollaboraterPages) => {
  return await axiosClient.delete(DELETE_USERS + `${String(Collaborater?.id)}`);
};

export const deleteCollaboratorById = (onCollaboratorDelete: any) => {
  return useMutation({
    mutationFn: deleteCollaborator,
    onSuccess: onCollaboratorDelete
  });
};

const mapUserToRequestBody = (user: selectedUser) => {
  return {
    id: user.userAccessId,
    projectId: user.projectId,
    role: user.projectRole,
    userId: user.id, // Use the userId from the selectedUser object
    version: user.projectVersion // Specify the version as needed
  };
};

const updateUserRole = async (selectedUser: selectedUser) => {
  const requestBody = mapUserToRequestBody(selectedUser);
  return await axiosClient.put(PUT_USERS + `${selectedUser?.userAccessId}`, requestBody);
};

export const useUpdateUserRoleId = (onCollaboratorUpdate: any) => {
  return useMutation({
    mutationFn: updateUserRole,
    onSuccess: onCollaboratorUpdate
  });
};

const deleteUser = async (selectedUser: selectedUser) => {
  return await axiosClient.delete(DELETE_USERS + `${selectedUser?.userAccessId}`);
};

export const usedeleteUserById = () => {
  return useMutation({
    mutationFn: deleteUser
  });
};

interface selectedUser {
  id: number;
  userAccessId: number;
  projectRole: string;
  approval: boolean;
  projectAbbr: null;
  projectName: string;
  projectId: number;
  projectVersion: number;
}

export interface CollaboraterPages {
  id: string | undefined;
  userId: number | undefined;
  emailId?: string | undefined;
  projectId: number;
  createdDate: string;
  role: string;
  version: string;
  updatedDate: string;
  userAccess: {
    id: string | undefined;
    emailId: string;
    password: string;
    createdDate: string;
    expiryDate: string;
  };
}

export type onSuccess = (data: CollaboraterPages[]) => void;

import React from "react";
import { Grid } from "@mui/material";
import TableSwitcher from "./TableSwitcher";

// Right section of Dashboard
export const Projects = ({ selectedPlatform }: { selectedPlatform: string }) => {
  return (
    <Grid item lg={12}>
      <div>
        <TableSwitcher selectedPlatform={selectedPlatform} />
      </div>
    </Grid>
  );
};

export default function NoDataSVG() {
  return (
    <svg
      width="108"
      height="82"
      viewBox="0 0 108 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="99.5" cy="13.5" r="3" stroke="white" />
      <circle opacity="0.2" cx="8.5" cy="22.5" r="3" stroke="white" />
      <circle opacity="0.2" cx="16.5" cy="49.5" r="2.5" fill="white" />
      <circle opacity="0.2" cx="96.5" cy="43.5" r="2.5" fill="white" />
      <circle opacity="0.2" cx="19.5" cy="4.5" r="2.5" fill="white" />
      <rect x="33" y="1" width="52" height="60" rx="8" fill="#565656" />
      <rect x="25" y="9" width="52" height="60" rx="8" fill="#686868" />
      <path d="M33.5 19H51.5" stroke="#909090" strokeWidth="2" strokeLinecap="round" />
      <path d="M33 35H45" stroke="#909090" strokeWidth="2" strokeLinecap="round" />
      <path d="M33 27H59" stroke="#909090" strokeWidth="2" strokeLinecap="round" />
      <circle
        cx="51.5564"
        cy="54.5566"
        r="10"
        transform="rotate(-45 51.5564 54.5566)"
        stroke="#919191"
        strokeWidth="2"
      />
      <path
        d="M58.6274 61.6279L61.4558 64.4564"
        stroke="#919191"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M61.4558 64.4561L70.6482 73.6484"
        stroke="#919191"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
}

import { axiosClient } from "../globals/client/axios";
import { GET_PROJECT_WEBPAGES } from "./paths";
import { Webpage } from "./useProjectWebPages";

export async function getFirstWebpageId(
  projectId: number | string
): Promise<[Error | null, number | null]> {
  try {
    const webpages = await axiosClient.get<Webpage[]>(GET_PROJECT_WEBPAGES, {
      params: { projectId }
    });
    if (webpages.data.length === 0) return [null, null];
    return [null, webpages.data[0].id];
  } catch (error) {
    if (error instanceof Error) return [error, null];
    throw new Error();
  }
}

export default function Success() {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 72C55.8 72 72 55.8 72 36C72 16.2 55.8 0 36 0C16.2 0 0 16.2 0 36C0 55.8 16.2 72 36 72Z"
        fill="#80BD24"
      />
      <path
        d="M20.5713 35.9009L30.7593 46.0889L51.1713 25.7129"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useCeConversion from "../../../../utils/useCeConversion";
import convert from "convert-units";

function getSubtitle(totaldata, isEmission) {
  return `<span style="font-size: 18px; color: white; text-align:center; font-family: inherit; "><b> ${
    !isEmission ? `${totaldata?.toFixed(4)} €` : `${useCeConversion(totaldata?.toFixed(4))}`
  }</b>  </span>
  <br>
  <span style="font-size: 14px; color: white; text-align:center; font-family: inherit; font-variant: normal;">Total</span>`;
}

const EmissionPieChart = ({ piedata, totaldata, isEmission, piechartcolors, backgroundColors }) => {
  const conv = (num) => {
    if (num > 1000) {
      const convertedWeight = convert(num).from("g").to("kg");
      return `${convertedWeight} kg`;
    } else {
      return `${num} g`;
    }
  };
  const chartOptions = {
    chart: {
      type: "pie",
      backgroundColor: { backgroundColors },
      fontFamily: "inherit",
      height: "340px"
    },
    title: {
      text: ""
    },
    subtitle: {
      text: getSubtitle(totaldata, isEmission),
      floating: true,
      verticalAlign: "middle",
      y: 30,
      style: {
        textAlign: "center"
      }
    },
    tooltip: {
      useHTML: true,
      borderColor: "#fff",
      backgroundColor: "none",
      borderRadius: 10,
      style: {
        color: "#fff",
        textAlign: "center",
        lineHeight: 1.5,
        fontFamily: "inherit"
      },
      formatter: function () {
        return (
          '<div class="emissionpie-chart-tooltip">' +
          '<div class="header" style="padding: 4px">' +
          `<p style="margin:2px;">${this.key}</p>` +
          "</div>" +
          '<div class="to-body">' +
          `<p class="head">${
            !isEmission ? `${this.y.toFixed(4)} €` : `${conv(this.y.toFixed(4))}`
          }</p>` +
          `<p class='title' style="margin:2px;">${
            isEmission ? "Carbon Emission" : "Carbon Offset"
          }</p>` +
          "</div>" +
          "</div>"
        );
      }
    },
    credits: {
      enabled: false
    },
    accessibility: {
      point: {
        valueSuffix: "%"
      }
    },
    plotOptions: {
      pie: {
        marker: {
          radius: 8
        },
        allowPointSelect: true,
        innerSize: "80%",
        borderWidth: 0,
        borderRadius: 0,
        size: "70%",
        cursor: "pointer",
        colors: piechartcolors,
        dataLabels: {
          enabled: true,
          format: `<p style = "font-family: inherit;font-size:13px;">${
            !isEmission ? "{point.y:.4f} €" : `{point.y:.4f} g`
          }<br> <div style="opacity:0.5;">{point.name}</div></p>`,
          distance: 50,
          connectorShape: function (labelPosition, connectorPosition, options) {
            const path =
              Highcharts.seriesTypes.pie.prototype.pointClass.prototype.connectorShapes.crookedLine.call(
                this,
                labelPosition,
                connectorPosition,
                options
              );

            const labelWidth = this.dataLabel.getBBox().width;
            if (labelPosition.alignment === "right") {
              path[0][1] -= labelWidth;
            } else {
              path[0][1] += labelWidth;
            }

            return path;
          }
        }
      }
    },
    series: [
      {
        marker: {
          radius: 8
        },
        colorByPoint: true,
        data: piedata
      }
    ]
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default EmissionPieChart;

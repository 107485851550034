import { useQuery } from "@tanstack/react-query";

import { axiosClient } from "../globals/client/axios";
import { VERIFY } from "./paths";

const headers = { "Content-Type": "application/x-www-form-urlencoded" };
const verifyUser = async (tokenID: string | null) => {
  return await axiosClient
    .get(VERIFY + `?authCode=${tokenID ?? ""}`, { headers })
    .then((res) => res.data);
};

export const useVerifyData = (tokenID: string | null) => {
  return useQuery({
    queryKey: ["verifyData", tokenID],
    queryFn: () => verifyUser(tokenID)
  });
};

import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { PUT_WEBPAGE } from "./paths";

const updateWebPage = async (webPage: IWebPage) => {
  return await axiosClient({
    method: "put",
    url: `${PUT_WEBPAGE}${webPage.id !== undefined ? webPage.id.toString() : ""}`,
    data: webPage
  });
};

export const updateWebpageById = (onUpdateSuccess: any) => {
  return useMutation({
    mutationFn: updateWebPage,
    onSuccess: onUpdateSuccess
  });
};

// Generated by https://quicktype.io

export interface IWebPage {
  name: any;
  id?: number | undefined;
  projectId?: number | undefined;
  url?: string | undefined;
  version?: string | undefined;
}

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: IWebPage,
  context: unknown
) => unknown;

export const OPTIONS_CLIENT_SCAN_TYPE = [
  { label: "Client(Static)", labelValue: "Client (Static)" },
  { label: "Client(Realtime)", labelValue: "Client (Realtime)" }
];

export const CLIENT_RT_MONITOR_TOOL_SETUP_STEPS = {
  heading:
    "Please follow the following steps to setup your monitoring tool thereby to conduct a scan",
  steps: [
    "Provide your Instana monitoring domain (e.g., https://apm-xsp.instana.xsp.cloud.corpintra.net/).",
    "Identify your project's Configuration name in Instana following the steps in this URL (https://pages.git.i.mercedes-benz.com/CARE-IT/docs/).",
    "Exclude URLs containing many parameters (e.g., Session) by entering keywords (e.g., 'abc') to exclude them from scanning, like /project/abc/123 & /project/abc/234"
  ]
};
export const CLIENT_RT_ENGINE_SETUP_STEPS = {
  heading:
    "Your configuration details have been successfully saved. Follow the below steps to setup the engine for your project",
  steps: [
    "Go to url www.instana.com and log in to your account to start scanning.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod .",
    "Dolore magna aliqua. Ut enim ad minim veniam.",
    "ex ea commodo consequat. Duis aute irure dolor in reprehenderit ."
  ]
};

export const CARBON_INSIGHT_JOBS_OPTIONS = [
  { label: "Last 5 Jobs", value: 5 },
  { label: "Last 10 Jobs", value: 10 },
  { label: "Last 15 Jobs", value: 15 },
  { label: "Last 30 Jobs", value: 30 },
  { label: "Last 45 Jobs", value: 45 }
];

export const CARBON_INSIGHT_MONTHS_OPTIONS = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
];

export const ADMIN_FILTER_OPTIONS = [
  { label: "Created Date", value: "Created Date" },
  { label: "Project Name", value: "Project Name" },
  { label: "Deputy Name", value: "Deputy Name" }
];

export const ONBOARD_MONITORING_TOOL_OPTIONS = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" }
];

export const ROLE_OPTIONS1 = [
  { label: "Admin", value: "Admin" },
  { label: "Power User", value: "Power User" },
  { label: "Collaborator", value: "Collaborator" }
];

export const ROLE_OPTIONS2 = [
  { label: "Power User", value: "Power User" },
  { label: "Collaborator", value: "Collaborator" }
];

export const CI_JOBS_STATUS_COLUMNS = [
  { field: "thresholdColour", headerName: "" },
  { field: "createdDate", headerName: "Date" },
  { field: "variation", headerName: "Variation" },
  { field: "ce", headerName: "Carbon Emission" },
  { field: "cc", headerName: "Carbon Offset" },
  { field: "errorMessage", headerName: "Messages" },
  { field: "status", headerName: "Status(CEC/Lighthouse)" }
];

import convert from "convert-units";
import { formatNumber } from "../pages/Carbon/Format";

const useCeConversionFixed = (value: number) => {
  if (value > 1000) {
    const convertedWeight: number = convert(value).from("g").to("kg");
    return `${formatNumber(convertedWeight).toFixed(3)} kg`;
  } else {
    return `${formatNumber(value).toFixed(3)} g`;
  }
};

export default useCeConversionFixed;

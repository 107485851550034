export default function New() {
  return (
    <svg width="28" height="11" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="13" rx="2" fill="#80BD24" />
      <path
        d="M7.6875 3.66797H8.33594L10.8984 8.10547V3.66797H11.457V9H10.8047L8.25391 4.57422V9H7.6875V3.66797ZM15.6719 7.19922H13.082C13.0951 7.60547 13.1719 7.92318 13.3125 8.15234C13.5052 8.46224 13.793 8.61719 14.1758 8.61719C14.3867 8.61719 14.5599 8.57292 14.6953 8.48437C14.8333 8.39583 14.9544 8.2487 15.0586 8.04297L15.582 8.10547C15.3503 8.76693 14.8802 9.09766 14.1719 9.09766C13.7083 9.09766 13.3268 8.94661 13.0273 8.64453C12.681 8.29297 12.5078 7.79297 12.5078 7.14453C12.5078 6.43099 12.7005 5.88542 13.0859 5.50781C13.362 5.23698 13.7122 5.10156 14.1367 5.10156C14.6628 5.10156 15.0677 5.32161 15.3516 5.76172C15.5651 6.09505 15.6719 6.52083 15.6719 7.03906C15.6719 7.08073 15.6719 7.13411 15.6719 7.19922ZM15.0898 6.75781C15.0664 6.47917 15.0065 6.2487 14.9102 6.06641C14.7383 5.73828 14.474 5.57422 14.1172 5.57422C13.8385 5.57422 13.6133 5.67448 13.4414 5.875C13.2695 6.07552 13.1549 6.36979 13.0977 6.75781H15.0898ZM16.0977 5.20703H16.6758L17.4883 8.21094L18.3789 5.20703H18.8594L19.7461 8.21094L20.5625 5.20703H21.1406L20.0391 9.04687H19.4922L18.6133 6.06641L17.7422 9.04687H17.1953L16.0977 5.20703Z"
        fill="white"
      />
    </svg>
  );
}

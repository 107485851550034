import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_USER_DATA } from "./paths";

const getNewAddedData = async (searchValue?: string, id?: string | null) => {
  const params = searchValue ? { searchValue } : {};
  return await axiosClient.get<tableData[]>(`${GET_USER_DATA}`, { params }).then((res) => res.data);
};

export const useAddedData = (searchValue?: string, id?: string | null) => {
  return useQuery({
    queryKey: ["new-added-data", searchValue, id],
    queryFn: () => getNewAddedData(searchValue, id)
  });
};

export interface Project {
  projectRole: string;
  approval: boolean | null;
  projectAbbr: string | null;
  projectName: string;
}

export interface tableData {
  id: number;
  name: string;
  powerUserAccessReq: string;
  applicationRole: string;
  projects: Project[];
}

import { Typography, Grid, Tooltip } from "@mui/material";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { useProjectOffsetChartData } from "../../../../api/getOffsetChart";
import { useAppSelector } from "../../../../store";
import { AbbrType } from "../../CarbonInsightChart";

export function CarbonCreditChart({
  selectedMonth,
  abbr
}: {
  selectedMonth: number;
  abbr: AbbrType;
}) {
  const curDate = new Date();
  const curYear = curDate.getFullYear();
  const userId = useAppSelector((state) => state.auth.emailId) ?? "";
  const { data: chartData, isInitialLoading } = useProjectOffsetChartData({
    userId,
    month: selectedMonth,
    year: curYear
  });

  if (isInitialLoading && !chartData) {
    return <div></div>;
  }

  if (chartData?.length === 0) return <Typography>No data Available</Typography>;

  const options: ApexOptions = {
    colors: ["#6CB2C2"],
    chart: {
      background: "#fff00000",
      type: "bar",
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
      offsetY: 5
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        horizontal: true,
        columnWidth: "80%",
        barHeight: "50%",
        dataLabels: {
          position: "top"
        }
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: "end",
      offsetX: 45,
      style: {
        fontSize: "10px"
      },
      formatter: function (val: number) {
        return ` ${val} €`;
      }
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: false,
        align: "left",
        style: {
          colors: "#fff",
          fontSize: "16px",
          fontFamily: "inherit"
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    grid: {
      show: false,
      padding: {
        left: 10,
        right: 0,
        top: -30,
        bottom: -20
      }
    },
    tooltip: {
      enabled: false
    }
  };

  const series = [
    {
      data:
        chartData?.map((itm) => {
          return { y: itm.cc.toFixed(2), x: abbr[itm.name] ?? itm.name.slice(0, 5) };
        }) ?? []
    }
  ];

  return (
    <Grid container alignItems="center" my={0} flexWrap="nowrap" className="ci-chart-wrap">
      <div className="cc-grid-container">
        {chartData?.map((item, index) => (
          <Tooltip key={index} title={item.name} placement="bottom">
            <div key={index} className="cc-grid-item">
              {abbr[item.name] ?? item.name.slice(0, 5)}
            </div>
          </Tooltip>
        ))}
      </div>
      <Grid item sx={{ flexGrow: 1 }}>
        <ReactApexChart
          series={series}
          type="bar"
          options={options}
          height={chartData?.length ? chartData.length * 58 : 100}
        />
      </Grid>
    </Grid>
  );
}

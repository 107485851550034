import { Grid, TableCell, IconButton, TableRow, Tooltip } from "@mui/material";
import { Text } from "../../../components/Text Component/Text";
import "./index.scss";
import * as React from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { AllplatformNJob, ServerData } from "../../../api/useServerTableData";
import { getFirstContainerId } from "../../../api/getFirstContainer";
import EmissionSideChart from "./EmissionSideChart";
import ActionIcon from "../../../assets/svg/ActionIcon.svg";
import DisabledActionIcon from "../../../assets/svg/DisabledActionIcon.svg";
interface ProjectItemProps {
  project: ServerData;
  platform: string;
}
export interface containerItem {
  [key: string]: number;
}
export interface serverDataContainers {
  [key: string]: containerItem;
}

export interface ProjectPieChartServerData {
  lastUpdated: string;
  thresholdColour: string;
  totalEmission: number;
  offset: number;
  name: string;
  containers: serverDataContainers[];
  abbr: string;
  totalEnegryConsumption: number;
  projectId: number;
  clientCE?: number;
  clientCC?: number;
  ceSum?: number;
  ccSum?: number;
  lastNJobs?: AllplatformNJob[];
}

const ProjectItemServer = ({ project, platform }: ProjectItemProps) => {
  const latestJob = project.lastNJobs?.at(project.lastNJobs.length - 1);
  const nav = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const navToProject = async (projectId: number) => {
    try {
      const [error, containerId] = await getFirstContainerId(projectId);
      if (error) throw error;
      if (containerId === null) {
        enqueueSnackbar("No Containers Available", {
          variant: "warning",
          autoHideDuration: 2000
        });
        return;
      }
      nav(`/dashboard/carbon/${projectId}/${platform}/${containerId}`);
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "warning",
        autoHideDuration: 2000
      });
    }
  };

  const actionBtnClickHandler = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    setActionVal: boolean
  ) => {
    event.stopPropagation(); // Stop event propagation(to stop navToProject from being called)
    setOpen(setActionVal);
  };

  return (
    <TableRow
      className="row dash-table-row"
      key={project.projectId}
      onClick={() => {
        navToProject(project.projectId);
      }}
    >
      <TableCell className="cell-design1">
        <Grid className="symbol" pl={1}>
          <div className={project.thresholdColour}></div>
        </Grid>
      </TableCell>
      <TableCell className="cell-design prj-name ellipsis">
        <Tooltip title={project.name} placement="left">
          <span className="prj-name-p">{project.abbr}</span>
        </Tooltip>
      </TableCell>
      <TableCell className="cell-design">
        {project.lastUpdated
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric"
            }).format(new Date(project.lastUpdated))
          : ""}
      </TableCell>

      <TableCell className="cell-design">
        <Text
          data={
            project.totalEmission === undefined
              ? ""
              : ` ${
                  project.totalEmission !== -1
                    ? project.totalEmission.toFixed(2) !== "N/A"
                      ? `${project.totalEmission.toFixed(2)} g`
                      : "N/A"
                    : "-"
                }`
          }
          className="cell-text"
        />
      </TableCell>

      <TableCell className="cell-design" component="th" align="left">
        <Text
          data={
            project.offset === undefined
              ? ""
              : ` ${
                  project.offset !== -1
                    ? project.offset.toFixed(2) !== "N/A"
                      ? `${project.offset.toFixed(2)} €`
                      : "N/A"
                    : "-"
                }`
          }
          className="cell-text"
        />
      </TableCell>
      <TableCell className="cell-design-n eb" component="th" align="left">
        {project.totalEmission === 0 && project.offset === 0 ? (
          <Tooltip title={"No data available"}>
            <div
              onClick={(event) => actionBtnClickHandler(event, false)}
              className="disabledActionIconServer"
            >
              <IconButton size="small" disabled>
                <img src={DisabledActionIcon} />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <div onClick={(event) => actionBtnClickHandler(event, true)} className="actionIconServer">
            <IconButton size="small" disabled>
              <img src={ActionIcon} />
            </IconButton>
          </div>
        )}

        {open && (
          <div className="drawerContainer">
            <EmissionSideChart
              project={project}
              onClose={handleClose}
              latestJob={latestJob}
              platform={"server"}
            />
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ProjectItemServer;

import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { DELETE_PROJECTS } from "./paths";

const deleteProjects = async (id: number) => {
  return await axiosClient.delete(DELETE_PROJECTS + `${id}`);
};
export const useDeleteProjectsById = (
  onSuccessCallback: onSuccess
): UseMutationResult<any, unknown, number, unknown> => {
  return useMutation({
    mutationFn: deleteProjects,
    onSuccess: (data, variables) => {
      onSuccessCallback(variables);
    }
  });
};

export type onSuccess = (id: number) => void;

import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { IClientRTConfig, fallbackClientRTConfig } from "../../../api/useClientRTConfig";
import MonitorTool from "../../../components/MonitorTool/MonitorTool";
import { CLIENT_RT_ENGINE_SETUP_STEPS } from "../../../utils/constants";
import EditConfigurationDetails from "../../../components/Svg/EditConfigurationDetails";
import CheckCircle from "../../../components/Svg/CheckCircle";
import EditConfiguration from "../EditConfiguration";
import { useEffect, useState } from "react";
import { CLIENT_RT_CONFIG } from "../../../api/paths";
import { axiosClient } from "../../../globals/client/axios";
import CustomButton from "../../../components/CustomButton/CustomButton";

export interface ClientRTConfigDetailsProps {
  projID: number;
  monitoringPlatformId: number;
  showSteps: boolean;
}

const ClientRTConfigDetails: React.FC<ClientRTConfigDetailsProps> = ({
  projID,
  monitoringPlatformId,
  showSteps
}) => {
  const { projectId } = useParams();
  const [openEditConfig, setOpenEditConfig] = useState(false);
  const [ConfigDetails, setConfigDetails] = useState<IClientRTConfig>(fallbackClientRTConfig);

  const validProjectId =
    projectId && (typeof projectId === "string" || typeof projectId === "number") ? projectId : 0;

  const handleEditConfigModalOpen = () => {
    setOpenEditConfig(true);
  };
  const handleEditConfigModalClose = () => {
    setOpenEditConfig(false);
  };

  useEffect(() => {
    const fetchConfigDetails = async () => {
      try {
        const response = await axiosClient
          .get(CLIENT_RT_CONFIG + `/${validProjectId}`)
          .then((res) => res.data);
        const responseObj = response?.filter((_: IClientRTConfig) => _.platform === "client")[0];
        setConfigDetails(responseObj);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };

    fetchConfigDetails();
  }, [openEditConfig]);
  return (
    <>
      <Box className="container-config-details-outer">
        {showSteps && (
          <Box style={{ margin: "12px" }}>
            <MonitorTool guidelines={CLIENT_RT_ENGINE_SETUP_STEPS}>
              <CheckCircle />
            </MonitorTool>
          </Box>
        )}
        <Box className="container-config-details">
          <Box className="heading">
            <Box className="header">
              <Typography className="configTitle">Configuration Details</Typography>
              <CustomButton className="edit-button" onClick={handleEditConfigModalOpen}>
                <EditConfigurationDetails />
              </CustomButton>
              <EditConfiguration
                projID={projID}
                monitoringPlatformId={monitoringPlatformId}
                showSteps={false}
                ConfigDetails={ConfigDetails}
                openEditConfig={openEditConfig}
                handleModalClose={handleEditConfigModalClose}
              />
            </Box>{" "}
          </Box>
          <div className="flexContainer">
            <Box className="config-row">
              <Box className="config-cell">
                <Typography variant="body1" className="configText">
                  <span className="labelText">Config Name : </span>{" "}
                  <span className="configLabelValue">{ConfigDetails.configName}</span>
                </Typography>
              </Box>
              <Box className="config-cell">
                <Typography variant="body1" className="configText">
                  <span className="labelText">Monitoring Domain URL : </span>
                  <span className="configLabelValue">{ConfigDetails.domainUrl}</span>
                </Typography>
              </Box>
            </Box>
            <Box className="config-row fixed-height">
              <Box className="config-cell">
                <Typography variant="body1" className="configText">
                  <span className="labelText">Page Excluded : </span>
                  <span className="configLabelValue">
                    {" "}
                    {ConfigDetails?.projectMonitoringSetting?.pathsExcluded?.join(" | ")}{" "}
                  </span>
                </Typography>
              </Box>
              {/* <Box className="config-cell">
                <Typography variant="body1">
                  <span className="labelText">Page Included : </span> <span>Page Included</span>
                </Typography>
              </Box> */}
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
};
export default ClientRTConfigDetails;

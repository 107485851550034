import { createTheme } from "@mui/material/styles";

const CareITTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000000"
    },
    primary: {
      main: "#00677f"
    },
    secondary: {
      main: "#6cb2c2"
    },

    contrastThreshold: 3,
    tonalOffset: 0.2
  },

  typography: {
    allVariants: {
      color: "#fff"
    },
    h1: { fontFamily: "Daimler CS, Bold", fontWeight: 700 },
    h2: { fontFamily: "Daimler CS, Bold", fontWeight: 600 },
    h3: { fontFamily: "Daimler CS, Bold", fontWeight: 600 },
    h4: { fontFamily: "Daimler CS, Bold", fontWeight: 600 },
    h5: { fontFamily: "Daimler CS, Bold", fontWeight: 500 },
    h6: { fontFamily: "Daimler CS, Bold", fontWeight: 500 },
    fontFamily: "Daimler CS"
  }
});

export { CareITTheme };

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_NEWADDED_DATA } from "./paths";

const getNewAddedData = async (emailId?: string | null) => {
  return await axiosClient
    .get<tableData[]>(`${GET_NEWADDED_DATA}${emailId!}`)
    .then((res) => res.data);
};

export const useNewAddedData = (emailId?: string | null) => {
  return useQuery({
    queryKey: ["new-added-data", emailId],
    queryFn: () => getNewAddedData(emailId)
  });
};
export interface tableData {
  id: number;
  name: string;
  approval: boolean;
  updatedDate: number;
  abbreviation: string;
}

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_WEBPAGE_OVERALL_CE } from "./paths";
import { useParams } from "react-router-dom";

const getPageOverallCEWithGraph = async (
  webpageId: string | number,
  isStatic: boolean,
  startDate: string,
  endDate: string
) => {
  return await axiosClient
    .get(
      GET_WEBPAGE_OVERALL_CE +
        `${isStatic ? "static" : "rt"}` +
        `/${webpageId}` +
        `/${startDate}/${endDate}`
    )
    .then((res) => res.data);
};

export const usePageOverallCEWithGraph = (
  selectedPlatform: string,
  isStatic: boolean,
  startDate: string,
  endDate: string
) => {
  const { webpageId } = useParams();
  return useQuery({
    queryKey: ["PageOverallCEWithGraph", webpageId],
    queryFn: () => {
      if (selectedPlatform === "client") {
        return getPageOverallCEWithGraph(webpageId!, isStatic, startDate, endDate);
      }
    },
    enabled: selectedPlatform === "client"
  });
};
export interface OverallCEWithGraph {
  pageId: number;
  overallCe: number;
  overallCC: number;
  overallSize: number;
  graphData: GraphDatum[];
  updatedDate: string;
}

export interface GraphDatum {
  jobId: number;
  createdDate: string;
  ce: number;
  cc: number;
  size: number;
  threshold: number;
  thresholdColour: string;
  variation: number;
  errorMessage: string;
  lhStatus: string;
  cecStatus: string;
}

import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { CareITTheme } from "./globals/theme/theme";
import RouterConfig from "./navigation/RouterConfig";
import { store } from "./store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

const App: React.FC<{}> = () => {
  return (
    <SnackbarProvider maxSnack={3}>
      <QueryClientProvider client={queryClient}>
        <StoreProvider store={store}>
          <ThemeProvider theme={CareITTheme}>
            <CssBaseline>
              <BrowserRouter basename={"/app"}>
                <Header />
                <RouterConfig />
              </BrowserRouter>
            </CssBaseline>
          </ThemeProvider>
        </StoreProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  );
};

export default App;

export default function DownArrow() {
  return (
    <svg width="65" height="45" viewBox="4 -6 56 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.1838 33.2866L64.6206 19.5759L57.7266 19.5759L57.7266 -1.20468e-05L48.631 -1.28419e-05L48.631 19.5759L41.747 19.5759L53.1838 33.2866Z"
        fill="#80BD24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4368 26.1594L22.8735 12.4487L15.9755 12.4487L15.9755 0.000182502L6.87993 0.000181707L6.87993 12.4487L-5.41145e-05 12.4487L11.4368 26.1594Z"
        fill="#80BD24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3103 45.0002L43.7471 31.2895L36.849 31.2895L36.849 0.000235908L27.7535 0.000235113L27.7535 31.2896L20.8735 31.2896L32.3103 45.0002Z"
        fill="#80BD24"
      />
    </svg>
  );
}

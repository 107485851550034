import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FilterUsers } from "./filterUsers";
import { UsersList } from "./usersList";
import { useAddedData } from "../../api/getUsersData";
import { useApprovedProjects } from "../../api/useAdminData";

function UsersMain({ userSearchValue, filters, setFilters, valueradio, setValueradio }) {
  const { data: tableData, isSuccess, refetch: refetchTableData } = useAddedData();
  const { data: projectData } = useApprovedProjects();
  const [userlist, setUserList] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setUserList(tableData);
      refetchTableData();
    }
  }, [isSuccess, tableData]);

  const searchUsers = userlist.filter((user) => {
    const lowerSearchValue = userSearchValue.toLowerCase();

    return (
      (user.name && user.name.toLowerCase().includes(lowerSearchValue)) ||
      (user.applicationRole && user.applicationRole.toLowerCase().includes(lowerSearchValue)) ||
      (user.projects &&
        user.projects.some(
          (project) =>
            (project.projectAbbr && project.projectAbbr.toLowerCase().includes(lowerSearchValue)) ||
            (project.projectName && project.projectName.toLowerCase().includes(lowerSearchValue))
        ))
    );
  });

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={3}>
            <Box className="container" sx={{ mr: 1, height: "100%" }}>
              <FilterUsers
                filters={filters}
                setFilters={setFilters}
                projectData={projectData}
                valueradio={valueradio}
                setValueradio={setValueradio}
              />
            </Box>
          </Grid>

          <Grid item xs={9}>
            <Box className="container" sx={{ ml: 1 }}>
              {isSuccess && (
                <UsersList
                  userlist={searchUsers}
                  filters={filters}
                  valueradio={valueradio}
                  setValueradio={setValueradio}
                ></UsersList>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default UsersMain;

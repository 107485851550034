import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { POST_USER_DATA } from "./paths";

interface ActionData {
  id: string;
  action: string;
}

const createAction = async ({ id, action }: ActionData) => {
  const response = await axiosClient.post(`${POST_USER_DATA}/${id}/${action}`);
  return response;
};

export const createNewAction = (onUpdateSuccess: any, onError: any) => {
  return useMutation({
    mutationFn: createAction,
    onSuccess: onUpdateSuccess
  });
};

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: ActionData,
  context: any
) => unknown;

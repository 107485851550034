import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Grid,
  Backdrop,
  Modal,
  Tooltip,
  Divider
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Webpage, useProjectWebPagesData } from "../../../api/useProjectWebPages";
import { red } from "@mui/material/colors";
import { IWebPage, updateWebpageById } from "../../../api/useUpdateWebPage";
import { ConfirmPopUp } from "../../../components/ConfirmPopup";
import { deleteWebpageById } from "../../../api/useDeleteWebPage";
import { createNewWebPage } from "../../../api/useCreateNewWebPages";
import { useSnackbar } from "notistack";
import { useUserRole } from "../../../api/useUserRole";
import { OPTIONS_CLIENT_SCAN_TYPE } from "../../../utils/constants";
import { IProject } from "../../../api/useProjectsData";
import ClientRTConfigForm from "../ClientRTConfigForm";
import ClientRTConfigDetails from "../ClientRTConfigDetails";
import { fallbackClientRTConfig } from "../../../api/useClientRTConfig";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { SearchBox } from "../../../components/SearchBox";
import ProjectScanForm from "../../../components/ProjectScanForm/ProjectScanForm";
import ProjectScanIcon from "../../../components/Svg/ProjectScanIcon";
interface rowsData {
  id: number;
  projectId: number;
  url: string | undefined;
  name: string | undefined;
  version: string;
}

export const Pages = ({ projectData, isStatic }: { projectData: IProject; isStatic: boolean }) => {
  const { data: webpages, isSuccess } = useProjectWebPagesData(projectData.id, true);
  const [openScanDetails, setOpenScanDetails] = useState(false);
  const [isGaLocked, setIsGaLocked] = useState(projectData?.isGaLock ?? false);
  const { data: userRole, isSuccess: roleSuccess } = useUserRole(
    projectData.id,
    localStorage.getItem("emailId")
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      setCheckUser(false);
      const rowsData = webpages?.map((page) => {
        return { ...page };
      });

      updateRows(rowsData);
    }
    if (roleSuccess) {
      checkUserAccess();
    }
  }, [webpages, userRole]);

  const checkUserAccess = () => {
    if (roleSuccess) {
      if (userRole !== "Collaborator") {
        setCheckUser(true);
      }
    }
  };

  const getMonitorDetails = () => {
    const isToolAvailable = projectData?.projectMonitoring?.isToolAvailable;
    const isClientMonitoring = projectData?.projectMonitoring?.client?.monitoringType === "Instana";
    const configName = projectData?.projectMonitoring?.client?.configName;
    if (isToolAvailable && isClientMonitoring) {
      if (!configName) {
        return "ConfigForm";
      } else if (configName && webpages && webpages.length === 0) {
        return "DetailForm";
      } else {
        return "ClientRT";
      }
    } else {
      return "ClientStatic";
    }
  };

  const [checkUser, setCheckUser] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [rows, updateRows] = useState<rowsData[] | undefined>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPage, setSelectedPage] = useState<IWebPage>();
  const [pageName, setPageName] = useState("");
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleOpen = () => setDeleteOpen(true);
  const handleClose = () => setDeleteOpen(false);
  const [open, setOpen] = React.useState(false);
  const handleModalOpen = () => {
    setOpen(true);
    setPageName("");
    setUrl("");
  };
  const handleModalClose = () => {
    setOpen(false);
    setAddUserError("");
    setPageName("");
    setUrl("");
  };
  const [addUserError, setAddUserError] = useState("");
  const [showConfigDetails, setShowConfigDetails] = useState(false);
  const onWebPageUpdate = (data: { data: Webpage }) => {
    console.log("user updated");
    // onSuccess("User Updated");
    const index = webpages?.findIndex((obj) => obj.id === data.data.id);
    if (index !== undefined && webpages !== undefined) {
      webpages[index] = data.data;
    } else {
      webpages?.push(data.data);
    }
    const updatedObject1 = webpages?.map((rowsData) => {
      return {
        ...rowsData
      };
    });
    updateRows(updatedObject1);
    console.log("updateRowsdata", updatedObject1);
  };

  const onWebPageDelete = (data: { data: { id: number } }) => {
    console.log(data);
    // onSuccess("User Deleted");
    const index: number | undefined = webpages?.findIndex((obj) => obj.id === data.data.id);
    if (index !== undefined) {
      webpages?.splice(index, 1);
    }

    const updatedObject1 = webpages?.map((obj) => {
      return {
        ...obj
      };
    });
    updateRows(updatedObject1);
    console.log("updateRowsData", updatedObject1);
  };

  const onWebPageAdded = (data: { data: Webpage }) => {
    console.log(data);
    // onSuccess("User Added");
    webpages?.push(data.data);
    const updatedObject1 = webpages?.map((obj) => {
      return {
        ...obj
      };
    });
    updateRows(updatedObject1);
    console.log("updateRowsdata", updatedObject1);
  };
  const onError = (error: {
    response: { data: { details: Array<React.SetStateAction<string>> } };
    data: { message: any };
  }) => {
    if (error.response) {
      console.log("Error Status:", error.response);
      console.log("Error Response:", error.response.data.details[0]);
      setAddUserError(error.response.data.details[0]);
    } else {
      console.error("Error:", error.data.message);
    }
  };

  const { mutate: createWebPage, isError } = createNewWebPage(onWebPageAdded, onError);
  const { mutate: updateWebPage } = updateWebpageById(onWebPageUpdate);
  const { mutate: deleteWebPage } = deleteWebpageById(onWebPageDelete);

  const editTable = (row: IWebPage) => {
    setIsEditing(true);
    setSelectedPage(row);
    setPageName(row.name);
    setUrl(row.url);
  };

  const confirmDelete = () => {
    if (selectedPage !== undefined) {
      deleteWebPage(selectedPage);
    }

    if (rows !== undefined) {
      let newArr = [...rows];

      newArr = newArr.filter(function (obj) {
        return obj.id !== selectedPage?.id;
      });
      updateRows(newArr);
    }
    // queryClient.invalidateQueries(["CollaboraterPages", projectId]);

    enqueueSnackbar("Page Deleted", { variant: "success", autoHideDuration: 2000 });
  };

  const handleSearch = (e: { target: { value: string } }) => {
    const value = e.target?.value;
    setSearchQuery(value);

    const results = webpages?.filter((webpage) =>
      webpage.name.toLowerCase().includes(value.toLowerCase())
    );
    updateRows(results);
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (url !== undefined) {
      const isValidUrl = urlRegex.test(url);

      const obj = {
        name: pageName,
        projectId: projectData.id,
        url,
        version: projectData.currentVersion
      };

      if (isValidUrl) {
        createWebPage(obj);
        setPageName("");
        setUrl("");
        enqueueSnackbar("Page Added", { variant: "success", autoHideDuration: 2000 });
        handleModalClose();
      } else if (isError) {
        setPageName("");
        setUrl("");
      } else {
        enqueueSnackbar("Enter Valid Url", { variant: "warning", autoHideDuration: 2000 });
      }
    }
  };
  const handleDelete = (row: rowsData) => {
    handleOpen();
    setSelectedPage(row);
  };

  const cancelRow = (id: string | number) => {
    console.log("Id clicked cancel:", selectedPage?.id);

    if (id === "") {
      // Remove the new row with an empty ID
      const filteredData = webpages?.filter((obj) => obj.id.toString() !== "");
      updateRows(filteredData);
    } else {
      // Restore original role value
      const originalpname = webpages?.find((obj) => obj.id === id)?.name;
      const originalUrl = webpages?.find((obj) => obj.id === id)?.url;
      const updatedRows = rows?.map((row) => {
        if (row.id === id) {
          return { ...row, name: originalpname, url: originalUrl };
        }
        return row;
      });
      // Update rows with the restored role value
      updateRows(updatedRows);
    }

    // Reset other states
    setIsEditing(false);
    setSelectedPage(undefined);
  };

  const handlePagenameChange = (event: { target: { value: string } }) => {
    const newPageName = event.target.value;
    setPageName(newPageName);

    setSelectedPage((prevSelectedPage) => ({
      ...prevSelectedPage,
      name: newPageName
    }));
  };

  const handleURLChange = (event: { target: { value: string } }) => {
    const newUrl = event.target.value;
    setUrl(newUrl);
    setSelectedPage((prevSelectedPage) => ({
      ...prevSelectedPage,
      url: newUrl
    }));
  };
  const urlRegex =
    /(http(s)?:\/\/)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
  const isValidUrl = (url: string) => {
    return urlRegex.test(url);
  };

  const saveRow = () => {
    const { ...rest } = selectedPage;
    if (selectedPage?.url !== undefined) {
      const isValid = isValidUrl(selectedPage.url);
      if (pageName.length > 0) {
        if (isValid) {
          updateWebPage(rest);
          setIsEditing(false);
          setPageName("");
          setUrl("");
          enqueueSnackbar("Page Updated", { variant: "success", autoHideDuration: 2000 });
        } else {
          enqueueSnackbar("Enter Valid Url", { variant: "warning", autoHideDuration: 2000 });
        }
      } else {
        enqueueSnackbar("Page name is empty", { variant: "warning", autoHideDuration: 2000 });
      }
      setIsEditing(false);
      setSelectedPage(undefined);
    }
  };
  const isBase64 = (str: string): boolean => {
    const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    return base64Regex.test(str);
  };

  const decodeBase64 = (base64: string): string => {
    try {
      return atob(base64);
    } catch (error) {
      console.error("Failed to decode base64 string:", base64, error);
      return base64; // Return the original string if decoding fails
    }
  };

  const handleScanModalOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpenScanDetails(true);
  };
  const handleScanModalClose = () => {
    setOpenScanDetails(false);
  };
  return (
    <>
      <Grid className="pagesContainer" item xs={6}>
        <Box className="clientRTHeader">
          <Box className="left-items">
            {getMonitorDetails() === "ClientStatic" ? (
              <Typography className="heading">{OPTIONS_CLIENT_SCAN_TYPE[0].labelValue}</Typography>
            ) : (
              <Typography className="heading">{OPTIONS_CLIENT_SCAN_TYPE[1].labelValue}</Typography>
            )}
            <Divider className="divider-vertical" orientation="vertical" />
            <span className="secondary-text-entity">Pages</span>
          </Box>
          <Box className="right-items">
            {getMonitorDetails() === "ClientStatic" &&
              Array.isArray(webpages) &&
              webpages.length > 0 && (
                <Box>
                  <CustomButton
                    variant="contained"
                    startIcon={<ProjectScanIcon />}
                    onClick={handleScanModalOpen}
                    className={`scan-btn ${isGaLocked ? "disabled" : ""}`}
                    disabled={isGaLocked}
                  >
                    Start Scan
                  </CustomButton>
                  <ProjectScanForm
                    projectAbbr={projectData?.projectAbbr}
                    openScanDetails={openScanDetails}
                    handleModalClose={handleScanModalClose}
                    projectId={projectData?.id}
                    setIsGaLocked={setIsGaLocked}
                  />
                </Box>
              )}
            {getMonitorDetails() !== "ConfigForm" && getMonitorDetails() !== "DetailForm" && (
              <SearchBox
                value={searchQuery}
                handleSearch={handleSearch}
                placeholder="Search Page"
                className="searchInput"
              />
            )}
            {getMonitorDetails() === "ClientStatic" && (
              <CustomButton
                className="addpagebtn"
                startIcon={<AddIcon />}
                variant="contained"
                onClick={handleModalOpen}
                disabled={!checkUser}
              >
                Add Page
              </CustomButton>
            )}
          </Box>
        </Box>
        <Grid item xs={12}>
          <Card className="cardPages">
            {getMonitorDetails() === "ConfigForm" ? (
              !showConfigDetails ? (
                <ClientRTConfigForm
                  projID={projectData.id}
                  monitoringPlatformId={projectData?.projectMonitoring?.client?.id}
                  setShowConfigDetails={setShowConfigDetails}
                  showSteps={true}
                  isEditMode={false}
                  ConfigDetails={fallbackClientRTConfig}
                />
              ) : (
                <ClientRTConfigDetails
                  projID={projectData.id}
                  monitoringPlatformId={projectData?.projectMonitoring?.client?.id}
                  showSteps={true}
                />
              )
            ) : getMonitorDetails() === "DetailForm" ? (
              <ClientRTConfigDetails
                projID={projectData.id}
                monitoringPlatformId={projectData?.projectMonitoring?.client?.id}
                showSteps={true}
              />
            ) : (
              <>
                {getMonitorDetails() === "ClientRT" && (
                  <ClientRTConfigDetails
                    projID={projectData.id}
                    monitoringPlatformId={projectData?.projectMonitoring?.client?.id}
                    showSteps={false}
                  />
                )}
                <TableContainer
                  className={`settings-entity-table ${isStatic ? "" : "rt-entity-list-height"}`}
                  component={Paper}
                >
                  <Table className="table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="head" style={{ width: " 94px" }}>
                          Page Name
                        </TableCell>
                        <TableCell className="head">URL</TableCell>
                        {checkUser && <TableCell className="head">Actions</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {rows?.map((row) => (
                        <TableRow key={row.name} className="row">
                          <TableCell className="cell-design" style={{ width: "148px" }}>
                            {isEditing && selectedPage?.id === row.id ? (
                              <TextField
                                size="small"
                                className="pcInput"
                                id="pname"
                                name="pname"
                                required
                                value={isEditing ? pageName : row.name}
                                disabled={selectedPage?.id !== row.id}
                                onChange={handlePagenameChange}
                              />
                            ) : (
                              <input
                                className="pcInput"
                                id="pname"
                                name="pname"
                                required
                                value={row.name}
                                disabled={selectedPage?.id !== row.id}
                              />
                            )}
                          </TableCell>
                          <TableCell className="cell-design">
                            {isEditing && selectedPage?.id === row.id ? (
                              <TextField
                                size="small"
                                className="pcInput"
                                id="purl"
                                type="url"
                                required
                                name="purl"
                                value={
                                  isEditing
                                    ? isBase64(String(url))
                                      ? decodeBase64(String(url))
                                      : url
                                    : row.url
                                }
                                disabled={selectedPage?.id !== row.id}
                                onChange={handleURLChange}
                              />
                            ) : (
                              <Tooltip
                                title={
                                  isBase64(String(row.url))
                                    ? decodeBase64(String(row.url))
                                    : row.url
                                }
                              >
                                <input
                                  className="pcInput"
                                  id="purl"
                                  type="url"
                                  required
                                  name="purl"
                                  value={
                                    isBase64(String(row.url))
                                      ? decodeBase64(String(row.url))
                                      : row.url
                                  }
                                  disabled={selectedPage?.id !== row.id}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell className="cell-design" style={{ width: "80px" }}>
                            {isEditing && selectedPage?.id === row.id ? (
                              <>
                                <CheckCircleOutlineIcon
                                  onClick={() => {
                                    saveRow();
                                  }}
                                  className="small-icon icon-white tableDelete"
                                />
                                <CancelIcon
                                  onClick={() => {
                                    cancelRow(row.id);
                                  }}
                                  sx={{ color: red[500] }}
                                  className="small-icon icon-red tableDelete"
                                />
                              </>
                            ) : (
                              checkUser && (
                                <>
                                  <BorderColorOutlinedIcon
                                    onClick={() => {
                                      editTable(row);
                                    }}
                                    className="small-icon icon-white tableEdit"
                                  />
                                  <DeleteOutlineOutlinedIcon
                                    sx={{ color: red[500] }}
                                    onClick={() => {
                                      handleDelete(row);
                                    }}
                                    className="small-icon icon-red tableDelete"
                                  />
                                </>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Card>
        </Grid>
      </Grid>

      {
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={deleteOpen}
          onClose={handleClose}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <div>
            <ConfirmPopUp
              onClose={handleClose}
              onSave={confirmDelete}
              data="Do you want to delete?"
              key="1"
            />
          </div>
        </Modal>
      }
      {
        <Modal
          open={open}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalContainer">
            <div>
              <Typography className="modalHeading">Add New Page</Typography>
              <CloseIcon className="modalClosebtn" onClick={handleModalClose}></CloseIcon>
            </div>
            <hr className="hrLine"></hr>
            <Box component="form" onSubmit={handleSubmit}>
              <Typography className="add-formHeading">Page Name</Typography>
              <TextField
                id="outlined-required"
                size="small"
                placeholder="Enter Page Name"
                required
                className="addInput"
                value={pageName}
                onChange={handlePagenameChange}
              ></TextField>
              <Typography className="add-formHeading1">URL</Typography>
              <TextField
                id="outlined-required"
                value={url}
                size="small"
                placeholder="Enter URL"
                required
                type="url"
                className="addInput1"
                onChange={handleURLChange}
              ></TextField>
              {isError ? (
                <Typography
                  style={{ top: "222px", position: "absolute", left: "79px" }}
                  color="red"
                >
                  {addUserError?.toString()}
                </Typography>
              ) : null}
              <CustomButton type="submit" className="add-saveBtn">
                Save
              </CustomButton>
            </Box>
          </Box>
        </Modal>
      }
    </>
  );
};

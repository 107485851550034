import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./CircularProgress.scss";

function CircularProgressWithLabel(props) {
  return (
    <Box className="CircularBox c-progress-with-label-wrap">
      <svg width="0" viewBox="0 0 30 30">
        <defs>
          {props.color === "redTab" ? (
            <linearGradient id={`my_gradient-${props.value}`}>
              <stop offset="0%" stopColor="rgba(246, 128, 62, 1)" />
              <stop offset="100%" stopColor="rgba(246, 62, 62, 1)" />
            </linearGradient>
          ) : props.color === "greenTab" ? (
            <linearGradient id={`my_gradient-${props.value}`}>
              <stop offset="0%" stopColor="rgba(50, 204, 158, 1)" />
              <stop offset="100%" stopColor="rgba(50, 204, 102, 1)" />
            </linearGradient>
          ) : (
            <linearGradient id={`my_gradient-${props.value}`}>
              <stop offset="0%" stopColor="rgba(255, 170, 99, 1)" />
              <stop offset="100%" stopColor="rgb(255, 120, 41,1)" />
            </linearGradient>
          )}
        </defs>
      </svg>

      <CircularProgress variant="determinate" className="circular-tack" value={100} />

      <CircularProgress
        variant="determinate"
        value={props.value}
        sx={{
          position: "absolute",
          "svg circle": {
            stroke: `url(#my_gradient-${props.value})`
          },
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round"
          }
        }}
      />
      <Box className="circular-progress-text-wrap">
        <Typography variant="caption" component="div" color="#fff" fontWeight={700}>
          {`${Math.round(props.value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired
};
export const CircularProgressComponent = (props) => {
  return (
    <CircularProgressWithLabel
      color={props.color}
      className={props.className}
      value={props.percentage}
    />
  );
};
export default CircularProgressComponent;

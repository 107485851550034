/* eslint-disable react/prop-types */
import { Box, Tab, Typography } from "@mui/material";
import CircularProgressComponent from "../CircularProgressBar/CircularProgress";
import "./Tab.scss";

interface TabComponentProps {
  classColor: string;
  value: number;
  percentage: number;
  label: string;
  onChange: any;
  isActive: boolean;
}

export const TabComponent = (props: TabComponentProps) => {
  return (
    <div className="MainTab">
      <Box p={1} sx={props.isActive ? { bgcolor: "#1E1E1E", borderRadius: "20px" } : {}}>
        <Tab
          sx={{ py: 0 }}
          icon={
            <CircularProgressComponent
              className={`CircularBar ${props.classColor}`}
              percentage={props.percentage}
              color={props.classColor}
            />
          }
          className={props.classColor}
          label={
            <Typography
              className="TabName"
              variant="h6"
              mt={1}
              mb={-1}
              style={{ fontSize: "0.8rem", lineHeight: 1, color: "white", opacity: 0.7 }}
            >
              {props.label}
            </Typography>
          }
          value={props.value}
          onChange={props.onChange}
        />
      </Box>
    </div>
  );
};

export default TabComponent;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, Grid, OutlinedInput, SelectChangeEvent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProjectSummary } from "../../../api/useProjectSummaryData";
import { Webpage } from "../../../api/useProjectWebPages";
import CustomBasicDropdown from "../../../components/CustomBasicDropdown";
import Lamp from "../../../components/Svg/Lamp";
import { returnDropDownOptionsFromAnyObj } from "../../../utils/common";

import CustomButton from "../../../components/CustomButton/CustomButton";

interface PageSectionHeaderProps {
  projectId: string | number | undefined;
  webpageId: string | number | undefined;
  projectSummaryData: ProjectSummary | undefined;
  jobId: number | undefined;
  setJobId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setPerVisitView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPlatform: string | undefined;
  entityData:
    | Webpage[]
    // | ContainerList[]
    | undefined;
  currentEntity:
    | Webpage
    // | ContainerList
    | undefined;
  setCurrentEntity: React.Dispatch<
    React.SetStateAction<
      | Webpage
      // | ContainerList
      | undefined
    >
  >;
  isStatic: boolean;
}
// interface PageItm {
//   id: number;
//   name: string;
//   projectId: number;
//   url: string;
//   version: string;
// }
export const PageSectionHeader = ({
  projectId,
  webpageId,
  projectSummaryData,
  jobId,
  setJobId,
  setPerVisitView,
  selectedPlatform,
  entityData,
  currentEntity,
  setCurrentEntity,
  isStatic
}: PageSectionHeaderProps) => {
  const nav = useNavigate();

  const suggestionsCount =
    selectedPlatform === "client"
      ? isStatic
        ? projectSummaryData?.client?.suggestionsCount.find(
            (item: any) => item.pageId.toString() === webpageId!
          )?.suggestionsCount
        : projectSummaryData?.clientRT?.suggestionsCount.find(
            (item: any) => item.pageId.toString() === webpageId!
          )?.suggestionsCount
      : 0;
  const handleSelect = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target;
    if (jobId !== undefined) {
      nav(`../${value as string}`, {
        replace: true,
        relative: "path"
      });
    } else {
      nav(`../${value as string}`, {
        replace: true,
        relative: "path"
      });
    }
    setJobId(undefined);
    setPerVisitView(false);
    setCurrentEntity(
      entityData?.find((itm) => itm.id === +(value as string)) as Webpage
      // | ContainerList
    );
  };

  const SuggestionsButton = () => {
    return (
      <CustomButton
        variant="contained"
        className={`suggestions-button ${!suggestionsCount ? "disabled" : ""}`}
        onClick={() =>
          nav(`../../../../suggestions/${projectId!}/${webpageId!}`, {
            replace: false,
            relative: "path"
          })
        }
        startIcon={<Lamp />}
        disabled={!suggestionsCount}
      >
        {suggestionsCount ? `${suggestionsCount} Suggestions` : "Suggestions"}
      </CustomButton>
    );
  };
  const entityDataOptions = entityData
    ? returnDropDownOptionsFromAnyObj(entityData, "name", "id")
    : [];
  const entityDataOptionsWithCustomClass = entityDataOptions?.map((itm) => ({
    ...itm,
    className: `menu-item ${webpageId === itm.value.toString() ? "active-menu-item" : ""}`
  }));

  return (
    <>
      {
        <>
          <Typography className="breadcrumbs-typography">
            <span style={{ cursor: "pointer" }}>{projectSummaryData?.name}</span> /{" "}
            {selectedPlatform &&
              selectedPlatform?.charAt(0)?.toUpperCase() + selectedPlatform?.slice(1)}{" "}
            /
          </Typography>
          <Grid py={2} className="grid-page-header">
            <Grid className="grid-title">
              {currentEntity ? (
                <CustomBasicDropdown
                  className="PageDropdown title-dropdown"
                  value={currentEntity?.name}
                  variant="standard"
                  IconComponent={ExpandMoreIcon}
                  onChange={handleSelect}
                  input={<OutlinedInput />}
                  renderValue={(selected) => entityData?.find((itm) => itm.name === selected)?.name}
                  options={entityDataOptionsWithCustomClass}
                />
              ) : (
                <div style={{ fontSize: "22px" }}>
                  <b>No Pages</b>&nbsp;
                </div>
              )}
              <Divider className="divider-vertical" orientation="vertical" flexItem />
              <div className="secondary-text">
                {selectedPlatform === "client" ? "Page Overview" : "Overview"}
              </div>
            </Grid>
            <Grid>{selectedPlatform === "client" && <SuggestionsButton />}</Grid>
          </Grid>
        </>
      }
    </>
  );
};

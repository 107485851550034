import Tabs from "@mui/material/Tabs";
import "./TabBar.scss";
import TabComponent from "../Tab/Tab";
import React, { useState } from "react";

export const TabBar: React.FC<any> = (props: any) => {
  const tabElements = ["Performance", "Accessibility", "Best Practices"];
  // dummy values
  const performanceValue = props.performanceScore;
  const bpValue = props.bestPracticesScore;
  const accessibilityValue = props.accessibilityScore;

  const tabColors = [performanceValue, bpValue, accessibilityValue];

  const [tabColor, setTabColor] = useState(determineColor(tabColors[props.value]));
  // const [tabColor] = useState(determineColor(tabColors[props.value]));

  // function to determine the color
  function determineColor(percentage: number): string {
    if (percentage >= 80) {
      return "greenTab";
    } else if (percentage >= 50 && percentage <= 80) {
      return "yellowTab";
    } else {
      return "redTab";
    }
  }

  const handleChange = (newValue: number): void => {
    console.log(newValue);
    props.setValue(newValue);
    setTabColor(determineColor(tabColors[newValue]));
  };

  return (
    <>
      <Tabs
        className={`tabs-container ${tabColor}`}
        value={props.value}
        onChange={(_event, newValue) => handleChange(newValue)}
        aria-label="tabs"
        centered
        classes={{
          indicator: "indicator"
        }}
      >
        <TabComponent
          classColor={determineColor(performanceValue)}
          value={0}
          label={tabElements[0]}
          onChange={handleChange}
          percentage={performanceValue}
          isActive={props.value === 0}
        />
        <TabComponent
          classColor={determineColor(accessibilityValue)}
          value={1}
          label={tabElements[1]}
          onChange={handleChange}
          percentage={accessibilityValue}
          isActive={props.value === 1}
        />
        <TabComponent
          classColor={determineColor(bpValue)}
          value={2}
          label={tabElements[2]}
          onChange={handleChange}
          percentage={bpValue}
          isActive={props.value === 2}
        />
      </Tabs>
    </>
  );
};

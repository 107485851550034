import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import "./CustomLoader.scss";

const CustomLoader = () => {
  return (
    <div className="loader-overlay">
      <CircularProgress
        variant="indeterminate"
        disableShrink
        size={40}
        thickness={4}
        className="customLoaderColor"
      />
      <div className="loader-text">
        <Typography>Please wait till we fetch the details...</Typography>
      </div>
    </div>
  );
};

export default CustomLoader;

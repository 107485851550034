/* eslint-disable */
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import "./style.scss";
import { CircularProgress } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  textAlign: "center"
};

export const GeneralPopUp = (props) => {
  const close = () => {
    props.onClose();
  };

  return (
    <Fade in={true}>
      <Box className="modalPop" sx={style}>
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "left", marginBottom: 2 }}
        >
          Rejected Comment :
        </Typography>
        {props.loading ? (
          <CircularProgress size={30} />
        ) : (
          <Typography id="transition-modal-description" sx={{ textAlign: "left" }}>
            {props.data}
          </Typography>
        )}
        <Box className="action-btns" sx={{ mt: 2 }}>
          <CustomButton className="sortButton" variant="outlined" onClick={close}>
            <Typography className="text-lightGrey secondary-font" variant="p">
              Close
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </Fade>
  );
};

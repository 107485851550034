import { Grid, Card } from "@mui/material";
import Bulb from "../../../components/Svg/Bulb";
import Tree from "../../../components/Svg/Tree";
// import { PoweredByLH } from "../../../components/Powered by LH";
import { ProjectSummary } from "../../../api/useProjectSummaryData";
import { formatNumber } from "../Format";
import OverviewCard from "../../../components/OverviewCard/OverviewCard";
import Cpu from "../../../components/Svg/Cpu";
import RamIcon from "../../../components/Svg/RamIcon";
import { Gpu } from "../../../components/Svg/Gpu";
import useCeConversionFixed from "../../../utils/useCeConversionFixed";
import useCeConversion from "../../../utils/useCeConversion";

export const PlatformSummaryCardsContainer = ({
  projectSummaryData,
  selectedPlatform,
  isStatic
}: {
  projectSummaryData: ProjectSummary | undefined;
  selectedPlatform: string;
  isStatic: boolean;
}) => {
  return (
    <Grid className="grid-design platform-cards-container" container rowSpacing={4}>
      {selectedPlatform === "server" ? (
        <Grid container className="bottom-padding-15" spacing={2}>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={useCeConversionFixed(Number(projectSummaryData?.server.ce))}
                metrictooltip={useCeConversion(Number(projectSummaryData?.server.ce))}
                title="Carbon Emission"
                secondaryText={`≈ ${String(
                  projectSummaryData?.server.ec.toFixed(3)
                )} kWh of Energy `}
                secondaryIcon={<Bulb />}
              />
            </Card>
          </Grid>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={`${String(projectSummaryData?.server.cc)} €`}
                metrictooltip={`${String(projectSummaryData?.server.cc)} €`}
                title="Carbon Offset"
                secondaryText={`≈ Planting ${String(
                  projectSummaryData?.server.trees.toFixed(3)
                )} tree`}
                secondaryIcon={<Tree />}
              />
            </Card>
          </Grid>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={useCeConversionFixed(Number(projectSummaryData?.server.cpuCe))}
                metrictooltip={useCeConversion(Number(projectSummaryData?.server.cpuCe))}
                secondaryMetric={`(${String(projectSummaryData?.server.cpuEc)} kWh)`}
                title="CPU Utilisation"
                secondaryText={`${String(projectSummaryData?.server.cpuPercent)} %`}
                secondaryIcon={<Cpu />}
                perProgress={Number(projectSummaryData?.server.cpuPercent)}
              />
            </Card>
          </Grid>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={useCeConversionFixed(Number(projectSummaryData?.server.memCe))}
                metrictooltip={useCeConversionFixed(Number(projectSummaryData?.server.memCe))}
                secondaryMetric={`(${String(projectSummaryData?.server.memEc)} kWh)`}
                title="Memory Utilisation"
                secondaryText={`${String(projectSummaryData?.server.memPercent)} %`}
                secondaryIcon={<RamIcon />}
                perProgress={Number(projectSummaryData?.server.memPercent)}
              />
            </Card>
          </Grid>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={useCeConversionFixed(Number(projectSummaryData?.server.gpuCe))}
                metrictooltip={useCeConversion(Number(projectSummaryData?.server.gpuCe))}
                secondaryMetric={`(${String(projectSummaryData?.server.gpuEc)} kWh)`}
                title="GPU Utilisation"
                secondaryText={`${String(projectSummaryData?.server.gpuPercent)} %`}
                secondaryIcon={<Gpu />}
                perProgress={Number(projectSummaryData?.server.gpuPercent)}
              />
            </Card>
          </Grid>
        </Grid>
      ) : isStatic ? (
        <Grid container className="bottom-padding-15" spacing={2}>
          <Grid item lg={4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={useCeConversionFixed(Number(projectSummaryData?.client?.ce))}
                metrictooltip={useCeConversion(Number(projectSummaryData?.client?.ce))}
                title="Carbon Emission"
                secondaryText={`≈ ${formatNumber(projectSummaryData?.client?.ec).toFixed(
                  3
                )} kWh of Energy `}
                secondaryIcon={<Bulb />}
              />
            </Card>
          </Grid>
          <Grid item lg={4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={`${formatNumber(projectSummaryData?.client?.cc).toFixed(3)} €`}
                metrictooltip={`${String(projectSummaryData?.client?.cc)} €`}
                title="Carbon Offset"
                secondaryText={`≈ Planting ${
                  projectSummaryData?.client?.trees
                    ? Math.round(projectSummaryData?.client?.trees)
                    : 0
                } tree`}
                secondaryIcon={<Tree />}
              />
            </Card>
          </Grid>
          {/* <Grid item lg={3} style={{ paddingRight: "16px" }}>
            <Card className="overview-cards">
              <OverviewCard
                metricdata={`${String(
                  projectSummaryData?.client?.score && Math.round(projectSummaryData?.client?.score)
                )}%`}
                metrictooltip={`${String(projectSummaryData?.client?.score)}%`}
                title="Quality Score"
                secondaryText={<PoweredByLH />}
              />
            </Card>
          </Grid> */}
          <Grid item lg={4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={`${formatNumber(projectSummaryData?.client?.storage).toFixed(3)} GB`}
                metrictooltip={`${String(projectSummaryData?.client?.storage)} GB`}
                title="Resources Consumed"
                secondaryText=""
              />
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="bottom-padding-15" spacing={2}>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={useCeConversionFixed(Number(projectSummaryData?.clientRT?.ce))}
                metrictooltip={useCeConversion(Number(projectSummaryData?.clientRT?.ce))}
                title="Carbon Emission"
                secondaryText={`≈ ${formatNumber(projectSummaryData?.clientRT?.ec).toFixed(
                  3
                )} kWh of Energy `}
                secondaryIcon={<Bulb />}
              />
            </Card>
          </Grid>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={`${formatNumber(projectSummaryData?.clientRT?.cc).toFixed(3)} €`}
                metrictooltip={`${String(projectSummaryData?.clientRT?.cc)} €`}
                title="Carbon Offset"
                secondaryText={`≈ Planting ${
                  projectSummaryData?.clientRT?.trees
                    ? Math.round(projectSummaryData?.clientRT?.trees)
                    : 0
                } tree`}
                secondaryIcon={<Tree />}
              />
            </Card>
          </Grid>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={`${formatNumber(projectSummaryData?.clientRT?.resourceConsumed).toFixed(
                  3
                )} GB`}
                metrictooltip={`${String(projectSummaryData?.clientRT?.resourceConsumed)} GB`}
                title="Resources Consumed"
                secondaryText=""
              />
            </Card>
          </Grid>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={`${formatNumber(projectSummaryData?.clientRT?.avgNumberOfUsers).toFixed(
                  3
                )}`}
                metrictooltip={`${String(projectSummaryData?.clientRT?.avgNumberOfUsers)}`}
                title="Avg No of Users"
                secondaryText=""
              />
            </Card>
          </Grid>
          <Grid item lg={2.4} className="right-padding-16">
            <Card className="overview-cards">
              <OverviewCard
                metricdata={`${formatNumber(
                  projectSummaryData?.clientRT?.avgNumberOfPageLoads
                ).toFixed(3)}`}
                metrictooltip={`${String(projectSummaryData?.clientRT?.avgNumberOfPageLoads)} GB`}
                title="Avg No of Page Loads"
                secondaryText=""
              />
            </Card>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { axiosClient } from "../globals/client/axios";
import { RESET_PASS } from "./paths";

const resetPassword = async ({
  newPassword,
  token: resetToken
}: {
  newPassword: string;
  token: string;
}) => {
  return await axiosClient.post(
    RESET_PASS,
    {},
    {
      params: {
        newPassword,
        resetToken
      }
    }
  );
};

export const useResetPasswordMutation = (onResetSuccess: onSuccess) => {
  return useMutation({
    mutationFn: resetPassword,
    onSuccess: onResetSuccess
  });
};

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: any,
  context: unknown
) => unknown;

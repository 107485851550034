import React from "react";

const ProjectScanIcon: React.FC = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1860_9297)">
        <path
          d="M1.33301 8.00016C1.33301 4.85747 1.33301 3.28612 2.30932 2.30981C3.28563 1.3335 4.85698 1.3335 7.99967 1.3335C11.1424 1.3335 12.7137 1.3335 13.69 2.30981C14.6663 3.28612 14.6663 4.85747 14.6663 8.00016C14.6663 11.1429 14.6663 12.7142 13.69 13.6905C12.7137 14.6668 11.1424 14.6668 7.99967 14.6668C4.85698 14.6668 3.28563 14.6668 2.30932 13.6905C1.33301 12.7142 1.33301 11.1429 1.33301 8.00016Z"
          stroke="white"
        />
        <path
          d="M4.66732 3.99992C4.66732 4.36811 4.36884 4.66659 4.00065 4.66659C3.63246 4.66659 3.33398 4.36811 3.33398 3.99992C3.33398 3.63173 3.63246 3.33325 4.00065 3.33325C4.36884 3.33325 4.66732 3.63173 4.66732 3.99992Z"
          fill="white"
        />
        <path
          d="M6.66732 3.99992C6.66732 4.36811 6.36884 4.66659 6.00065 4.66659C5.63246 4.66659 5.33398 4.36811 5.33398 3.99992C5.33398 3.63173 5.63246 3.33325 6.00065 3.33325C6.36884 3.33325 6.66732 3.63173 6.66732 3.99992Z"
          fill="white"
        />
        <path
          d="M8.66732 3.99992C8.66732 4.36811 8.36884 4.66659 8.00065 4.66659C7.63246 4.66659 7.33398 4.36811 7.33398 3.99992C7.33398 3.63173 7.63246 3.33325 8.00065 3.33325C8.36884 3.33325 8.66732 3.63173 8.66732 3.99992Z"
          fill="white"
        />
        <path d="M1 6H14.3333" stroke="white" strokeLinecap="round" />
        <path d="M5.99902 14L5.99902 6.66667" stroke="white" strokeLinecap="round" />
      </g>
      <path
        d="M11.3993 8.94055C12.0263 9.36681 12.3398 9.57993 12.3398 9.94446C12.3398 10.309 12.0263 10.5221 11.3993 10.9484C11.2262 11.066 11.0545 11.1768 10.8967 11.2692C10.7583 11.3501 10.6016 11.4339 10.4393 11.5162C9.81374 11.8332 9.50095 11.9917 9.22042 11.8162C8.93989 11.6407 8.91439 11.2733 8.8634 10.5386C8.84898 10.3308 8.83984 10.1271 8.83984 9.94446C8.83984 9.76185 8.84898 9.55815 8.8634 9.35036C8.91439 8.61561 8.93989 8.24823 9.22042 8.07274C9.50095 7.89725 9.81374 8.05575 10.4393 8.37276C10.6016 8.455 10.7583 8.53878 10.8967 8.61977C11.0545 8.71209 11.2262 8.82287 11.3993 8.94055Z"
        stroke="white"
      />
      <defs>
        <clipPath id="clip0_1860_9297">
          <rect width="16" height="16" rx="3.95833" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProjectScanIcon;

export default function AccessReq() {
  return (
    <svg width="96" height="72" viewBox="0 0 96 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9998 9.02991C18.9998 7.85576 19.8 6.83274 20.9396 6.55001L47.0378 0.0751802C47.4419 -0.0250601 47.8643 -0.0250601 48.2683 0.0751802L74.3666 6.55001C75.5062 6.83274 76.3064 7.85576 76.3064 9.02991V37.6535C76.3064 57.242 58.401 67.7182 48.5933 71.8115C47.9913 72.0628 47.3149 72.0628 46.7129 71.8115C36.9052 67.7182 18.9998 57.242 18.9998 37.6535V9.02991Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4104 18.0693C43.5802 18.0693 40.4752 21.1743 40.4752 25.0045V28.8368H37.5551C36.1439 28.8368 35 29.9807 35 31.3918V44.1672C35 45.5783 36.1439 46.7223 37.5551 46.7223H57.2656C58.6768 46.7223 59.8207 45.5783 59.8207 44.1672V31.3918C59.8207 29.9807 58.6768 28.8368 57.2657 28.8368H54.3456V25.0045C54.3456 21.1743 51.2406 18.0693 47.4104 18.0693ZM51.0605 28.8368V25.0045C51.0605 22.9886 49.4263 21.3544 47.4104 21.3544C45.3945 21.3544 43.7603 22.9886 43.7603 25.0045V28.8368H51.0605Z"
        fill="#80BD24"
      />
      <circle opacity="0.2" cx="87.5" cy="53.501" r="2.5" fill="white" />
      <circle opacity="0.2" cx="9.5" cy="58.501" r="3" stroke="white" />
      <circle opacity="0.2" cx="85" cy="26.001" r="1.5" stroke="white" />
      <circle opacity="0.2" cx="11" cy="28.001" r="2" fill="#D9D9D9" />
    </svg>
  );
}

import * as React from "react";
import { Grid, Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../Svg/CalendarIcon";
import "./index.scss";

interface CustomDatePickerProps {
  startDate: Dayjs | null;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  endDate: Dayjs | null;
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
}

const CustomDatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate
}: CustomDatePickerProps) => {
  const startDateAsDate = startDate?.toDate() ?? null;
  const endDateAsDate = endDate?.toDate() ?? null;
  const [startDateField, setStartDateField] = React.useState<Date | null>(
    startDate ? startDate.toDate() : null
  );
  const [endDateField, setEndDateField] = React.useState<Date | null>(
    endDate ? endDate.toDate() : null
  );
  const isSameDay = (date1: Dayjs | null, date2: Dayjs | null) => {
    return date1 && date2 && date1.isSame(date2, "day");
  };
  const minDate = startDateAsDate ? new Date(startDateAsDate) : null;
  if (minDate) {
    minDate.setMonth(minDate.getMonth());
  }
  React.useEffect(() => {
    setStartDateField(startDate ? startDate.toDate() : null);
  }, [startDate]);

  React.useEffect(() => {
    setEndDateField(endDate ? endDate.toDate() : null);
  }, [endDate]);

  const handleStartDateChange = (date: Date) => {
    if (date <= endDateField!) {
      setStartDateField(date);
      setStartDate(dayjs(date));
    } else {
      alert("Start date shouldn't be greater than End date");
    }
  };
  const handleEndDateChange = (date: Date) => {
    if (date >= startDateField!) {
      setEndDateField(date);
      setEndDate(dayjs(date));
    } else {
      alert("End date shouldn't be less than Start date");
    }
  };

  const compare = (end: Date | null) => {
    const yearOfFirstDate = startDateAsDate?.getFullYear();
    const monthOfFirstDate = startDateAsDate?.getMonth();

    if (end === null || yearOfFirstDate === undefined || monthOfFirstDate === undefined) {
      return false;
    }

    const yearOfSecondDate = end.getFullYear();
    const monthOfSecondDate = end.getMonth();

    if (yearOfSecondDate < yearOfFirstDate) {
      return true;
    } else if (yearOfSecondDate === yearOfFirstDate) {
      return monthOfSecondDate <= monthOfFirstDate;
    } else {
      return false;
    }
  };

  const renderCustomHeader1: React.FC<ReactDatePickerCustomHeaderProps> = ({
    date,
    decreaseMonth,
    increaseMonth
  }) => {
    return (
      <Grid className="date-picker-header">
        <Box className="date-picker-header-heading">
          {date.toLocaleString("default", { month: "long", year: "numeric" })}
        </Box>
        <Box>
          <ArrowBackIosIcon onClick={decreaseMonth} className="arrow" />
          <ArrowForwardIosIcon onClick={increaseMonth} className="arrow" />
        </Box>
      </Grid>
    );
  };

  const renderCustomHeader2: React.FC<ReactDatePickerCustomHeaderProps> = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled
  }) => {
    return (
      <Grid className="date-picker-header">
        <Box className="date-picker-header-heading">
          {date.toLocaleString("default", { month: "long", year: "numeric" })}
        </Box>
        <Box>
          <ArrowBackIosIcon
            onClick={decreaseMonth}
            className={`arrow ${compare(date) ? "hide" : ""}`}
          />

          <ArrowForwardIosIcon onClick={increaseMonth} className="arrow" />
        </Box>
      </Grid>
    );
  };
  return (
    <Grid container className="date-picker-container">
      <Grid item className="date-picker-item">
        <Grid className="custom-datepicker-field-container">
          <DatePicker
            selected={startDateField}
            onChange={handleStartDateChange}
            className="custom-datepicker-field"
            dateFormat="MMM dd,yyyy"
          />
          <Box className="custom-datepicker-field-icon">
            <CalendarIcon />
          </Box>
        </Grid>
        <DatePicker
          selected={startDateAsDate}
          onChange={(date) => setStartDate(date ? dayjs(date) : null)}
          selectsStart
          startDate={startDateAsDate}
          endDate={endDateAsDate}
          inline
          fixedHeight
          renderCustomHeader={renderCustomHeader1}
          dayClassName={(date) =>
            isSameDay(dayjs(date), startDate) ? "datepicker-highlight-start" : ""
          }
        />
      </Grid>
      <Grid item className="date-picker-item">
        <Grid className="custom-datepicker-field-container">
          <DatePicker
            selected={endDateField}
            onChange={handleEndDateChange}
            className="custom-datepicker-field"
            dateFormat="MMM dd,yyyy"
          />
          <Box className="custom-datepicker-field-icon">
            <CalendarIcon />
          </Box>
        </Grid>
        <DatePicker
          selected={endDateAsDate}
          onChange={(date) => setEndDate(date ? dayjs(date) : null)}
          selectsEnd
          startDate={startDateAsDate}
          endDate={endDateAsDate}
          minDate={minDate}
          inline
          fixedHeight
          renderCustomHeader={renderCustomHeader2}
          dayClassName={(date) =>
            isSameDay(dayjs(date), endDate) ? "datepicker-highlight-end" : ""
          }
        />
      </Grid>
    </Grid>
  );
};

export default CustomDatePicker;

import { Grid, Card, CardContent, Typography, Box, Divider } from "@mui/material";
import { OverallCEWithGraph } from "../../../../../api/usePageOverallCEWithGraph";
import CloudCarbon from "../../../../../components/Svg/CloudCarbon";
import Dollar from "../../../../../components/Svg/Dollar";
import Storage from "../../../../../components/Svg/Storage";
import { ToolTip } from "../../../../../components/ToolTip";
import { formatNumber } from "../../../Format";
import CpuIcon from "../../../../../components/Svg/CpuIcon";
import Memory from "../../../../../components/Svg/Memory";
import GpuIcon from "../../../../../components/Svg/GpuIcon";
import { formatDate } from "../../../../../utils/useFormatDate";
// import Users from "../../../../../components/Svg/Users";
// import PagesIcon from "../../../../../components/Svg/PagesIcon";
import useCeConversion from "../../../../../utils/useCeConversion";
import { useContainerSummaryLatest } from "../../../../../api/useContainerSummary";
interface PageSummarySectionProps {
  pageOverallCEWithGraph: OverallCEWithGraph;
  selectedPlatform: string | undefined;
  isStatic: boolean;
}
const SecondaryText = ({ percentage, label }: { percentage: number; label: string }) => {
  return (
    <Grid className="secondary-text-server-container">
      <Grid>{percentage}%</Grid>
      <Divider className="divider" orientation="vertical" flexItem />
      <Grid>{label}</Grid>
    </Grid>
  );
};

const SummaryCards = ({
  tooltip,
  value,
  energyValue,
  text,
  icon
}: {
  tooltip: string;
  value: string;
  energyValue?: string;
  text: string | React.ReactNode;
  icon: React.ReactNode;
}) => {
  return (
    <Grid item className="ci-summary-grid-items" lg={12}>
      <Card className="card-items item">
        {/* 1st card */}
        <CardContent className="card-content">
          <Box className="box-design1" display="flex" justifyContent="space-between">
            <ToolTip
              title={tooltip}
              placement={"left-start"}
              child={
                <Typography className="card-label1">
                  {value}
                  {energyValue && <span className="card-label3"> ({energyValue})</span>}
                  <div className="card-label3">{text}</div>
                </Typography>
              }
            />
            <Box style={{ height: "68px", width: "64px" }}>{icon}</Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export const PageSummarySection = ({
  pageOverallCEWithGraph,
  selectedPlatform,
  isStatic
}: PageSummarySectionProps) => {
  const { data: containerSummarydata } = useContainerSummaryLatest(selectedPlatform);
  const getSummaryDate = () => {
    if (selectedPlatform === "client") {
      return pageOverallCEWithGraph?.updatedDate;
    } else if (selectedPlatform === "server") {
      return containerSummarydata?.date;
    }
  };

  return (
    <Grid item xs={2.8}>
      <Card className="container" style={{ borderRadius: 15, height: "100%" }}>
        <CardContent style={{ padding: "0" }}>
          <Grid py={1.1} px={2.4} className="card-heading">
            {formatDate(getSummaryDate()) ? formatDate(getSummaryDate()) : "Summary"}
          </Grid>
          <Divider />
          <Grid
            className="ci-summary-grid"
            container
            columnSpacing={1}
            rowSpacing={4}
            style={{
              display: "flex"
            }}
          >
            {selectedPlatform === "client" ? (
              isStatic ? (
                <>
                  <SummaryCards
                    tooltip={`${String(pageOverallCEWithGraph?.overallCe)} g`}
                    value={`${formatNumber(pageOverallCEWithGraph?.overallCe).toFixed(3)} g`}
                    text="Carbon Emission"
                    icon={<CloudCarbon />}
                  />
                  <SummaryCards
                    tooltip={`${String(pageOverallCEWithGraph?.overallCC)} €`}
                    value={`${formatNumber(pageOverallCEWithGraph?.overallCC).toFixed(3)} €`}
                    text="Carbon Credit"
                    icon={<Dollar />}
                  />

                  <SummaryCards
                    tooltip={`${String(pageOverallCEWithGraph?.overallSize)} MB`}
                    value={`${formatNumber(pageOverallCEWithGraph?.overallSize).toFixed(3)} MB`}
                    text="Resources Consumed"
                    icon={<Storage />}
                  />
                </>
              ) : (
                <>
                  <SummaryCards
                    tooltip={`${String(pageOverallCEWithGraph?.overallCe)} g`}
                    value={`${formatNumber(pageOverallCEWithGraph?.overallCe).toFixed(3)} g`}
                    text="Carbon Emission"
                    icon={<CloudCarbon />}
                  />
                  <SummaryCards
                    tooltip={`${String(pageOverallCEWithGraph?.overallCC)} €`}
                    value={`${formatNumber(pageOverallCEWithGraph?.overallCC).toFixed(3)} €`}
                    text="Carbon Credit"
                    icon={<Dollar />}
                  />

                  <SummaryCards
                    tooltip={`${String(pageOverallCEWithGraph?.overallSize)} MB`}
                    value={`${formatNumber(pageOverallCEWithGraph?.overallSize).toFixed(3)} MB`}
                    text="Resources Consumed"
                    icon={<Storage />}
                  />
                  {/* <SummaryCards tooltip={``} value={`123`} text="No of Users" icon={<Users />} /> */}

                  {/* <SummaryCards
                    tooltip={``}
                    value={`145`}
                    text="No of Page Loads"
                    icon={<PagesIcon />}
                  /> */}
                </>
              )
            ) : (
              <>
                <SummaryCards
                  tooltip={`${String(Number(containerSummarydata?.cpuEmission))} g`}
                  value={useCeConversion(Number(containerSummarydata?.cpuEmission))}
                  energyValue={`${formatNumber(
                    Number(containerSummarydata?.cpuConsumption)
                  ).toFixed(3)} kWh`}
                  text={
                    <SecondaryText
                      percentage={Number(containerSummarydata?.cpuPercent)}
                      label="CPU Utilisation"
                    />
                  }
                  icon={<CpuIcon />}
                />
                <SummaryCards
                  tooltip={`${String(Number(containerSummarydata?.memoryEmission))} g`}
                  value={useCeConversion(Number(containerSummarydata?.memoryEmission))}
                  energyValue={`${formatNumber(
                    Number(containerSummarydata?.memoryConsumption)
                  ).toFixed(3)} kWh`}
                  text={
                    <SecondaryText
                      percentage={Number(containerSummarydata?.memPercent)}
                      label="Memory Utilisation"
                    />
                  }
                  icon={<Memory />}
                />
                <SummaryCards
                  tooltip={`${String(Number(containerSummarydata?.gpuEmission))} g`}
                  value={useCeConversion(Number(containerSummarydata?.gpuEmission))}
                  energyValue={`${formatNumber(
                    Number(containerSummarydata?.gpuConsumption)
                  ).toFixed(3)} kWh`}
                  text={
                    <SecondaryText
                      percentage={Number(containerSummarydata?.gpuPercent)}
                      label="GPU Utilisation"
                    />
                  }
                  icon={<GpuIcon />}
                />
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_LATEST_CONTAINER_SUMMARY } from "./paths";
import { useParams } from "react-router-dom";

const getContainerSummary = async (id: string | undefined, projectId: string | undefined) => {
  return await axiosClient
    .get<ContainerSummary>(`${GET_LATEST_CONTAINER_SUMMARY}/${String(id!)}/${projectId!}`)
    .then((res) => res.data);
};

export const useContainerSummaryLatest = (selectedPlatform: string | undefined) => {
  const { webpageId: id, projectId } = useParams();
  return useQuery({
    queryKey: ["container-summary", id],
    queryFn: () => getContainerSummary(id, projectId),
    enabled: selectedPlatform === "server"
  });
};

export interface ContainerSummary {
  memoryEmission: number;
  gpuConsumption: number;
  memPercent: number;
  cpuEmission: number;
  cpuConsumption: number;
  gpuEmission: number;
  memoryConsumption: number;
  cpuPercent: number;
  gpuPercent: number;
  date: string;
}

import { Grid } from "@mui/material";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { GraphDatum } from "../../../../../../api/usePageOverallCEWithGraph";
import { formatNumber } from "../../../../Format";
import { seriesItem } from "../../../../Wrapper";
import { JobStatusTableContainer } from "./Table/JobStatusTable";
import { DropdownOption } from "../../PageSectionBody";
import dayjs from "dayjs";
import { NoData } from "../../../../../../components/NoData/NoData";

export const CarbonEmissionCardBody = ({
  isOpen,
  setIsOpen,
  jobId,
  setJobId,
  setPerVisitView,
  chartView,
  series,
  isLoading,
  filteredData,
  colorTab,
  selectedPlatform,
  selectedOption,
  startDate,
  endDate
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  jobId: number | undefined;
  setJobId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setPerVisitView: React.Dispatch<React.SetStateAction<boolean>>;
  chartView: boolean;
  series: seriesItem[];
  isLoading: boolean;
  filteredData: GraphDatum[] | undefined;
  colorTab: number;
  selectedPlatform: string | undefined;
  selectedOption: DropdownOption | null;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
}) => {
  const graphColor = "rgba(136, 192, 205,0.7)";
  let xAxisFormat = "MMM dd";
  let isMultiDayCustomRange: boolean;

  switch (selectedOption?.value) {
    case "today":
    case "yesterday":
      xAxisFormat = "HH:mm";
      isMultiDayCustomRange = false;
      break;
    case "custom":
      isMultiDayCustomRange = isCustomRangeMultiDay(startDate, endDate);
      console.log(isMultiDayCustomRange);
      if (isMultiDayCustomRange) {
        xAxisFormat = "MMM dd";
      } else {
        xAxisFormat = "HH:mm";
      }
      break;
    default:
      xAxisFormat = "MMM dd";
  }

  function isCustomRangeMultiDay(
    startDate: dayjs.Dayjs | null,
    endDate: dayjs.Dayjs | null
  ): boolean {
    if (!startDate || !endDate) {
      return false;
    }

    return startDate.format("YYYY-MM-DD") !== endDate.format("YYYY-MM-DD");
  }

  const xAxisConfig: ApexXAxis | undefined = {
    type: "datetime",
    labels: {},
    tooltip: {
      enabled: false
    },
    axisBorder: {
      show: false
    },
    crosshairs: {
      show: false
    }
  };

  switch (xAxisFormat) {
    case "HH:mm":
      xAxisConfig.labels = {
        format: "HH:mm",
        show: true,
        showDuplicates: false,
        style: {
          fontFamily: "inherit",
          colors: "rgba(159, 162, 180, 1)"
        }
      };
      break;
    default:
      xAxisConfig.labels = {
        format: xAxisFormat,
        show: true,
        showDuplicates: false,
        style: {
          fontFamily: "inherit",
          colors: "rgba(159, 162, 180, 1)"
        }
      };
      break;
  }

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      },
      background: "transparent",
      zoom: {
        enabled: false
      },
      events: {
        markerClick: function (chartContext, ots, { seriesIndex, dataPointIndex }) {
          setIsOpen(false);
          setJobId(series[0].data[dataPointIndex].id);
          setPerVisitView(true);
        },
        dataPointMouseEnter: function (event) {
          event.path[0].style.cursor = "pointer";
        }
      }
    },
    colors: [graphColor],
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 3,
      hover: {
        size: 4
      }
    },
    xaxis: xAxisConfig,
    yaxis: {
      min: 0,
      forceNiceScale: true,
      title: {
        text: "Carbon Emission in g",
        style: {
          fontSize: "12px",
          fontWeight: "400",
          fontFamily: "inherit",
          color: "rgba(159, 162, 180, 1)"
        }
      },
      labels: {
        show: true,
        style: { colors: "rgba(159, 162, 180, 1)" },
        formatter: function (value) {
          return value.toFixed(1);
        }
      }
    },

    stroke: {
      curve: "smooth",
      width: 3
    },
    legend: {
      show: false
    },
    theme: {
      mode: "dark"
    },
    tooltip: {
      custom: function (opts) {
        const itm = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];

        const { id: jobId, x, y: emission, size: sizeMB, cc: offset } = itm;
        if (jobId < 0) {
          return null;
        } else {
          return `<div class='header'>${new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric"
          }).format(new Date(x))}</div>
              <div class='to-body'>
                  <p class='value'>${formatNumber(emission).toFixed(3)} g</p>
                  <p class='label'>Carbon Emission</p>
                  <p class='value'>${formatNumber(offset).toFixed(3)} € </p>
                  <p class='label'>Carbon Offset</p>
                  <p class='value'>${formatNumber(sizeMB).toFixed(3)} MB</p>
                  <p class='label'>Storage Used</p>
                </div>
              </div>`;
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0.3,
        stops: [0, 100]
      }
    },
    grid: {
      show: true,
      borderColor: "#242323"
    },
    annotations: {
      yaxis: [
        {
          y: 0.87,
          strokeDashArray: 0,
          borderColor: "#F63E3E",
          label: {
            borderColor: "transparent",
            style: {
              color: "#F63E3E",
              fontSize: "9px",
              background: "transparent"
            },
            text: `${0.87}`,
            offsetY: 5,
            position: "left"
          }
        }
      ]
    }
  };

  return (
    <Grid
      container
      columnSpacing={1}
      rowSpacing={4}
      style={{
        display: "flex",
        padding: "16px"
      }}
    >
      {chartView ? (
        <Grid item lg={12}>
          <Grid item flexGrow={1}>
            <>
              {series[0].data.length > 0 ? (
                <>
                  <ReactApexChart series={series} type="area" options={options} height={478} />
                </>
              ) : (
                <NoData />
              )}
            </>
          </Grid>
        </Grid>
      ) : (
        <>
          <JobStatusTableContainer
            jobId={jobId}
            setJobId={setJobId}
            setPerVisitView={setPerVisitView}
            filteredData={filteredData}
            colorTab={colorTab}
            selectedPlatform={selectedPlatform}
          />
        </>
      )}
    </Grid>
  );
};

import { Box } from "@mui/material";
import { RelativeAuditWithMetricType } from "../../../api/useSuggestionsData";
import PerformanceTable, { HeadCell } from "./Table";
interface OpportunitiesProps {
  activeMetric: string | null;
  opportunities: RelativeAuditWithMetricType[];
  deferredsearchValue: string;
}
const opportunityHeadCells: HeadCell[] = [
  {
    id: "relAudScore",
    numeric: true,
    label: "Priority"
  },
  {
    id: "relAudTitle",
    numeric: false,
    label: "Audits"
  }
  // {
  //   id: "estimatedSavings",
  //   numeric: true,
  //   label: "Estimated Savings"
  // }
  // {
  //   id: "relAudExapnd",
  //   numeric: false,
  //   label: "Actions"
  // }
];
export const Opportunities = ({
  activeMetric,
  opportunities,
  deferredsearchValue
}: OpportunitiesProps) => {
  return (
    <Box>
      <PerformanceTable
        activeMetric={activeMetric}
        data={opportunities}
        searchValue={deferredsearchValue}
        headCells={opportunityHeadCells}
      />
    </Box>
  );
};

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_ACCESS_KEY } from "./paths";

const getAccessKeyById = async (projectId?: number | string) => {
  return await axiosClient.get<string>(`${GET_ACCESS_KEY}${projectId!}`).then((res) => res.data);
};

export const getAccessKey = (projectId?: number | string, onSuccess?: (data: string) => void) => {
  return useQuery({
    queryKey: ["get-access-key", projectId],
    queryFn: () => getAccessKeyById(projectId),
    enabled: false,
    onSuccess
  });
};

import React from "react";

const CheckCircle = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_337_6235)">
        <circle cx="7.5" cy="7.5" r="6.25" stroke="#80BD24" strokeWidth="1.5" />
        <path
          d="M5.3125 7.8125L6.5625 9.0625L9.6875 5.9375"
          stroke="#80BD24"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_337_6235">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckCircle;

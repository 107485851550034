import { Box } from "@mui/material";
import "./NoData.scss";
import NoDataSVG from "../Svg/NaData";
export const NoData = () => {
  return (
    <Box className="noData">
      <NoDataSVG />
      <span className="noData-text">No record found! </span>
    </Box>
  );
};

import { Grid } from "@mui/material";
import { useState } from "react";
import { Audit, RelAudScoreDisMode, RelAudType } from "../../../api/useSuggestionsData";
import Metrics from "../Metrics/Metrics";
import { SuggestionsTable } from "../SuggestionsTable";

export interface SuggestionMetricProps {
  metrics: Audit[];
  allRelAudits: relativeAudit[];
}
export interface relativeAudit {
  metricType: string;
  id: number | string;
  relAudTitle: string | null;
  relAudDescription: string;
  relAudScore: string | null;
  relAudScoreDisMode: RelAudScoreDisMode | null;
  relAudType: RelAudType | null;
}
export const Performance = ({ metrics, allRelAudits }: SuggestionMetricProps) => {
  const [activeMetric, setActiveMetric] = useState<string | null>(metrics?.at(0)?.audit ?? null);

  return (
    <Grid container sx={{ bgcolor: "#252525", borderRadius: "0.7rem" }} className="audit-warpper">
      <Grid item xs={5}>
        <Metrics
          metrics={metrics}
          activeMetric={activeMetric}
          setActiveMetric={setActiveMetric}
          activeTab={"Performance"}
        />
      </Grid>
      <Grid item xs={7} className="audits-table">
        <SuggestionsTable
          activeMetric={activeMetric}
          allRelAudits={allRelAudits}
          activeTab={"Performance"}
        />
      </Grid>
    </Grid>
  );
};

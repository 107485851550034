import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_LATEST_JOBS_CLIENT } from "./paths";
import { serverDataContainers } from "../pages/Dashboard/Table/ProjectItemForServer";

const getLatestPagesAndJobs = async ({ Id, nJobs }: input) => {
  return await axiosClient
    .get<LatestPagesJobs[]>(`${GET_LATEST_JOBS_CLIENT}${Id!}/${nJobs}`)
    .then((res) => res.data);
};

export const useLatestJobsAndPagesData = ({ Id, nJobs }: input) => {
  return useQuery({
    staleTime: 2 * 60 * 1000,
    queryKey: ["pages-jobs", Id, nJobs],
    queryFn: () => getLatestPagesAndJobs({ Id, nJobs })
  });
};

interface input {
  Id?: string | null;
  nJobs: number;
}

export interface LatestPagesJobs {
  abbr: string;
  ccSum: number;
  ceSum: number;
  suggestionsCount: number;
  name: string;
  id: number;
  latestJobPages: LatestJobPage[];
  lastNJobs: LastNJob[];
  lastJobs: LastJobs[];
  pageCount: number;
  clientCE: number;
  clientCC: number;
  serverCE: number;
  serverCC: number;
  lastUpdated: string;
  snId: number;
  totalEmission: number;
  offset: number;
  totalEnegryConsumption: number;
  thresholdColour: string;
  projectId: number;
  containers: serverDataContainers[];
}

export interface LastNJob {
  jobId: number;
  ceGms: number;
  createdDate: string;
  sizeMB: number;
  ccEuro: number;
  thresholdColour: "Green" | "Red" | "Amber";
  score: number;
  errorMessage: string;
  cecStatus: string;
  lhStatus: string;
}

export interface LastJobs {
  jobId: number;
  ceGms: number;
  createdDate: string;
  // sizeMB: number;
  ccEuro: number;
  thresholdColour: "Green" | "Red" | "Amber" | "Grey";
  errorMessage: string;
  cecStatus: string;
  lhStatus: string;
}

export interface LatestJobPage {
  pageId: number;
  name: string;
  ccEuro: number;
  ceGms: number;
  score: number;
  fromLastJob: "m" | "l" | "n";
}

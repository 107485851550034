import { Box, Grid, Typography } from "@mui/material";
import { SetStateAction, useDeferredValue, useState } from "react";
import { RelativeAuditWithMetricType } from "../../../api/useSuggestionsData";
import TabPanel from "../../../components/TabPanel";
import { Diagnostics } from "./Diagnostics";
import { Opportunities } from "./Opportunities";
import "./index.scss";
import { ToggleButtonGroup } from "../../../components/ToggleButtonGroup/ToggleButtonGroup";
import { SearchBox } from "../../../components/SearchBox";
interface SuggestionsTableProps {
  activeMetric: string | null;
  allRelAudits: RelativeAuditWithMetricType[];
  activeTab: string | null;
}

export const SuggestionsTable = ({
  activeMetric,
  allRelAudits,
  activeTab
}: SuggestionsTableProps) => {
  const [isOpportunity, setOpportunity] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const deferredsearchValue = useDeferredValue(searchValue);

  const opportunities = allRelAudits.filter((audit) => audit.relAudType === "opportunity");
  const diagnostics = allRelAudits.filter((audit) => audit.relAudType === "diagnostics");

  const handleSearch = (event: { target: { value: SetStateAction<string> } }) => {
    setSearchValue(event.target.value);
  };
  return (
    <Box className="performance-tabWrapper tabWrapper">
      <Grid container alignItems="center">
        <Box className="suggestion-table-header-container" flex={1}>
          <Box>
            <Typography color="white" fontSize="1.5rem" variant="h1" component="h1">
              {activeMetric}
            </Typography>
          </Box>
          <Grid container className="suggestion-table-header-options-container" mr={1}>
            <Grid item>
              <SearchBox
                value={searchValue}
                handleSearch={handleSearch}
                placeholder="Search"
              ></SearchBox>
            </Grid>
          </Grid>
        </Box>
        {activeTab === "Performance" && (
          <Grid item>
            <Grid container justifyContent="space-between" ml="auto">
              <Grid item>
                <ToggleButtonGroup
                  button1="Opportunities"
                  button2="Diagnostics"
                  active={isOpportunity}
                  setActive={setOpportunity}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <TabPanel value={isOpportunity ? 0 : 1} index={0}>
        <Opportunities
          activeMetric={activeMetric}
          opportunities={opportunities}
          deferredsearchValue={deferredsearchValue}
        />
      </TabPanel>
      <TabPanel value={isOpportunity ? 0 : 1} index={1}>
        <Diagnostics
          activeMetric={activeMetric}
          diagnostics={diagnostics}
          deferredsearchValue={deferredsearchValue}
        />
      </TabPanel>
    </Box>
  );
};

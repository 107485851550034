import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  ButtonGroup,
  Card,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Table,
  Tooltip,
  Typography
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { usechangeAppRole } from "../../api/useChangeAppRole";
import { useUpdateUserRoleId, usedeleteUserById } from "../../api/useCollaboratorsData";
import CustomBasicDropdown from "../../components/CustomBasicDropdown";
import { ROLE_OPTIONS1, ROLE_OPTIONS2 } from "../../utils/constants";
import "./indexdetails.scss";

import CustomButton from "../../components/CustomButton/CustomButton";

export default function UserDetailsDialog({
  open,
  onClose,
  user,
  editingProjectId,
  setEditingProjectId
}) {
  const [users, setUsers] = useState(null);
  const [selectedProject, setselectedProject] = useState(null);
  const [openAppRole, setopenAppRole] = useState(false);
  const [applicationRole, setApplicationRole] = useState(null);

  useEffect(() => {
    if (user) {
      setUsers(user);
      setApplicationRole(user.applicationRole);
    }
  }, [user]);

  const onCollaboratorUpdate = (response) => {
    const projectIdToUpdate = response.projectId;
    const newRole = response.role;

    const updatedProjects = users.projects.map((project) => {
      if (project.projectId === projectIdToUpdate) {
        return { ...project, projectRole: newRole };
      }
      return project;
    });

    const updatedUser = { ...users, projects: updatedProjects };
    setUsers(updatedUser);
  };

  const { mutate: updateUserRole } = useUpdateUserRoleId(onCollaboratorUpdate);
  const { mutate: deleteUserRole } = usedeleteUserById();
  const { mutate: changeAppRole, isSuccess: changeRoleSuccess } = usechangeAppRole();

  const toggleEdit = (projectId) => {
    if (editingProjectId === projectId) {
      setEditingProjectId(null); // Turn off editing for the current row
    } else {
      setEditingProjectId(projectId); // Turn on editing for the clicked row
    }
  };

  const handleRoleChange = (event, project) => {
    const newRole = event.target.value;

    const updatedProjects = users.projects.map((p) => {
      if (p.projectId === project.projectId) {
        return { ...p, projectRole: newRole };
      }
      return p;
    });
    const updatedUser = { ...users, projects: updatedProjects };
    setUsers(updatedUser);
    const selectedEditProject = updatedProjects.find((r) => r.projectId === project.projectId);
    selectedEditProject.id = users.id;
    setselectedProject(selectedEditProject);
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleSave = () => {
    updateUserRole(selectedProject);
    setEditingProjectId(null);
    enqueueSnackbar("User Acess Updated", { variant: "success", autoHideDuration: 2000 });
  };

  const handleRevoke = (e, row) => {
    deleteUserRole(row);
    const updatedProjects = users.projects.filter((user) => user.projectId !== row.projectId);
    const updatedUsers = { ...users, projects: updatedProjects };
    setUsers(updatedUsers);
    enqueueSnackbar("User Acess Revoked", { variant: "error", autoHideDuration: 2000 });
    setEditingProjectId(null);
  };

  const handleApplicationRole = () => {
    setopenAppRole(true);
  };

  const handleClose = () => {
    setopenAppRole(false);
    setEditingProjectId(null);
  };

  const handleApplicationRoleChange = (e) => {
    const roleapp = e.target.value;
    setApplicationRole(roleapp);
  };

  const saveApplicationRole = () => {
    const id = users.id;
    const role = applicationRole;
    changeAppRole({ id, role });
    enqueueSnackbar("User Role Updated", { variant: "success", autoHideDuration: 2000 });
    setopenAppRole(false);
  };
  return (
    <>
      <Modal open={open} onClose={onClose} className="dialougstyle">
        <>
          <Grid item xs={12}>
            <div className="header-nav">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                User Details
              </Typography>
              <div className="close-icon" onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Box className="header-details">
              <div className="details">
                <Avatar
                  sx={{ width: 64, height: 64 }}
                  variant="square"
                  style={{ borderRadius: "4px", background: " #80BD24" }}
                >
                  <Typography variant="h3">{users?.name?.charAt(0)}</Typography>
                </Avatar>
                <div className="text-info">
                  <Typography className="user-name" variant="h5">
                    {user?.name?.replace(",", " ")?.split(" ").reverse().join(" ")}
                  </Typography>
                  <div className="secondary-text">
                    <Typography className="secondary-text-subtext">Application Role:</Typography>
                    <Typography style={{ paddingLeft: "7px", fontSize: "16px" }}>
                      {changeRoleSuccess ? applicationRole : users?.applicationRole}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="action-button">
                <CustomButton
                  startIcon={<CachedOutlinedIcon />}
                  className="actionbtn"
                  onClick={handleApplicationRole}
                  disabled={
                    users?.applicationRole === "Admin" || users?.powerUserAccessReq === "Pending"
                  }
                  sx={{
                    background:
                      users?.applicationRole === "Admin" || users?.powerUserAccessReq === "Pending"
                        ? "grey"
                        : "#00677f",
                    textTransform: "capitalize"
                  }}
                >
                  Change Role
                </CustomButton>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div className="table-container">
              <Typography variant="h5" component="h2">
                Projects
              </Typography>
              <div className="table-wrapper">
                <Card className="cardUser">
                  <Table className="tableUser">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className="head-Usertable head-name ">
                          Name
                        </TableCell>
                        <TableCell align="left" className="head-Usertable head-role">
                          Project Level Role
                        </TableCell>
                        <TableCell align="center" className="head-Usertable head-actions ">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {users?.projects?.map((row) => (
                        <TableRow key={row.projectName}>
                          <TableCell align="left" className="cell-designsidepanel">
                            <Tooltip title={row.projectName}>
                              <p>{row.projectAbbr}</p>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="left" className="cell-designsidepanel">
                            {editingProjectId === row.projectId ? (
                              <CustomBasicDropdown
                                name="role1"
                                options={ROLE_OPTIONS1}
                                value={row.projectRole}
                                onChange={(e) => handleRoleChange(e, row)}
                                className="select-allUsers1"
                              />
                            ) : (
                              <span className="role-cell">{row.projectRole}</span>
                            )}
                          </TableCell>
                          <TableCell align="center" className="cell-designsidepanel">
                            <ButtonGroup className="actions-cell">
                              {editingProjectId === row.projectId ? (
                                <CustomButton
                                  variant="text"
                                  className="successFontColor"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={handleSave}
                                >
                                  {"Save"}
                                </CustomButton>
                              ) : (
                                <CustomButton
                                  style={{ textTransform: "capitalize" }}
                                  variant="text"
                                  className="successFontColor"
                                  onClick={() => toggleEdit(row.projectId)}
                                >
                                  {"Change"}
                                </CustomButton>
                              )}

                              <CustomButton
                                style={{ textTransform: "capitalize" }}
                                variant="text"
                                className="rejectFontColor "
                                onClick={(e) => handleRevoke(e, row)}
                              >
                                {"Revoke"}
                              </CustomButton>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </div>
            </div>
          </Grid>
        </>
      </Modal>
      {
        <Modal open={openAppRole} onClose={handleClose} id="modal1">
          <>
            <Box className="reqModal-user">
              <DialogTitle>Change Role</DialogTitle>
              <div>
                <CloseIcon className="reqCancel" onClick={handleClose}></CloseIcon>
              </div>
              <Divider className="reqdivider1" />
              <div className="userolemenu">
                <Typography className="user-modal-text">User Role</Typography>
                <CustomBasicDropdown
                  name="role2"
                  options={ROLE_OPTIONS2}
                  value={applicationRole}
                  onChange={(e) => handleApplicationRoleChange(e)}
                  className="user-role-select"
                />
              </div>
              <Divider className="reqdivider2" />
              <CustomButton className="reqbtnrole" onClick={() => saveApplicationRole()}>
                Save
              </CustomButton>
            </Box>
          </>
        </Modal>
      }
    </>
  );
}

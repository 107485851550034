import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import "./index.scss";

export interface Column {
  field: keyof Row;
  headerName: string;
  width?: string | number;
}

export interface Row {
  id?: number;
  [key: string]: any;
}

interface Action {
  label: string;
  component: (props: { row: Row }) => JSX.Element;
}

interface ReusableTableProps {
  columns: Column[];
  data: Row[];
  enableSorting?: boolean;
  enableFiltering?: boolean;
  enablePagination?: boolean;
  actions?: Action[];
  onRowClick?: (row: Row) => void;
  renderCustomContent?: (row: Row, column: Column) => JSX.Element | string;
  rowClassName?: (row: Row) => string;
  cellClassName?: (row: Row, column: Column) => string;
  containerClassName?: string;
}

const StyledTableContainer = styled(TableContainer)(() => ({}));
const StyledTableCell = styled(TableCell)(() => ({}));
const StyledTableRow = styled(TableRow)(({ clickable }: { clickable: unknown }) => ({
  cursor: clickable ? "pointer" : "default"
}));

const ReusableTable: React.FC<ReusableTableProps> = ({
  columns,
  data,
  enableSorting = true,
  enableFiltering = true,
  enablePagination = true,
  actions = [],
  onRowClick,
  renderCustomContent,
  rowClassName,
  cellClassName,
  containerClassName
}) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Row>(columns[0].field);
  const [filterText, setFilterText] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const handleRequestSort = (property: keyof Row) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data.filter((row) => {
    return columns.some((column) => {
      return row[column.field].toString().toLowerCase().includes(filterText.toLowerCase());
    });
  });

  const sortedData = filteredData.sort((a, b) => {
    if (enableSorting) {
      if (a[orderBy] < b[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  const paginatedData = enablePagination
    ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : sortedData;

  return (
    <>
      {enableFiltering && (
        <TextField
          label="Filter"
          variant="outlined"
          value={filterText}
          onChange={handleFilterChange}
        />
      )}
      <StyledTableContainer className={containerClassName}>
        <TableContainer className="table-sub-container">
          <Table className="table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field as string}
                    className="head"
                    sx={{ width: column.width, p: 0 }}
                  >
                    {enableSorting ? (
                      <TableSortLabel
                        active={orderBy === column.field}
                        direction={orderBy === column.field ? order : "asc"}
                        onClick={() => handleRequestSort(column.field)}
                      >
                        {column.headerName}
                      </TableSortLabel>
                    ) : (
                      column.headerName
                    )}
                  </StyledTableCell>
                ))}
                {actions.length > 0 && <StyledTableCell>Actions</StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {paginatedData.map((row) => (
                <StyledTableRow
                  key={row.id}
                  clickable={!!onRowClick}
                  onClick={onRowClick ? () => onRowClick(row) : undefined}
                  className={rowClassName ? rowClassName(row) : undefined}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.field as string}
                      sx={{ width: column.width, p: 0 }}
                      className={cellClassName ? cellClassName(row, column) : undefined}
                    >
                      {renderCustomContent ? renderCustomContent(row, column) : row[column.field]}
                    </StyledTableCell>
                  ))}
                  {actions.length > 0 && (
                    <StyledTableCell>
                      {actions.map((action, index) => (
                        <span key={index}>{action.component({ row })}</span>
                      ))}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {enablePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </StyledTableContainer>
    </>
  );
};

export default ReusableTable;

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_WEBPAGE_CE } from "./paths";

const getPageCEWithBreakdownJobId = async (
  selectedPlatform: string,
  webpageId: string | number,
  jobId: string | number
) => {
  return await axiosClient
    .get<PageCEWithBreakdown>(GET_WEBPAGE_CE + `${webpageId}?jobId=${jobId}`)
    .then((res) => res.data);
};
const getPageCEWithBreakdown = async (selectedPlatform: string, webpageId: string | number) => {
  return await axiosClient
    .get<PageCEWithBreakdown>(GET_WEBPAGE_CE + `${webpageId}`)
    .then((res) => res.data);
};

export const usePageCEWithBreakdown = (
  selectedPlatform: string,
  webpageId: string | number,
  jobId: number
) => {
  return useQuery({
    queryKey: [
      "PageCEWithBreakdown",
      jobId ? { selectedPlatform, webpageId, jobId } : { selectedPlatform, webpageId }
    ],
    queryFn: async () => {
      if (selectedPlatform === "client") {
        return jobId
          ? await getPageCEWithBreakdownJobId(selectedPlatform, webpageId, jobId)
          : await getPageCEWithBreakdown(selectedPlatform, webpageId);
      }
    },
    enabled: selectedPlatform === "client" && jobId !== undefined && jobId > 0
  });
};

export interface PageCEWithBreakdown {
  pageId: number;
  latestCe: number;
  latestCC: number;
  latestSize: number;
  ceComparison: number;
  ccComparision: number;
  sizeComparision: number;
  resources: Resource[];
  lastUpdated: string;
}

export interface Resource {
  type: string;
  count: number;
  overallSize: number;
  overallCe: number;
  overallCc: number;
}

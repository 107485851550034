import SquareIcon from "@mui/icons-material/Square";
import CircleIcon from "@mui/icons-material/Circle";
import RedTriangle from "../../assets/svg/redtriangle.svg";
import React from "react";

interface StatusSymbolProps {
  color: "red" | "yellow" | "green" | "na";
}

export const StatusSymbol: React.FC<StatusSymbolProps> = ({ color }) => {
  if (color === "red")
    return (
      <img
        src={RedTriangle}
        alt="red tri"
        style={{
          color: "var(--cit-red)",
          fontSize: "15px",
          marginBottom: "-1px"
        }}
      />
    );
  if (color === "yellow")
    return (
      <SquareIcon
        style={{
          color: "var(--cit-yellow)",
          fontSize: "14px",
          marginBottom: "-2px"
        }}
      />
    );
  if (color === "green")
    return (
      <CircleIcon
        style={{
          color: "var(--cit-green)",
          fontSize: "14px",
          marginBottom: "-2px"
        }}
      />
    );
  return (
    <CircleIcon
      style={{
        color: "var(--cit-gray)",
        fontSize: "14px",
        marginBottom: "-2px"
      }}
    />
  );
};

export default function Lamp() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00004 5.26074L7.28671 6.50074C7.12671 6.77408 7.26004 7.00074 7.57337 7.00074H8.42004C8.74004 7.00074 8.86671 7.22741 8.70671 7.50074L8.00004 8.74074"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.53357 12.0271V11.2538C4.00023 10.3271 2.74023 8.52044 2.74023 6.60044C2.74023 3.30044 5.77357 0.713776 9.20023 1.46044C10.7069 1.79378 12.0269 2.79378 12.7136 4.17378C14.1069 6.97378 12.6402 9.94711 10.4869 11.2471V12.0204C10.4869 12.2138 10.5602 12.6604 9.8469 12.6604H6.17357C5.44023 12.6671 5.53357 12.3804 5.53357 12.0271Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66602 14.6663C7.19268 14.233 8.80602 14.233 10.3327 14.6663"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

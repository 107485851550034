import { Box, CircularProgress, Container, Grid, TextField, Typography } from "@mui/material";
import { FormEvent, useState } from "react";
import { useRestPassEmailMutation } from "../../api/useRestPassEmailMutation";
import CustomButton from "../../components/CustomButton/CustomButton";

const emailRE = /[a-z0-9._%+-]+@mercedes-benz.com$/;

export default function ForgotPassword() {
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const emailValid = emailRE.test(email);

  const onEmailSent = () => {
    setSent(true);
  };
  const { mutate: sendResetEmail, isLoading, isError } = useRestPassEmailMutation(onEmailSent);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendResetEmail({ emailId: email });
  };

  return (
    <Container component="main" className="main-container">
      {sent ? (
        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            lineHeight: "130%"
          }}
        >
          <svg
            width={50}
            height={50}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.75 18.75L28.125 25L37.5 18.75M6.24998 28.125H10.4166M2.08331 21.875H10.4166"
              stroke="#50886F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.4166 15.6253V14.5837C10.4166 13.4786 10.8556 12.4188 11.637 11.6374C12.4184 10.856 13.4782 10.417 14.5833 10.417H41.6666C42.7717 10.417 43.8315 10.856 44.6129 11.6374C45.3943 12.4188 45.8333 13.4786 45.8333 14.5837V35.417C45.8333 36.5221 45.3943 37.5819 44.6129 38.3633C43.8315 39.1447 42.7717 39.5837 41.6666 39.5837H14.5833C13.4782 39.5837 12.4184 39.1447 11.637 38.3633C10.8556 37.5819 10.4166 36.5221 10.4166 35.417V34.3753"
              stroke="#50886F"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
          <Grid className="verify-text">
            <Typography>Password reset email sent!</Typography>
            <br></br>
            <Typography>We sent a password reset link to your email</Typography>
          </Grid>
          <br></br>
          <Typography>Please check your inbox for next steps.</Typography>
        </Box>
      ) : (
        <Box className="main-container-box">
          <Typography className="main-container-typography" component="h1" variant="h5">
            Enter your Email-Id
          </Typography>
          <Box component="form" className="form-fields-box" onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="emailId"
              label="Email ID"
              name="emailId"
              autoFocus
              type="email"
              value={email}
              //   color={emailValid && email !== "" ? "error" : "primary"}
              error={!emailValid && email !== ""}
              helperText={!emailValid && email !== "" ? "Incorrect email." : ""}
              onChange={(e) => setEmail(e.target.value)}
            />

            {
              <CustomButton
                type="submit"
                fullWidth
                variant="contained"
                className="login-button"
                disabled={sent || !emailValid}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" className="circular-progress" />
                ) : (
                  "Continue"
                )}
              </CustomButton>
            }
            {isError && (
              <Typography sx={{ color: "red" }} align="center">
                Email-id not exist!
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Container>
  );
}

import "./index.scss";
import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  Tooltip
} from "@mui/material";

import { ProjectPopUp } from "./ProjectPopUp";

const inprogress = {
  borderLeft: "6px solid #ee9225 !important"
};

const inapproved = {
  borderLeft: "6px solid #80BD24 !important"
};

const inrejected = {
  borderLeft: "6px solid #F63E3E !important"
};

export function descendingComparator(a: any, b: any, orderBy: any) {
  if (a[orderBy] == null) {
    return -1;
  }
  if (b[orderBy] == null) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function sortByString(a: any, b: any, orderBy: any) {
  if (a[orderBy]?.toLowerCase() < b[orderBy]?.toLowerCase()) {
    return -1;
  }
  if (a[orderBy]?.toLowerCase() > b[orderBy]?.toLowerCase()) {
    return 1;
  }
  return 0;
}

export function getSortValue(orderBy: any) {
  return orderBy === "Created Date"
    ? (a: any, b: any) => descendingComparator(a, b, "createdDate")
    : orderBy === "Project Name"
    ? (a: any, b: any) => aescendingSortByString(a, b, "projectAbbr")
    : orderBy === "Deputy Email"
    ? (a: any, b: any) => aescendingSortByString(a, b, "deputyId")
    : null; // Handle invalid orderBy values if needed
}

function aescendingSortByString(a: any, b: any, property: string) {
  const valueA = a[property]?.toUpperCase();
  const valueB = b[property]?.toUpperCase();
  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;
}

const getApprovalType = (n: any) => {
  switch (n) {
    case 0:
      return "all";
    case 1:
      return null;
    case 2:
      return true;
    case 3:
      return false;
  }
};

export const ProjectList = (props: any) => {
  const [open, setOpen] = React.useState(false);

  const [selectedData, setSelectedData] = React.useState<any | null>(null);
  const rows = props.data.filter((data: any) => {
    if (props.categoryValue !== 0) {
      if (data.approval === getApprovalType(props.categoryValue)) {
        const search =
          // removed search based on project name
          /*        data.name?.toLowerCase().includes(props.searchValue.trim().toLowerCase()) | */
          data.projectAbbr?.toLowerCase().includes(props.searchValue.trim().toLowerCase()) |
          data.powerUser?.toLowerCase().includes(props.searchValue.trim().toLowerCase()) |
          data.deputyId?.toLowerCase().includes(props.searchValue.trim().toLowerCase()) |
          data.departmentName?.toLowerCase().includes(props.searchValue.trim().toLowerCase());

        return search;
      }
      return null;
    } else {
      const search =
        // removed search based on project name
        /*  data.name?.toLowerCase().includes(props.searchValue.trim().toLowerCase()) | */
        data.projectAbbr?.toLowerCase().includes(props.searchValue.trim().toLowerCase()) |
        data.powerUser?.toLowerCase().includes(props.searchValue.trim().toLowerCase()) |
        data.deputyId?.toLowerCase().includes(props.searchValue.trim().toLowerCase()) |
        data.departmentName?.toLowerCase().includes(props.searchValue.trim().toLowerCase());

      return search;
    }
  });

  React.useEffect(() => {
    if (selectedData != null) {
      rows.filter((data: any) => {
        if (data.id === selectedData.id) {
          data.approval = selectedData.approval;
        }
        return data;
      });
    }
  }, [selectedData]);

  const handleOpen = (row: any) => {
    setSelectedData(row);
    setOpen(true);
  };

  return (
    <div className="projectList">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Project</TableCell>
              <TableCell align="left">Created Date</TableCell>
              <TableCell align="left">Administrator Name</TableCell>
              <TableCell align="left">Deputy Name</TableCell>
              <TableCell align="left">Department</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.sort(getSortValue(props.sortBy)).map((row: any) => (
              <TableRow
                onClick={() => handleOpen(row)}
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="left"
                  sx={row.approval == null ? inprogress : row.approval ? inapproved : inrejected}
                >
                  <Tooltip title={row.name}>
                    <span className="prj-name-p">{row.projectAbbr}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">{row.displayCreatedDate.replace(/\//g, "-")}</TableCell>
                <TableCell align="left">{row.powerUser || "N/A"}</TableCell>
                <TableCell align="left">{row.deputyId || "N/A"}</TableCell>
                <TableCell align="left">
                  {row.departmentName
                    ? row.departmentName.length > 6
                      ? String(row.departmentName).slice(0, 6) + "..."
                      : row.departmentName
                    : "N/A"}
                </TableCell>
                <TableCell align="left">
                  {row.approval == null ? "New" : row.approval ? "Approved" : "Rejected"}
                </TableCell>
                <TableCell align="left">
                  {row.approval == null && (
                    <Box sx={{ display: "flex" }} className="actionColumn">
                      <Typography sx={{ pr: 1 }} className="successFontColor">
                        Approve
                      </Typography>
                      <Typography className="rejectFontColor">Reject</Typography>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ProjectPopUp
        open={open}
        setOpen={setOpen}
        selectedData={selectedData}
        onAdminAction={props.onAdminAction}
      ></ProjectPopUp>
    </div>
  );
};

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Divider
} from "@mui/material";
import { useLatestJobsAndPagesData, LatestPagesJobs } from "../../../api/useLatestJobsAndPagesData";
import "./index.scss";
// import { useAllplatformJobsAndPagesData } from "../../../api/useLatestJobsAndPagesAllPlatform";
// import { useServerData } from "../../../api/useServerTableData";
import ProjectItemForAllPlatform from "./ProjectItemForAllPlatform";
import ProjectItem from "./ProjectItem";
import { TableCards } from "./TableCards";
import ProjectItemForServer from "./ProjectItemForServer";
import { NoData } from "../../../components/NoData/NoData";

const TableItem = ({
  selectedPlatform,
  setSelectedPlatform
}: {
  selectedPlatform: string;
  setSelectedPlatform: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const Id = localStorage.getItem("id");
  const nJobs = 5;
  let tableData: LatestPagesJobs[] | undefined;
  let isError: boolean = false;

  const conditionalScroll = `dash-table-container ${
    selectedPlatform === "client" ? "" : "project-scroll"
  }`;
  // if (selectedPlatform === "all") {
  //   const {
  //     data: Data,
  //     isError: isAllPlatformError,
  //     isLoading: isAllPlatformLoading
  //   } = useAllplatformJobsAndPagesData({
  //     Id,
  //     nJobs
  //   });

  //   tableData = Data;
  //   isError = isAllPlatformError;
  //   isLoading = isAllPlatformLoading;
  // } else
  if (selectedPlatform === "client") {
    const { data: Data, isError: isClientError } = useLatestJobsAndPagesData({
      Id,
      nJobs
    });
    tableData = Data;
    isError = isClientError;
  }
  // else if (selectedPlatform === "server") {
  //   const {
  //     data: Data,
  //     isError: isServerError,
  //     isLoading: isServerLoading
  //   } = useServerData({ Id });
  //   tableData = Data;
  //   isError = isServerError;
  //   isLoading = isServerLoading;
  // }

  if (isError) {
    return <p>Overview data not available at this moment</p>;
  }

  const getColumnsForPlatform = ({ platform }: { platform: string | undefined }) => {
    switch (platform) {
      case "server":
        return ["", "Project", "Last Updated", "Total Emission", "Carbon Offset", "Actions"];

      case "all":
        return [
          "",
          "Project",
          "Last Updated",
          "Carbon Emission",
          "Carbon Credit",
          "Status",
          "Actions"
        ];
      case "client":
        return ["", "Project", "Last Updated", "Carbon Emission", "Carbon Credit", "Suggestions"];
    }
  };

  const mapProjectsForPlatform = ({
    projects,
    platform
  }: {
    projects: LatestPagesJobs[] | undefined;
    platform: string;
  }) => {
    switch (platform) {
      case "client":
        return projects?.map((project) => (
          <ProjectItem key={project.id} project={project} platform={platform} />
        ));

      case "server":
        return projects?.map((project) => (
          <ProjectItemForServer key={project.projectId} project={project} platform={platform} />
        ));

      case "all":
        return projects?.map((project) => (
          <ProjectItemForAllPlatform key={project.id} project={project} />
        ));
    }
  };

  const tableColumns = getColumnsForPlatform({ platform: selectedPlatform });

  return (
    <>
      <Card className="dash-br-15 ">
        <CardContent className="projects-cardStyling" style={{ padding: "0" }}>
          <Grid
            py={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
              alignItems: "center"
            }}
          >
            <Grid py={1.1} px={2.4} className="card-heading">
              {tableData?.length} Projects
            </Grid>
          </Grid>
          <Divider />

          <TableCards tableData={tableData} selectedPlatform={selectedPlatform} />
          <TableContainer component={Paper} className={conditionalScroll}>
            {tableData?.length ? (
              <Table className="table dash-table">
                <TableHead>
                  <TableRow>
                    {tableColumns?.map((column, index) => (
                      <TableCell key={index} className="dash-head">
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mapProjectsForPlatform({ projects: tableData, platform: selectedPlatform })}
                </TableBody>
              </Table>
            ) : (
              <NoData />
            )}
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default TableItem;

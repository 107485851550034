export default function Storage() {
  return (
    <svg width="75" height="54" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.639 4.25195H9.35902C6.41902 4.25195 3.99902 6.67195 3.99902 9.61195V14.872C3.99902 17.832 6.41902 20.252 9.35902 20.252H38.619C41.579 20.252 43.999 17.832 43.999 14.892V9.61195C43.999 6.67195 41.579 4.25195 38.639 4.25195ZM13.499 14.252C13.499 15.072 12.819 15.752 11.999 15.752C11.179 15.752 10.499 15.072 10.499 14.252V10.252C10.499 9.43195 11.179 8.75195 11.999 8.75195C12.819 8.75195 13.499 9.43195 13.499 10.252V14.252ZM21.499 14.252C21.499 15.072 20.819 15.752 19.999 15.752C19.179 15.752 18.499 15.072 18.499 14.252V10.252C18.499 9.43195 19.179 8.75195 19.999 8.75195C20.819 8.75195 21.499 9.43195 21.499 10.252V14.252ZM35.999 13.752H27.999C27.179 13.752 26.499 13.072 26.499 12.252C26.499 11.432 27.179 10.752 27.999 10.752H35.999C36.819 10.752 37.499 11.432 37.499 12.252C37.499 13.072 36.819 13.752 35.999 13.752Z"
        fill="#6CB2C2"
      />
      <path
        d="M38.639 28.252H9.35902C6.41902 28.252 3.99902 30.672 3.99902 33.612V38.872C3.99902 41.832 6.41902 44.252 9.35902 44.252H38.619C41.579 44.252 43.979 41.832 43.979 38.892V33.632C43.999 30.672 41.579 28.252 38.639 28.252ZM13.499 38.252C13.499 39.072 12.819 39.752 11.999 39.752C11.179 39.752 10.499 39.072 10.499 38.252V34.252C10.499 33.432 11.179 32.752 11.999 32.752C12.819 32.752 13.499 33.432 13.499 34.252V38.252ZM21.499 38.252C21.499 39.072 20.819 39.752 19.999 39.752C19.179 39.752 18.499 39.072 18.499 38.252V34.252C18.499 33.432 19.179 32.752 19.999 32.752C20.819 32.752 21.499 33.432 21.499 34.252V38.252ZM35.999 37.752H27.999C27.179 37.752 26.499 37.072 26.499 36.252C26.499 35.432 27.179 34.752 27.999 34.752H35.999C36.819 34.752 37.499 35.432 37.499 36.252C37.499 37.072 36.819 37.752 35.999 37.752Z"
        fill="#6CB2C2"
      />
    </svg>
  );
}

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_PROJECTS_OFFSET_CHART } from "./paths";

const getProjectsOffsetChart = async ({
  userId,
  month,
  year
}: {
  userId: string;
  month: number;
  year: number;
}) => {
  return await axiosClient
    .get<OffSetChartData[]>((GET_PROJECTS_OFFSET_CHART as string) + `${userId}/${month}/${year}`)
    .then((res) => res.data);
};

export const useProjectOffsetChartData = ({
  userId,
  month,
  year
}: {
  userId: string;
  month: number;
  year: number;
}) => {
  return useQuery({
    queryKey: ["projects-offset-chart", userId, month, year],
    queryFn: () => getProjectsOffsetChart({ userId, month, year })
  });
};

export interface OffSetChartData {
  name: string;
  cc: number;
  id: number;
}

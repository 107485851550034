import "./index.scss";
import Modal from "@mui/material/Modal";
import { Typography, Grid, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { updateAdmin } from "../../api/useAdminData";
import * as React from "react";
import Close from "../../components/Svg/Close";
import CustomButton from "../../components/CustomButton/CustomButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#252525",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

export const ProjectPopUp = (props: any) => {
  const [comment, setComment] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setComment("");
    props.setOpen(false);
  };

  const adminAction = (data: any, approved: boolean) => {
    const apiObj = {
      projectId: props.selectedData.id,
      obj: {
        comment: data,
        approval: approved
      }
    };
    props.selectedData.approval = approved;
    props.selectedData.comment = data;
    updateAdminApproval(apiObj);
    handleClose();
  };

  const onSuccess = (message: any) => {
    enqueueSnackbar(message, { variant: "success", autoHideDuration: 2000 });
  };

  const onUpdate = (data: any) => {
    if (data.data === "Approved") {
      onSuccess("Project Approved");
      props.onAdminAction(props.selectedData);
    } else if (data.data === "Rejected") {
      onSuccess("Project Rejected");
      props.onAdminAction(props.selectedData);
    }
  };

  const { mutate: updateAdminApproval } = updateAdmin(onUpdate);

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="adminProjectPopup"
    >
      <Box sx={style}>
        {props.selectedData != null && (
          <Box sx={{ p: 0, mb: 2 }}>
            {" "}
            <Box className="header">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Project Details
              </Typography>
              <Box className="closeBtn" onClick={handleClose}>
                {" "}
                <Close />
              </Box>
            </Box>
            <Box className="container">
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ mb: 1 }} id="modal-modal-title" className="subHeader">
                  Project Details
                </Typography>
                <Grid container>
                  <Grid item xs={4} className="pb1">
                    <Typography className="titleHeader">Project Name</Typography>
                    <Typography>{props.selectedData.name}</Typography>
                  </Grid>
                  <Grid item xs={4} className="pb1">
                    <Typography className="titleHeader">App ID</Typography>
                    <Typography>{props.selectedData.appId}</Typography>
                  </Grid>
                  <Grid item xs={4} className="pb1">
                    <Typography className="titleHeader">App Type</Typography>
                    <Typography>{props.selectedData.appType}</Typography>
                  </Grid>
                  <Grid item xs={4} className="pb1">
                    <Typography className="titleHeader">Network Type</Typography>
                    <Typography>{props.selectedData.networkType}</Typography>
                  </Grid>
                  <Grid item xs={4} className="pb1">
                    <Typography className="titleHeader">Hosting Info</Typography>
                    <Typography>{props.selectedData.hostingType}</Typography>
                  </Grid>
                  <Grid item xs={4} className="pb1">
                    <Typography className="titleHeader">Department Name</Typography>
                    <Typography>{props.selectedData.departmentName}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ mb: 1 }} id="modal-modal-title" className="subHeader">
                  User Details
                </Typography>
                <Grid container>
                  <Grid item xs={4} sx={{ pb: 1, pr: 1 }}>
                    <Typography className="titleHeader">Administrator Email ID</Typography>
                    <Typography>{props.selectedData.powerUser}</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pb: 1, pr: 1 }}>
                    <Typography className="titleHeader">Deputy Email ID</Typography>
                    <Typography>{props.selectedData.deputyId}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ mb: 2 }} className="comments">
              <Typography>Comments</Typography>
              <textarea
                value={props.selectedData.comment != null ? props.selectedData.comment : comment}
                onChange={(e) => setComment(e.target.value)}
                readOnly={props.selectedData.approval != null}
                placeholder="Enter Comments"
                className={props.selectedData.approval != null ? "readonly" : ""}
              ></textarea>
            </Box>
            {props.selectedData.approval == null && (
              <Box className="actionButton">
                <CustomButton
                  onClick={() => adminAction(comment, true)}
                  type="submit"
                  className="successBgColor"
                  sx={{ mr: 1 }}
                >
                  Approve
                </CustomButton>
                <CustomButton
                  onClick={() => adminAction(comment, false)}
                  type="submit"
                  className="rejectBgColor"
                  sx={{ ml: 1 }}
                >
                  Reject
                </CustomButton>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

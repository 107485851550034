import { useQuery, useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { GET_APPROVED_PROJECTS, GET_PROJECTS, PUT_ADMIN } from "./paths";

const getProjectsAdmin = async () => {
  return await axiosClient.get<AdminProjectList[]>(GET_PROJECTS).then((res) => res.data);
};

const putApprove = async (project: IAdminAction) => {
  return await axiosClient.put(PUT_ADMIN + `/${project.projectId}`, project.obj);
};

export const useProjectsDataAdmin = () => {
  return useQuery({
    queryKey: ["projects"],
    queryFn: getProjectsAdmin
  });
};

const getApprovedProjects = async () => {
  return await axiosClient.get(GET_APPROVED_PROJECTS).then((res) => res.data);
};

export const useApprovedProjects = () => {
  return useQuery({
    queryKey: ["Approved projects"],
    queryFn: getApprovedProjects
  });
};

export const updateAdmin = (onUpdateSuccess: onSuccess) => {
  return useMutation({
    mutationFn: putApprove,
    onSuccess: onUpdateSuccess
  });
};

export interface AdminProjectList {
  approval: boolean;
  id: 0;
  name: "";
  projectAbbr: "";
  currentVersion: "";
  image: "";
  devType: "";
  departmentName: "";
  projectsInfra: {
    appId: "";
    appType: "";
    networkType: "";
    hostingType: "";
    environment: "";
    deputyId: "";
    powerUser: "";
    powerUserId: 0;
    deputyUserId: 0;
  };
  projectDetails: {
    description: "";
    appInfoClassification: "";
    techStack: "";
    endUserType: "";
    plannedStartDate: null;
    plannedEndDate: null;
  };
}
export interface IAdminAction {
  projectId: number;
  obj: {
    comment: string;
    approval: boolean;
  };
}

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: IAdminAction,
  context: unknown
) => unknown;

import { Grid } from "@mui/material";
import { useloggedInUserDetails } from "../../api/getLoggedInUserDetails";
import React, { useEffect, useState } from "react";
import { Projects } from "./Projects";
import { Summary } from "./Summary";
import { useIsFetching } from "@tanstack/react-query";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

// Left section of Dashboard

export default function Overview() {
  const isFetching = useIsFetching();

  const { data: userData, isSuccess } = useloggedInUserDetails();

  const [userEmail, setUserEmail] = useState("");

  const [selectedPlatform, setSelectedPlatform] = useState("client");

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("id", userData.id);
      localStorage.setItem("emailId", userData.emailId);
      localStorage.setItem("role", userData.role);

      const userEmail: string | null = localStorage.getItem("emailId");
      setUserEmail(userEmail ?? "");
    }
  }, [isSuccess, userData]);

  return (
    <>
      {isFetching ? <CustomLoader /> : null}
      {userEmail && userEmail.length > 0 && isSuccess && localStorage.getItem("id") && (
        <div style={{ margin: "30px" }}>
          <Grid className="grid-design" container columnSpacing={2} rowSpacing={4}>
            <Grid item lg={5}>
              <Summary
                selectedPlatform={selectedPlatform}
                setSelectedPlatform={setSelectedPlatform}
              />
            </Grid>
            <Grid item lg={7}>
              <Projects selectedPlatform={selectedPlatform} />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { POST_WEBPAGE } from "./paths";

const createWebPage = async (webPage: IWebPage) => {
  return await axiosClient({
    method: "post",
    url: POST_WEBPAGE,
    data: webPage
  });
};

export const createNewWebPage = (onUpdateSuccess: any, onError: any) => {
  return useMutation({
    mutationFn: createWebPage,
    onSuccess: onUpdateSuccess,
    onError
  });
};

export interface IWebPage {
  id?: number;
  name?: string;
  projectId?: number;
  url?: string;
  version?: string;
}
// Generated by https://quicktype.io

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: IWebPage,
  context: unknown
) => unknown;

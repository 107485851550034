import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { axiosClient } from "../globals/client/axios";
import { CLIENT_RT_CONFIG } from "./paths";
import { IFormState } from "../pages/Project Settings/ClientRTConfigForm";

const saveConfig = async ({
  projID,
  monitoringPlatformId,
  configName,
  domainUrl,
  pathsExcluded
}: IFormState) => {
  pathsExcluded = pathsExcluded.filter((_: string) => _ !== "");
  const pathsExcludedObj = {
    pathsExcluded
  };
  const projectMonitoringSetting = pathsExcludedObj;
  return await axiosClient.put(`${CLIENT_RT_CONFIG}/${projID}/${monitoringPlatformId}`, {
    configName,
    domainUrl,
    projectMonitoringSetting
  });
};
export const useConfigClientRTMutation = (onSaveSuccess: onSuccess, onError: any) => {
  return useMutation({
    mutationFn: saveConfig,
    onSuccess: onSaveSuccess,
    onError
  });
};

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: IFormState,
  context: unknown
) => unknown;

export interface IClientRTConfig {
  id: number;
  monitoringType: string;
  platform: string;
  projectId: number;
  apiToken: string;
  domainUrl: string;
  configName: string;
  isToolAvailable: boolean;
  projectMonitoringSetting: IpathExcludedObj;
}

export const fallbackClientRTConfig: IClientRTConfig = {
  id: 0,
  monitoringType: "",
  platform: "",
  projectId: 0,
  apiToken: "",
  domainUrl: "",
  configName: "",
  isToolAvailable: false,
  projectMonitoringSetting: {
    pathsExcluded: []
  }
};
export interface IpathExcludedObj {
  pathsExcluded: string[];
}

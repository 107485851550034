import { Box, SelectChangeEvent } from "@mui/material";
import React, { useEffect } from "react";
import { onSuccess } from "../../../api/getLoggedInUserDetails";
import { IClientRTConfig, useConfigClientRTMutation } from "../../../api/useClientRTConfig";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CustomButtonWithRightIcon from "../../../components/CustomButtonWithRightIcon";
import MonitorTool from "../../../components/MonitorTool/MonitorTool";
import InfoCircle from "../../../components/Svg/InfoCircle";
import { CLIENT_RT_MONITOR_TOOL_SETUP_STEPS } from "../../../utils/constants";

export interface IFormState {
  projID: number;
  configName: string;
  pathsExcluded: string[];
  domainUrl: string;
  monitoringPlatformId: number;
}
export interface IClientRTConfigProps {
  projID: number;
  monitoringPlatformId: number;
  setShowConfigDetails?: React.Dispatch<React.SetStateAction<boolean>>;
  showSteps: boolean;
  isEditMode: boolean;
  ConfigDetails: IClientRTConfig;
  setConfigUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  handleModalClose?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClientRTConfigForm: React.FC<IClientRTConfigProps> = ({
  projID,
  monitoringPlatformId,
  setShowConfigDetails,
  showSteps,
  isEditMode,
  ConfigDetails,
  setConfigUpdated,
  handleModalClose
}) => {
  const [pathExcluded, setPathExcluded] = React.useState("");

  const onError = (error: any) => {
    console.log("error", error.response);
    if (error.response) {
      console.log("Error Status:", error.response);
    } else {
      console.error("Error:", error.message);
    }
  };
  const onSaveConfigSuccess: onSuccess = (data) => {
    if (setShowConfigDetails) setShowConfigDetails(true);
    if (handleModalClose) handleModalClose(false);
    console.log(data);
  };
  const { mutate } = useConfigClientRTMutation(onSaveConfigSuccess, onError);

  const [configFormData, setConfigFormData] = React.useState<IFormState>({
    projID,
    monitoringPlatformId,
    configName: "",
    domainUrl: "",
    pathsExcluded: []
  });
  const handleConfigSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate(configFormData);
    setConfigFormData({
      projID,
      monitoringPlatformId,
      configName: "",
      domainUrl: "",
      pathsExcluded: []
    });
  };
  useEffect(() => {
    setConfigFormData({
      projID,
      monitoringPlatformId,
      configName: ConfigDetails.configName,
      domainUrl: ConfigDetails.domainUrl,
      pathsExcluded: ConfigDetails.projectMonitoringSetting.pathsExcluded
    });
  }, [ConfigDetails]);

  const handlConfigName = (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    setConfigFormData((prevData) => ({
      ...prevData,
      configName: event.target.value
    }));
  };

  const handleKeyDownPathExcl: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const path = pathExcluded.trim();
      if (path) {
        setConfigFormData((prevData) => ({
          ...prevData,
          pathsExcluded: [...configFormData.pathsExcluded, path]
        }));
      }
      setPathExcluded("");
    }
  };

  const handleChangePathExcl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPathExcluded(e.target.value);
  };

  // const handleChangePathInc = (
  //   event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  // ): void => {};

  const handleDomainName = (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { value } = event.target;
    setConfigFormData((prevData) => ({
      ...prevData,
      domainUrl: value
    }));
  };

  const handleCloseClick = (itemDeleted: string): void => {
    setConfigFormData((prevData) => ({
      ...prevData,
      pathsExcluded: configFormData.pathsExcluded.filter((_) => _ !== itemDeleted)
    }));
  };

  return (
    <>
      <Box className="container-config-outer">
        {showSteps && (
          <Box className="steps-heading steps-spacing">
            <MonitorTool guidelines={CLIENT_RT_MONITOR_TOOL_SETUP_STEPS}>
              <InfoCircle />
            </MonitorTool>
          </Box>
        )}
        <Box className="container-config">
          <Box className="heading">
            <Box className="header spacing-header">
              {isEditMode ? "Edit Configuration Details" : "Configuration Details"}
            </Box>
          </Box>
          <form className="form" onSubmit={handleConfigSubmit}>
            <Box className="form-column text-fieldCOnfig">
              <label htmlFor="configName">
                Config Name<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="input2"
                className="inputFlex"
                name="configName"
                placeholder="Enter Config Name"
                onChange={handlConfigName}
                value={configFormData.configName}
                required
              />

              <label htmlFor="pageExcluded">Page Excluded</label>
              <input
                type="text"
                id="input3"
                className="inputFlex"
                name="pageExcluded"
                value={pathExcluded}
                placeholder="Enter Keyword "
                onChange={handleChangePathExcl}
                onKeyDown={handleKeyDownPathExcl}
              />
              {pathExcluded.length > 0 ? (
                <span className="press-enter">Please press Enter to add each value</span>
              ) : null}
              <Box mt={1}>
                <CustomButtonWithRightIcon
                  excludedList={configFormData.pathsExcluded}
                  onCloseClicked={handleCloseClick}
                />
              </Box>
            </Box>
            <Box className="form-column text-fieldCOnfig">
              <label htmlFor="domainName">Monitoring Domain URL</label>
              <input
                type="text"
                id="input4"
                className="inputFlex"
                name="domainName"
                placeholder="Enter Domain URL"
                onChange={handleDomainName}
                value={configFormData.domainUrl}
              />
              {/* <label htmlFor="pageIncluded">Page Included</label>
              <input
                type="text"
                id="input5"
                className="inputFlex"
                name="pageIncluded"
                placeholder="Enter Keyword"
                onChange={handleChangePathInc}
              /> */}
            </Box>

            <Box className="button-row">
              {isEditMode ? (
                <CustomButton type="submit" className="add-saveBtn" data-value="Update">
                  Update
                </CustomButton>
              ) : (
                <CustomButton type="submit" className="add-saveBtn" data-value="Save">
                  Save & Proceed
                </CustomButton>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default ClientRTConfigForm;

import { Grid, Card } from "@mui/material";
import React from "react";
import OverviewCard from "../../../components/OverviewCard/OverviewCard";
import { PoweredByLH } from "../../../components/Powered by LH";
import DownArrow from "../../../components/Svg/DownArrow";
// import PerExecution from "../../../components/Svg/PerExececution";
import QualityScoreTick from "../../../components/Svg/QualityScoreTick";
import UpArrow from "../../../components/Svg/UpArrow";
// import { useAllPlatformData } from "../../../api/useAllPlatformData";
import { useOverviewData } from "../../../api/useOverviewData";
import { EnergyBulb } from "../../../components/Svg/EnergyBulb";
// import { useServerOverviewData } from "../../../api/useServerOverviewData";
import useCeConversionFixed from "../../../utils/useCeConversionFixed";
import useCeConversion from "../../../utils/useCeConversion";
import { LatestPagesJobs } from "../../../api/useLatestJobsAndPagesData";

export const TableCards = ({
  selectedPlatform,
  tableData
}: {
  selectedPlatform: string;
  tableData: LatestPagesJobs[] | undefined;
}) => {
  const { data: clientSummaryData } = useOverviewData();
  // const { data: serverSummaryData, isLoading: serverSummaryLoading } = useServerOverviewData();
  // const { data: allPlatformSummaryData, isLoading: allPlatformSummaryLoading } =
  // useAllPlatformData();

  return (
    <Grid>
      {selectedPlatform === "client" ? (
        // <Card>
        //   <CardContent className="project-card">
        //     <ProjectCards cardItems={ClientCardItems} size={2.3} />
        //   </CardContent>
        // </Card>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Card className="all-table-cards">
              <OverviewCard
                metricdata={
                  tableData === undefined
                    ? ""
                    : `${String(
                        tableData?.reduce((total, item) => total + item.suggestionsCount, 0)
                      )}`
                }
                metrictooltip={
                  tableData === undefined
                    ? ""
                    : `${String(
                        tableData?.reduce((total, item) => total + item.suggestionsCount, 0)
                      )}`
                }
                title="Total Suggestions"
                primaryIcon={<QualityScoreTick />}
                secondaryText=""
                secondaryIcon={<PoweredByLH />}
                sp={selectedPlatform}
              />
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card className="all-table-cards">
              <OverviewCard
                metricdata={useCeConversionFixed(clientSummaryData?.HighestEmittedValue)}
                metrictooltip={useCeConversion(clientSummaryData?.HighestEmittedValue)}
                title={clientSummaryData?.HighestEmittingProjectAbbr}
                primaryIcon={<UpArrow />}
                secondaryText="Most Carbon Emitted"
                sp={selectedPlatform}
              />
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card className="all-table-cards">
              <OverviewCard
                metricdata={useCeConversionFixed(clientSummaryData?.LeastEmittedValue)}
                metrictooltip={useCeConversion(clientSummaryData?.LeastEmittedValue)}
                title={clientSummaryData?.LeastEmittingProjectAbbr}
                primaryIcon={<DownArrow />}
                secondaryText="Least Carbon Emitted"
                sp={selectedPlatform}
              />
            </Card>
          </Grid>
        </Grid>
      ) : selectedPlatform === "server" ? (
        <Grid container spacing={2}>
          {/* <Grid item lg={4}>
            <Card className="all-table-cards">
              <OverviewCard
                metricdata={`231 Kg`}
                title="Per Execution"
                primaryIcon={<PerExecution />}
                secondaryText=""
                secondaryIcon={<PoweredByLH />}
                sp={selectedPlatform}
              />
            </Card>
          </Grid> */}
          <Grid item lg={6}>
            <Card className="all-table-cards">
              {/* <OverviewCard
                metricdata={useCeConversionFixed(serverSummaryData?.HighestEmittedValue)}
                metrictooltip={useCeConversion(serverSummaryData?.HighestEmittedValue)}
                title={serverSummaryData.HighestEmittingProjectAbbr}
                primaryIcon={<UpArrow />}
                secondaryText="Most Carbon Emitted"
                sp={selectedPlatform}
              /> */}
            </Card>
          </Grid>
          <Grid item lg={6}>
            <Card className="all-table-cards">
              {/* <OverviewCard
                metricdata={useCeConversionFixed(serverSummaryData?.LeastEmittedValue)}
                metrictooltip={useCeConversion(serverSummaryData?.LeastEmittedValue)}
                title={serverSummaryData.LeastEmittingProjectAbbr}
                primaryIcon={<DownArrow />}
                secondaryText="Least Carbon Emitted"
                sp={selectedPlatform}
              /> */}
            </Card>
          </Grid>
        </Grid>
      ) : selectedPlatform === "cloud" ? (
        <Grid container spacing={3}>
          <Grid item lg={4}>
            <Card className="all-table-cards">
              <OverviewCard
                metricdata={`378 kWh`}
                title="Energy Usage"
                primaryIcon={<EnergyBulb />}
                secondaryText=""
                secondaryIcon={<PoweredByLH />}
                sp={selectedPlatform}
              />
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card className="all-table-cards">
              <OverviewCard
                metricdata={`32.6 Kg`}
                title="WAO"
                primaryIcon={<UpArrow />}
                secondaryText="Most Carbon Emitted"
                sp={selectedPlatform}
              />
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card className="all-table-cards">
              <OverviewCard
                metricdata={`2.6 Kg`}
                title="MBO"
                primaryIcon={<DownArrow />}
                secondaryText="Least Carbon Emitted"
                sp={selectedPlatform}
              />
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <Card className="all-table-cards">
              {/* <OverviewCard
                metricdata={useCeConversionFixed(allPlatformSummaryData?.HighestEmittedValue)}
                metrictooltip={useCeConversion(allPlatformSummaryData?.HighestEmittedValue)}
                title={allPlatformSummaryData?.HighestEmittingProjectAbbr}
                primaryIcon={<UpArrow />}
                secondaryText="Most Carbon Emitted"
                sp={selectedPlatform}
              /> */}
            </Card>
          </Grid>
          <Grid item lg={6}>
            <Card className="all-table-cards">
              {/* <OverviewCard
                metricdata={useCeConversionFixed(allPlatformSummaryData?.LeastEmittedValue)}
                metrictooltip={useCeConversion(allPlatformSummaryData?.LeastEmittedValue)}
                title={allPlatformSummaryData?.LeastEmittingProjectAbbr}
                primaryIcon={<DownArrow />}
                secondaryText="Least Carbon Emitted"
                sp={selectedPlatform}
              /> */}
            </Card>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { REGEN_ACCESS_KEY } from "./paths";

const regenKey = async (projectId: number | string) => {
  return await axiosClient({
    headers: { "content-type": "text/plain" },
    method: "put",
    url: REGEN_ACCESS_KEY + projectId.toString()
  });
};

export const generateNewAccessKey = (onUpdateSuccess: any) => {
  return useMutation({
    mutationFn: regenKey,
    onSuccess: onUpdateSuccess
  });
};

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: number | string,
  context: unknown
) => unknown;

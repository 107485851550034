import { Grid } from "@mui/material";
import { useState } from "react";
import Metrics from "../Metrics/Metrics";
import { SuggestionMetricProps } from "../Performance/Performance";
import { SuggestionsTable } from "../SuggestionsTable";

export const Accessibility = ({ metrics, allRelAudits }: SuggestionMetricProps) => {
  const [activeMetric, setActiveMetric] = useState<string | null>(metrics?.at(0)?.audit ?? null);

  return (
    <Grid container sx={{ bgcolor: "#252525", borderRadius: "0.7rem" }} className="audit-warpper">
      <Grid item xs={5}>
        <Metrics
          activeTab={"Accessibility"}
          metrics={metrics}
          activeMetric={activeMetric}
          setActiveMetric={setActiveMetric}
        />
      </Grid>
      <Grid item xs={7} className="audits-table">
        <SuggestionsTable
          activeTab={"Best Practices"}
          activeMetric={activeMetric}
          allRelAudits={allRelAudits}
        />
      </Grid>
    </Grid>
  );
};

import { Grid, Typography, Box } from "@mui/material";
import LightHouse from "../Svg/LightHouse";
// import Lighthouse from "../../assets/svg/Lighthouse.svg";
import "./index.scss";

export const PoweredByLH = ({ gray = false }: { gray?: Boolean }) => {
  return (
    <Grid className="powered-by-lh-container">
      <Grid className="powered-by-lh-grid">
        <Typography className={`powered-by-lh-text ${gray ? "op" : ""}`} component="p">
          Powered by
        </Typography>
        <Box className="image">
          <LightHouse />
        </Box>
      </Grid>
    </Grid>
  );
};

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { GET_LOGGEDIN_USERDETAILS } from "./paths";

const getLoggedInUsers = async () => {
  return await axiosClient
    .get(GET_LOGGEDIN_USERDETAILS + `?emailId=${localStorage.getItem("emailId") ?? ""}`)
    .then((res) => res.data);
};

export const useloggedInUserDetails = () => {
  return useQuery({
    queryKey: ["loggedin users"],
    queryFn: getLoggedInUsers
  });
};

export type onSuccess = (
  data: AxiosResponse<any, any>,

  context: unknown
) => unknown;

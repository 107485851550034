import AddIcon from "@mui/icons-material/Add";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Autocomplete,
  Backdrop,
  Box,
  Card,
  Grid,
  Modal,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";

import { red } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CollaboraterPages,
  createNewCollaborator,
  deleteCollaboratorById,
  updateCollaboratorById,
  useCollaboratorsData
} from "../../../api/useCollaboratorsData";
import { IProject } from "../../../api/useProjectsData";
import { IUsers, useUsersData } from "../../../api/useUsersData";
import { ConfirmPopUp } from "../../../components/ConfirmPopup";
import CustomBasicDropdown from "../../../components/CustomBasicDropdown";

import CustomButton from "../../../components/CustomButton/CustomButton";
import { SearchBox } from "../../../components/SearchBox";
import { RootState } from "../../../store";

import { returnDropDownOptionsFromArray } from "../../../utils/common";
import { ROLE_OPTIONS1 } from "../../../utils/constants";

export const Collaborators = ({ projectData }: { projectData: IProject }) => {
  const { data: allUsers } = useUsersData();
  const { data: CollaboratorsData, isSuccess } = useCollaboratorsData(projectData.id, true);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (isSuccess) {
      setcheckUser(false);
      const rowsData: CollaboraterPages[] = CollaboratorsData?.map((page) => {
        return { ...page };
      });

      checkUserAccess();
      updateRows(rowsData);
    }
    const collabEmailList = CollaboratorsData?.map((user) => user.userAccess.emailId);
    const allUsersEmailList = allUsers?.map((user) => user.emailId);
    if (collabEmailList && allUsersEmailList !== undefined) {
      const uniqueEmails = allUsersEmailList.filter((item) => !collabEmailList.includes(item));
      setallUniqueUsers(uniqueEmails);
    }
  }, [CollaboratorsData]);

  const [currUserId, setCurrUserId] = useState<string | undefined>(undefined);
  const EmailIdUser = useSelector<RootState>((state) => state.auth.emailId);

  const [checkUser, setcheckUser] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [rows, updateRows] = useState<CollaboraterPages[] | undefined>(undefined);
  const [allUniqueUsers, setallUniqueUsers] = useState<string[]>([]);
  const [isEditing, setisEditing] = useState(false);
  const [selectedPage, setSelectedPage] = useState<CollaboraterPages | undefined>(undefined);
  const [userEmail, setUserEmail] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const handleModalOpen = () => {
    setOpen(true);
    setAddUserError("");
    setFirstDropdownValue(null);
    setUserRole("");
  };
  const handleModalClose = () => {
    setOpen(false);
    setAddUserError("");
    setFirstDropdownValue(null);
    setUserRole("");
  };
  const [deleteopen, setdeleteOpen] = React.useState(false);
  const handleOpen = () => setdeleteOpen(true);
  const handleClose = () => setdeleteOpen(false);
  const [formData, setFormData] = useState({
    role: ""
  });
  const [firstDropdownValue, setFirstDropdownValue] = useState<IUsers | null>();
  const [addUserError, setAddUserError] = useState("");

  const handleInputChange = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target;
    setUserEmail(value as string);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const checkUserAccess = () => {
    const userexist = CollaboratorsData
      ? CollaboratorsData.filter((user) => user.userAccess.emailId === EmailIdUser)
      : null;
    if (userexist?.[0]?.role !== "Collaborator") {
      setcheckUser(true);
    }
    if (userexist !== null) {
      setCurrUserId(userexist[0]?.id);
    }
  };
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const useremail = firstDropdownValue?.emailId;
    if (useremail !== undefined) {
      const userId1: number | undefined = getEmailIdById(useremail);
      const obj: CollaboraterPages = {
        id: "",
        userId: userId1,
        projectId: projectData.id,
        createdDate: "",
        role: formData.role,
        version: projectData.currentVersion,
        updatedDate: "",
        userAccess: {
          id: "",
          emailId: useremail,
          password: "",
          createdDate: "",
          expiryDate: ""
        }
      };
      // Handle form submission with formData

      setSelectedPage(obj);
      createCollaborator(obj);
      setFirstDropdownValue(null);
    }
  };

  const onCollaboratorAdded = (data: { data: CollaboraterPages }) => {
    // onSuccess("User Added");
    CollaboratorsData?.push(data.data);
    const updatedObject1 = CollaboratorsData?.map((obj) => {
      return {
        ...obj
      };
    });
    updateRows(updatedObject1);

    enqueueSnackbar("User Added", { variant: "success", autoHideDuration: 2000 });
    handleModalClose();
  };

  const onCollaboratorDelete = (data: { data: { id: string | undefined } }) => {
    // onSuccess("User Deleted");
    const index: number | undefined = CollaboratorsData?.findIndex(
      (obj) => obj?.id === data?.data?.id
    );
    if (index !== undefined) {
      CollaboratorsData?.splice(index, 1);
    }

    const updatedObject1 = CollaboratorsData?.map((obj) => {
      return {
        ...obj
      };
    });
    updateRows(updatedObject1);
  };

  const onCollaboratorUpdate = (data: { data: CollaboraterPages }) => {
    // onSuccess("User Updated");
    const index = CollaboratorsData?.findIndex((obj) => obj.id === data.data.id);
    if (index !== undefined && CollaboratorsData !== undefined) {
      CollaboratorsData[index] = data.data;
    } else {
      CollaboratorsData?.push(data.data);
    }
    const updatedObject1 = CollaboratorsData?.map((obj) => {
      return {
        ...obj
      };
    });
    updateRows(updatedObject1);
  };
  const onError = (error: {
    response: { data: { message: React.SetStateAction<string> } };
    data: { message: string };
  }) => {
    if (error.response) {
      setAddUserError(error.response.data.message);
    } else {
      console.error("Error:", error.data.message);
    }
  };
  const { mutate: createCollaborator, isError } = createNewCollaborator(
    onCollaboratorAdded,
    onError
  );
  const { mutate: updateCollaboratorPage } = updateCollaboratorById(onCollaboratorUpdate);
  const { mutate: deleteCollaboratorPage } = deleteCollaboratorById(onCollaboratorDelete);

  const handleDelete = (row: React.SetStateAction<CollaboraterPages | undefined>) => {
    handleOpen();
    setSelectedPage(row);
  };

  const confirmDelete = () => {
    if (selectedPage !== undefined) {
      deleteCollaboratorPage(selectedPage);
      if (rows !== undefined) {
        let newArr = [...rows];
        newArr = newArr.filter(function (obj) {
          return obj.id !== selectedPage?.id;
        });

        // queryClient.invalidateQueries(["CollaboratorPages", projectId]);
        updateRows(newArr);
        enqueueSnackbar("User Deleted", { variant: "success", autoHideDuration: 2000 });
      }
    }
  };

  const editTable = (row: CollaboraterPages) => {
    setisEditing(true);
    setSelectedPage(row);
    setUserEmail(row?.userAccess.emailId);
    if (row?.role !== undefined) {
      setUserRole(row?.role);
    }
  };

  const handleEmailChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    const searchTerm = (value as string).toLowerCase();
    setUserEmail(searchTerm);
    const userId = getEmailIdById(value as string);
    if (selectedPage !== undefined) {
      selectedPage.userId = userId;
    }
    setSelectedPage(selectedPage);
  };

  const getEmailIdById = (emailId: string) => {
    const filteredUsers = allUsers ? allUsers.filter((user) => user.emailId === emailId) : "";
    if (filteredUsers.length > 0 && filteredUsers !== "") {
      return filteredUsers[0].id;
    }
    return undefined;
  };

  const handleSearch = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setSearchQuery(value);

    const results = CollaboratorsData?.filter((user) =>
      user.userAccess.emailId.toLowerCase().includes(value.toLowerCase())
    );
    updateRows(results);
  };

  const handleFirstDropdownChange = (event: any, value: IUsers | null) => {
    setFirstDropdownValue(value);
  };
  const handleRoleChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    setUserRole(value as string);
    if (selectedPage !== undefined) {
      selectedPage.role = value as string;
      setSelectedPage(selectedPage);
    }
  };

  const cancelRow = (id: string | undefined) => {
    if (id === "") {
      // Remove the new row with an empty ID
      const filteredData = CollaboratorsData?.filter((obj) => obj?.id !== "");
      updateRows(filteredData);
    } else {
      // Restore original role value
      const originalRole = CollaboratorsData?.find((obj) => obj.id === id)?.role;
      const originalEmail = CollaboratorsData?.find((obj) => obj.id === id)?.userAccess.emailId;
      const updatedRows = rows?.map((row) => {
        if (row.id === id && originalRole !== undefined && originalEmail !== undefined) {
          row.role = originalRole;
          row.userAccess.emailId = originalEmail;
        }
        return row;
      });

      // Update rows with the restored role value
      updateRows(updatedRows);
    }

    // Reset other states
    setisEditing(false);
  };

  const saveRow = () => {
    const { ...rest } = selectedPage;
    updateCollaboratorPage(rest);
    setisEditing(false);
    enqueueSnackbar("User Updated", { variant: "success", autoHideDuration: 2000 });
  };
  const allUserOptions = returnDropDownOptionsFromArray(allUniqueUsers);
  return (
    <>
      <Grid className="collabContainer" item xs={6}>
        <div className="collabHeader">
          <Typography className="pgHeading">Collaborators</Typography>

          <div className="searchButton-container">
            <SearchBox
              value={searchQuery}
              handleSearch={handleSearch}
              placeholder="Search Email Id"
              className="searchInput"
            />
            <CustomButton
              className="addpagebtn"
              startIcon={<AddIcon />}
              variant="contained"
              onClick={handleModalOpen}
              disabled={!checkUser}
            >
              Add User
            </CustomButton>
          </div>
        </div>

        <Grid item xs={12}>
          <Card className="card">
            <TableContainer className="settings-collab-table" component={Paper}>
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell className="head">Email</TableCell>
                    <TableCell className="head">Role</TableCell>
                    {checkUser && <TableCell className="actions-head">Actions</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody className="pc-table-container">
                  {rows
                    ? rows?.map((row) => (
                        <TableRow
                          style={
                            currUserId === row.id
                              ? { visibility: "collapse" }
                              : { fontSize: "18px" }
                          }
                          className="row"
                          key={row.id}
                          // onClick={() => {
                          //   setSelectedPage(row);
                          // }}
                        >
                          <TableCell className="cell-design" key={row.id}>
                            {isEditing && selectedPage?.id === row.id ? (
                              <>
                                <CustomBasicDropdown
                                  name="ename1"
                                  className="pcInput select-allUsers2"
                                  type="text"
                                  options={allUserOptions}
                                  value={isEditing ? userEmail : row.userAccess.emailId}
                                  renderValue={(value) => (
                                    <span style={{ opacity: 1 }}>{value as string}</span>
                                  )}
                                  onChange={handleEmailChange}
                                  disabled={selectedPage?.id !== row.id}
                                />
                              </>
                            ) : (
                              <span className="email-display">{row.userAccess.emailId}</span>
                            )}
                          </TableCell>
                          <TableCell className="cell-design" key={row.id}>
                            {isEditing && selectedPage?.id === row.id ? (
                              <CustomBasicDropdown
                                name="role1"
                                options={ROLE_OPTIONS1}
                                className="select-allUsers1"
                                value={isEditing ? userRole : row.role}
                                onChange={handleRoleChange}
                                disabled={selectedPage?.id !== row.id}
                              />
                            ) : (
                              <span className="role-display">{row.role}</span>
                            )}
                          </TableCell>
                          <TableCell className="cell-design" key={row.id}>
                            {isEditing && selectedPage?.id === row.id ? (
                              <>
                                <CheckCircleOutlineIcon
                                  onClick={() => {
                                    saveRow();
                                  }}
                                  className="small-icon icon-white tableDelete"
                                  /* size="small" */
                                />
                                <CancelIcon
                                  onClick={() => {
                                    cancelRow(row.id);
                                  }}
                                  sx={{ color: red[500] }}
                                  className="small-icon icon-red tableDelete"
                                />
                              </>
                            ) : (
                              checkUser && (
                                <>
                                  <BorderColorOutlinedIcon
                                    onClick={() => {
                                      editTable(row);
                                    }}
                                    className="small-icon icon-white tableEdit"
                                  />
                                  <DeleteOutlineOutlinedIcon
                                    sx={{ color: red[500] }}
                                    onClick={() => {
                                      handleDelete(row);
                                    }}
                                    className="small-icon icon-red tableDelete"
                                  />
                                </>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
      {
        <Modal
          open={open}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalContainer">
            <div>
              <Typography className="modalHeading">Add New User</Typography>
              <CloseIcon className="modalClosebtn" onClick={handleModalClose}></CloseIcon>
            </div>
            <hr className="hrLine"></hr>
            <Box component="form" onSubmit={handleSubmit}>
              <Typography className="add-formHeading">Email</Typography>
              {allUsers !== undefined ? (
                <Autocomplete
                  value={firstDropdownValue}
                  onChange={handleFirstDropdownChange}
                  className="addInput"
                  size="small"
                  /*  required */
                  options={allUsers}
                  autoHighlight
                  getOptionLabel={(option) => option.emailId}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.emailId}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select User"
                      required
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : null}

              <Typography className="add-formHeading1">Select Role </Typography>
              <CustomBasicDropdown
                name="role"
                className="addInput1 user-role-custom-dropdown"
                options={ROLE_OPTIONS1}
                value={userEmail}
                onChange={handleInputChange}
              />
              {isError ? (
                <Typography
                  style={{ top: "228px", position: "absolute", left: "100px" }}
                  color="red"
                >
                  {addUserError?.toString()}
                </Typography>
              ) : null}
              <CustomButton type="submit" className="add-saveBtn">
                Save
              </CustomButton>
            </Box>
          </Box>
        </Modal>
      }
      {
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={deleteopen}
          onClose={handleClose}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <div>
            <ConfirmPopUp
              onClose={handleClose}
              onSave={confirmDelete}
              data="Do you want to delete?"
              key="1"
            />
          </div>
        </Modal>
      }
    </>
  );
};

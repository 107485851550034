import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import NewlyAdded from "../NewlyAdded";
import TableItem from "../Table";
import Onboard from "../Onboard";
import "./index.scss";
import { ToggleButtonGroup } from "../../../components/ToggleButtonGroup/ToggleButtonGroup";

interface Tab {
  id: string;
  active: boolean;
  content: React.ReactNode;
}

interface PlatformProps {
  selectedPlatform: string;
}

const TableSwitcher: React.FC<PlatformProps> = ({ selectedPlatform }) => {
  const [userRole, setUserRole] = useState<string>("");

  useEffect(() => {
    const role: string | null = localStorage.getItem("role");
    if (role) {
      setUserRole(role);
    }
  }, [userRole]);

  const [isActive, setActiveTab] = useState<boolean>(true);

  const tabs: Tab[] = [
    {
      id: "tab1",
      active: true,
      content: (
        <TableItem
          selectedPlatform={selectedPlatform}
          setSelectedPlatform={function (value: React.SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          }}
        />
      )
    },
    { id: "tab2", active: false, content: <NewlyAdded /> }
  ];

  return (
    <div>
      <Box
        className="switch-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline"
        }}
      >
        <Box
          className="label1"
          my={0}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            marginLeft: "0px"
          }}
        >
          Projects
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          Project Status:
          <Box mx={1}>
            <ToggleButtonGroup
              button1="Active"
              button2="New"
              active={isActive}
              setActive={setActiveTab}
            />
          </Box>
          {<Onboard setActiveTab={setActiveTab} />}
        </Box>
      </Box>
      <div className="tableAllContainer">
        <div className="dash-br-15">{tabs.find((tab) => tab.active === isActive)?.content}</div>
      </div>
    </div>
  );
};

export default TableSwitcher;

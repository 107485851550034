import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { LOCK_GA } from "./paths";

const regenKey = async (projectId: number | string) => {
  return await axiosClient({
    method: "put",
    url: LOCK_GA + projectId.toString()
  });
};

export const useGALock = (onLockSuccess: onSuccess) => {
  return useMutation((projectId: number | string) => regenKey(projectId), {
    onSuccess: (data) => {
      onLockSuccess(data);
    },
    onError: (error) => {
      console.error("Mutation failed - GA Lock", error);
    }
  });
};

export type onSuccess = (data: AxiosResponse<any, any>) => unknown;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProjectSummaryData } from "../../api/useProjectSummaryData";
import { Webpage, useProjectWebPagesData } from "../../api/useProjectWebPages";
import { PageSectionHeader } from "./Page Section/PageSectionHeader";
import { ProjectSummaryHeader } from "./Project Section/ProjectSummaryHeader";
import { PageSectionBody } from "./Page Section/Page Section Body/PageSectionBody";
import "./index.scss";
import { Box, Divider, Typography } from "@mui/material";
import { PlatformSummaryCardsContainer } from "./Platform Section/PlatformSummaryBody";
import { PlatformSectionHeader } from "./Platform Section/PlatformSectionHeader";
import dayjs, { Dayjs } from "dayjs";
import { usePageOverallCEWithGraph } from "../../api/usePageOverallCEWithGraph";
import RightArrow from "../../components/Svg/RightArrow";
import { useIsFetching } from "@tanstack/react-query";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
// import { ContainerList, useContainerList } from "../../api/useContainerList";

export const CIWrapper = () => {
  const isFetching = useIsFetching();
  const { projectId, platform, webpageId, "*": jobId } = useParams();
  sessionStorage.setItem("webpageId", webpageId!);
  const { data: webpages, isSuccess } = useProjectWebPagesData(projectId!, true);
  const { data: projectSummaryData } = useProjectSummaryData(projectId!);
  const [defaultJobId, setJobId] = useState<number | undefined>(Number(jobId));
  const [perVisitView, setPerVisitView] = useState(!!jobId);
  const currentDate = dayjs();
  const [startDate, setStartDate] = useState<Dayjs | null>(currentDate.startOf("month"));
  const [endDate, setEndDate] = useState<Dayjs | null>(currentDate.endOf("month"));
  // const { data: containerList } = useContainerList(projectId!);
  const [entityData, setEntityData] = useState<
    | Webpage[]
    // | ContainerList[]
    | undefined
  >([]);
  const [isStatic, setStaticTab] = useState<boolean>(true);
  const [currentEntity, setCurrentEntity] = useState<
    | Webpage
    // | ContainerList
    | undefined
  >(undefined);
  const {
    data: pageOverallCEWithGraph,
    refetch,
    isLoading
  } = usePageOverallCEWithGraph(
    platform!,
    isStatic,
    startDate?.format("YYYY-MM-DD") ?? "",
    endDate?.format("YYYY-MM-DD") ?? ""
  );

  useEffect(() => {
    if (platform === "client") {
      setEntityData(webpages);
      setCurrentEntity(webpages?.find((page) => page.id === +webpageId!));
      // } else if (platform === "server") {
      //   setEntityData(containerList);
      //   setCurrentEntity(containerList?.find((c) => c.id === +webpageId!));
    }
  }, [
    webpages,
    // containerList,
    entityData,
    currentEntity,
    platform
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const nav = useNavigate();

  useEffect(() => {
    refetch();
  }, [startDate, endDate, platform]);
  return (
    <>
      {isFetching ? <CustomLoader /> : null}
      {isSuccess && (
        <Box px={2}>
          <div className="TopDiv">
            <Typography className="breadcrumbs-typography">
              <span
                className="breadcrumbs-link"
                onClick={() => {
                  if (defaultJobId !== undefined) {
                    nav(`../../../../../`, {
                      replace: false,
                      relative: "path"
                    });
                  } else {
                    nav(`../../../../`, {
                      replace: false,
                      relative: "path"
                    });
                  }
                }}
              >
                Dashboard
              </span>
              {"  "}
              <RightArrow /> Project <Divider
                className="divider-vertical"
                orientation="vertical"
              />{" "}
              {projectSummaryData?.name}
            </Typography>
            <ProjectSummaryHeader
              projectId={projectId}
              webpageId={webpageId}
              setJobId={setJobId}
              setPerVisitView={setPerVisitView}
              pageOverallCEWithGraph={pageOverallCEWithGraph}
              isStatic={isStatic}
            />
            <Divider />
            <PlatformSectionHeader
              projectId={projectId}
              projectSummaryData={projectSummaryData}
              selectedPlatform={platform!}
              setPerVisitView={setPerVisitView}
              setStatic={setStaticTab}
              isStatic={isStatic}
            />
            <PlatformSummaryCardsContainer
              projectSummaryData={projectSummaryData}
              selectedPlatform={platform!}
              isStatic={isStatic}
            />
            <>
              <PageSectionHeader
                projectId={projectId}
                webpageId={webpageId}
                entityData={entityData}
                currentEntity={currentEntity}
                setCurrentEntity={setCurrentEntity}
                projectSummaryData={projectSummaryData}
                jobId={defaultJobId}
                setJobId={setJobId}
                setPerVisitView={setPerVisitView}
                selectedPlatform={platform}
                isStatic={isStatic}
              />
              <PageSectionBody
                webpageId={webpageId}
                projectId={projectId}
                jobId={defaultJobId}
                setJobId={setJobId}
                perVisitView={perVisitView}
                setPerVisitView={setPerVisitView}
                selectedPlatform={platform}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                pageOverallCEWithGraph={pageOverallCEWithGraph}
                isLoading={isLoading}
                isStatic={isStatic}
              />
            </>
          </div>
        </Box>
      )}
    </>
  );
};

export interface seriesItem {
  name: string;
  data: Array<{
    x: Date;
    y: number;
    id: any;
    cc: number;
    size: number;
  }>;
}

import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { POST_CHANGE_APP_ROLE } from "./paths";

const changeAppliRole = async ({ id, role }: Userdata) => {
  return await axiosClient.post(`${POST_CHANGE_APP_ROLE}/${id}/${role}`);
};

interface Userdata {
  id: number;
  role: string;
}

export const usechangeAppRole = () => {
  return useMutation({
    mutationFn: changeAppliRole
  });
};

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: any,
  context: unknown
) => unknown;

import { Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import "./index.scss";
import * as React from "react";

export const Filter = ({
  categoryValue,
  setCategoryValue,
  filterValue,
  setFilter
}: {
  categoryValue: number;
  setCategoryValue: React.Dispatch<React.SetStateAction<number>>;
  filterValue: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}) => {
  // const category = [
  //   { id: 0, label: "All Projects", approval: "all", isSelected: true },
  //   { id: 1, label: "Pending Projects", approval: "null", isSelected: false },
  //   { id: 2, label: "Approved Projects", approval: "true", isSelected: false },
  //   { id: 3, label: "Rejected Projects", approval: "false", isSelected: false }
  // ];
  const category = [
    { id: 0, label: "All Projects" },
    { id: 1, label: "Pending Projects" },
    { id: 2, label: "Approved Projects" },
    { id: 3, label: "Rejected Projects" }
  ];
  const filterCategory = (cat: any) => {
    setCategoryValue(cat.id);
    // category.map((data) => {
    //   if (data.label === cat.label) {
    //     data.isSelected = true;
    //   } else {
    //     data.isSelected = false;
    //   }
    //   return data;
    // });
    // setCategoryType(cat.id);
  };

  return (
    <div className="filter">
      <Typography sx={{ mb: 2 }} className="title">
        Filters
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ mb: 1 }}>Sort By</Typography>
        <select
          className="dropdown"
          name="sort"
          id="sort"
          value={filterValue}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="Created Date" className="options">
            <Typography className="optionstext">Created Date</Typography>
          </option>
          <option value="Project Name" className="options">
            <Typography className="optionstext">Project Name</Typography>
          </option>
          <option value="Deputy Email" className="options">
            <Typography className="optionstext">Deputy Name</Typography>
          </option>
        </select>
      </Box>
      <Box>
        <Typography sx={{ mb: 1 }}>Categories</Typography>
        <Grid container>
          {category.map((cat) => (
            <Grid
              onClick={() => {
                filterCategory(cat);
              }}
              key={cat.id}
              item
              className={"categoryFilter " + (cat.id === categoryValue ? "selected" : "")}
            >
              {" "}
              {cat.label}
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

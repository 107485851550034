import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Grid,
  Tooltip,
  Typography,
  Modal
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import New from "../../../components/Svg/New";
import Reject from "../../../components/Svg/Reject";
import { useNewAddedData } from "../../../api/getNewAddedData";
import { useDeleteProjectsById, onSuccess } from "../../../api/useDeleteOnboardProjects";
import ResubmitDialogue from "./ResubmitDailogue";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { ConfirmPopUp } from "../../../components/ConfirmPopup";
import { usecomment } from "../../../api/useViewComment";
import { GeneralPopUp } from "../../../components/GeneralPopup";
import { useSnackbar } from "notistack";

const TableItem = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<number | undefined>();
  const emailId = useSelector((state: RootState) => state.auth.emailId);
  const { data: tableData, refetch: refetchTableData } = useNewAddedData(emailId);
  const [openPopup, setOpenPopup] = useState(false);
  const [id, setId] = useState<number>();
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [commentResponseData, setCommentResponseData] = useState<String>();
  const [commentLoading, setCommentLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { data: commentResponse, isLoading, isSuccess } = usecomment(id);
  const nav = useNavigate();
  // logic to view comment
  useEffect(() => {
    if (isLoading) {
      setCommentLoading(true);
    }
    if (isSuccess) {
      const fetchCommentData = async () => {
        if (id !== undefined) {
          try {
            const commentData = await commentResponse;
            setCommentResponseData(commentData);
          } catch (error) {
            console.error("Error fetching comment data:", error);
          } finally {
            // Set loading state to false when data fetching is done
            setCommentLoading(false);
          }
        }
      };

      fetchCommentData();
    }
  }, [id, isLoading, isSuccess]);

  const openCommentView = (rowId: number) => {
    setId(rowId);
    setOpenCommentModal(true);
  };

  const openConfirmPopUp = (rowId: number) => {
    refetchTableData();
    setOpenPopup(true);
    setId(rowId);
  };

  const onDeleteSuccess: onSuccess = (deletedId: number) => {
    enqueueSnackbar("Project Deleted", { variant: "success", autoHideDuration: 2000 });
    refetchTableData();
  };

  const deleteMutation = useDeleteProjectsById(onDeleteSuccess);

  const onProjectDelete = () => {
    deleteMutation.mutate(id!);
  };

  // resubmit logic
  const openResubmitDialog = (rowId: number) => {
    setSelectedRowId(rowId);
    setOpenDialog(true);
    refetchTableData();
  };

  const getStatus = (approval: boolean | null): string => {
    switch (approval) {
      case null:
        return "The project is awaiting approval.";
      case true:
        return "In order to access the detail screen, you need to configure the project details.";
      case false:
        return "The project has been rejected.";
      default:
        return "";
    }
  };

  const getSymbol = (approval: boolean | null): JSX.Element | null => {
    switch (approval) {
      case true:
        return <New />;
      case false:
        return <Reject />;
      default:
        return null;
    }
  };

  const getAction = (approval: boolean | null, id: number): string[] => {
    switch (approval) {
      case false:
        return ["Resubmit", "Remove", "View Comment"];
      case true:
        return ["Configure"];
      case null:
        return ["Remove"];
      default:
        return [];
    }
  };

  const handleActionClick = (action: string, id: number) => {
    if (action === "Resubmit") {
      openResubmitDialog(id);
    } else if (action === "Remove") {
      // deleteMutation.mutate(id);
      openConfirmPopUp(id);
    } else if (action === "Configure") {
      sessionStorage.setItem("webpageId", "-1");
      nav(`/project-settings/${id}`);
    } else if (action === "View Comment") {
      openCommentView(id);
    }
  };
  // Sort the tableData based on the timestamp field in descending order
  const sortedTableData = tableData
    ? [...tableData].sort(
        (a, b) => new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime()
      )
    : [];

  return (
    <>
      <Card className="dash-br-15">
        <TableContainer component={Paper}>
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell className="head">Project</TableCell>
                <TableCell className="head">Status</TableCell>
                <TableCell className="head">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTableData?.map((row) => (
                <TableRow className="row" key={row.id} style={{ height: "50px" }}>
                  <TableCell className="cell-design1">
                    <Grid container spacing={1} alignItems="stretch" flexWrap="nowrap">
                      <Grid item sx={{ ml: 0.7 }}>
                        <Tooltip title={row.name}>
                          <Typography sx={{ cursor: "pointer" }}>{row.abbreviation}</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item sx={{ m: 0.3 }}>
                        {getSymbol(row.approval)}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ ml: 1.07 }} className="cell-design">
                    {getStatus(row.approval)}
                  </TableCell>
                  <TableCell sx={{ ml: 1.1 }} className="cell-design-n">
                    {getAction(row.approval, row.id).map((action, index) => (
                      <span
                        key={index}
                        className={
                          action === "Resubmit" || action === "Configure"
                            ? "green-action"
                            : "normal-action"
                        }
                        onClick={() => handleActionClick(action, row.id)}
                      >
                        {action}
                      </span>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {selectedRowId !== undefined && (
        <ResubmitDialogue
          openModal={openDialog}
          selectedRowId={selectedRowId}
          onClose={() => {
            setOpenDialog(false);
            refetchTableData();
          }}
          onResubmit={function (): void {
            throw new Error("Function not implemented.");
          }}
          setActiveTab={function (value: React.SetStateAction<string>): void {
            throw new Error("Function not implemented.");
          }}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCommentModal}
        onClose={() => {
          setOpenCommentModal(false);
          setCommentResponseData("");
          setId(undefined);
        }}
      >
        <div>
          <GeneralPopUp
            onClose={() => {
              setOpenCommentModal(false);
              setCommentResponseData("");
              setId(undefined);
            }}
            onSave={onProjectDelete}
            data={commentResponseData ?? "No comments available."}
            loading={commentLoading}
            key="1"
          />
        </div>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
          refetchTableData();
        }}
      >
        <div>
          <ConfirmPopUp
            onClose={() => {
              setOpenPopup(false);
              refetchTableData();
            }}
            onSave={onProjectDelete}
            data="Do you want to delete?"
            key="1"
          />
        </div>
      </Modal>
    </>
  );
};

export default TableItem;

import { Box, Grid } from "@mui/material";
import { RelativeAuditWithMetricType } from "../../../api/useSuggestionsData";
import PerformanceTable, { HeadCell } from "./Table";

interface DiagnosticsProps {
  activeMetric: string | null;
  diagnostics: RelativeAuditWithMetricType[];
  deferredsearchValue: string;
}
export const Diagnostics = ({
  activeMetric,
  diagnostics,
  deferredsearchValue
}: DiagnosticsProps) => {
  return (
    <Box>
      <Grid container justifyContent="flex-end">
        {/* <Button
          variant="outlined"
          startIcon={<FilterAltOutlinedIcon color="primary" />}
          sx={{ color: "gray", textTransform: "capitalize" }}
        >
          Filter
        </Button> */}
      </Grid>
      <Box>
        <PerformanceTable
          activeMetric={activeMetric}
          data={diagnostics}
          searchValue={deferredsearchValue}
          headCells={diagnosticsHeadCells}
        />
      </Box>
    </Box>
  );
};

const diagnosticsHeadCells: HeadCell[] = [
  {
    id: "relAudScore",
    numeric: false,
    label: "Priority"
  },
  {
    id: "relAudTitle",
    numeric: false,
    label: "Audits"
  }
  // {
  //   id: "estimatedSavings",
  //   numeric: true,
  //   label: "Estimated Savings"
  // }
  // {
  //   id: "relAudExapnd",
  //   numeric: false,
  //   label: "Actions"
  // }
];

import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { PUT_WEBPAGE } from "./paths";

const deleteWebPage = async (webPage: IWebPage) => {
  return await axiosClient({
    method: "delete",
    url: PUT_WEBPAGE + (webPage.id !== undefined ? webPage.id.toString() : ""),
    data: webPage
  });
};

export const deleteWebpageById = (onUpdateSuccess: any) => {
  return useMutation({
    mutationFn: deleteWebPage,
    onSuccess: onUpdateSuccess
  });
};

export interface IWebPage {
  name: any;
  id?: number | undefined;
  projectId?: number | undefined;
  url?: string | undefined;
  version?: string | undefined;
}

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: string,
  context: unknown
) => unknown;

export default function Chart() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 18.3335H17.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.66665 6.9834H3.33333C2.875 6.9834 2.5 7.3584 2.5 7.81673V15.0001C2.5 15.4584 2.875 15.8334 3.33333 15.8334H4.66665C5.12498 15.8334 5.49998 15.4584 5.49998 15.0001V7.81673C5.49998 7.3584 5.12498 6.9834 4.66665 6.9834Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6666 4.3252H9.33333C8.875 4.3252 8.5 4.7002 8.5 5.15853V15.0002C8.5 15.4585 8.875 15.8335 9.33333 15.8335H10.6666C11.125 15.8335 11.5 15.4585 11.5 15.0002V5.15853C11.5 4.7002 11.125 4.3252 10.6666 4.3252Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6666 1.6665H15.3333C14.875 1.6665 14.5 2.0415 14.5 2.49984V14.9998C14.5 15.4582 14.875 15.8332 15.3333 15.8332H16.6666C17.125 15.8332 17.5 15.4582 17.5 14.9998V2.49984C17.5 2.0415 17.125 1.6665 16.6666 1.6665Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { Link, useLocation } from "react-router-dom";
import Logo from "../../../assets/svg/newlogo.svg";

import "./AppLogo.scss";

export const AppLogo = () => {
  const { pathname } = useLocation();
  const disableHome =
    pathname.includes("login") ||
    pathname.includes("signup") ||
    pathname.includes("verify") ||
    pathname.includes("forgot-password") ||
    pathname.includes("paswordReset");

  return (
    <Link to="/" style={{ textDecoration: "none", pointerEvents: disableHome ? "none" : "auto" }}>
      <img src={Logo} alt="Logo" className="AppLogo Care-IT Logo" />
    </Link>
  );
};

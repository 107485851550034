import React, { useEffect, useState } from "react";
import { Typography, Grid, Modal, Divider, TextField } from "@mui/material";
import { SearchBox } from "../../components/SearchBox";
import { Filter } from "./filter";
import { ProjectList } from "./projectList";
import "./index.scss";
import Box from "@mui/material/Box";
import { useProjectsDataAdmin } from "../../api/useAdminData";
import UsersMain from "../Users";
import Invite from "../../components/Svg/Invite";
import Close from "../../components/Svg/Close";
import { useSnackbar } from "notistack";
import { useInviteUser } from "../../api/useInviteUser";
import Success from "../../components/Svg/Success";
import CustomButton from "../../components/CustomButton/CustomButton.tsx";
import { useIsFetching } from "@tanstack/react-query";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
export const Admin = () => {
  const isFetching = useIsFetching();
  const { data: projectData, isSuccess, isError } = useProjectsDataAdmin();
  const [selectedTab, setSelectedTab] = useState("Projects");
  const [showSuccess, setShowSuccess] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");

  useEffect(() => {
    if (isError) {
      return <p>Error message</p>;
    }

    if (isSuccess) {
      // setcheckUser(false);
      const rowsData = projectData?.map((page) => {
        const date = new Date(page.createdDate);
        const obj = {
          accessKey: page.accessKey,
          appId: page.projectsInfra?.appId,
          appType: page.projectsInfra?.appType,
          approval: page.approval,
          cc: page.cc,
          ce: page.ce,
          comment: page.comment,
          createdDate: page.createdDate,
          currentVersion: page.currentVersion,
          deputyId: page.projectsInfra?.deputyId,
          description: page.projectDetails?.description,
          departmentName: page.departmentName,
          environment: page.projectsInfra?.environment,
          hostingType: page.projectsInfra?.hostingType,
          id: page.id,
          image: page.image,
          name: page.name,
          networkType: page.projectsInfra?.networkType,
          pageCount: page.pageCount,
          pages: page.pages,
          powerUser: page.projectsInfra?.powerUser,
          projectAbbr: page.projectAbbr,
          projects: page.projects,
          redirectPage: page.redirectPage,
          role: page.role,
          suggestions: page.suggestions,
          teamId: page.teamId,
          threshold: page.threshold,
          updatedDate: page.updatedDate,
          displayCreatedDate: new Intl.DateTimeFormat("en-UK", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",

            separator: "-"
          }).format(date)
        };
        return obj;
      });
      updateRows(rowsData);
    }
  }, [projectData]);

  const [rows, updateRows] = useState([]);
  const [categoryValue, setCategoryValue] = useState(0);
  const [filterValue, setFilter] = useState("Created Date");
  const [searchValue, setSearchValue] = useState("");
  const [userSearchValue, setUserSearchValue] = useState("");
  const [valueradio, setValueradio] = React.useState("role");
  const [filters, setFilters] = useState({
    role: "All",
    status: "All",
    prjct: "All"
  });
  const onAdminAction = (data) => {
    const rowsData = rows?.map((page) => {
      if (page.id === data.id) {
        page = data;
      }
      return { ...page };
    });
    updateRows(rowsData);
  };
  const [open, setOpen] = React.useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };
  const { enqueueSnackbar } = useSnackbar();

  const onInviteSuccess = (data) => {
    setShowSuccess(true);
  };

  const onInviteError = (error) => {
    if (error.response && error.response.status === 406) {
      callSnackBar("User Already Exists ", "error");
    }
  };
  const handleUserSearch = (event) => {
    setUserSearchValue(event.target.value);
  };
  const handleProjectSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const { mutate: inviteUser } = useInviteUser(onInviteSuccess, onInviteError);

  const callSnackBar = (message, type) => {
    enqueueSnackbar(message, { variant: type, autoHideDuration: 2000 });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const regex = /^[A-Z0-9._%+-]+@mercedes-benz+\.[A-Z]{2,}$/i;
    const formData = new FormData(event.currentTarget);
    const emailId = formData.get("emailId")?.toString();
    const role = formData.role;
    const result = regex.test(emailId);
    if (!emailId) return;
    if (result) {
      inviteUser({ emailId, role });
      setInviteEmail(emailId);
    } else if (!result) callSnackBar("Enter a valid Email-ID", "error");
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowSuccess(false);
    setInviteEmail("");
  };

  return (
    <>
      {isFetching ? <CustomLoader /> : null}
      {isSuccess && (
        <div className="adminProjects">
          <div className="header">
            <div className="tab">
              <Typography
                className={selectedTab === "Projects" ? "active" : "inactive"}
                onClick={() => setSelectedTab("Projects")}
              >
                Projects
              </Typography>
              <Typography
                className={selectedTab === "Users" ? "active" : "inactive"}
                onClick={() => setSelectedTab("Users")}
                sx={{ paddingLeft: 0.5 }}
              >
                Users
              </Typography>
            </div>

            <div className="invite-and-search-div">
              {selectedTab === "Users" ? (
                <div className="invite-user-container">
                  <CustomButton
                    variant="outlined"
                    className="settings-button"
                    startIcon={<Invite fontSize="small" className="settings-icon" />}
                    onClick={handleModalOpen}
                  >
                    Invite User
                  </CustomButton>
                </div>
              ) : null}
              <div className="search-box">
                {selectedTab === "Projects" ? (
                  <SearchBox
                    value={searchValue}
                    handleSearch={handleProjectSearch}
                    placeholder="Search"
                    className="search-user"
                  ></SearchBox>
                ) : (
                  <SearchBox
                    value={userSearchValue}
                    handleSearch={handleUserSearch}
                    placeholder="Search"
                    className="search-user"
                  />
                )}
              </div>
            </div>
          </div>
          {selectedTab === "Projects" && rows != null && (
            <Box sx={{ width: "100%" }}>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={3}>
                  <Box className="container" sx={{ mr: 1, height: "100%" }}>
                    <Filter
                      categoryValue={categoryValue}
                      setCategoryValue={setCategoryValue}
                      filterValue={filterValue}
                      setFilter={setFilter}
                    ></Filter>
                  </Box>
                </Grid>

                <Grid item xs={9}>
                  <Box className="container" sx={{ ml: 1 }}>
                    <ProjectList
                      categoryValue={categoryValue}
                      sortBy={filterValue}
                      searchValue={searchValue}
                      data={rows}
                      onAdminAction={onAdminAction}
                    ></ProjectList>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {selectedTab === "Users" && rows != null && (
            <UsersMain
              userSearchValue={userSearchValue}
              filters={filters}
              setFilters={setFilters}
              valueradio={valueradio}
              setValueradio={setValueradio}
            />
          )}
          {
            <Modal
              open={open}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              {showSuccess ? (
                <Grid className="modal-container">
                  <Grid className="modal-header">
                    <Box></Box>
                    <Box onClick={handleModalClose} className="close-container">
                      <Close />
                    </Box>
                  </Grid>
                  <Grid>
                    <Box display="flex" justifyContent="center" my={1}>
                      <Success />
                    </Box>
                    <Typography display="flex" justifyContent="center" className="success-title">
                      Successful
                    </Typography>
                    <Typography display="flex" justifyContent="center" className="success-messgae">
                      {`Invitation sent to "${inviteEmail}" successfully`}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid className="modal-container">
                  <Grid className="modal-header">
                    <Box>Invite User</Box>
                    <Box onClick={handleModalClose} className="close-container">
                      <Close />
                    </Box>
                  </Grid>
                  <Grid>
                    <Divider />
                  </Grid>
                  <Grid>
                    <Box className="form-container" component="form" onSubmit={handleSubmit}>
                      <Box className="form-items">
                        <Typography className="text-field-header ">Email</Typography>

                        <TextField
                          id="outlined-required"
                          size="small"
                          placeholder="Enter Email ID"
                          required
                          className="text-field"
                          name="emailId"
                          autoFocus
                          type="email"
                        />
                      </Box>

                      <Box my={1.5}>
                        <Divider />
                      </Box>
                      <Box display="flex" justifyContent="center">
                        <CustomButton type="submit" className="send-button">
                          Send invite
                        </CustomButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Modal>
          }
        </div>
      )}
    </>
  );
};

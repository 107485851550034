import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { POST_ONBOARD_DATA, GET_DATA } from "./paths";
import { Dayjs } from "dayjs";

export interface DetailsBase {
  name: string;
  projectAbbr: string;
  currentVersion: string;
  image: string | null;
  devType: string;

  department: {
    name: string;
  };
  projectMonitoring: {
    isToolAvailable: boolean;
    server: {
      monitoringType: string;
      domainUrl: string;
    };
    client: {
      monitoringType: string;
      domainUrl: string;
    };
  };
  projectsInfra: {
    appId: string;
    appType: string;
    networkType: string;
    hostingType: string;
    environment: string;
    powerUser: string;
    deputyId: string;
    powerUserId: number;
    deputyUserId: number;
  };
  projectDetails: {
    description: string;
    appInfoClassification: string;
    techStack: string;
    endUserType: string;
    plannedStartDate: Dayjs | null;
    plannedEndDate: Dayjs | null;
  };
}
export interface Details2 extends DetailsBase {
  id: number;
}
// Interface without extra property
export interface Details extends DetailsBase {}

export const createOnboard = async (Data: Details) => {
  return await axiosClient({
    method: "post",
    url: POST_ONBOARD_DATA,
    data: Data
  });
};

const getData = async (id: number) => {
  return await axiosClient.get<Details2>(`${GET_DATA}${id}`).then((res) => res.data);
};
export const useDataById = (id: number) => {
  return useQuery({
    queryKey: ["projects-data-by-id", id],
    queryFn: () => getData(id)
  });
};
export const updateOnboard = async (data: Details2) => {
  // Update existing data using PUT
  return await axiosClient.put(`${POST_ONBOARD_DATA}/${data.id}`, data);
};

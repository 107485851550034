import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { AxiosResponse } from "axios";
import { GET_USERS } from "./paths";

const getUsers = async () => {
  return await axiosClient.get<IUsers[]>(GET_USERS).then((res) => res.data);
};

export const useUsersData = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: getUsers
  });
};

export interface IUsers {
  id: number;
  name: string;
  createdDate: string;
  emailId: string;
  password: string;
  expiryDate: string;
}

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: IUsers,
  context: unknown
) => unknown;

import { Box, Modal } from "@mui/material";
import React from "react";
import ClientRTConfigForm from "../ClientRTConfigForm";
import { IClientRTConfig } from "../../../api/useClientRTConfig";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../components/CustomButton/CustomButton";

interface EditConfigurationProps {
  projID: number;
  monitoringPlatformId: number;
  showSteps: boolean;
  ConfigDetails: IClientRTConfig;
  openEditConfig: boolean;
  handleModalClose: () => void;
}

const EditConfiguration: React.FC<EditConfigurationProps> = ({
  projID,
  monitoringPlatformId,
  ConfigDetails,
  openEditConfig,
  handleModalClose
}) => {
  return (
    <Modal
      open={openEditConfig}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="editConfig-modal">
        <CustomButton className="close-button" onClick={handleModalClose}>
          <CloseIcon />
        </CustomButton>
        <ClientRTConfigForm
          projID={projID}
          monitoringPlatformId={monitoringPlatformId}
          showSteps={false}
          isEditMode={true}
          ConfigDetails={ConfigDetails}
          handleModalClose={handleModalClose}
        />
      </Box>
    </Modal>
  );
};

export default EditConfiguration;

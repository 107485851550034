import { Card, CardContent, Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { OverallCEWithGraph } from "../../../../api/usePageOverallCEWithGraph";
import { CarbonEmissionCardBody } from "./Detailed/Body/CarbonEmissionCardBody";
import { CarbonEmissionCardHeader } from "./Detailed/Header/CarbonEmissionCardHeader";
import { PageSummarySection } from "./Summary/PageSummarySection";
import { PerVisitCard } from "./Detailed/PerVisitCard";
import isKeyPresentAndNotNull from "../../../../utils/common";
dayjs.extend(isBetweenPlugin);
dayjs.extend(quarterOfYear);

export interface ColorCounts {
  Red: number;
  Amber: number;
  Green: number;
  Failed: number;
}
interface PagesSectionBodyProps {
  webpageId: string | undefined;
  projectId: string | undefined;
  jobId: number | undefined;
  setJobId: React.Dispatch<React.SetStateAction<number | undefined>>;
  perVisitView: boolean;
  setPerVisitView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPlatform: string | undefined;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  pageOverallCEWithGraph: OverallCEWithGraph;
  isLoading: boolean;
  isStatic: boolean;
}

export const PageSectionBody = ({
  projectId,
  webpageId,
  jobId,
  setJobId,
  perVisitView,
  setPerVisitView,
  selectedPlatform,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  pageOverallCEWithGraph,
  isLoading,
  isStatic
}: PagesSectionBodyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [chartView, setChartView] = useState(true);

  const [visits] = useState(1);
  const [colorTab, setColorTab] = useState(0);

  const colorCounts: ColorCounts = {
    Red: 0,
    Amber: 0,
    Green: 0,
    Failed: 0
  };

  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>({
    label: "This Month",
    value: "month"
  });

  useEffect(() => {
    if ((selectedPlatform === "client" && !isStatic) || selectedPlatform === "server") {
      setSelectedOption({ label: "Last 7 days", value: "last-week" });
      setStartDate(dayjs().subtract(6, "day").startOf("day"));
      setEndDate(dayjs().endOf("day"));
    } else {
      setSelectedOption({ label: "This Month", value: "month" });
      setStartDate(dayjs().startOf("month"));
      setEndDate(dayjs().endOf("month"));
    }
  }, [projectId, isStatic, selectedPlatform]);

  const series = [
    {
      name: "",
      data:
        pageOverallCEWithGraph?.graphData?.map((itm: any) => {
          return {
            x: new Date(itm.createdDate),
            y: itm.ce * visits,
            id: itm.jobId,
            cc: itm.cc * visits,
            size: itm.size * visits
          };
        }) ?? []
    }
  ];
  pageOverallCEWithGraph?.graphData?.forEach((job) => {
    switch (job.thresholdColour) {
      case "Red":
        colorCounts.Red++;
        break;
      case "Amber":
        colorCounts.Amber++;
        break;
      case "Green":
        colorCounts.Green++;
        break;
      case "Grey":
        colorCounts.Failed++;
    }
  });

  return (
    <Grid item>
      <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
        <PageSummarySection
          pageOverallCEWithGraph={pageOverallCEWithGraph}
          selectedPlatform={selectedPlatform}
          isStatic={isStatic}
        />
        <>
          <Grid item xs={9} style={{ position: "relative" }}>
            <Card className="container" style={{ borderRadius: 15 }}>
              <CardContent style={{ padding: "0" }}>
                <CarbonEmissionCardHeader
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setChartView={setChartView}
                  chartView={chartView}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  colorCounts={colorCounts}
                  colorTab={colorTab}
                  setColorTab={setColorTab}
                  isStatic={isStatic}
                  selectedPlatform={selectedPlatform}
                  isCEGraphDataPresent={
                    pageOverallCEWithGraph
                      ? isKeyPresentAndNotNull(pageOverallCEWithGraph, "graphData")
                      : false
                  }
                />
                <Divider />
                <CarbonEmissionCardBody
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  filteredData={pageOverallCEWithGraph?.graphData ?? []}
                  isLoading={isLoading}
                  jobId={jobId}
                  setJobId={setJobId}
                  selectedOption={selectedOption}
                  startDate={startDate}
                  endDate={endDate}
                  series={series}
                  chartView={chartView}
                  setPerVisitView={setPerVisitView}
                  colorTab={colorTab}
                  selectedPlatform={selectedPlatform}
                />
              </CardContent>
            </Card>

            <Card
              className="per-visit-card"
              style={{
                left: perVisitView ? "unset" : "100%"
              }}
            >
              <PerVisitCard
                webpageId={webpageId}
                setPerVisitView={setPerVisitView}
                setJobId={setJobId}
                defaultJobId={jobId}
                selectedPlatform={selectedPlatform}
              />
            </Card>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};
export interface DropdownOption {
  label: string;
  value: string;
}

import { Grid, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import * as React from "react";
import "./index.scss";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getFirstWebpageId } from "../../../api/getFirstWebpage";
import { AllplatformPagesJobs } from "../../../api/useLatestJobsAndPagesAllPlatform";
import EmissionSideChart from "./EmissionSideChart";
import { getFirstContainerId } from "../../../api/getFirstContainer";
import ActionIcon from "../../../assets/svg/ActionIcon.svg";
interface ProjectItemProps {
  project: AllplatformPagesJobs;
}

const ProjectItemAllplatform = ({ project }: ProjectItemProps) => {
  const latestJob = project.lastNJobs?.at(project.lastNJobs.length - 1);
  const nav = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const redirectPlatform = (project: AllplatformPagesJobs) => {
    const platformArray = [
      { name: "client", value: project.clientCE || 0 },
      { name: "server", value: project.serverCE || 0 }
    ];
    const maxPlatform = platformArray.reduce(
      (max, platform) => (platform.value > max.value ? platform : max),
      platformArray[0]
    );
    return maxPlatform.name;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navToProject = async (projectId: number) => {
    try {
      const platform = redirectPlatform(project);
      if (platform === "client") {
        const [error, pageId] = await getFirstWebpageId(projectId);
        if (error) throw error;
        if (pageId === null) {
          enqueueSnackbar("No Web pages Available", {
            variant: "warning",
            autoHideDuration: 2000
          });
          return;
        }
        nav(`/dashboard/carbon/${projectId}/${platform}/${pageId}`);
      } else if (platform === "server") {
        const [containerError, containerId] = await getFirstContainerId(projectId);
        if (containerError) throw containerError;
        if (containerId === null) {
          enqueueSnackbar("No Container Available", {
            variant: "warning",
            autoHideDuration: 2000
          });
          return;
        }
        nav(`/dashboard/carbon/${projectId}/${platform}/${containerId}`);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "warning",
        autoHideDuration: 2000
      });
    }
  };

  const getStatus = (thresholdColour: string | undefined) => {
    // Add logic to map color classes to corresponding names
    switch (thresholdColour) {
      case "Red":
        return "Exceeded";
      case "Amber":
        return "Needs Attention";
      case "Green":
        return "On Track";
      default:
        return "Failed";
    }
  };

  return (
    <TableRow
      className="row dash-table-row"
      key={project.id}
      onClick={() => {
        navToProject(project.id);
      }}
    >
      <TableCell className="cell-design1">
        <Grid className="symbol" pl={1}>
          <div className={latestJob?.thresholdColour}></div>
        </Grid>
      </TableCell>
      <TableCell className="cell-design prj-name ellipsis">
        <Tooltip title={project.name} placement="left">
          <span className="prj-name-p">{project.abbr}</span>
        </Tooltip>
      </TableCell>
      <TableCell className="cell-design">
        {latestJob
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric"
            }).format(new Date(latestJob.createdDate))
          : ""}
      </TableCell>

      <TableCell className="cell-design" key={project.ceSum}>
        {` ${
          project.ceSum !== -1
            ? project.ceSum.toFixed(2) !== "N/A"
              ? `${project.ceSum.toFixed(2)} g`
              : "N/A"
            : "-"
        }`}
      </TableCell>

      <TableCell className="cell-design" key={project.abbr}>
        {` ${
          project.ccSum !== -1
            ? project.ccSum.toFixed(2) !== "N/A"
              ? `${project.ccSum.toFixed(2)} €`
              : "N/A"
            : "-"
        }`}
      </TableCell>

      <TableCell className="cell-design">{getStatus(latestJob?.thresholdColour)}</TableCell>

      <TableCell className="cell-design-n eb" component="th" align="left">
        <IconButton
          style={{ color: "white" }}
          size="small"
          onClick={(event) => {
            event.stopPropagation(); // Stop event propagation(to stop navToProject from being called)
            setOpen(true);
          }}
        >
          <img src={ActionIcon} />
        </IconButton>
        {open && (
          <div className="drawerContainer">
            <EmissionSideChart
              project={project}
              onClose={handleClose}
              latestJob={latestJob}
              platform="All"
            />
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ProjectItemAllplatform;

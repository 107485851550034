import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { BestPractices } from "./BestPractices/BestPractices";
import { Accessibility } from "./Accessibility/Accessibility";
import { Performance } from "./Performance/Performance";
import { MainTabBar } from "./TabBar/MainTabBar";
import { useNavigate, useParams } from "react-router-dom";
import { RelAudType, useSuggestionsData } from "../../api/useSuggestionsData";
import { useProjectData } from "../../api/useProjectData";
import { PoweredByLH } from "../../components/Powered by LH";
import PageDropdown from "../../components/PageDropdown/PageDropdown";
import { useProjectWebPagesData } from "../../api/useProjectWebPages";
import "./index.scss";
import RightArrow from "../../components/Svg/RightArrow";
import { useIsFetching } from "@tanstack/react-query";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

export const Suggestions = () => {
  const isFetching = useIsFetching();
  const { projectId, webpageId } = useParams();
  const [value, setValue] = React.useState(0);
  const { data: webpages } = useProjectWebPagesData(projectId!, true);
  const nav = useNavigate();

  const { data: projectData } = useProjectData(+projectId!);

  const {
    data: performaceSuggestionData,
    isError,
    error
  } = useSuggestionsData(+webpageId!, "Performance");

  const {
    data: accessibilitySuggestionData,
    isError: isError2,
    error: error2
  } = useSuggestionsData(+webpageId!, "Accessibility");

  const {
    data: bestPracticesSuggestionData,
    isError: isError3,
    error: error3
  } = useSuggestionsData(+webpageId!, "Best Practices");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isError || isError2 || isError3) {
    return (
      <Grid container justifyContent="center" mt={5}>
        <Typography color="red">Suggestions not found</Typography>
      </Grid>
    );
  }
  function goBack() {
    nav(`/dashboard/carbon/${projectId ?? ""}/client/${webpageId ?? ""}`, {
      replace: true,
      relative: "path"
    });
  }

  if (isError) {
    console.log(error, error2, error3);
    return <p>Error message</p>;
  }

  const performanceMetricsData =
    performaceSuggestionData?.category?.auditGroups.filter((data) => {
      return data.auditGroup === "metrics";
    }) ?? [];

  const allRelAuditsPerformance =
    performanceMetricsData[0]?.audits
      .filter(
        (metric) => !(metric.audit === "Time to Interactive" || metric.audit === "Speed Index")
      )
      .map((metric) =>
        metric.relativeAudits.map((ra) => {
          return { ...ra, metricType: metric.audit };
        })
      )
      .flat(1)
      .filter((itm) => !!itm.relAudType) ?? [];
  const accessibilityMetricsData =
    accessibilitySuggestionData?.category?.auditGroups
      .map((ag) => {
        return {
          audit: ag.auditGroup,
          auditScore: null,
          auditDescription: "",
          displayValue: "",
          numericUnit: null,
          numericValue: null,
          relativeAudits: []
        };
      })
      .flat(1) ?? [];

  const allRelAuditsAccessibility =
    accessibilitySuggestionData?.category?.auditGroups
      .map((ag) =>
        ag.audits.map((a) => {
          return { ...a, metricType: ag.auditGroup };
        })
      )
      .flat(1)
      .map((a, idx) => {
        return {
          ...a,
          id: `${idx}-${a.audit}`,
          relAudTitle: a.audit,
          relAudDescription: a.auditDescription,
          relAudScore: a.auditScore ?? "-1",
          relAudScoreDisMode: null,
          relAudType: RelAudType.Opportunity
        };
      }) ?? [];
  const bestPracticesMetricsData =
    bestPracticesSuggestionData?.category?.auditGroups
      .map((ag) => {
        return {
          audit: ag.auditGroup,
          auditScore: null,
          auditDescription: "",
          displayValue: "",
          numericUnit: null,
          numericValue: null,
          relativeAudits: []
        };
      })
      .flat(1) ?? [];

  const allRelAuditsBestPractices =
    bestPracticesSuggestionData?.category?.auditGroups
      .map((ag) =>
        ag.audits.map((a) => {
          return { ...a, metricType: ag.auditGroup };
        })
      )
      .flat(1)
      .map((a, idx) => {
        return {
          ...a,
          id: `${idx}-${a.audit}`,
          relAudTitle: a.audit,
          relAudDescription: a.auditDescription,
          relAudScore: a.auditScore ?? "-1",
          relAudScoreDisMode: null,
          relAudType: RelAudType.Opportunity
        };
      }) ?? [];
  return (
    <>
      {isFetching ? <CustomLoader /> : null}
      <Box className="TopDiv" px={2}>
        <Typography className="breadcrumbs-typography">
          <span
            className="breadcrumbs-link"
            onClick={() =>
              nav(`../../../../`, {
                replace: false,
                relative: "path"
              })
            }
          >
            Dashboard
          </span>{" "}
          <RightArrow />{" "}
          <span className="breadcrumbs-link" onClick={goBack}>
            Project <Divider className="divider-vertical" orientation="vertical" />{" "}
            {projectData?.name ?? "Project"}
          </span>{" "}
          <RightArrow /> Suggestions
        </Typography>
        <Grid container className="title-container" mb={1}>
          <Grid item>
            <Typography className="title-typography" component="h2" variant="h2">
              {allRelAuditsPerformance.length +
                allRelAuditsAccessibility.length +
                allRelAuditsBestPractices.length}{" "}
              Suggestions
            </Typography>
          </Grid>
          <Grid item mt="3px">
            <PoweredByLH gray />
          </Grid>
          <Grid item ml="auto">
            <Grid container justifyContent="flex-end">
              <Grid item className="page-selection-container">
                <Typography className="page-typography">Page:</Typography>
                <PageDropdown webpages={webpages ?? []} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MainTabBar
          value={value}
          setValue={setValue}
          performanceScore={
            typeof performaceSuggestionData?.category?.categoryScore !== "undefined"
              ? +performaceSuggestionData?.category?.categoryScore
              : 0
          }
          accessibilityScore={
            typeof accessibilitySuggestionData?.category?.categoryScore !== "undefined"
              ? +accessibilitySuggestionData?.category?.categoryScore
              : 0
          }
          bestPracticesScore={
            typeof bestPracticesSuggestionData?.category.categoryScore !== "undefined"
              ? +bestPracticesSuggestionData?.category.categoryScore
              : 0
          }
        />
        <Typography className="ma-typography" variant="h1" component="h1" mt={1}>
          {value === 0 ? "Metrics" : "Audits"}
        </Typography>
        <Box mt={1} className="metrics-parent-wrap">
          {value === 0 ? (
            <Performance
              metrics={performanceMetricsData[0]?.audits}
              allRelAudits={allRelAuditsPerformance}
            />
          ) : value === 1 ? (
            <Accessibility
              metrics={accessibilityMetricsData}
              allRelAudits={allRelAuditsAccessibility}
            />
          ) : (
            <BestPractices
              metrics={bestPracticesMetricsData}
              allRelAudits={allRelAuditsBestPractices}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
export default Suggestions;

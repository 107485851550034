import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { axiosClient } from "../globals/client/axios";
import { POST_INVITE_USER } from "./paths";

const inviteUser = async ({ emailId }: { emailId: string }) => {
  return await axiosClient.post(POST_INVITE_USER + emailId);
};

export const useInviteUser = (onInviteSuccess: onSuccess, onInviteError: onFailure) => {
  return useMutation({
    mutationFn: inviteUser,
    onSuccess: onInviteSuccess,
    onError: onInviteError
  });
};

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: any,
  context: unknown
) => unknown;

export type onFailure = (
  data: AxiosResponse<any, any>,
  variables: any,
  context: unknown
) => unknown;

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_WEBPAGE_SUGGESTIONS } from "./paths";

const getSuggestions = async (pageId: string | number, category: string) => {
  return await axiosClient
    .get<ISuggestionData>(GET_WEBPAGE_SUGGESTIONS + `${pageId}/${category}`)
    .then((res) => res.data);
};

export const useSuggestionsData = (pageId: string | number, category: string) => {
  return useQuery({
    queryKey: ["suggestions", pageId, category],
    queryFn: () => getSuggestions(pageId, category)
  });
};

// Generated by https://quicktype.io

export interface ISuggestionData {
  pageId: number;
  jobId: number;
  category: Category;
}

export interface Category {
  category: string;
  categoryScore: string;
  auditGroups: AuditGroup[];
}

export interface AuditGroup {
  auditGroup: string;
  audits: Audit[];
}

export interface Audit {
  audit: string;
  auditScore: null | string;
  auditDescription: string;
  displayValue: null | string;
  numericUnit: NumericUnit | null;
  numericValue: number | null;
  relativeAudits: RelativeAudit[];
}

export enum NumericUnit {
  Millisecond = "millisecond",
  Unitless = "unitless"
}

export interface RelativeAudit {
  id: string | number;
  relAudTitle: null | string;
  relAudDescription: string;
  relAudScore: null | string;
  relAudScoreDisMode: RelAudScoreDisMode | null;
  relAudType: RelAudType | null;
}

export interface RelativeAuditWithMetricType extends RelativeAudit {
  metricType: string;
}

export enum RelAudScoreDisMode {
  Binary = "binary",
  Error = "error",
  Informative = "informative",
  NotApplicable = "notApplicable",
  Numeric = "numeric"
}

export enum RelAudType {
  Diagnostics = "diagnostics",
  Opportunity = "opportunity"
}

import { Typography, Grid, RadioGroup, FormControlLabel, Radio, FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import "./index.scss";
import * as React from "react";

export const FilterUsers = ({ setFilters, projectData, filters, valueradio, setValueradio }) => {
  const StatusCategory = [
    { label: "All", approval: "All" },
    { label: "Pending Users", approval: "Pending" },
    { label: "Approved Users", approval: "Approved" },
    { label: "Rejected Users", approval: "Rejected" }
  ];

  const RoleCategory = [
    { label: "All" },
    { label: "Power User" },
    { label: "Admin" },
    { label: "Collaborator" }
  ];

  const handleChange = (event) => {
    setValueradio(event.target.value);
    setFilters({ role: "All", status: "All", prjct: "All" });
  };

  // const [role, setRole] = React.useState("All");
  // const [status, setStatus] = React.useState("All");
  // const [prjct, setPrjct] = React.useState("All");

  // React.useEffect(() => {
  //   setFilters({ role, status, prjct });
  // }, [role, status, prjct]);

  // const filterRoleCategory = (id) => {
  //   setRole(id.applicationRole);
  //   const selectedRole = Rolecategory.map((data) => ({
  //     ...data,
  //     isSelected: data.label === id.label
  //   }));
  //   updateRoleCategory(selectedRole);
  // };

  // const filterCategorystatus = (id) => {
  //   setStatus(id.approval);
  //   const selectStatus = statuscategory.map((data) => ({
  //     ...data,
  //     isSelected: data.label === id.label
  //   }));
  //   updateStatusCategory(selectStatus);
  // };

  return (
    <div className="filter">
      <Typography sx={{ mb: 2 }} className="title">
        Filters
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ mb: 1 }}>Projects</Typography>

        <select
          className="dropdown"
          name="sort"
          id="sort"
          value={filters.prjct}
          onChange={(e) =>
            setFilters((prevFilter) => ({
              ...prevFilter,
              prjct: e.target.value
            }))
          }
        >
          <option className="options" value={"All"}>
            {"All"}
          </option>
          {projectData?.map((item, i) => (
            <>
              <option className="options" key={i} value={item.projectAbbr}>
                <Typography className="optionstext">{item.projectAbbr}</Typography>
              </option>
            </>
          ))}
        </select>
      </Box>
      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={valueradio}
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel value="role" control={<Radio />} label="Filter by Role " />
            <FormControlLabel value="status" control={<Radio />} label="Filter by Status" />
          </RadioGroup>
        </FormControl>
      </Box>
      {valueradio === "role" ? (
        <Box>
          <Typography sx={{ mb: 1 }}>Application Role</Typography>
          <Grid container>
            {RoleCategory.map((i) => (
              <Grid
                onClick={() =>
                  setFilters((prevFilter) => ({
                    ...prevFilter,
                    role: i.label
                  }))
                }
                key={i.label}
                item
                className={"categoryFilter " + (i.label === filters.role ? "selected" : "")}
              >
                {i.label}
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box>
          <Typography sx={{ mb: 1 }}>Status</Typography>
          <Grid container>
            {StatusCategory.map((index) => (
              <Grid
                onClick={() =>
                  setFilters((prevFilter) => ({
                    ...prevFilter,
                    status: index.approval
                  }))
                }
                key={index.label}
                item
                className={
                  "categoryFilter " + (index.approval === filters.status ? "selected" : "")
                }
              >
                {index.label}
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </div>
  );
};

import React from "react";

export default function CpuIcon() {
  return (
    <svg width="68" height="90" viewBox="0 0 45 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.4995 25.752C43.3395 25.752 43.9995 25.072 43.9995 24.252C43.9995 23.412 43.3395 22.752 42.4995 22.752H39.9995V18.352H42.4995C43.3395 18.352 43.9995 17.692 43.9995 16.852C43.9995 16.032 43.3395 15.352 42.4995 15.352H39.5395C38.5795 12.172 36.0795 9.67195 32.8995 8.71195V5.75195C32.8995 4.93195 32.2195 4.25195 31.3995 4.25195C30.5795 4.25195 29.8995 4.93195 29.8995 5.75195V8.25195H25.4995V5.75195C25.4995 4.93195 24.8195 4.25195 23.9995 4.25195C23.1795 4.25195 22.4995 4.93195 22.4995 5.75195V8.25195H18.1195V5.75195C18.1195 4.93195 17.4395 4.25195 16.6195 4.25195C15.7795 4.25195 15.1195 4.93195 15.1195 5.75195V8.71195C11.9195 9.67195 9.41951 12.172 8.45951 15.352H5.49951C4.67951 15.352 3.99951 16.032 3.99951 16.852C3.99951 17.692 4.67951 18.352 5.49951 18.352H7.99951V22.752H5.49951C4.67951 22.752 3.99951 23.412 3.99951 24.252C3.99951 25.072 4.67951 25.752 5.49951 25.752H7.99951V30.152H5.49951C4.67951 30.152 3.99951 30.812 3.99951 31.652C3.99951 32.472 4.67951 33.152 5.49951 33.152H8.45951C9.39951 36.332 11.9195 38.832 15.1195 39.792V42.752C15.1195 43.572 15.7795 44.252 16.6195 44.252C17.4395 44.252 18.1195 43.572 18.1195 42.752V40.252H22.5195V42.752C22.5195 43.572 23.1795 44.252 24.0195 44.252C24.8395 44.252 25.5195 43.572 25.5195 42.752V40.252H29.8995V42.752C29.8995 43.572 30.5795 44.252 31.3995 44.252C32.2195 44.252 32.8995 43.572 32.8995 42.752V39.792C36.0795 38.832 38.5795 36.332 39.5395 33.152H42.4995C43.3395 33.152 43.9995 32.472 43.9995 31.652C43.9995 30.812 43.3395 30.152 42.4995 30.152H39.9995V25.752H42.4995ZM34.5195 28.772C34.5195 32.072 31.8195 34.772 28.5195 34.772H19.4795C16.1795 34.772 13.4795 32.072 13.4795 28.772V19.732C13.4795 16.432 16.1795 13.732 19.4795 13.732H28.5195C31.8195 13.732 34.5195 16.432 34.5195 19.732V28.772Z"
        fill="#6CB2C2"
      />
      <path
        d="M20.0405 32.7534H27.9805C30.4805 32.7534 32.5205 30.7334 32.5205 28.2134V20.2734C32.5205 17.7734 30.5005 15.7334 27.9805 15.7334H20.0405C17.5405 15.7334 15.5005 17.7534 15.5005 20.2734V28.2134C15.5005 30.7334 17.5205 32.7534 20.0405 32.7534Z"
        fill="#6CB2C2"
      />
    </svg>
  );
}

import { Stack, Button, ButtonProps } from "@mui/material";
import React from "react";

interface ICustomButton extends ButtonProps {}

const CustomButton: React.FC<ICustomButton> = ({ children, ...rest }) => {
  return (
    <Stack>
      <Button {...rest}>{children}</Button>
    </Stack>
  );
};

export default CustomButton;

import React from "react";

export default function PlusButton() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="3" transform="matrix(1 0 0 -1 0 16)" fill="#262626" />
      <path
        d="M7.99976 11.9999V4.00012"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9998 8.00012L4.00024 8.00012"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

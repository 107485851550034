import { ToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from "@mui/material";
import "./ToggleButtonGroup.scss";

export const ToggleButtonGroup = ({
  button1,
  button2,
  active,
  setActive,
  isDisabled
}: {
  button1: string | React.ReactNode;
  button2: string | React.ReactNode;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
}) => {
  return (
    <MuiToggleButtonGroup
      value={active}
      exclusive
      disabled={isDisabled}
      onChange={() => setActive(!active)}
      className="toggle-button-group"
      color="secondary"
    >
      <ToggleButton
        value="true"
        className={`button ${
          active ? "active" : isDisabled ? "inactive disabled-toggle" : "inactive"
        }`}
        disabled={isDisabled}
      >
        {button1}
      </ToggleButton>
      <ToggleButton
        value="false"
        className={`button ${
          !active ? "active" : isDisabled ? "inactive disabled-toggle" : "inactive"
        }`}
        disabled={isDisabled}
      >
        {button2}
      </ToggleButton>
    </MuiToggleButtonGroup>
  );
};

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_USERROLE } from "./paths";

const getUserRole = async (id: number | null, emailId: string | null) => {
  return await axiosClient
    .get<Iuser[] | string>(`${GET_USERROLE}/${id!}/${emailId!}`)
    .then((res) => res.data);
};

export const useUserRole = (id: number | null, emailId: string | null) => {
  return useQuery({
    queryKey: ["UserRole", id, emailId],
    queryFn: () => getUserRole(id, emailId)
  });
};

export interface Iuser {
  role: string;
}

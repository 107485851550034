export const LOGIN = "login/auth";
export const REGISTER = "login/user-auths";
export const VERIFY = "login/user-auths/verify";
export const RESET_PASS_EMAIL = "login/user-auths/forgotPassword/";
export const RESET_PASS = "login/user-auths/resetPassword";
export const RESET_TOKEN_VERIFY = "login/user-auths/passwordReset";

export const GET_PROJECTS = "projects/";
export const GET_PROJECTS_BYEMAIL = "projects/getProjectsByEmail/";
export const GET_PROJECT_GRAPH = "projectGraphs/";
export const GET_ALL_PLATFORM_GRAPH = "projectGraphs/allPlatforms/";
export const GET_PROJECTS_OFFSET_CHART = "/projects/cc/";

export const GET_PROJECT_WEBPAGES = "web-pages/from-project/";
export const GET_WEBPAGE_CE = "web-pages/latestCE/";
export const GET_WEBPAGE_OVERALL_CE = "web-pages/overallCE/";

export const GET_WEBPAGE_SUGGESTIONS = "web-pages/suggestions/";

export const PUT_PROJECT_IMAGE = "projects/image/";
export const REGEN_ACCESS_KEY = "projects/accessKey/";
export const LOCK_GA = "projects/isGALock/";

export const PUT_WEBPAGE = "web-pages/";
export const POST_WEBPAGE = "web-pages/";
export const GET_LATEST_JOBS_CLIENT = "projects/clientCarbonData/";
export const GET_LATEST_JOBS_ALL = "projects/allPlatformCarbonData/";
export const GET_LATEST_JOBS_SERVER = "projects/serverTableData/";

export const GET_USERS = "/login/user-auths";
export const GET_PROJECT_COLLABDATA = "/user-access/getByProjectId/";
export const POST_USERS = "/user-accesses";
export const PUT_USERS = "/user-accesses/";
export const DELETE_USERS = "/user-accesses/";

export const GET_OVERVIEW_DATA = "/projects/summary/";
export const GET_CLIENT_SUMMARY = "/projects/clientSummary/";
export const GET_SERVER_SUMMARY = "/projects/serverSummary/";
export const GET_PROJECT_SUMMARY_DATA = "/projects/projectsummary";
export const GET_ALL_PLATFORM_SUMMARY = "projects/allPlatformSummary/";

export const POST_ONBOARD_DATA = "/projects";
export const GET_NEWADDED_DATA = "/projects/getProjectsAndActions/";
export const DELETE_PROJECTS = "/projects/";
export const GET_DATA = "/projects/";
export const PUT_DATA = "/projects";
export const PUT_ADMIN = "/projects/approveReject";
export const GET_ACCESS_KEY = "/projects/getAccessKey/";

export const GET_USERROLE = "/projects/getRoleOfUserByProject";
export const GET_LOGGEDIN_USERDETAILS = "/login/user-auth/getEmailAndRole";
export const POST_REQUSER_ACCESS = "/user-auths/requestPowerUserAccess/";
export const POST_INVITE_USER = "/user-auth/inviteUser/";

export const GET_USER_DATA = "projects/getUsersAndProjectDetails";
export const POST_USER_DATA = "/user-auths/approveOrRejectAccess";
export const POST_CHANGE_APP_ROLE = "/user-auth/changeRoleAtTool";

export const GET_APPROVED_PROJECTS = "/projects/getAllApprovedProjects";

export const GET_COMMENTS = "/projects/viewComment/";

export const GET_CONTAINER_LIST = "/sne/getAllContainers";
export const GET_LATEST_CONTAINER_SUMMARY = "/sne/getContainerDataByProjId";

export const CLIENT_RT_CONFIG = "projects/Project-config";

export const GIT_ACTION_DISPATCH = "/trigger_git_actions";

import React from "react";

const VisibilityOffIcon: React.FC = () => {
  return (
    <svg width="25" height="23" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5" clipPath="url(#clip0_1832_4993)">
        <path
          d="M14.888 6.98859C14.2842 5.67904 13.3998 4.5183 12.2974 3.58859L14.1924 1.69359L13.308 0.808594L11.2499 2.86484C10.1108 2.20592 8.81587 1.86428 7.49992 1.87547C2.81242 1.87547 0.660545 5.78859 0.111795 6.98859C0.038131 7.14941 0 7.32421 0 7.50109C0 7.67798 0.038131 7.85278 0.111795 8.01359C0.71563 9.32315 1.60006 10.4839 2.70242 11.4136L0.808045 13.3086L1.69179 14.1923L3.74992 12.1361C4.88907 12.795 6.18397 13.1367 7.49992 13.1255C12.1874 13.1255 14.3393 9.21234 14.888 8.01234C14.9616 7.85171 14.9996 7.67713 14.9996 7.50047C14.9996 7.32381 14.9616 7.14923 14.888 6.98859ZM1.24992 7.50734C1.71867 6.47922 3.55804 3.12547 7.49992 3.12547C8.48092 3.11973 9.44907 3.34859 10.3237 3.79297L9.22055 4.89609C8.6205 4.49772 7.90111 4.31922 7.18444 4.39087C6.46777 4.46253 5.79796 4.77993 5.28867 5.28922C4.77938 5.79851 4.46198 6.46832 4.39032 7.18499C4.31867 7.90166 4.49717 8.62105 4.89555 9.22109L3.59617 10.5205C2.5964 9.70634 1.79422 8.67614 1.24992 7.50734ZM9.37492 7.50047C9.37492 7.99775 9.17738 8.47466 8.82575 8.82629C8.47412 9.17793 7.9972 9.37547 7.49992 9.37547C7.22149 9.37439 6.94692 9.31029 6.6968 9.18797L9.18742 6.69734C9.30974 6.94747 9.37384 7.22204 9.37492 7.50047ZM5.62492 7.50047C5.62492 7.00319 5.82246 6.52627 6.17409 6.17464C6.52573 5.82301 7.00264 5.62547 7.49992 5.62547C7.77835 5.62655 8.05292 5.69065 8.30305 5.81297L5.81242 8.30359C5.6901 8.05347 5.626 7.7789 5.62492 7.50047ZM7.49992 11.8755C6.51892 11.8812 5.55077 11.6523 4.67617 11.208L5.77929 10.1048C6.37934 10.5032 7.09873 10.6817 7.8154 10.6101C8.53207 10.5384 9.20188 10.221 9.71117 9.71172C10.2205 9.20243 10.5379 8.53262 10.6095 7.81595C10.6812 7.09928 10.5027 6.37989 10.1043 5.77984L11.403 4.48109C12.4048 5.29598 13.2074 6.32863 13.7499 7.50047C13.2724 8.53609 11.4324 11.8755 7.49992 11.8755Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1832_4993">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VisibilityOffIcon;

import { Box, Grid } from "@mui/material";
import "./MainTabBar.style.scss";
import { TabBar } from "./TabBar";

// import { PoweredByLH } from "../../../components/Powered by LH";
import { useParams } from "react-router-dom";

interface MainTabBarProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  performanceScore: number;
  bestPracticesScore: number;
  accessibilityScore: number;
}

export const MainTabBar = (props: MainTabBarProps) => {
  const { value, setValue, performanceScore, accessibilityScore, bestPracticesScore } = props;
  const { projectId, webpageId } = useParams();
  if (!projectId || !webpageId) return null;

  return (
    <Box className="tabbar" p={1}>
      <Grid container justifyContent="center">
        <Grid item className="suggestion-title">
          <TabBar
            value={value}
            setValue={setValue}
            performanceScore={performanceScore}
            bestPracticesScore={bestPracticesScore}
            accessibilityScore={accessibilityScore}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

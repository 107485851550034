import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { addAuthToken, axiosClient } from "../globals/client/axios";
import { LOGIN } from "./paths";

const loginUser = async ({ emailId, password }: ILoginData) => {
  const data = {
    emailId,
    password
  };
  const response = await axiosClient.post(LOGIN, data);
  const authCode = response.headers.authcode;
  localStorage.setItem("authCode", authCode);
  localStorage.setItem("emailId", emailId);
  addAuthToken();
  return response;
};

export const useLoginMutation = (onLoginSuccess: onSuccess, onError: any) => {
  return useMutation({
    mutationFn: loginUser,
    onSuccess: onLoginSuccess,
    onError
  });
};

interface ILoginData {
  emailId: string;
  password: string;
}

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: ILoginData,
  context: any
) => unknown;

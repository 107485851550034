import { NavLink } from "react-router-dom";
import "./NavBar.style.scss";

export const NavBar = () => {
  const userRole = localStorage.role;

  return (
    <div className="nav-bar-component">
      <div className="nav-bar-container">
        <div className="nav-menu">
          <NavLink
            className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}
            to="/dashboard"
          >
            Dashboard
          </NavLink>

          {userRole === "Admin" && (
            <NavLink
              className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}
              to="/admin"
            >
              Admin
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import "./index.scss";

import { onSuccess, useRegisterMutation } from "../../api/useRegisterMutation";

import { FormEvent, useState } from "react";
import { CircularProgress } from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";

export default function Signup() {
  const [emailError, setEmailError] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [otherError, setOtherError] = useState(false);
  const onRegisterSuccess: onSuccess = (data) => {
    console.log(data);
    setUserEmail(data?.data?.emailId);
    setSignupSuccess(true);
  };

  const onError = (error: any) => {
    console.log("error", error.response);
    if (error.response) {
      console.log("Error Status:", error.response);

      setOtherError(error.response.data.message);

      console.log("Error Status1:", otherError);
    } else {
      console.error("Error:", error.message);
    }
  };
  const { mutate, isLoading } = useRegisterMutation(onRegisterSuccess, onError);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmailError(false);
    setPasswordError(false);
    const regex: RegExp = /^[A-Z0-9._%+-]+@mercedes-benz+\.[A-Z]{2,}$/i;
    const formData = new FormData(event.currentTarget);
    const emailId = formData.get("emailId")?.toString();
    const result: boolean = regex.test(emailId!);
    const passwordRegex: RegExp =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
    const password = formData.get("password")?.toString();
    const passwordCheck: boolean = passwordRegex.test(password!);
    if (!emailId || !password) return;
    if (result && passwordCheck) {
      mutate({ emailId, password });
    } else if (!result) setEmailError(true);
    else setPasswordError(true);
  };
  return (
    <>
      {signupSuccess ? (
        <>
          <Container
            component="main"
            maxWidth="xs"
            sx={{
              background: "var(--login-card-bg)",
              borderRadius: 1,
              p: "20px",
              padding: "80px"
            }}
          >
            <>
              <Box
                sx={{
                  marginTop: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  lineHeight: "130%"
                }}
              >
                <svg
                  width={50}
                  height={50}
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.75 18.75L28.125 25L37.5 18.75M6.24998 28.125H10.4166M2.08331 21.875H10.4166"
                    stroke="#50886F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.4166 15.6253V14.5837C10.4166 13.4786 10.8556 12.4188 11.637 11.6374C12.4184 10.856 13.4782 10.417 14.5833 10.417H41.6666C42.7717 10.417 43.8315 10.856 44.6129 11.6374C45.3943 12.4188 45.8333 13.4786 45.8333 14.5837V35.417C45.8333 36.5221 45.3943 37.5819 44.6129 38.3633C43.8315 39.1447 42.7717 39.5837 41.6666 39.5837H14.5833C13.4782 39.5837 12.4184 39.1447 11.637 38.3633C10.8556 37.5819 10.4166 36.5221 10.4166 35.417V34.3753"
                    stroke="#50886F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
                <Grid className="verify-text">
                  <Typography>Verification email sent!</Typography>
                  <br></br>
                  <Typography>We sent a verification link to</Typography>
                  <Typography style={{ textDecoration: "underline", color: " #50886F" }}>
                    {userEmail}
                  </Typography>
                </Grid>
                <br></br>
                <Typography>Please check your inbox for next steps.</Typography>
              </Box>
            </>
          </Container>
        </>
      ) : (
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            background: "var(--login-card-bg)",
            borderRadius: 1,
            p: "20px"
          }}
        >
          <>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Typography component="h1" variant="h5" mt={2}>
                Sign Up
              </Typography>
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="emailId"
                  label="Email ID"
                  name="emailId"
                  autoFocus
                  type="email"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <CustomButton type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  {isLoading ? <CircularProgress color="inherit" size={16} /> : "Sign Up"}
                </CustomButton>
                <Grid container justifyContent="center" mb={2}>
                  <Grid item>
                    <Link component={RouterLink} to="/login" variant="body2">
                      {"Already have an account? Login"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
              {/* {isError ? <Typography color="red">{error?.toString()}</Typography> : null} */}
              {otherError ? <Typography color="red">{otherError?.toString()}</Typography> : null}
              {emailError ? (
                <Typography style={{ color: "red" }}>Enter correct email address</Typography>
              ) : (
                ""
              )}
              {passwordError ? (
                <Typography style={{ color: "red" }}>
                  Password must be 8 character long consisting of uppercase letters, lowercase
                  letters, numbers, and symbols.
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </>
        </Container>
      )}
    </>
  );
}

import React, { useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tooltip
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import "./ProjectScanForm.scss";
import VisibilityOffIcon from "../Svg/VisibilityOffIcon";
import VisibilityIcon from "../Svg/VisibilityIcon";
import Close from "../Svg/Close";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import CustomButton from "../CustomButton/CustomButton";
import { useSnackbar } from "notistack";
import { onSuccess, useGALock } from "../../api/useGALock";
import {
  onDispatchFailure,
  onDispatchSuccess,
  useGitActionDispatch
} from "../../api/useGADispatch";

interface ProjectScanFormProps {
  projectAbbr: string | undefined;
  openScanDetails: boolean;
  handleModalClose: () => void;
  projectId: string | number;
  setIsGaLocked: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectScanForm: React.FC<ProjectScanFormProps> = ({
  projectAbbr,
  openScanDetails,
  handleModalClose,
  projectId,
  setIsGaLocked
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPairingKey, setShowPairingKey] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [mfaImplemented, setMfaImplemented] = useState(true);
  const [proxyAddressPresent, setProxyAddressPresent] = useState(false);
  const [pairingKey, setPairingKey] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const onDispatchSuccess: onDispatchSuccess = (data) => {
    enqueueSnackbar("Scan Initiated", { variant: "success", autoHideDuration: 2000 });
    mutateGALock(projectId);
  };
  const onDispatchFailure: onDispatchFailure = (data) => {
    enqueueSnackbar("Failed to Initiate scan ", { variant: "error", autoHideDuration: 2000 });
  };

  const { mutate: mutateGaDispatch } = useGitActionDispatch(onDispatchSuccess, onDispatchFailure);
  const onLockSuccess: onSuccess = () => {
    setIsGaLocked(true);
  };

  const { mutate: mutateGALock } = useGALock(onLockSuccess);

  const handleToggleFormVisibility = () => setFormVisible(!formVisible);
  const handleToggleMfaImplemented = () => setMfaImplemented(!mfaImplemented);
  const handleToggleProxyAddress = () => setProxyAddressPresent(!proxyAddressPresent);

  const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
  const formatInput = (value: string) => {
    return (
      value
        .toUpperCase()
        .replace(/\s+/g, "")
        .match(/.{1,4}/g)
        ?.join(" ") ?? value
    );
  };

  const handleUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value.toUpperCase().slice(0, 7);
    setUsername(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handlePairingKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\s+/g, "");
    if (value.length <= 32) {
      setPairingKey(formatInput(value));
    }
  };

  const handleProceed = () => {
    console.log("Proceed button clicked");
    mutateGaDispatch({
      projectId: Number(projectId),
      body: {
        poolId: `${username}|${password}`,
        pingOnePairingKey: pairingKey,
        proxyAddress: proxyAddressPresent
      }
    });
    handleModalClose();
  };

  return (
    <Box onClick={handleContentClick}>
      <Modal open={openScanDetails} onClose={handleModalClose}>
        <Box className="modal-style">
          <Card className="card-style">
            <form onSubmit={handleProceed}>
              <CardHeader
                title={`Scan Project - ${projectAbbr ?? ""}`}
                action={
                  <IconButton onClick={handleModalClose} color="inherit">
                    <Close />
                  </IconButton>
                }
                className="header-style"
              />
              <Divider />
              <Box className="header-style">
                <Typography className="secondary-title" color="white">
                  Project Details
                </Typography>
              </Box>
              <CardContent className="content-style">
                <Box className="header-style">
                  <Typography variant="body1" color="white">
                    Needs Login to Access
                  </Typography>
                  <ToggleSwitch isOn={formVisible} handleToggle={handleToggleFormVisibility} />
                </Box>
                <div className={`transition-container ${formVisible ? "expanded" : ""}`}>
                  {formVisible && (
                    <>
                      <Divider />
                      <Box className="content-padding">
                        <Box>
                          <label>
                            Short-ID<span className="mandatory">*</span>
                          </label>
                          <TextField
                            required
                            type="text"
                            fullWidth
                            value={username}
                            placeholder="Enter Short-ID"
                            onChange={handleUsernameChange}
                            className="input-field"
                          />
                        </Box>
                        <Box>
                          <label>
                            Password<span className="mandatory">*</span>
                          </label>
                          <TextField
                            required
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            placeholder="Enter Password"
                            onChange={handlePasswordChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            className="input-field"
                          />
                        </Box>
                        <Box className="toggle-box">
                          <Typography variant="body1" color="white">
                            Proxy Address
                          </Typography>
                          <ToggleSwitch
                            isOn={proxyAddressPresent}
                            handleToggle={handleToggleProxyAddress}
                          />
                        </Box>
                        <Box className="toggle-box">
                          <Typography variant="body1" color="white">
                            MFA Implemented
                          </Typography>
                          <ToggleSwitch
                            isOn={mfaImplemented}
                            handleToggle={handleToggleMfaImplemented}
                          />
                        </Box>
                        <div
                          className={`pk-field-transition-container ${
                            mfaImplemented ? "expanded" : ""
                          }`}
                        >
                          {mfaImplemented && (
                            <Box>
                              <Divider />
                              <Box className="mfa-form-style">
                                <label>
                                  PingOne_Pairing Key<span className="mandatory">*</span>
                                </label>
                                <Tooltip
                                  title={
                                    <Box className="field-guide-tooltip">
                                      Refer{" "}
                                      <a
                                        href="https://git.i.mercedes-benz.com/IT-QIS/cec-engine-r#mfa"
                                        target="blank"
                                        className="field-guide-link"
                                      >
                                        MFA Docs
                                      </a>{" "}
                                      to get pingOne Pairing Key
                                    </Box>
                                  }
                                  placement="right"
                                >
                                  <IconButton className="field-guide">
                                    <InfoOutlined className="field-guide-icon" />
                                  </IconButton>
                                </Tooltip>
                                <TextField
                                  required
                                  type={showPairingKey ? "text" : "password"}
                                  placeholder="Enter Pairing Key"
                                  fullWidth
                                  value={pairingKey}
                                  onChange={handlePairingKeyChange}
                                  inputProps={{ maxLength: 39 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => setShowPairingKey(!showPairingKey)}
                                        >
                                          {showPairingKey ? (
                                            <VisibilityIcon />
                                          ) : (
                                            <VisibilityOffIcon />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                  className="input-field"
                                />
                              </Box>
                            </Box>
                          )}
                        </div>
                      </Box>
                    </>
                  )}
                </div>
              </CardContent>
              <CardActions className="footer-style">
                <CustomButton
                  className="scan-proceed"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Proceed
                </CustomButton>
              </CardActions>
            </form>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProjectScanForm;

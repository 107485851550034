import { Grid, Avatar, Typography, List, ListItemText } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQueryClient
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { IProject } from "../../../api/useProjectsData";
import { generateNewAccessKey } from "../../../api/useAccessKeyRegen";
import { getAccessKey } from "../../../api/getAccessKey";
import CustomButton from "../../../components/CustomButton/CustomButton";

interface ProjectDetailsProps {
  // Define the props for your component here
  projectData: IProject;
  projectDataRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<IProject | undefined, unknown>>;
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({ projectData, projectDataRefetch }) => {
  // Implement your component logic here
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const onKeySuccess = (data: string) => {
    projectDataRefetch();
    navigator.clipboard.writeText(data);
    enqueueSnackbar("Copied to Clipboard", { variant: "success", autoHideDuration: 2000 });
  };
  const { refetch } = getAccessKey(projectData?.id, onKeySuccess);
  const onRegenSuccess = () => {
    enqueueSnackbar("Access Key regenerated", { variant: "success", autoHideDuration: 2000 });
    queryClient.invalidateQueries();
  };
  const { mutate: regenerateKey } = generateNewAccessKey(onRegenSuccess);

  const handleCopyKey = () => {
    // refetch will set the enabled to true
    if (projectData.copied) {
      return null;
    }
    refetch();
  };

  const date = new Date(projectData?.createdDate);
  // Format the date
  const formattedDate = date
    .toLocaleDateString("en-UK", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric"
    })
    .replace(/\//g, "-");
  // Format the time
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  });

  const CreatedDateTime = `${formattedDate} ${formattedTime}`;
  const dateformat = new Date(projectData.updatedDate);

  // Format the date
  const formatupdateDate = dateformat.toLocaleDateString("en-UK", {
    year: "numeric",
    month: "long",
    day: "numeric"
  });
  const UpdatedDateTime = `Last updated on ${formatupdateDate}`;

  return (
    <Grid className="headerBox" xs={12}>
      <Avatar variant="square" className="ProjectAvatar" src={projectData.image}>
        {!projectData.image && (
          <Typography className="avatarText">{projectData.name.charAt(0)}</Typography>
        )}
      </Avatar>
      <Typography className="projectHeadText">{projectData.name}</Typography>
      <List className="prjct-details">
        <ListItemText>{UpdatedDateTime}</ListItemText>
        <ListItemText>
          Power User:
          <Typography className="pwruser-details">{projectData.department.powerUser}</Typography>
        </ListItemText>
        <ListItemText>
          Created Date:
          <Typography className="prjctcreated-details">{CreatedDateTime}</Typography>
        </ListItemText>
        <ListItemText>
          Access Key:
          <Typography className="accesskey-details">
            ......................................................................
          </Typography>
        </ListItemText>
        <Tooltip title={`${projectData.copied ? "Copy limit exceeded" : "Copy"}`}>
          <ContentCopyIcon
            className={`copyIcon ${projectData.copied ? "disable-copy" : "copy"}`}
            fontSize="small"
            onClick={handleCopyKey}
          />
        </Tooltip>
      </List>

      <CustomButton
        className="accessKeyBtn"
        variant="outlined"
        startIcon={<CachedIcon />}
        onClick={() => regenerateKey(projectData.id)}
      >
        Generate New Key
      </CustomButton>
      <hr className="ruler"></hr>
      <Typography className="boxHeading">Description</Typography>
      <Typography className="prjctDescription">
        {projectData?.projectDetails?.description}
      </Typography>
    </Grid>
  );
};

export default ProjectDetails;

import React from "react";

export default function AllColors() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="4.5" height="4.5" rx="1" fill="#878787" />
      <rect y="5.5" width="4.5" height="4.5" rx="1" fill="#EE9225" />
      <rect x="5.5" width="4.5" height="4.5" rx="1" fill="#80BD24" />
      <rect x="5.5" y="5.5" width="4.5" height="4.5" rx="1" fill="#F63E3E" />
    </svg>
  );
}

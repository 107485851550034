export default function Dollar() {
  return (
    <svg width="67" height="55" viewBox="0 -6 30 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6582 7.73701C13.9649 5.75443 13.3446 3.75415 12.8155 1.71846C14.7786 -0.370327 22.3573 -0.0941815 24.5393 1.6866L22.5215 6.34212C23.6089 4.95786 23.9738 4.39141 24.6196 3.61962C24.8933 3.79309 25.1487 3.98781 25.3895 4.20023C25.9624 4.70295 26.4769 5.26232 26.5791 6.03765C26.6447 6.54038 26.4988 7.05373 26.0354 7.57769L21.405 12.8103C20.8102 12.7147 20.2264 12.5766 19.6608 12.3819C19.9235 11.7836 20.241 11.1216 20.5037 10.5232L18.8179 12.2863C17.0628 11.9252 15.647 12.1412 14.3334 12.8174L9.63367 7.34757C9.35635 7.02186 9.22864 6.69615 9.22864 6.37044C9.23229 5.04991 11.2611 3.90638 12.3302 3.47446L14.6582 7.73701ZM20.6788 17.618L19.763 13.5502C23.6892 14.2583 29.9835 22.008 32.0159 25.5307C33.0522 27.3291 33.9644 29.3117 34.7088 31.5244C36.1902 36.8845 34.7635 41.8976 28.772 43.0659C25.0173 43.7987 18.0151 43.8483 14.0671 43.65C9.82341 43.4376 3.25178 43.4447 1.5368 39.214C-1.2327 32.3883 3.8429 24.2561 8.46968 19.2785C9.07904 18.6235 9.70665 18.0146 10.3598 17.4481C12.0419 16.0107 13.8591 14.3078 16.0229 13.5962L13.9284 17.3773L16.9679 13.4688H18.5698L20.6788 17.618Z"
        fill="#6CB2C2"
      />
      <path
        d="M15.2718 27.2554H22.1599L21.3641 28.9726H15.0728C15.048 29.267 15.048 29.4877 15.048 29.7576C15.048 30.1255 15.048 30.1991 15.0728 30.469H20.6679L19.8721 32.1861H15.3961C15.6945 34.4185 16.714 35.3506 18.8277 35.3506C21.016 35.3506 22.4583 34.443 23.6519 32.2843V35.8413C22.1847 37.3867 20.6679 38 18.3055 38C14.6004 38 12.7851 36.2828 12.1385 32.1861H10L10.7709 30.469H11.9645C11.9396 30.1991 11.9396 29.9538 11.9396 29.6349C11.9396 29.267 11.9396 29.1934 11.9645 28.9726H10.1741L10.9449 27.2554H12.1634C12.9094 23.1587 15.1972 21 18.8028 21C20.9663 21 22.7318 21.7605 24 23.2814L22.7069 26.0534C21.4885 24.189 20.444 23.5267 18.8277 23.5267C16.8881 23.5267 15.7691 24.7042 15.2718 27.2554Z"
        fill="#252525"
      />
    </svg>
  );
}

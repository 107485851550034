import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_PROJECT_WEBPAGES } from "./paths";

// const getProjectWebPages = async (projectId: number | string) => {
//   return await axiosClient
//     .get<Webpage[]>(GET_PROJECT_WEBPAGES, { params: { projectId } })
//     .then((res) => res.data);
// };
const isBase64 = (str: string): boolean => {
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  return base64Regex.test(str);
};

const decodeBase64 = (base64: string): string => {
  try {
    return atob(base64);
  } catch (error) {
    console.error("Failed to decode base64 string:", base64, error);
    return base64; // Return the original string if decoding fails
  }
};

const getProjectWebPages = async (projectId: number | string) => {
  const response = await axiosClient.get<Webpage[]>(GET_PROJECT_WEBPAGES, {
    params: { projectId }
  });
  const webpages = response.data.map((webpage) => ({
    ...webpage,
    url: isBase64(webpage.url) ? decodeBase64(webpage.url) : webpage.url
  }));
  return webpages;
};

export const useProjectWebPagesData = (
  projectId: number | string,
  enabled: boolean,
  onSuccess?: onSuccess
) => {
  return useQuery({
    queryKey: ["webpages", projectId],
    queryFn: () => getProjectWebPages(projectId),
    onSuccess,
    enabled
  });
};

export const useProjectWebPagesDataForNav = (
  projectId: number | string,
  enabled: boolean,
  onSuccess?: onSuccess
) => {
  return useQuery({
    queryKey: ["navwebpages", projectId],
    queryFn: () => getProjectWebPages(projectId),
    onSuccess,
    enabled
  });
};

export interface Webpage {
  id: number;
  projectId: number;
  url: string;
  name: string;
  version: string;
}

export type onSuccess = (data: Webpage[]) => void;

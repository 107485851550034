/* eslint-disable */
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import "./style.scss";
import CustomButton from "../CustomButton/CustomButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  textAlign: "center"
};

export const ConfirmPopUp = (props) => {
  const close = () => {
    props.onClose();
  };

  const save = () => {
    props.onSave();
    props.onClose();
  };

  return (
    <Fade in={true}>
      <Box className="modalPop" sx={style}>
        <QuestionMarkIcon className="iconStyle" />

        <Typography id="transition-modal-title" variant="h6" component="h2">
          Are you sure?
        </Typography>
        <Typography id="transition-modal-description">{props.data}</Typography>

        <Box className="action-buttons" sx={{ mt: 2 }}>
          <CustomButton className="sortButton" variant="outlined" onClick={close}>
            <Typography className="text-lightGrey secondary-font" variant="p">
              Cancel
            </Typography>
          </CustomButton>

          <CustomButton className="sortButton" variant="outlined" onClick={save}>
            <Typography className="text-lightGrey secondary-font" variant="p">
              Confirm
            </Typography>
          </CustomButton>
        </Box>
      </Box>
    </Fade>
  );
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  // IconButton,
  MenuItem,
  Select,
  SelectChangeEvent
  // Tooltip
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import "./index.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useUsersData } from "../../../api/useUsersData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { updateOnboard, useDataById, Details2 } from "../../../api/useCreateOnboardData";
import { useMutation } from "@tanstack/react-query";
import Successfull from "../../../components/Svg/Successfull";
import CustomButton from "../../../components/CustomButton/CustomButton";
// import { InfoOutlined } from "@mui/icons-material";

interface Option {
  emailId: string;
  id: number;
  name: string;
}

interface ResubmitDialogProps {
  openModal: boolean;
  onClose: () => void;
  onResubmit: () => void;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  selectedRowId: any;
}

const ResubmitDialogue: React.FC<ResubmitDialogProps> = ({
  openModal,
  onClose,
  onResubmit,
  setActiveTab,
  selectedRowId
}) => {
  const { mutate } = useMutation(updateOnboard, {
    onSuccess: (data) => {
      setSuccessBoxOpen(true);
    },
    onError: () => {
      console.log("There is an error");
    }
  });

  const [abbreviationErrorMessage, setAbbreviationErrorMessage] = useState("");
  const [firstDropdownValue, setFirstDropdownValue] = useState<Option | null>(null);
  const [secondDropdownValue, setSecondDropdownValue] = useState<Option | null>(null);
  const [successBoxOpen, setSuccessBoxOpen] = useState(false);
  const dataByIdQuery = useDataById(selectedRowId);
  const [idVerify, setidVerify] = useState("");
  const [depNameLimitation, setDepNameLimitation] = useState("");

  const [inputValue, setInputValue] = useState<Details2>({
    name: "",
    projectAbbr: "",
    currentVersion: "",
    image: "",
    devType: "",
    id: 0,
    department: {
      name: ""
    },
    projectMonitoring: {
      isToolAvailable: false,
      server: {
        monitoringType: "",
        domainUrl: ""
      },
      client: {
        monitoringType: "",
        domainUrl: ""
      }
    },
    projectsInfra: {
      appId: "",
      appType: "",
      networkType: "",
      hostingType: "",
      environment: "",
      deputyId: "",
      powerUser: "",
      powerUserId: 0,
      deputyUserId: 0
    },
    projectDetails: {
      description: "",
      appInfoClassification: "",
      techStack: "",
      endUserType: "",
      plannedStartDate: null,
      plannedEndDate: null
    }
  });

  useEffect(() => {
    if (selectedRowId && dataByIdQuery.isSuccess) {
      const fetchedData = dataByIdQuery.data;
      setInputValue({
        name: fetchedData.name,
        projectAbbr: fetchedData.projectAbbr,
        currentVersion: fetchedData.currentVersion,
        image: fetchedData.image,
        devType: fetchedData.devType,
        id: fetchedData.id,
        department: {
          name: fetchedData.department.name
        },
        projectMonitoring: {
          isToolAvailable: fetchedData.projectMonitoring.isToolAvailable,
          server: {
            monitoringType: fetchedData.projectMonitoring.server.monitoringType || "",
            domainUrl: ""
          },

          client: {
            monitoringType: fetchedData.projectMonitoring.client.monitoringType || "",
            domainUrl: ""
          }
        },
        projectsInfra: {
          appId: fetchedData.projectsInfra.appId,
          appType: fetchedData.projectsInfra.appType,
          networkType: fetchedData.projectsInfra.networkType,
          hostingType: fetchedData.projectsInfra.hostingType,
          environment: fetchedData.projectsInfra.environment,
          deputyId: fetchedData.projectsInfra.deputyUserId.toString(),
          powerUser: fetchedData.projectsInfra.powerUserId.toString(),
          deputyUserId: fetchedData.projectsInfra.deputyUserId,
          powerUserId: fetchedData.projectsInfra.powerUserId
        },
        projectDetails: {
          description: fetchedData.projectDetails.description,
          appInfoClassification: fetchedData.projectDetails.appInfoClassification,
          techStack: fetchedData.projectDetails.techStack,
          endUserType: fetchedData.projectDetails.endUserType,
          plannedStartDate: fetchedData.projectDetails.plannedStartDate,
          plannedEndDate: fetchedData.projectDetails.plannedEndDate
        }
      });

      setFirstDropdownValue({
        emailId: "",
        id: fetchedData.projectsInfra.deputyUserId || 0,
        name: fetchedData.projectsInfra.deputyId || ""
      });
      setSecondDropdownValue({
        emailId: "",
        id: fetchedData.projectsInfra.powerUserId || 0,
        name: fetchedData.projectsInfra.powerUser || ""
      });
    }
  }, [selectedRowId, dataByIdQuery.isSuccess, dataByIdQuery.data]);

  const { data: allUsers } = useUsersData();

  const firstDropdownOptions = allUsers?.filter(
    (option) => option.name !== (secondDropdownValue?.name ?? undefined)
  );

  const secondDropdownOptions = allUsers?.filter(
    (option) => option.name !== (firstDropdownValue?.name ?? undefined)
  );

  const handleFirstDropdownChange = (event: React.SyntheticEvent, value: Option | null) => {
    setFirstDropdownValue(value);
    setInputValue((prevFormData) => ({
      ...prevFormData,
      projectsInfra: {
        ...prevFormData.projectsInfra,
        deputyId: value?.id.toString() ?? ""
      }
    }));
  };

  const handleSecondDropdownChange = (event: React.SyntheticEvent, value: Option | null) => {
    setSecondDropdownValue(value);
    setInputValue((prevFormData) => ({
      ...prevFormData,
      projectsInfra: {
        ...prevFormData.projectsInfra,
        powerUser: value?.id.toString() ?? ""
      }
    }));
  };

  const handleClose = () => {
    onClose();
    setSuccessBoxOpen(false);
  };
  const handlesuccessBoxClose = () => {
    setSuccessBoxOpen(false); // Close successBox
    setActiveTab("tab2");
  };

  const handleInputChange = (
    event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = event.target;
    if (name.startsWith("projectMonitoring.client.monitoringType")) {
      const nestedFieldName = name.split(".")[2];
      // Update the nested fields in projectMonitoring
      setInputValue((prevFormData) => ({
        ...prevFormData,

        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          client: {
            ...prevFormData.projectMonitoring.client,
            [nestedFieldName]: value
          }
        }
      }));
    } else if (name.startsWith("projectMonitoring.server.monitoringType")) {
      const nestedFieldName = name.split(".")[2];

      // Update the nested fields in projectMonitoring
      setInputValue((prevFormData) => ({
        ...prevFormData,

        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          server: {
            ...prevFormData.projectMonitoring.server,
            [nestedFieldName]: value
          }
        }
      }));
    } else if (name === "projectMonitoring.isToolAvailable") {
      // Convert the select input's string value to boolean
      const isToolAvailable = value === "true";
      const fetchedData = dataByIdQuery.data;

      // Update the state with the boolean value
      setInputValue((prevFormData) => ({
        ...prevFormData,
        projectMonitoring: {
          ...prevFormData.projectMonitoring,
          isToolAvailable,
          client: {
            ...prevFormData.projectMonitoring.client,
            monitoringType:
              fetchedData?.projectMonitoring?.isToolAvailable === isToolAvailable
                ? fetchedData.projectMonitoring.client.monitoringType
                : isToolAvailable
                ? "Instana"
                : "None"
          },
          server: {
            ...prevFormData.projectMonitoring.server,
            monitoringType:
              fetchedData?.projectMonitoring?.isToolAvailable === isToolAvailable
                ? fetchedData.projectMonitoring.server.monitoringType
                : isToolAvailable
                ? "None"
                : "NA"
          }
        }
      }));
    } else if (
      name.startsWith("projectsInfra.") ||
      name.startsWith("projectDetails.") ||
      name.startsWith("department.")
    ) {
      const nestedFieldName = name.split(".")[1]; // Extract the nested field name

      // Check for department name limitation
      if (nestedFieldName === "name" && value.length > 7) {
        setDepNameLimitation("Max 7 characters can be used.");
        return; // Prevent further processing
      } else {
        setDepNameLimitation("");
      }

      setInputValue((prevFormData) => ({
        ...prevFormData,
        department: {
          ...prevFormData.department,
          [nestedFieldName]: value
        },

        projectsInfra: {
          ...prevFormData.projectsInfra,
          [nestedFieldName]: value
        },
        projectDetails: {
          ...prevFormData.projectDetails,
          [nestedFieldName]: value
        }
      }));
    } else {
      // Update non-nested fields
      let parsedValue: string | number = value;
      if (name === "projectAbbr") {
        if (value.length > 5) {
          setAbbreviationErrorMessage("Max 5 characters can be used.");
          return;
        } else {
          setAbbreviationErrorMessage("");
        }
        parsedValue = value.slice(0, 5).toUpperCase();
      }

      setInputValue((prevFormData) => ({
        ...prevFormData,
        [name]: parsedValue
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const id = selectedRowId;
    const userId = parseInt(localStorage.id, 10);
    const deputyIdId = firstDropdownValue?.id;
    const powerUserId = secondDropdownValue?.id;
    if (deputyIdId === userId || powerUserId === userId) {
      const formData: Details2 = {
        ...inputValue,
        id
      };

      mutate(formData);
      handleClose();
      setActiveTab("tab2");
    } else {
      setidVerify("Logged in user should be selected either as Deputy or Admin.");
      // setSnackbarOpen(true);
    }
  };
  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "760px",
              backgroundColor: "#000",
              borderRadius: "15px"
            }
          }
        }}
      >
        <div>
          <DialogTitle sx={{ fontFamily: "inherit" }}>Onboarding Project</DialogTitle>
          <div className="cancel">
            <CloseIcon onClick={handleClose}></CloseIcon>
          </div>
        </div>
        <Divider />

        <form onSubmit={handleSubmit}>
          <div className="sub-heading">Team Details</div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="depName">
                Department Name<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="input"
                name="department.name"
                placeholder=" Enter Department Name"
                value={inputValue.department.name}
                onChange={handleInputChange}
              />
              {depNameLimitation && <div className="error-message">{depNameLimitation}</div>}
            </div>
          </div>
          <div className="sub-heading">Monitoring Details</div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="MonitoringTool">
                Monitoring Tool<span className="mandatory">*</span>
              </label>
              <FormControl className="onboard-dropdown">
                <Select
                  labelId="input-label"
                  id="dropdown-input"
                  name="projectMonitoring.isToolAvailable"
                  IconComponent={ExpandMoreIcon}
                  value={inputValue.projectMonitoring.isToolAvailable.toString()}
                  size="small"
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem className="onboard-menu-item" value="true">
                    Yes
                  </MenuItem>
                  <MenuItem className="onboard-menu-item" value="false">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          {/* {inputValue.projectMonitoring.isToolAvailable && ( // Check if monitoring tool is available
            <div className="text-field">
              <div className="text-field1">
                <label htmlFor="client">
                  Client<span className="mandatory">*</span>
                  <Tooltip
                    title={
                      <Box className="field-guide-tooltip-option">
                        <div>
                          NA -
                          <span className="field-guide-tooltip-description">
                            {" "}
                            Client not present for the project
                          </span>
                        </div>
                        <div>
                          None -
                          <span className="field-guide-tooltip-description">
                            {" "}
                            No monitoring tool, hence static scan for client
                          </span>
                        </div>
                        <div>
                          Instana -
                          <span className="field-guide-tooltip-description">
                            {" "}
                            Real time scan through Instana
                          </span>
                        </div>
                      </Box>
                    }
                    placement="right"
                  >
                    <IconButton className="field-guide">
                      <InfoOutlined className="field-guide-icon" />
                    </IconButton>
                  </Tooltip>
                </label>
                <FormControl className="onboard-dropdown">
                  <Select
                    labelId="input-label"
                    id="dropdown-input"
                    name="projectMonitoring.client.monitoringType"
                    value={inputValue?.projectMonitoring?.client?.monitoringType || ""}
                    IconComponent={ExpandMoreIcon}
                    className={`${
                      inputValue?.projectMonitoring?.client?.monitoringType
                        ? "selected-white"
                        : "grey-class"
                    }`}
                    size="small"
                    onChange={handleInputChange}
                    renderValue={(selected) => selected || "Choose Client Monitoring Type"}
                    displayEmpty
                    required
                  >
                    <MenuItem className="onboard-menu-item" value="NA">
                      NA
                    </MenuItem>
                    <MenuItem className="onboard-menu-item" value="None">
                      None
                    </MenuItem>
                    <MenuItem className="onboard-menu-item" value="Instana">
                      Instana
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="text-field2">
                <label htmlFor="server">
                  Server<span className="mandatory">*</span>
                  <Tooltip
                    title={
                      <Box className="field-guide-tooltip-option">
                        <div>
                          None -
                          <span className="field-guide-tooltip-description">
                            {" "}
                            No monitoring tool, hence no scan for server
                          </span>
                        </div>
                        <div>
                          Instana -
                          <span className="field-guide-tooltip-description">
                            {" "}
                            Real time scan through Instana
                          </span>
                        </div>
                      </Box>
                    }
                    placement="right"
                  >
                    <IconButton className="field-guide">
                      <InfoOutlined className="field-guide-icon" />
                    </IconButton>
                  </Tooltip>
                </label>
                <FormControl className="onboard-dropdown">
                  <Select
                    labelId="input-label"
                    id="dropdown-input"
                    name="projectMonitoring.server.monitoringType"
                    value={inputValue?.projectMonitoring?.server?.monitoringType || ""}
                    IconComponent={ExpandMoreIcon}
                    className={`${
                      inputValue?.projectMonitoring?.server?.monitoringType
                        ? "selected-white"
                        : "grey-class"
                    }`}
                    size="small"
                    onChange={handleInputChange}
                    renderValue={(selected) => selected || "Choose Server Monitoring Type"}
                    displayEmpty
                    required
                  >
                    <MenuItem className="onboard-menu-item" value="None">
                      None
                    </MenuItem>
                    <MenuItem className="onboard-menu-item" value="Instana">
                      Instana
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )} */}

          <div className="sub-heading">Project Details</div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="name">
                Project Name<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="input"
                name="name"
                placeholder=" Enter Project Name"
                value={inputValue.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="text-field2">
              <label htmlFor="AppprojectAbbr">
                Project Abbreviation<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="input"
                name="projectAbbr"
                placeholder=" Enter Project Abbreviation"
                value={inputValue.projectAbbr}
                onChange={handleInputChange}
                required
              />
              {abbreviationErrorMessage && (
                <div className="error-message">{abbreviationErrorMessage}</div>
              )}
            </div>
          </div>

          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="currentVersion">
                Current Version<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="input"
                name="currentVersion"
                placeholder=" Enter Current Version"
                value={inputValue.currentVersion}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="text-field2">
              <label htmlFor="appId">
                App ID<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="input"
                name="projectsInfra.appId"
                placeholder=" Enter App ID"
                value={inputValue.projectsInfra.appId}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="appType">
                App Type<span className="mandatory">*</span>
              </label>
              <FormControl className="onboard-dropdown">
                <Select
                  labelId="input-label"
                  id="dropdown-input"
                  name="projectsInfra.appType"
                  value={inputValue.projectsInfra.appType || ""}
                  IconComponent={ExpandMoreIcon}
                  className={`${
                    inputValue.projectsInfra.appType ? "selected-white" : "grey-class"
                  }`}
                  size="small"
                  onChange={handleInputChange}
                  displayEmpty
                  renderValue={(selected) => selected || "Choose App Type"}
                  required
                >
                  {/* <MenuItem value="Mobile">Mobile</MenuItem> */}
                  <MenuItem className="onboard-menu-item" value="Web">
                    Web
                  </MenuItem>
                  {/* <MenuItem value="ECU">ECU</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="text-field2">
              <label htmlFor="networkType">
                Network Type<span className="mandatory">*</span>
              </label>
              <FormControl className="onboard-dropdown">
                <Select
                  labelId="input-label"
                  id="dropdown-input"
                  name="projectsInfra.networkType"
                  value={inputValue.projectsInfra.networkType || ""}
                  IconComponent={ExpandMoreIcon}
                  className={`${
                    inputValue.projectsInfra.networkType ? "selected-white" : "grey-class"
                  }`}
                  size="small"
                  onChange={handleInputChange}
                  displayEmpty
                  renderValue={(selected) => selected || "Select Network Type"}
                  required
                >
                  <MenuItem className="onboard-menu-item" value="Private">
                    Private
                  </MenuItem>
                  <MenuItem className="onboard-menu-item" value="Public">
                    Public
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="text-field">
            <div className="text-field1">
              <label htmlFor="hostingType">
                Hosting Info<span className="mandatory">*</span>
              </label>
              <FormControl className="onboard-dropdown">
                <Select
                  labelId="input-label"
                  id="dropdown-input"
                  name="projectsInfra.hostingType"
                  value={inputValue.projectsInfra.hostingType || ""}
                  IconComponent={ExpandMoreIcon}
                  className={`${
                    inputValue.projectsInfra.hostingType ? "selected-white" : "grey-class"
                  }`}
                  size="small"
                  onChange={handleInputChange}
                  displayEmpty
                  renderValue={(selected) => selected || "Select Hosting Info"}
                  required
                >
                  <MenuItem className="onboard-menu-item" value="Green">
                    Green Hosted
                  </MenuItem>
                  <MenuItem className="onboard-menu-item" value="NonGreen">
                    Non Green Hosted
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="text-field2">
              <label htmlFor="environment">
                Environment Type<span className="mandatory">*</span>
              </label>
              <FormControl className="onboard-dropdown">
                <Select
                  labelId="input-label"
                  id="dropdown-input"
                  name="projectsInfra.environment"
                  value={inputValue.projectsInfra.environment || ""}
                  IconComponent={ExpandMoreIcon}
                  className={`${
                    inputValue.projectsInfra.environment ? "selected-white" : "grey-class"
                  }`}
                  size="small"
                  onChange={handleInputChange}
                  displayEmpty
                  renderValue={(selected) => selected || "Select Environment Type"}
                  required
                >
                  <MenuItem className="onboard-menu-item" value="INT">
                    INT
                  </MenuItem>
                  <MenuItem className="onboard-menu-item" value="DEV">
                    DEV
                  </MenuItem>
                  <MenuItem className="onboard-menu-item" value="PROD">
                    PROD
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <div className="sub-heading">
              User Details {idVerify && <div className="error-message">{idVerify}</div>}
            </div>
            <div className="text-field-bottom">
              <div className="text-field1">
                <label htmlFor="DEmailId" className="auto-Label">
                  Deputy Name<span className="mandatory">*</span>
                </label>
                <Autocomplete
                  value={firstDropdownValue}
                  onChange={handleFirstDropdownChange}
                  sx={{ width: 340, height: 20 }}
                  size="small"
                  options={firstDropdownOptions!}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Deputy Name"
                      required
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>

              <div className="text-field2">
                <label htmlFor="AEmailId" className="auto-Label">
                  Administrator Name<span className="mandatory">*</span>
                </label>

                <Autocomplete
                  sx={{ width: 340, height: 20 }}
                  size="small"
                  options={secondDropdownOptions!}
                  value={secondDropdownValue}
                  onChange={handleSecondDropdownChange}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Administrator Name"
                      required
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password"
                        // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <Divider />
          <DialogActions>
            <div className="button">
              <CustomButton
                type="submit"
                sx={{
                  background: "#00677f",
                  borderRadius: 0.5,
                  color: "#FFFFFF",
                  fontFamily: "inherit",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "18px",
                  px: "25px",
                  py: "6px",
                  textTransform: "Capitalize",
                  "&:hover": { backgroundColor: "#00677f" }
                }}
              >
                Resubmit
              </CustomButton>
            </div>
          </DialogActions>
        </form>
      </Dialog>
      <div>
        <Dialog
          className="success-box"
          open={successBoxOpen}
          onClose={handlesuccessBoxClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "100%",
                maxWidth: "574px",
                maxHeight: "369px",
                backgroundColor: "#000",
                borderRadius: "15px"
              }
            }
          }}
        >
          <div>
            <CloseIcon className="close-icon" onClick={handleClose}></CloseIcon>
          </div>
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              mb: "45px"
            }}
          >
            <div className="success-image">
              <Successfull />
            </div>
            <div className="success-message1">Successfully Resubmitted</div>
            <div className="success-message2">Project will be activated after admin approval.</div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
export default ResubmitDialogue;

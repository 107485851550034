// CustomDropdown.tsx
import { FormControl, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/system";
import React from "react";

interface Option {
  label: string;
  value: string | number;
  className?: string;
  [key: string]: any;
}

interface CustomDropdownProps {
  label?: string;
  name?: string;
  options: Option[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
  value: string | number;
  renderValue?: (value: unknown) => React.ReactNode;
  [key: string]: any; // Allow any other prop
}

const CustomFormControl = styled(FormControl)(() => ({}));

const CustomSelect = styled(Select)(() => ({}));

const CustomBasicDropdown: React.FC<CustomDropdownProps> = ({
  label,
  name,
  options,
  onChange,
  value,
  renderValue,
  ...props
}) => {
  return (
    <CustomFormControl className="drop">
      <CustomSelect
        value={value}
        onChange={onChange}
        label={label}
        name={name}
        renderValue={renderValue}
        {...props}
      >
        {options.map(({ value, label, className, ...optionProps }, index) => (
          <MenuItem key={index} value={value} className={className} {...optionProps}>
            {label}
          </MenuItem>
        ))}
      </CustomSelect>
    </CustomFormControl>
  );
};

export default CustomBasicDropdown;

import React from "react";
import { ButtonGroup, Divider, Button, Grid, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import EmissionPieChart from "../Charts/EmissionPieChart/EmissionPieCharts";
import { AllplatformPagesJobs } from "../../../api/useLatestJobsAndPagesAllPlatform";
import { ServerData } from "../../../api/useServerTableData";
import { serverDataContainers, containerItem } from "./ProjectItemForServer";

interface EmissionSideChartProps {
  onClose: () => void;
  project: AllplatformPagesJobs | ServerData;
  latestJob: any;
  platform: string;
}
interface forPieData {
  name: string;
  y: number;
}

function EmissionSideChart({ project, onClose, latestJob, platform }: EmissionSideChartProps) {
  const [isEmission, setIsEmission] = React.useState(true);
  let pieData, totalData;

  const totalPieData = (containerObj: serverDataContainers, keyName: string) => {
    const pieDataServer: forPieData[] = [];
    for (const key of Object.keys(containerObj)) {
      const containerProjItem: containerItem = containerObj[key];
      const pieDataItem = {
        name: key,
        y: containerProjItem[keyName]
      };
      pieDataServer.push(pieDataItem);
    }
    return pieDataServer;
  };

  if (platform === "All") {
    if (isEmission) {
      pieData = [
        {
          name: "Client",
          y: project?.clientCE
        }
      ];

      totalData = project?.ceSum;
    } else {
      pieData = [
        {
          name: "Client",
          y: project?.clientCC
        }
      ];
      totalData = project?.ccSum;
    }
  } else if (platform === "server") {
    const containerObj = project.containers[0];
    if (isEmission) {
      pieData = totalPieData(containerObj, "ce");
      totalData = project?.totalEmission;
    } else {
      pieData = totalPieData(containerObj, "cc");
      totalData = project?.offset;
    }
  }
  const piechartcolors: any[] = ["#BBDCAD", "#FDBD7D", "#CEB3D6", "#F1ED8C", "#F59597"];
  return (
    <div className="emission-sidegraph-card">
      <Grid className="emissionpie-sidechart-heading" container justifyContent="space-between">
        <Grid item>
          <Typography style={{ fontSize: "16px" }}>{project?.name}</Typography>
        </Grid>
        <Grid item style={{ position: "relative", bottom: "5px" }}>
          <IconButton
            color="default"
            size="small"
            onClick={(event) => {
              event.stopPropagation(); // Stop event propagation(to stop navToProject from being called)
              onClose();
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <br></br>
      {latestJob?.thresholdColour === "Grey" ? (
        <Typography style={{ padding: "20px" }}>No records for this data</Typography>
      ) : (
        <>
          <Grid item className="sideChartBtnGroup">
            {/* Emission-Offset Tab Switcher */}
            <ButtonGroup
              sx={{ borderRadius: "4px" }}
              size="small"
              variant="contained"
              color="secondary"
            >
              <Button
                sx={isEmission ? activeSx : inActiveSx}
                onClick={(event) => {
                  event.stopPropagation();
                  if (!isEmission) setIsEmission(true);
                }}
              >
                Emission
              </Button>
              <Button
                sx={!isEmission ? activeSx : inActiveSx}
                onClick={(event) => {
                  event.stopPropagation();
                  if (isEmission) setIsEmission(false);
                }}
              >
                Offset
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid className="emissionpie-chart-section-container">
            <div className="highcharts-figure">
              <EmissionPieChart
                backgroundColors={"#3a3a3a"}
                isEmission={isEmission}
                piedata={pieData}
                totaldata={totalData}
                piechartcolors={piechartcolors}
              />
            </div>
          </Grid>
        </>
      )}
    </div>
  );
}
const commonSx = {
  textTransform: "capitalize",
  borderColor: "#00677F"
};
const activeSx = {
  ...commonSx,
  color: "white",
  bgcolor: "#00677F",
  "&:hover": { backgroundColor: "#00677F", borderRightColor: "transparent", borderColor: "#00677F" }
};

const inActiveSx = {
  ...commonSx,
  color: "white",
  bgcolor: "#303030",
  "&:hover": { borderRightColor: "transparent", borderColor: "#00677F" }
};

export default EmissionSideChart;

import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { axiosClient } from "../globals/client/axios";
import { RESET_PASS_EMAIL } from "./paths";

const sendResetPassEmail = async ({ emailId }: { emailId: string }) => {
  return await axiosClient.post(RESET_PASS_EMAIL + emailId);
};

export const useRestPassEmailMutation = (onEmailSuccess: onSuccess) => {
  return useMutation({
    mutationFn: sendResetPassEmail,
    onSuccess: onEmailSuccess
  });
};

export type onSuccess = (
  data: AxiosResponse<any, any>,
  variables: any,
  context: unknown
) => unknown;

import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const userRole = localStorage.role;
  const location = useLocation();
  if (userRole !== "Admin") {
    return <Navigate to="/error/401" state={{ from: location }} />;
  }
  return children;
};

export default ProtectedRoute;

import React from "react";

export default function LightHouse() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_364_596)">
        <path
          d="M8.00001 14.9433C12.1265 14.9433 15.4716 11.5981 15.4716 7.47163C15.4716 3.34516 12.1265 0 8.00001 0C3.87354 0 0.528381 3.34516 0.528381 7.47163C0.528381 11.5981 3.87354 14.9433 8.00001 14.9433Z"
          fill="#0535C1"
        />
        <path
          d="M12.9245 5.23557H12.4776C12.2552 4.60019 11.6065 4.21829 10.943 4.33211C10.2795 4.44594 9.79521 5.02225 9.79731 5.69539C9.79748 6.45744 10.4152 7.07511 11.1772 7.07531H12.9245C13.4206 7.05879 13.8143 6.65182 13.8143 6.15542C13.8143 5.65903 13.4206 5.25209 12.9245 5.23557Z"
          fill="#EAEAEA"
        />
        <path
          d="M13.8445 6.16681V6.1556C13.8443 5.64756 13.4325 5.23577 12.9245 5.23557H12.4647V5.25865H12.9245C13.4279 5.25862 13.8378 5.66314 13.8445 6.16646V6.16681Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M11.1771 4.31624L11.923 6.67184C12.1722 6.42151 12.5567 6.07645 12.5567 5.69574C12.5565 4.93375 11.9388 4.31606 11.1768 4.31589L11.1771 4.31624Z"
          fill="#E1E1E1"
        />
        <path
          d="M11.1772 7.07563C11.9393 7.07563 12.5571 6.45783 12.5571 5.69574C12.5571 4.93365 11.9393 4.31586 11.1772 4.31586C10.4151 4.31586 9.7973 4.93365 9.7973 5.69574C9.7973 6.45783 10.4151 7.07563 11.1772 7.07563Z"
          fill="#EEEEEE"
        />
        <path
          d="M11.1772 4.33897C11.9347 4.33932 12.5504 4.95013 12.5567 5.70765V5.69644C12.557 4.93446 11.9395 4.31653 11.1775 4.31624C10.4155 4.31594 9.79759 4.93343 9.7973 5.69542V5.70663C9.80419 4.9494 10.4199 4.33912 11.1772 4.33897Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M12.9245 7.05222H11.1768C10.4193 7.05184 9.80364 6.44101 9.7973 5.68354V5.69475C9.79765 6.45662 10.4153 7.07408 11.1772 7.07426H12.9245C13.4324 7.07408 13.8442 6.6625 13.8445 6.15461V6.14337C13.8382 6.64703 13.4282 7.05205 12.9245 7.05222Z"
          fill="#212121"
          fillOpacity="0.1"
        />
        <path d="M5.96234 3.14147H9.86796V6.53767H5.96234V3.14147Z" fill="#FFF176" />
        <path d="M5.53778 8.32065H10.2075V12.9904H5.53778V8.32065Z" fill="white" />
        <path
          d="M10.717 6.19806H11.3962V7.55653H4.60382V6.19806H5.28307V3.48111L8.00003 1.78298L10.717 3.48108V6.19806ZM9.35849 6.19806V4.23402L8.00003 3.385L6.64156 4.23402V6.19806H9.35849Z"
          fill="#F4481E"
        />
        <path
          d="M4.30225 13.9655L5.45288 6.19806H10.5472L11.6978 13.9655C10.5715 14.6082 9.29679 14.9453 8.00003 14.9433C6.70328 14.9453 5.42853 14.6082 4.30225 13.9655ZM9.52121 8.54176L6.32536 9.58003L5.93687 12.2018L9.87375 10.9225L9.52121 8.54176Z"
          fill="#F4481E"
        />
        <path
          d="M5.9623 6.96218H5.4464C5.18869 6.23034 4.4408 5.79098 3.67607 5.92223C2.91133 6.05351 2.35274 6.71711 2.35382 7.49302C2.35441 8.37205 3.06695 9.08445 3.94598 9.08483H5.9623C6.53799 9.07023 6.99709 8.59938 6.99709 8.02351C6.99709 7.44764 6.53799 6.97675 5.9623 6.96218Z"
          fill="#FAFAFA"
        />
        <path
          d="M7.02358 8.03609V8.02354C7.02358 7.43765 6.54814 6.96218 5.96229 6.96218H5.43146V6.98869H5.96229C6.54295 6.98883 7.01579 7.45546 7.02358 8.03609Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M3.94597 5.90089L4.80623 8.61852C5.09388 8.32987 5.53742 7.9318 5.53742 7.49235C5.53684 6.61344 4.82453 5.90113 3.94562 5.90054L3.94597 5.90089Z"
          fill="#E1E1E1"
        />
        <path
          d="M3.94601 9.08515C4.82532 9.08515 5.53814 8.37233 5.53814 7.49302C5.53814 6.61371 4.82532 5.90089 3.94601 5.90089C3.0667 5.90089 2.35388 6.61371 2.35388 7.49302C2.35388 8.37233 3.0667 9.08515 3.94601 9.08515Z"
          fill="white"
        />
        <path
          d="M3.94598 5.92739C4.81998 5.92792 5.53031 6.63262 5.53778 7.50662V7.49404C5.53785 7.28498 5.49674 7.07795 5.4168 6.88478C5.33685 6.6916 5.21965 6.51607 5.07187 6.36819C4.92408 6.22031 4.74862 6.10299 4.5555 6.02293C4.36238 5.94286 4.15538 5.90162 3.94631 5.90155C3.73725 5.90148 3.53022 5.94259 3.33705 6.02254C3.14388 6.10248 2.96834 6.21969 2.82046 6.36747C2.52181 6.66593 2.35396 7.0708 2.35382 7.49302V7.50557C2.36167 6.6318 3.07215 5.92754 3.94598 5.92736V5.92739Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M5.9623 9.05833H3.94563C3.07162 9.0578 2.36129 8.35308 2.35382 7.4791V7.49165C2.3542 8.37082 3.06684 9.08343 3.94598 9.08381H5.9623C6.54815 9.08381 7.02359 8.60834 7.02359 8.02248V8.00958C7.01652 8.59074 6.54348 9.05818 5.9623 9.05833Z"
          fill="#212121"
          fillOpacity="0.1"
        />
        <path
          d="M8.00001 0.0781019C12.1135 0.0781019 15.4496 3.40195 15.471 7.51068L15.4716 7.47163C15.4716 3.34525 12.1264 0 8.00001 0C3.87363 0 0.528381 3.34525 0.528381 7.47163C0.528381 7.48453 0.529403 7.49711 0.529403 7.51068C0.550446 3.4023 3.88586 0.0781019 8.00001 0.0781019Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M15.471 7.43258C15.4492 11.5413 12.1135 14.8652 8.00001 14.8652C3.88621 14.8652 0.550446 11.5409 0.529403 7.43258C0.529403 7.44615 0.528381 7.45873 0.528381 7.47163C0.528381 11.598 3.87363 14.9433 8.00001 14.9433C12.1264 14.9433 15.4716 11.598 15.4716 7.47163L15.471 7.43258Z"
          fill="#263238"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_364_596">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import React from "react";

const VisibilityIcon: React.FC = () => {
  return (
    <svg width="25" height="19" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5432 4.8868C13.5739 3.30805 11.3689 0.659302 7.49887 0.659302C3.62887 0.659302 1.42387 3.30805 0.454497 4.8868C0.154827 5.37149 -0.00390625 5.93008 -0.00390625 6.49993C-0.00390625 7.06978 0.154827 7.62836 0.454497 8.11305C1.42387 9.6918 3.62887 12.3406 7.49887 12.3406C11.3689 12.3406 13.5739 9.6918 14.5432 8.11305C14.8429 7.62836 15.0017 7.06978 15.0017 6.49993C15.0017 5.93008 14.8429 5.37149 14.5432 4.8868ZM13.4776 7.45868C12.6451 8.81243 10.7607 11.0906 7.49887 11.0906C4.237 11.0906 2.35262 8.81243 1.52012 7.45868C1.34208 7.17058 1.24778 6.8386 1.24778 6.49993C1.24778 6.16126 1.34208 5.82927 1.52012 5.54118C2.35262 4.18743 4.237 1.9093 7.49887 1.9093C10.7607 1.9093 12.6451 4.18493 13.4776 5.54118C13.6557 5.82927 13.75 6.16126 13.75 6.49993C13.75 6.8386 13.6557 7.17058 13.4776 7.45868Z"
        fill="white"
      />
      <path
        d="M7.5 3.37488C6.88193 3.37488 6.27775 3.55816 5.76384 3.90154C5.24994 4.24491 4.8494 4.73297 4.61288 5.30399C4.37635 5.87501 4.31447 6.50335 4.43505 7.10954C4.55563 7.71573 4.85325 8.27255 5.29029 8.70959C5.72733 9.14663 6.28415 9.44425 6.89034 9.56483C7.49654 9.68541 8.12487 9.62353 8.69589 9.387C9.26691 9.15048 9.75496 8.74994 10.0983 8.23604C10.4417 7.72213 10.625 7.11795 10.625 6.49988C10.624 5.67138 10.2944 4.8771 9.70861 4.29127C9.12278 3.70543 8.3285 3.37587 7.5 3.37488ZM7.5 8.37488C7.12916 8.37488 6.76665 8.26491 6.45831 8.05888C6.14996 7.85286 5.90964 7.56002 5.76773 7.21741C5.62581 6.8748 5.58868 6.4978 5.66103 6.13408C5.73338 5.77037 5.91195 5.43628 6.17418 5.17405C6.4364 4.91183 6.77049 4.73325 7.13421 4.66091C7.49792 4.58856 7.87492 4.62569 8.21753 4.7676C8.56014 4.90952 8.85298 5.14984 9.05901 5.45818C9.26503 5.76653 9.375 6.12904 9.375 6.49988C9.375 6.99716 9.17746 7.47407 8.82583 7.8257C8.4742 8.17733 7.99728 8.37488 7.5 8.37488Z"
        fill="white"
      />
    </svg>
  );
};

export default VisibilityIcon;

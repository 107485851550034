import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";
import { GET_PROJECTS } from "./paths";
import { IProject } from "./useProjectsData";

const getProject = async (projectId: string | number | undefined) => {
  return await axiosClient
    .get<IProject | undefined>(GET_PROJECTS + `${projectId !== undefined ? projectId : ""}`)
    .then((res) => res.data);
};

export const useProjectData = (projectId: string | number | undefined) => {
  return useQuery({
    queryKey: ["projects", projectId],
    queryFn: () => getProject(projectId)
  });
};

import {
  // Avatar,
  Grid,
  // List,
  // ListItemText,
  Typography,
  Divider
} from "@mui/material";
import React from "react";
import "./index.scss";
import Box from "@mui/material/Box";
import { Collaborators } from "./Collaborator/collaborators";
import { Pages } from "./Pages/pages";
import { useProjectData } from "../../api/useProjectData";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { EditProject } from "../../components/Svg/EditProject";
import { useUserRole } from "../../api/useUserRole";
import RightArrow from "../../components/Svg/RightArrow";
import ProjectDetails from "./ProjectDetails/ProjectDetails";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useIsFetching } from "@tanstack/react-query";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

export const ProjectSettings = () => {
  const isFetching = useIsFetching();
  const { projectId } = useParams();
  const webpageId = sessionStorage.getItem("webpageId");
  const location = useLocation();

  const nav = useNavigate();
  const { data: userRole, isSuccess: userrolesuccess } = useUserRole(
    Number(projectId),
    localStorage.getItem("emailId")
  );

  if (userrolesuccess) {
    localStorage.setItem("ProjectlevelRole", userRole as unknown as string);
  }

  const {
    data: projectData,
    isError,
    error,
    isSuccess,
    refetch: projectDataRefetch
  } = useProjectData(projectId);

  if (isError) {
    console.log(error);
    return <p>Error message</p>;
  }

  function goBack() {
    if (webpageId === "-1") {
      nav(`/dashboard`, {
        replace: true,
        relative: "path"
      });
    } else {
      nav(`/dashboard/carbon/${projectId ?? ""}/client/${webpageId ?? ""}`, {
        replace: true,
        relative: "path"
      });
    }
  }

  const EditButtonComponent = ({ projectId }: { projectId: string | undefined }) => {
    const nav = useNavigate();

    return (
      <CustomButton
        variant="outlined"
        className="settings-button"
        onClick={() =>
          nav(`/project-settings/editProject/${projectId ?? ""}`, {
            replace: false,
            relative: "path"
          })
        }
        startIcon={
          <EditProject fontSize="small" className="settings-icon" some="EditButtonComponent" />
        }
      >
        Edit Projects
      </CustomButton>
    );
  };
  return (
    <>
      {isFetching ? <CustomLoader /> : null}
      <div className={isFetching ? "disabled-content" : ""}>
        {isSuccess && (
          <Box sx={{ flexGrow: 1 }} style={{ margin: "16px" }}>
            <div className="TopDiv">
              <Typography className="breadcrumbs-typography">
                <span
                  className="breadcrumbs-link"
                  onClick={() =>
                    nav(`../../../../`, {
                      replace: false,
                      relative: "path"
                    })
                  }
                >
                  Dashboard
                </span>
                {"  "}
                <RightArrow />{" "}
                <span className="breadcrumbs-link" onClick={goBack}>
                  Project <Divider className="divider-vertical" orientation="vertical" />{" "}
                  {projectData?.name}
                </span>{" "}
                <RightArrow /> Settings
              </Typography>
              <div className="pg-header">
                <div>
                  {/* <KeyboardBackspaceIcon className="backbtn" onClick={goBack} /> */}
                  <Typography className="mainHeading">Project Details</Typography>
                </div>
                <div className="editbtn">
                  {userRole !== "Collaborator" && <EditButtonComponent projectId={projectId} />}
                </div>
              </div>
              <Grid container spacing={2} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <ProjectDetails
                  projectData={projectData!}
                  projectDataRefetch={projectDataRefetch}
                />
                <Pages projectData={projectData!} isStatic={location.state} />
                <Collaborators projectData={projectData!} />
              </Grid>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../globals/client/axios";

import { GET_CLIENT_SUMMARY } from "./paths";
// import { GET_CLIENT_SUMMARY } from "./paths";

const getOverviewData = async () => {
  return await axiosClient
    .get(`${String(GET_CLIENT_SUMMARY)}${localStorage?.getItem("id") ?? ""}`)
    .then((res) => res.data);
};

export const useOverviewData = () => {
  return useQuery({
    queryKey: ["Overview"],
    queryFn: () => getOverviewData()
  });
};

export interface Overview {
  cc: number;
  score: number;
  ce: number;
  trees: number;
  ec: number;
  HighestEmittedValue: number;
  HighestEmittingProject: string;
  LeastEmittedValue: number;
  LeastEmittingProject: string;
}

export type onSuccess = (data: Overview) => void;

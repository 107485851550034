export default function QualityScoreTick() {
  return (
    <svg width="48" height="48" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.502 0.00341952C19.4867 -0.0378025 20.2636 0.297862 21.0465 0.783693C22.0402 1.39908 23.1604 2.61807 24.5426 3.38951C26.4848 4.476 30.0862 2.97729 31.9291 5.65672C33.0041 7.21726 33.0553 8.44214 33.1336 9.6523C33.2209 10.9567 33.4528 12.158 34.8199 13.9247C37.0813 16.8485 37.5511 18.7947 36.3857 20.8235C35.5908 22.2073 33.9195 22.9758 33.5311 23.8503C32.709 25.7141 33.6184 27.1186 32.4922 29.2916C31.7093 30.7992 30.5018 31.7914 28.8938 32.2979C27.5387 32.7248 26.1746 32.1065 25.0906 32.5541C23.1815 33.3373 21.7752 35.1599 20.2546 35.6221C19.6674 35.7988 19.0832 35.8871 18.502 35.8842C17.9178 35.8871 17.3337 35.8018 16.7495 35.6221C15.2318 35.1599 13.8226 33.3402 11.9134 32.5541C10.8264 32.1065 9.4653 32.7248 8.11025 32.2979C6.50225 31.7914 5.29474 30.7992 4.51182 29.2916C3.38261 27.1186 4.29501 25.7141 3.46993 23.8503C3.08148 22.9758 1.41025 22.2073 0.615283 20.8235C-0.550065 18.7947 -0.077301 16.8485 2.18112 13.9247C3.54823 12.158 3.78009 10.9567 3.86742 9.6523C3.94872 8.44214 3.9969 7.21726 5.07191 5.65672C6.91479 2.97729 10.5162 4.476 12.4585 3.38951C13.8406 2.61807 14.9578 1.39908 15.9545 0.783693C16.7404 0.297862 17.5173 -0.0378025 18.502 0.00341952ZM15.3071 15.8709L17.6739 18.0734L21.7481 14.0248C22.1516 13.6243 22.4046 13.3034 22.9014 13.8039L24.5155 15.4204C25.0454 15.9328 25.0183 16.2331 24.5185 16.7101L18.5954 22.3958C17.5414 23.4057 17.7251 23.4676 16.6561 22.4311L12.591 18.4767C12.3681 18.2412 12.3922 18.0027 12.6361 17.7671L14.5091 15.868C14.7892 15.5794 15.015 15.5971 15.3071 15.8709ZM18.4478 8.11826C24.1541 8.11826 28.7763 12.6409 28.7763 18.2176C28.7763 23.7973 24.1511 28.317 18.4478 28.317C12.7415 28.317 8.11928 23.7944 8.11928 18.2176C8.11928 12.638 12.7445 8.11826 18.4478 8.11826Z"
        fill="#6CB2C2"
      />
    </svg>
  );
}
